import React, { useState, useEffect } from "react";
import PHASE_BLUEPRINT from "./cycle-phase-blueprint";
import { orderBy } from 'lodash';
import { If } from "../../../../components/ui-utility/Conditional";

import useApprovalAPI from "../approval-hooks";


import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import '../approval.css';
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage, localizeMessage} from "../../../../intl/components/LocalizedMessage";
import {useIntl} from "react-intl";


const selectFirstPhaseAsActive = (phases) => {
  const [firstPhase = {}] = phases;
  const phaseBlueprint = PHASE_BLUEPRINT[firstPhase.phase];

  return phaseBlueprint?.tabKey;
}

function CyclePhases({phases, user, cycleId}) {
  const intl = useIntl();
  const activeTabOnInitialization = selectFirstPhaseAsActive(phases);

  const [activeTab, setActiveTab] = useState(activeTabOnInitialization);

  // FIXME: TB-160
  const [phaseSteps, setPhaseSteps] = useState<any>({projectleadPhase: [], approverPhase: []});

  const {fetchApproverSteps} = useApprovalAPI();

  const constructPhases = phase => {
    const phaseBlueprint = PHASE_BLUEPRINT[phase.phase];

    const Component = phaseBlueprint.component;
    const phaseStepsForPhase = phaseBlueprint.extractSteps(phaseSteps);
    const title = phaseBlueprint.getTitle(phaseSteps);
    const localizedTitle = localizeMessage(title, intl);

    return (
      <Tab eventKey={phaseBlueprint.tabKey} title={localizedTitle} key={phase.id}>
        <div className="phase-tab">
          <Component phaseSteps={phaseStepsForPhase}/>
        </div>
      </Tab>
    );
  };

  const sortedPhases = orderBy(phases, phase => phase.order);

  useEffect(() => {
    const fetchPhaseSteps = async () => {
      const steps = await fetchApproverSteps(cycleId, user.id);

      steps && setPhaseSteps(steps);
    };

    fetchPhaseSteps();
  }, [fetchApproverSteps, cycleId, user.id]);

  return (
    <div className="cycle-phases">
      <If condition={phases.length === 0}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.noApprovalPhasesMessage}}/>
      </If>
      <If condition={phases.length > 0}>
        <Tabs id="cycle-phases" activeKey={activeTab} onSelect={setActiveTab}>
          {sortedPhases.map(constructPhases)}
        </Tabs>
      </If>
    </div>
  );
}


export default CyclePhases;
