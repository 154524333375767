import types from "./types";

const initialState = {
  progress: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_PROGRESS_COMPLETED:
      const {progress} = action.payload;

      return {
        ...state,
        progress,
      };

    default:
      return state;
  }
};

export default reducer;
