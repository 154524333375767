import {useMemo, useCallback, useState, useEffect} from "react";

import {useSelector} from "react-redux";

import {selectDateRangesUserHasCyclesActive} from "../../store/ducks/cycle/cycle-selectors";

import {ApiResponse, sendRequestSimple} from "../../api/api";
import {
  emptyRecordsData,
  processGetOverallRecordListing,
  RecordsApiResponse,
} from "../../api/report/report-api-implementation";
import {getOverallRecordGrouping, getOverallRecordListing} from "../../api/report/report-api";
import {selectIsMemberOfProject, selectTimesheetUser} from "../../store/ducks/mobile/timesheet/selectors";
import {isWithinInterval, today, tryParseISO, subDays, toISO} from "../../util/dates";
import {Project} from "../../components/project/ProjectEntity";
import {Task} from "../../components/task/TaskEntity";
import RecordEntity from "../../api/report/RecordEntity";

const useRecordAPI = () => {
  const fetchRecords = async (params): Promise<ApiResponse<RecordsApiResponse>> => {
    return await sendRequestSimple<RecordsApiResponse>(getOverallRecordListing({params}));
  };

  const fetchGroupedRecords = async (params): Promise<ApiResponse<RecordsApiResponse>> => {
    return await sendRequestSimple(getOverallRecordGrouping({params}))
  }

  const fetchOverallRecords = useCallback(async params => {
    const {response}: ApiResponse<RecordsApiResponse> = await fetchRecords(params);

    return processGetOverallRecordListing(response);
  }, []);

  const fetchRecordsForApprovalListing = useCallback(async (startDate, endDate, userId): Promise<RecordsApiResponse> => {
    const params = {
      "record.date__gte": startDate,
      "record.date__lte": endDate,
      "record.user": userId,
      "page_size": 0,
      "ordering": 'date'
    };

    const {response}: ApiResponse<RecordsApiResponse> = await fetchRecords(params);

    return response || emptyRecordsData;
  }, []);

  const fetchGroupedRecordsForTable = useCallback(async (extraParams): Promise<RecordsApiResponse> => {
    const toDate = today();
    const fromDate = subDays(toDate, 29);

    const params = {
      "record.date__gte": toISO(fromDate),
      "record.date__lte": toISO(toDate),
      "page_size": 0,
      "timeslot": "day",
      ...extraParams
    };

    const {response}: ApiResponse<RecordsApiResponse> = await fetchGroupedRecords(params);

    return response || emptyRecordsData;
  }, []);

  const fetchTotalRecordsForTable = useCallback(async (extraParams) => {
    const params = {
      "page_size": 0,
      ...extraParams
    };

    const {response}: ApiResponse<RecordsApiResponse> = await fetchGroupedRecords(params);

    return response || emptyRecordsData;
  }, []);

  return {
    fetchOverallRecords,
    fetchRecordsForApprovalListing,

    fetchGroupedRecordsForTable,
    fetchTotalRecordsForTable
  }
};

/*
  User might have multiple cycles in the past. Check all of them and see if the intersects with them
 */
export const useCycleDateCollisionChecker = date => {
  const timesheetUser = useSelector(selectTimesheetUser);
  const cycleDateRanges = useSelector(selectDateRangesUserHasCyclesActive(timesheetUser.id));

  const cycleLockedResolver = useCallback((userCycles) => {

    if (!userCycles)
      return false;

    return userCycles.some(singleUserCycle => {
      const [startDate, endDate] = singleUserCycle;

      return isWithinInterval(tryParseISO(date), {
        start: tryParseISO(startDate),
        end: tryParseISO(endDate),
      });
    });

  }, [date]);

  return useMemo(() => cycleLockedResolver(cycleDateRanges), [cycleLockedResolver, cycleDateRanges]);
}

export const useActualTableDataFetching = params => {
  const [summedRecords, setSumRecords] = useState({});
  const [allRecords, setAllRecords] = useState<RecordsApiResponse>(emptyRecordsData);

  const {fetchGroupedRecordsForTable, fetchTotalRecordsForTable} = useRecordAPI();

  useEffect(() => {
    const fetchOverallRecords = async () => {
      const [rawRecords, totalData = {}] = await Promise.all(
        [fetchGroupedRecordsForTable(params), fetchTotalRecordsForTable(params)]);

      setAllRecords(rawRecords)
      setSumRecords(totalData);
    }

    fetchOverallRecords();
  }, [params, fetchGroupedRecordsForTable, fetchTotalRecordsForTable]);

  return {
    allRecords,
    summedRecords
  }
}

// TODO: Instead of bringing the logic to frontend. Backend should directly return the list of tasks user is allowed to timetrack on.
export const useTimetrackingAllowed = (task: Task, project: Project) => {
  const timeTrackingAllowed = RecordEntity.timeTrackingAllowedByTaskAndProject(task, project);
  const isProjectMember = useSelector(selectIsMemberOfProject(project.id));

  return timeTrackingAllowed && isProjectMember;
}

export default useRecordAPI;