import types from "./types";

const fetchProjectProgressCompleted = progress => ({
  type: types.FETCH_PROJECT_PROGRESS_COMPLETED,
  payload: {
    progress,
  }
});

export {
  fetchProjectProgressCompleted,
};
