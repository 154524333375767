import timesheetTypes from "./types";
import SaveRecordStateManager from "./SaveRecordStateManager";
import { isWithinInterval, today } from "../../../../util/dates";
import { toString, omit, orderBy, uniq } from "lodash";
import { parseISO } from '../../../../util/dates';
import { removeObjectFromResult } from "../../../helpers";

const initialState = {
  date: today(),
  projects: {},
  project_ids: [],
  tasks: {},
  task_ids: [],
  records: {},
  project_tasks: [],
  pinned_tasks: {},
  visibleTasksOnTimesheet: [],
  userDailyStatus: {},

  projects_as_member: [],
  recordIdsBeingEdited: [],
};

function dailyTimesheetReducer(state = initialState, action) {
  const {payload} = action;

  switch (action.type) {

    case timesheetTypes.START_EDITING: {
      const recordIdsBeingEdited = [...state.recordIdsBeingEdited, payload.id];

      return {
        ...state,
        recordIdsBeingEdited,
      };
    }

    case timesheetTypes.CANCEL_EDITING:
      const recordIdsBeingEdited = removeObjectFromResult(state.recordIdsBeingEdited, payload);

      return {
        ...state,
        recordIdsBeingEdited,
      };
    case timesheetTypes.SET_TIMESHEET_DATE_SUCCESSFUL:
      return {
        ...state,
        date: payload,
      };

    case timesheetTypes.FETCH_DAILY_TIMESHEET_SUCCESS:
      const newState = {...state, date: payload.startDate, ...payload};
      const visibleTasksOnTimesheet = extractVisibleTasksOnTimesheet(newState);

      return {...newState, visibleTasksOnTimesheet};

    case timesheetTypes.SAVE_RECORD_SUCCESS:
      return {
        ...state,
        records: SaveRecordStateManager.updateRecord(state, payload),
      };

    case timesheetTypes.CREATE_RECORD:
      return {
        ...state,
        records: {...state.records, [payload.id]: payload}
      };

    case timesheetTypes.DELETE_RECORD_SUCCESS: {
      const newState = {...state, records: SaveRecordStateManager.deleteRecord(state, payload.recordId)};
      const visibleTasksOnTimesheet = extractVisibleTasksOnTimesheet(newState);

      return {
        ...newState,
        visibleTasksOnTimesheet
      };
    }

    case timesheetTypes.FETCH_TIMESHEET_STATUS_SUCCESS:
      const {userId, values, startDate, endDate} = action.payload;

      const updatedUserStatus = reduceUpdatedUserStatus(state, userId, startDate, endDate, values);

      return {
        ...state,
        userDailyStatus: {
          ...state.userDailyStatus,
          [userId]: updatedUserStatus,
        }
      };

    case timesheetTypes.REMOVE_PINNED_TASK: {
      const newPinnedState = omit(state.pinned_tasks, action.taskId);
      const newState = {...state, pinned_tasks: {...newPinnedState}};

      const visibleTasksOnTimesheet = extractVisibleTasksOnTimesheet(newState);

      return {...newState, visibleTasksOnTimesheet};
    }

    case timesheetTypes.ADD_PINNED_TASK: {
      const newState = mergeNewPinnedStateIntoState(state, {[action.data.task]: action.data});
      const visibleTasksOnTimesheet = extractVisibleTasksOnTimesheet(newState);

      return {...newState, visibleTasksOnTimesheet};
    }
    case timesheetTypes.UPDATE_PINNED_TASKS: {
      const newState = mergeNewPinnedStateIntoState(state, action.data);

      const visibleTasksOnTimesheet = extractVisibleTasksOnTimesheet(newState);

      return {...newState, visibleTasksOnTimesheet};
    }
    default:
      return state;
  }
}


function reduceUpdatedUserStatus(state, userId, startDate, endDate, values) {
  const existingUserStatusDates = Object.keys(state.userDailyStatus[userId] || {});

  const isNotInRange = dateKey => !isWithinInterval(parseISO(dateKey), {start: startDate, end: endDate});

  // Reduce userStatus to a map where the old values between given range has been cleared.
  const statusClearedWithRange = existingUserStatusDates.filter(isNotInRange).reduce((acc, dateKey) => {
    acc[dateKey] = state.userDailyStatus[userId][dateKey];
    return acc;
  }, {});

  return {
    ...statusClearedWithRange,
    ...values,
  };
}

function mergeNewPinnedStateIntoState(state, newPinState) {
  const pinned_tasks = {...state.pinned_tasks, ...newPinState};

  return {...state, pinned_tasks};
}

// Its either here, in actions or in selectors.
function extractVisibleTasksOnTimesheet(state) {
  const pinnedTaskIds = Object.keys(state.pinned_tasks).map(toString);

  const tasksWithRecords = Object.keys(state.records).map(recordId => toString(state.records[recordId].task));

  const uniqueTaskIds = uniq([...tasksWithRecords, ...pinnedTaskIds]);

  return orderBy(uniqueTaskIds, (taskId) => {
    const taskInstance = state.tasks[taskId];
    const projectInstance = state.projects[taskInstance?.project];

    return projectInstance?.name;
  });
}


export default dailyTimesheetReducer;
