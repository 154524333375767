import React from "react";
import PropTypes from 'prop-types';

import {useDispatch, useSelector} from "react-redux";

import filterSelectors from "../../../store/ducks/filter/filter-selectors";

import {changeFilterValueAction} from "../../../store/ducks/filter/filter-actions";
import {useInputDebounce} from "../../../util/hooks";

import InputFilterField from "../entities/InputFilterField";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {useSimpleLocalizedMessage} from "../../../intl/use-localized-message";

function InputFilter({filter}) {
  const dispatch = useDispatch();
  const filterKey = filter.getFilterKey();

  const filterValue = useSelector(filterSelectors.selectFilterForField(filterKey)) || "";

  const handleChange = value => dispatch(changeFilterValueAction(filterKey, value));

  const [value, setValue] = useInputDebounce(filterValue, handleChange);

  const localizedPlaceholder = useSimpleLocalizedMessage(TRANSLATION_KEYS.search.searchText);

  return (
    <div>
      <input type="text" onChange={setValue} className="form-control"
             placeholder={localizedPlaceholder} value={value}/>
    </div>
  )
}

InputFilter.propTypes = {
  filter: PropTypes.instanceOf(InputFilterField).isRequired,
};

export default InputFilter;