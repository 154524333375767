const paginationSelectors = Object.freeze({
  selectPagination: state => ({...state.pagination}),
  selectPaginationForPage: page => state => {
    const pagination = paginationSelectors.selectPagination(state);

    return pagination[page] || {};
  },
  selectCurrentPageForPage: page => state => {
    const paginationForPage = paginationSelectors.selectPaginationForPage(page)(state);

    return paginationForPage.current || 1;
  },
});

export default paginationSelectors;
