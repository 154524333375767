import React from 'react';
import { Time } from "../util/Time";
import { Controller } from "react-hook-form";
import { validTimeValidator } from "./validators";

const FormTimeInput = ({name, control, setValue, ...props}) => {
  const onBlur = e => {
    const minutesCandidate = Time.FromUserInput(e.target.value)?.minutes;

    if (minutesCandidate !== undefined) {
      setValue(name, Time.FromMinutes(minutesCandidate).toColonSeparated(true));
    }
  };

  const component = <input type="text" className="form-control" onBlur={onBlur} onFocus={e => e.target.select()} />;

  return (
    <Controller as={component}
                control={control}
                name={name}
                {...props}
                rules={{validate: validTimeValidator}}/>
  )
};

export default FormTimeInput;
