import { createGetRequest } from "../../api";
import { joinRouteParts } from "../../utils";

const baseUrl = 'cycle-phase';

const getCyclePhases = cycleId => {
  const url = joinRouteParts(baseUrl, cycleId, 'for_cycle');

  return createGetRequest({url})
};

export {
  getCyclePhases,
};