import React from 'react';
import { BriefcaseIcon, FileChartLineIcon, StopWatchIcon, UsersIcon } from "../buttons";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {CustomerIcon} from "../customers/CustomerName";

const timesheetLink = <LocalizedMessage text={{ id: TRANSLATION_KEYS.sidebar.timesheet }} />;
const projectsLink = <LocalizedMessage text={{ id: TRANSLATION_KEYS.sidebar.projects }} />;
const peopleLink = <LocalizedMessage text={{ id: TRANSLATION_KEYS.sidebar.people }} />;
const reportsLink = <LocalizedMessage text={{ id: TRANSLATION_KEYS.sidebar.reports }} />;
const customersLink = <LocalizedMessage text={{ id: TRANSLATION_KEYS.customers.pageHeader }} />;

const routes = [
  {key: 'timesheet', name: timesheetLink, path: '/timesheet', icon: StopWatchIcon},
  {key: 'projects', name: projectsLink, path: '/projects', icon: BriefcaseIcon},
  {key: 'people', name: peopleLink, path: '/people', icon: UsersIcon},
  {key: 'customers', name: customersLink, path: '/customers', icon: CustomerIcon},
  {key: 'reports', name: reportsLink, path: '/reports/overall', icon: FileChartLineIcon},
];

export default routes;