import React from 'react';
import styled from "styled-components";
import Delay from "../loaders/Delay";
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from '../loaders/LoadingIndicator';

const PlaceholderText = styled.div`
  max-width: 300px;
  text-align: center;
  font-weight: 700;
  color: #9FB3C8;
`;

const EmptyStatePlaceholder = ({ iconName, isLoading = false, delay = 200, children }) => {
  const classes = classNames(
    'fa-layers',
    'fa-fw',
    'fa-7x'
  );

  const iconColor = '#D9E2EC';

  const icon = (
    <span className={classes} style={{ color: iconColor }}>
      <FontAwesomeIcon icon="circle" />
      <FontAwesomeIcon icon={['fas', iconName]} transform="shrink-9" inverse />
    </span>
  );

  return (
    <Delay delay={delay}>
      {isLoading ? <LoadingIndicator /> :
        <div className="d-flex align-items-center flex-column p-4">
          <div className="pb-3">
            {icon}
          </div>

          <PlaceholderText>
            {children}
          </PlaceholderText>
        </div>
      }
    </Delay>
  );
};

export default EmptyStatePlaceholder;
