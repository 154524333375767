import { TableColumnConfiguration } from "../../../components/table/types/TableColumnConfiguration";
import { TableColumnContentAlignment } from "../../../components/table/types/TableColumnContentAlignment";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export const getPeopleListingColumnConfiguration: TableColumnConfiguration[] = [{
    key: 'person',
    label: {id: TRANSLATION_KEYS.people.tableHeaders.name},
    isSortable: true,
    sortBy: 'user__last_name'
},
{
    key: 'latestRecord',
    label: {id: TRANSLATION_KEYS.people.tableHeaders.latestRecord},
}, {
    key: 'lastMonth',
    label: {id: TRANSLATION_KEYS.people.tableHeaders.last30Days},
}, {
    key: 'balance',
    label: {id: TRANSLATION_KEYS.people.tableHeaders.balanceToday},
    columnContentAlignment: TableColumnContentAlignment.Right
}]

export const peopleListingEmptyResultParagraphs = [
  {id: TRANSLATION_KEYS.people.noResultsFoundTitle},
  {id: TRANSLATION_KEYS.people.noResultsFoundDescription},
]