import {TableColumnConfiguration} from "../../../components/table/types/TableColumnConfiguration";
import {TableColumnContentAlignment} from "../../../components/table/types/TableColumnContentAlignment";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export const getPersonRecordsByProjectTableColumnConfiguration: TableColumnConfiguration[] = [{
  key: 'project',
  label: {id: TRANSLATION_KEYS.commonProperties.project},
},
  {
    key: 'lastMonth',
    label: {id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabTableHeaders.timeForLast30Days},
    columnContentAlignment: TableColumnContentAlignment.Center
  }, {
    key: 'totalTime',
    label: {id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabTableHeaders.totalTime},
    columnContentAlignment: TableColumnContentAlignment.Right
  }]