import React, {useEffect, useState} from 'react';
import {useCustomerAPI} from "../../api/customers/customer-hooks";
import {useDispatch, useSelector} from 'react-redux';
import {useFiltersAndPagination} from "../../components/filter/filter-hooks";
import {useColumnOrdering} from "../../components/column-sorter/use-column-ordering";
import {PlainSectionBlock, Section} from "../../components/layout/LayoutHelpers";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {CustomerListing} from "./CustomerListing";
import {changePageAction} from "../../store/ducks/pagination/pagination-actions";
import {EditCustomerModal} from "./add-customers/EditCustomerModal";

import PageHeader from "../../components/text/PageHeader";
import ModalComponent from "../../components/modal/ModalComponent";

import paginationSelectors from "../../store/ducks/pagination/pagination-selectors";
import modalDefinitions from "../../components/modal/modal-definitions";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {fetchCustomersAction} from "../../store/ducks/customer/customer-actions";
import {paginationKeys} from "../../store/ducks/pagination/pagination-keys";

import {RouteComponentProps} from "@reach/router";
import {selectAllCustomers} from "../../store/ducks/customer/customer-selectors";
import {CustomerPageFilterBlock} from "../../components/filter/configuration/filter-blocks-for-page/CustomerPageFilterBlock";
import {CustomersAPIResponseParser} from "../../api/customers/customers-api-response";

export const Customers: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const [customerExtraColumns, setCustomerExtraColumns] = useState({});

  const {fetchCustomerDetails} = useCustomerAPI();

  const {page, filters} = useFiltersAndPagination(CustomerPageFilterBlock.blockKey, paginationKeys.CUSTOMER_LISTING);
  const [ordering, setOrdering] = useColumnOrdering(paginationKeys.CUSTOMER_LISTING);

  useEffect(() => {
    const params = {ordering, page, ...filters};

    const fetchExtraColumnsForCustomer = async () => {
      const response = await fetchCustomerDetails(params);

      setCustomerExtraColumns(response);
    }

    fetchExtraColumnsForCustomer();

    dispatch(fetchCustomersAction(params));
  }, [dispatch, ordering, page, filters, fetchCustomerDetails]);

  const customers = useSelector(selectAllCustomers);
  const pagination = useSelector(paginationSelectors.selectPaginationForPage(paginationKeys.CUSTOMER_LISTING));

  const handlePageChange = (newPage) => dispatch(changePageAction(paginationKeys.CUSTOMER_LISTING, newPage));

  const customersWithExtraColumns = CustomersAPIResponseParser.mergeExtraColumnsIntoCustomers(customers, customerExtraColumns);

  return (
    <>
      <Section>
        <PlainSectionBlock>
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.customers.pageHeader}}/>
          </PageHeader>
        </PlainSectionBlock>
      </Section>
      <CustomerListing customers={customersWithExtraColumns}
                       ordering={ordering}
                       pagination={pagination}
                       onPageChange={handlePageChange}
                       onOrderingChange={setOrdering}/>
      <ModalComponent Component={EditCustomerModal} modalKey={modalDefinitions.EDIT_CUSTOMER}/>
    </>
  );
}