import React from 'react';
import {ScheduleIndicatorIcon} from "./icons/ProjectInfoIcons";
import {CustomerNameSelector} from "../../components/customers/CustomerName";
import ProjectSchedule from "../../components/project/ProjectSchedule";
import {If} from "../../components/ui-utility/Conditional";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

const ProjectDetailsCustomer = ({project}) => {
  const hasCustomer = project.customer;

  return (
    <div className="d-flex">
      <If condition={hasCustomer}>
        <CustomerNameSelector customerId={project.customer}/>
      </If>
      <If condition={!hasCustomer}>
        <span style={{color: '#486581'}}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.customerNotSetMessage}} />
        </span>
      </If>
    </div>
  );
};

const ProjectDetailsSchedule = ({project}) => {
  return (
    <div>
      <ScheduleIndicatorIcon/>
      <ProjectSchedule projectId={project.id}/>
    </div>
  );
};

export {
  ProjectDetailsCustomer,
  ProjectDetailsSchedule,
}