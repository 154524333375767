import React from 'react';
import styled from "styled-components";

import {ButtonGutter} from "../../screens/people/user-details/UserDetails";

const Heading: React.FunctionComponent = ({children}) => (
  <h3>{children}</h3>
);

const Action: React.FunctionComponent = ({children}) => (
  <ButtonGutter>{children}</ButtonGutter>
);

interface DetailsHeaderSubComponents {
  Action: typeof Action,
  Heading: typeof Heading
}

const DetailsPageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CenterizedInlineSpan = styled.span`
  width: auto;
  display: inline-block;
  vertical-align: middle;
`

export const DetailsPageHeader: React.FunctionComponent & DetailsHeaderSubComponents = ({children}) => {
  return (
    <CenterizedInlineSpan>
      <DetailsPageHeaderWrapper>{children}</DetailsPageHeaderWrapper>
    </CenterizedInlineSpan>

  );
};

DetailsPageHeader.Heading = Heading;
DetailsPageHeader.Action = Action;


