import React from "react";
import {If} from "../../../components/ui-utility/Conditional";


export const getTaxRateForCountry = (country) => {
  // On later iterations, fetch this information from server / stripe
  const mapOfTaxRatesPerCountry = {
    "FI": 24,
    "AX": 24
  };

  return mapOfTaxRatesPerCountry[country];
}

export const TaxRateResolver = ({country}) => {
  const taxRateForCountry = getTaxRateForCountry(country);

  return (
    <If condition={taxRateForCountry}>
      Additional {taxRateForCountry}% will be included in the bill as a form of VAT
    </If>
  )
}
