import {TRANSLATION_KEYS} from "../../../intl/intl";

const LABELS = {
  'record.is_billable': {id: TRANSLATION_KEYS.actualsReport.filters.billable},
  'record.customer': {id: TRANSLATION_KEYS.commonProperties.customer},
  'record.user': {id: TRANSLATION_KEYS.actualsReport.filters.people},
  'record.date': {id: TRANSLATION_KEYS.actualsReport.filters.date},
  'project.id': {id: TRANSLATION_KEYS.commonProperties.project},
  'project.is_active': {id: TRANSLATION_KEYS.projects.filters.projectIsActiveFilterLabel},
  'project.q': {id: TRANSLATION_KEYS.projects.filters.searchTextFilterLabel},

  'customer.id': {id: TRANSLATION_KEYS.commonProperties.customer},

  'user.is_active': {id: TRANSLATION_KEYS.people.searchFilters.onlyActiveUsers},
  'user.q': {id: TRANSLATION_KEYS.projects.filters.searchTextFilterLabel},
  'customer.q': {id: TRANSLATION_KEYS.projects.filters.searchTextFilterLabel}
};

const ACTIVE_TEXTS = {
  'record.is_billable': {id: TRANSLATION_KEYS.actualsReport.filters.onlyBillableIndicatorMessage},
  'record.user': {id: TRANSLATION_KEYS.actualsReport.filters.selectedUserIndicatorMessage},
  'record.date': {id: TRANSLATION_KEYS.actualsReport.filters.selectedDatesIndicatorMessage},
  'record.project.customer': {id: TRANSLATION_KEYS.actualsReport.filters.selectedCustomerIndicatorMessage},

  'project.is_active': {id: TRANSLATION_KEYS.projects.filters.isActiveIndicatorMessage},
  'project.id': {id: TRANSLATION_KEYS.actualsReport.filters.selectedProjectIndicatorMessage},
  'project.q': {id: TRANSLATION_KEYS.actualsReport.filters.selectedTextIndicatorMessage},
  'customer.q': {id: TRANSLATION_KEYS.actualsReport.filters.selectedTextIndicatorMessage},

  'customer.id': {id: TRANSLATION_KEYS.actualsReport.filters.selectedCustomerIndicatorMessage},

  'user.is_active': {id: TRANSLATION_KEYS.people.searchFilters.onlyActiveUsers},
  'user.q': {id: TRANSLATION_KEYS.people.searchFilters.matchingTheSearchText},
};

export {LABELS, ACTIVE_TEXTS};