import { combineReducers } from "redux";

import filterSagaTypes from "./filter-types";
import filterDefaults from "../../../components/filter/configuration/default-values-definitions";

function filterFieldsReducer(state = filterDefaults, action) {
  switch (action.type) {

    case filterSagaTypes.CHANGE_FILTER_VALUE:
      return {...state, [action.filterKey]: action.filterValue};

    default:
      return state;
  }
}

// Declarations not needed, this is merely to illustrate the configuration
const initialState = {
  entities: {
    "user": {},
    "project": {}
  },
  result: {
    "user": [],
    "project": []
  }
};

function filterDropdownEntityReducer(state = initialState, action) {
  switch (action.type) {

    case filterSagaTypes.RECEIVE_FILTER_VALUES:
      const {result, entities} = action.response;

      return {
        entities: {...state.entities, ...entities},
        result: {...state.result, [action.entityKey]: result}
      };

    default:
      return state;
  }
}


const filterReducer = combineReducers({fields: filterFieldsReducer, dropdown: filterDropdownEntityReducer});

export {
  filterReducer as default,
  initialState
};