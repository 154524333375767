import FilterFactory from "../../entities/FilterFactory";
import FilterBlockEntity from "../../entities/FilterBlockEntity";
import FilterRegistry from "../filter-registry";

class RecordPageFilterBlock extends FilterBlockEntity {
  static blockKey = 'recordPage';

  createFilters() {
    return [
      FilterFactory.createBooleanFilter('record.is_billable'),
      FilterFactory.createDateRangeFilter('record.date'),
      FilterFactory.createApiSelectFilter('record.user', 'users', false),
      FilterFactory.createApiSelectFilter('project.id', 'projects', false),
      FilterFactory.createApiSelectFilter('customer.id', 'customers', false),
    ];
  };

}

FilterRegistry.registerFilterBlock(RecordPageFilterBlock.blockKey, new RecordPageFilterBlock());

export default RecordPageFilterBlock;
