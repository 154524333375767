import {createGetRequest} from "../../api";
import {joinRouteParts} from "../../utils";

const baseUrl = 'cycle';

const getCycles = params => createGetRequest({url: baseUrl, params});

const getUnsubmittedCycles = userId => {
  const url = joinRouteParts(baseUrl, userId, 'unsubmitted_cycles');

  return createGetRequest({url})
}

const getCycleHistoryForApprover = approverId => {
  const url = joinRouteParts(baseUrl, approverId, 'cycle_history_for_approver');

  return createGetRequest({url});
};

const getApproverSteps = (cycleId, params) => {
  const url = joinRouteParts(baseUrl, cycleId, 'approver_steps');

  return createGetRequest({url, params});
}

export {
  getCycleHistoryForApprover,
  getCycles,
  getApproverSteps,
  getUnsubmittedCycles
};