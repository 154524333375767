import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { TimesheetSection } from "../Login";
import { Link } from "@reach/router";

import { CenteredSection } from "../../../components/layout/LayoutHelpers";
import { AppLogo } from "../../../components/ui-utility/AppLogo";
import { usePasswordResetApi } from "./use-password-reset";
import { If } from "../../../components/ui-utility/Conditional";
import { changeTenantAction } from "../../../store/ducks/auth/actions";

import PasswordChangeForm from "./PasswordChangeForm";
import Error from "../../../components/notication/Error";
import useUserAPI from "../../../components/people/user-hooks";
import { extractUrlParameter } from "../../timesheet/daily/timesheet-utility";
import { LocalizedMessage } from "../../../intl/components/LocalizedMessage";
import { TRANSLATION_KEYS } from "../../../intl/intl";
import { NON_BREAKING_SPACE } from "../../../util/character-entities";

function PasswordReset({uib, token, location}) {
  const dispatch = useDispatch();

  const invitationFrom = extractUrlParameter(location, 'invitation_from');
  // FIXME: TB-160
  const [errors, setErrors] = useState<any>({});

  const {authenticateUser} = useUserAPI();
  const {postReset} = usePasswordResetApi();

  const onSubmitForm = async (password, rePassword) => {
    setErrors({});

    const {response, error} = await postReset(uib, token, password, rePassword);

    if (error)
      setErrors(error);
    else {
      await authenticateUser(response.user, response.password);

      if (invitationFrom) {
        dispatch(changeTenantAction(invitationFrom));
      }
    }
  };

  return (
    <CenteredSection className="mt-4">
      <TimesheetSection>
        <h3 className="text-center mb-2">
          <AppLogo/>
        </h3>

        <If condition={errors.token || errors.uid}>
          <Error error={[
            <span><LocalizedMessage text={{id: TRANSLATION_KEYS.login.resetPassword.linkUsedErrorText}} />
            {NON_BREAKING_SPACE}
            <Link to={'/'}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.login.resetPassword.newPasswordRequestLinkLabel}} />
            </Link>.</span>]}/>
        </If>

        <PasswordChangeForm onSubmitForm={onSubmitForm} errors={errors}/>

      </TimesheetSection>
    </CenteredSection>
  )
}

export default PasswordReset;