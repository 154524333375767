import {Dispatch, useState} from 'react';

export function useLocalStorage(key: string, initialValue: string): [string, Dispatch<string>] {
  const [value, setValue] = useState(() => window.localStorage.getItem(key) || initialValue);

  const setItem = (newValue: string) => {
    setValue(newValue);

    window.localStorage.setItem(key, newValue);
  };

  return [value, setItem];
}

export function useBooleanLocalStorage(key: string, initialValue: boolean): [boolean, Dispatch<boolean>] {
  const [value, setValue] = useLocalStorage(key, initialValue.toString());

  const setItem = (newValue: boolean) => {
    const stringifiedValue = newValue.toString();

    setValue(stringifiedValue);
  };

  const boolifiedValue = (value === "true");

  return [boolifiedValue, setItem];
}
