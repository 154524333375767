import React, {useState} from "react";

import {useForm} from "react-hook-form";
import ActuallySelect from "./ActuallySelect";
import WithErrorInput from "../../screens/timesheet/components/WithErrorInput";

import {If} from "../ui-utility/Conditional";
import {ActuallyButton} from "../buttons";
import {sendRequestSimple} from "../../api/api";
import {RightAligned} from "../table/ActualDataTable";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";

function SelectOrAdd({api, onChange, name, entityId, tabIndex}) {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setErrors] = useState({});

  const {register, handleSubmit} = useForm();

  const handleSave = async data => {
    const {response, error} = await sendRequestSimple(api.post(data));

    if (response) {
      const [addedEntity] = extractEntityFromResponse(response);

      onChange(addedEntity);
      setIsAdding(false);
    } else {
      setErrors(error.data);
    }
  };

  const switchStance = isAdding => () => {
    setIsAdding(isAdding);
    setErrors({});
  };

  const extractEntityFromResponse = response => {
    if (Array.isArray(response))
      return response;
    return [response];
  };

  return (
    <div>
      <If condition={isAdding}>
        {/*} FIXME: TB-160 {*/}
        <WithErrorInput errors={(error as any).name}>
          <input type="text" className="form-control" ref={register} name="name" tabIndex={tabIndex}/>
        </WithErrorInput>
        <RightAligned>
          <ActuallyButton variant="link" onClick={switchStance(false)}>Cancel</ActuallyButton>
          <ActuallyButton type="submit" variant="primary" onClick={handleSubmit(handleSave)}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.addButton}}/>
          </ActuallyButton>
        </RightAligned>
      </If>
      <If condition={!isAdding}>
        <ActuallySelect api={api} onChange={onChange} entityId={entityId} name={name} tabIndex={tabIndex}/>
        <ActuallyButton variant="link" onClick={switchStance(true)}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.orAddNewButton}}/>
        </ActuallyButton>
      </If>
    </div>
  );
}

SelectOrAdd.defaultProps = {};

export default SelectOrAdd;