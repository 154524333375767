const FETCH = 'FETCH';
const FETCH_SUCCESSFUL = 'FETCH_SUCCESSFUL';

const SELECT_TASK = 'SELECT_TASK';
const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';

const ADD_COMPLETED = 'ADD_COMPLETED';
const EDIT_SUCCESSFUL = 'EDIT_SUCCESSFUL';
const DELETE_SUCCESSFUL = 'DELETE_SUCCESSFUL';


const fetchStarted = () => ({
  type: FETCH,
});

const fetchCompleted = tasks => ({
  type: FETCH_SUCCESSFUL,
  payload: tasks,
});

const selectTask = taskId => ({
  type: SELECT_TASK,
  payload: taskId,
});

const addCompleted = tasks => ({
  type: ADD_COMPLETED,
  payload: tasks,
});

const editCompleted = task => ({
  type: EDIT_SUCCESSFUL,
  payload: task,
});

const deleteCompleted = taskId => ({
  type: DELETE_SUCCESSFUL,
  payload: taskId,
});

const clearSelectedTask = () => ({
  type: CLEAR_SELECTED_TASK,
});

export {
  fetchStarted,
  fetchCompleted,

  selectTask,

  addCompleted,
  editCompleted,
  deleteCompleted,

  clearSelectedTask,

  FETCH,
  FETCH_SUCCESSFUL,

  SELECT_TASK,
  CLEAR_SELECTED_TASK,

  ADD_COMPLETED,
  EDIT_SUCCESSFUL,
  DELETE_SUCCESSFUL,
};
