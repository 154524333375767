import React from "react";
import PropTypes from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

import 'react-day-picker/lib/style.css';
import './Datepicker.css';
import {TRANSLATION_KEYS, getCurrentUserDateLocale} from "../../intl/intl";
import {useSimpleLocalizedMessage} from "../../intl/use-localized-message";
import { useIntl } from "react-intl";
import { DateUtils } from "react-day-picker";

function Datepicker({onDateChange, selected}) {
  const intl = useIntl();
  const dateLocale = getCurrentUserDateLocale();

  function parseDate(str, format) {
    const parsed = dateFnsParse(str, format, new Date(), { locale: dateLocale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }
  
  function formatDate(date, format) {
    return dateFnsFormat(date, format, { locale: dateLocale });
  }

  return <DayPickerInput onDayChange={onDateChange}
                         value={selected}
                         formatDate={formatDate}
                         parseDate={parseDate}
                         format='P'
                         dayPickerProps={{
                           locale: intl.locale
                         }}
                         placeholder={
                           useSimpleLocalizedMessage(TRANSLATION_KEYS.actualsReport.filters.selectDateDropdownPlaceholder)
                         }/>;
}

Datepicker.propTypes = {
  onDateChange: PropTypes.func,
};

Datepicker.defaultProps = {};


export default Datepicker;
