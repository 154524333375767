import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {useCustomerAPI} from "../../../api/customers/customer-hooks";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

import {TRANSLATION_KEYS} from "../../../intl/intl";
import {BreatherRow} from "../../authentication/signup/SignUpEmailPhase";
import {removeCustomerAction} from "../../../store/ducks/customer/customer-actions";

import {Customer} from "../../../api/customers/CustomerEntity";
import {FormElement, SimpleFormBuilder} from "../../../components/forms/SimpleFormBuilder";

import WithErrorInput from "../../timesheet/components/WithErrorInput";
import FormActionRow from "../../../forms/FormActionRow";
import Col from "react-bootstrap/Col";

interface EditCustomerFormProps {
  customer: Customer,
  onDone: () => void;
}

export const EditCustomerForm: React.FC<EditCustomerFormProps> = ({customer, onDone}) => {
  const dispatch = useDispatch();
  const {updateCustomer} = useCustomerAPI();

  const {register, formState, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: customer.name,
    }
  });

  const [errors, setErrors] = useState<Customer | null>();

  const {dirty, isValid} = formState;

  const handleSubmitProxy = async (data) => {
    const {response, error} = await updateCustomer(customer.id, data);

    response && onDone();
    error && setErrors(error);
  };

  const handleDelete = () => {
    dispatch(removeCustomerAction(Number(customer.id)));

    onDone();
  }

  const formElements: Array<FormElement> = [
    {
      label: <LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.name}}/>,
      input: <WithErrorInput errors={errors?.name}>
        <input className="form-control" name="name" type="text" ref={register} tabIndex={1}/>
      </WithErrorInput>
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleSubmitProxy)}>
      <SimpleFormBuilder formElements={formElements}/>

      <BreatherRow>
        <Col className="text-right">
          <FormActionRow showDeleteButton={true} tabIndexStart={3} handleDelete={handleDelete}
                         isValid={isValid} dirty={dirty} handleCancel={onDone}>
          </FormActionRow>
        </Col>
      </BreatherRow>
    </form>
  )
}
