import React from "react";

import {Location, RouteComponentProps} from "@reach/router";
import {useModalAsRoute} from "./modal-hooks";

interface ModalComponentProps {
  Component: any,
  modalKey: string
}

type ModalRendererProps = ModalComponentProps & RouteComponentProps;

const ModalComponent: React.FC<ModalComponentProps> = ({Component, modalKey}) => {
  return (
    <Location>
      {({location}) => <ModalRenderer location={location} Component={Component} modalKey={modalKey}/>}
    </Location>
  )
}

// TODO: This component is to prevent modal being mounted when we are not on the location. This needs a more sophisticated solution
const ModalRenderer: React.FC<ModalRendererProps> = ({Component, location, modalKey}) => {
  const {isOpen} = useModalAsRoute(modalKey, location);

  if (isOpen)
    return <Component location={location}/>

  return null;
}

export default ModalComponent;