const cycleTemplateSelectors = Object.freeze({
  selectTemplates: state => state.cycleTemplates,
  selectTemplateIds: state => {
    const {templateIds} = cycleTemplateSelectors.selectTemplates(state);

    return templateIds;
  },
  selectTemplateObjects: state => {
    const {templates} = cycleTemplateSelectors.selectTemplates(state);

    return templates;
  },

  selectTemplateForId: templateId => state => {
    const templates = cycleTemplateSelectors.selectTemplateObjects(state);

    return templates[templateId];
  }
});

export default cycleTemplateSelectors;