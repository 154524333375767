import * as React from 'react';
import TimeValue from '../../../components/TimeValue';
import { PaginationData } from '../../../components/pagination/Pagination';
import { TRANSLATION_KEYS } from '../../../intl/intl';
import ActiveFilterBlock from '../../../components/filter/ActiveFilterBlock';
import { ColumnSelectionWithControls } from '../../../components/column/ColumnSelectionWithControls';
import { getGroupableTableColumns } from '../../../components/table/actually-table-utils';
import { IndexedObject } from '../../../types/generics';
import { RecordsData } from '../../../api/report/report-api-implementation';
import FileExport from '../../../components/export/FileExport';
import { overallRecordsGroupingExportUrl, overallRecordsListingExportUrl } from '../../../api/report/report-api';
import RecordPageFilterBlock from '../../../components/filter/configuration/filter-blocks-for-page/RecordPageFilterBlock';
import RecordEntity from '../../../api/report/RecordEntity';
import styled from 'styled-components';
import { getGroupedOverallReportColumnConfiguration, getEditableOverallReportColumnConfiguration } from './overall-report-listing-columns';
import { DefaultBlock } from '../../../components/layout/LayoutHelpers';
import { GroupableOverallReportListing } from './GroupableOverallReportListing';
import { EditableOverallReportListing } from './EditableOverallReportListing';
import { ControlWrapper, ControlWrapperControl } from '../../../components/layout/ControlWrapper';

const StyledTimeValue = styled(TimeValue)`
    font-weight: 700;
`

interface OwnPropTypes {
    // FIXME: TB-160
    results: Array<number>;
    records: IndexedObject<RecordsData>;
    filterBlock: any;
    activeFilters: any;
    pagination?: PaginationData;
    selectedColumns: any;
    ordering: Array<string>;
    totalRow: any;
    cornerAction: React.ReactElement;
    isLocked: boolean;
    isLoading: boolean;
    onRecordChange: (recordId: number, fieldName: string, newValue: any) => void;
    onGroupingChange: (selectedColumns: Array<string>) => void;
    onOrderingChange: (newOrdering: Array<string>) => void;
    onPageChange: (page: number) => void;
    setErrorsForEntity: (recordEntity: RecordEntity) => void;
}

export type OverallReportListingProps = OwnPropTypes;

const getOverallReportListingFooter = (totalRow: any) => ({ time: <StyledTimeValue minutes={totalRow.total_minutes} showHourLabel={true} /> })

export const OverallReportListing: React.FunctionComponent<OverallReportListingProps> = ({
    results,
    records,
    filterBlock,
    activeFilters,
    selectedColumns,
    totalRow,
    pagination,
    ordering,
    isLocked,
    isLoading,
    cornerAction,
    onRecordChange,
    onGroupingChange,
    onOrderingChange,
    onPageChange,
    setErrorsForEntity
}) => {

    const isGrouped = selectedColumns?.activeColumns?.length > 0;
    const groupableTableColumns = getGroupableTableColumns(getGroupedOverallReportColumnConfiguration);
    const orderedRecords = results.map(result => records[result]);
    const footer = getOverallReportListingFooter(totalRow);

    const overallReportListingControls: ControlWrapperControl[] = [
        {
            name: 'filtering',
            label: { id: TRANSLATION_KEYS.search.filtersButton },
            component: <ActiveFilterBlock filterBlock={filterBlock} />,
            isActive: !!activeFilters.length
        },
        {
            name: 'grouping',
            label: { id: TRANSLATION_KEYS.search.groupingButton },
            component: <ColumnSelectionWithControls
                columns={groupableTableColumns}
                onApplyColumns={onGroupingChange}
                // FIXME: This is a bit of a hack to make this work, since this was previously done using the identifier
                currentColumns={
                  selectedColumns.activeColumns
                    ? selectedColumns.activeColumns.map(
                      column => groupableTableColumns.find(tableColumn => tableColumn.name === column)?.identifier
                    )
                    : []
                }
            />,
            isActive: !!selectedColumns.activeCount
        },
    ]

    const overallReportListingActions = [
        <FileExport filterBlockName={RecordPageFilterBlock.blockKey}
            additionalParameters={selectedColumns.activeCount > 0 ? selectedColumns : undefined}
            endpointUrl={selectedColumns.activeCount ? overallRecordsGroupingExportUrl : overallRecordsListingExportUrl} />
    ]


    return (
        <ControlWrapper
            controls={overallReportListingControls}
            actions={overallReportListingActions}
            isLoading={isLoading}
        >
            <DefaultBlock withBackground={true} cornerAction={isGrouped ? undefined : cornerAction}>
                {isGrouped ?
                    <GroupableOverallReportListing
                        tableColumns={getGroupedOverallReportColumnConfiguration}
                        groupedColumns={selectedColumns.activeColumns}
                        records={orderedRecords}
                        ordering={ordering}
                        pagination={pagination}
                        footer={footer}
                        isLoading={isLoading}
                        onPageChange={onPageChange}
                        onOrderingChange={onOrderingChange}
                    />
                    : <EditableOverallReportListing
                        tableColumns={getEditableOverallReportColumnConfiguration}
                        records={orderedRecords}
                        ordering={ordering}
                        pagination={pagination}
                        footer={footer}
                        isLoading={isLoading}
                        isLocked={isLocked}
                        onPageChange={onPageChange}
                        setErrorsForEntity={setErrorsForEntity}
                        onRecordChange={onRecordChange}
                        onOrderingChange={onOrderingChange}
                    />
                }

            </DefaultBlock>
        </ControlWrapper>
    )
}