import filterSagaTypes from "./filter-types";

const changeFilterValueAction = (filterKey, filterValue) => ({
  type: filterSagaTypes.CHANGE_FILTER_VALUE,
  filterKey,
  filterValue
});

const fetchFilterValuesAction = (entity) => ({type: filterSagaTypes.FETCH_FILTER_VALUES, entity});

const receiveFilterValuesAction = (response, entityKey) => ({
  type: filterSagaTypes.RECEIVE_FILTER_VALUES,
  response,
  entityKey
});

export {
  changeFilterValueAction,
  fetchFilterValuesAction,
  receiveFilterValuesAction
}