import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";

import LoadingIndicator from "./LoadingIndicator";

import loadingSelectors from "../../store/ducks/loading/loading-selectors";

import './LoadingIndicator.css';

function SoftLoading({actionName, children}) {
  const loadingElement = useSelector(loadingSelectors.selectLoadingForRequest(actionName));

  return <SoftLoadingImpl loadingElement={loadingElement}>{children}</SoftLoadingImpl>;
}

function StaticSoftLoading({isLoading, children}) {
  const loadingElement = {loading: isLoading, callCount: 2};

  return <SoftLoadingImpl loadingElement={loadingElement}>{children}</SoftLoadingImpl>;
}


function SoftLoadingImpl({loadingElement, children}) {
  const isInitialLoad = loadingElement.callCount === 1 && loadingElement.loading;

  const renderComponent = () => {
    if (isInitialLoad)
      return (<LoadingIndicator/>);

    if (!loadingElement.loading)
      return children;

    return (
      <div className="loading-overlay">
        <div className="loading-content">
          {children}
        </div>
        <LoadingIndicator/>
      </div>
    );
  };

  return renderComponent();
}

SoftLoading.propTypes = {
  actionName: PropTypes.string
};

export {
  SoftLoading,
  StaticSoftLoading
};
