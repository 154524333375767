import executeRequest from '../../../api/api';

import { takeEvery, put, fork, call } from 'redux-saga/effects';
import { receiveFilterValuesAction } from "./filter-actions";
import filterSagaTypes from './filter-types';

export function* fetchFilterValuesFlow(action) {
  const {entity} = action;

  // FIXME: Page_size is capped to 1000 because dropdown refuses to render too much data.
  const {response} = yield call(executeRequest, entity.fetch({page_size: 0}), {cancelOnFailure: true});
  const handledResponse = entity.handleEntityResponse(response.data);

  yield put(receiveFilterValuesAction(handledResponse, entity.getEntityKey()));
}

function* fetchEntitiesWatch() {
  yield takeEvery(filterSagaTypes.FETCH_FILTER_VALUES, fetchFilterValuesFlow);
}

const filterSagaWatches = [fork(fetchEntitiesWatch)];

export default filterSagaWatches;