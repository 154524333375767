import { createDeleteRequest, createGetRequest, createPostRequest } from "../../api";
import { joinRouteParts } from "../../utils";

const authRightsBaseUrl = 'authrights';
const authRolesBaseUrl = 'authroles';
const grantedRightsBaseUrl = 'grantedrights';
const rights = 'rights';

const getAuthRoles = params => createGetRequest({url: authRolesBaseUrl, params});
const getUserObjectRights = targetUserId => {
  const url = joinRouteParts(rights, targetUserId, 'user');

  return createGetRequest({url});
};

const getProjectObjectRights = targetProjectId => {
  const url = joinRouteParts(rights, targetProjectId, 'project');

  return createGetRequest({url});
};

const getGlobalRights = userId => {
  const url = joinRouteParts(rights, userId, 'global_rights');

  return createGetRequest({url});
};

const getAuthRightsWithGrants = params => {
  const url = joinRouteParts(authRightsBaseUrl, 'with_grants');

  return createGetRequest({url, params});
};

const postGrantedRight = (role, group, right) => {
  return createPostRequest({url: grantedRightsBaseUrl, data: {role, group, right}});
};

const deleteGrantedRight = grantedId => {
  const url = joinRouteParts(grantedRightsBaseUrl, grantedId);

  return createDeleteRequest({url});

};

export {
  getAuthRoles,
  getUserObjectRights,
  getProjectObjectRights,
  getGlobalRights,

  getAuthRightsWithGrants,
  postGrantedRight,
  deleteGrantedRight
};