import React from "react";
import { Time } from "../util/Time";

const TimeValue = ({minutes, showZero=false, showHourLabel=false, ...props}) => {
  if (minutes === undefined || minutes === null) {
    return <span style={{ color: '#9FB3C8' }}>-</span>;
  }

  return <span {...props}>
    {Time.FromMinutes(minutes).toColonSeparated(showZero) || <span>&nbsp;</span>}
    {showHourLabel && ' h'}
  </span>
};

export default TimeValue;