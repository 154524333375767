import React from 'react';
import PropTypes from 'prop-types';

import ExportLink from "./ExportLink";
import { useFiltersAsExportUrl } from "../filter/filter-hooks";

function FileExport({filterBlockName, endpointUrl, additionalParameters}) {
  const exportUrl = useFiltersAsExportUrl(filterBlockName, endpointUrl, additionalParameters);

  return <ExportLink exportUrl={exportUrl}/>;
}

FileExport.propTypes = {
  endpointUrl: PropTypes.string.isRequired,
  filterBlockName: PropTypes.string.isRequired,
  additionalParameters: PropTypes.object
};

FileExport.defaultProps = {
  additionalParameters: {}
};

export default FileExport;