import React from 'react';

import {useModal} from '../../../../components/modal/modal-hooks';

import modalDefinitions from '../../../../components/modal/modal-definitions';

import SimpleModal from '../../../../components/modal/SimpleModal';
import ModalFrame from "../../../../components/modal/ModalFrame";
import EditCalendarManager from "./EditCalendarManager";
import {ActuallyButton} from "../../../../components/buttons";
import {RightAngleLightIcon} from "../../../../components/icons";
import {OnClickInterface} from "../../../../components/layout/CornerAction";


export const ActionColumn: React.FunctionComponent<OnClickInterface> = ({children, onClick}) => {
  return (
    <ActuallyButton variant="slick" onClick={onClick} className="btn-block no-focus">
      {children}
      <span className="pl-2"><RightAngleLightIcon/></span>
    </ActuallyButton>
  )
}

function EditCalendarModal({calendar}) {
  const [openModal, closeModal, name] = useModal(modalDefinitions.EDIT_CALENDAR, calendar.id);

  return (
    <>
      <ActionColumn onClick={openModal}>
        {calendar.name}
      </ActionColumn>

      <SimpleModal name={name} onClose={closeModal}>
        <ModalFrame heading="Edit calendar">
          <EditCalendarManager calendar={calendar} onDone={closeModal}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default EditCalendarModal;
