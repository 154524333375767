const selectTasksResult = state => {
  return state.tasks.result;
};

const selectTaskEntities = state => {
  return state.tasks.entities.tasks;
};

const selectIsLoadingTasks = state => {
  return state.tasks.isLoading;
};

const selectTaskList = projectId => state => {
  const taskIds = selectTasksResult(state);
  return taskIds.map(taskId => state.tasks.entities.tasks[taskId]).filter(task => task.project === projectId);
};

const selectSelectedTask = projectId => state => {
  const selectedId = state.tasks.selectedTaskId;
  const entities = selectTaskEntities(state);
  const taskCandidate = entities ? entities[selectedId] : null;

  return taskCandidate && taskCandidate.project === projectId ? taskCandidate : null;
};

const selectActualAndBudgetedMinutes = projectId => state => {
  const projectTasks = selectTaskList(projectId)(state);

  return projectTasks.reduce((acc, task) => {
    const actualMinutes = acc['actualMinutes'] + (task.actual_minutes || 0);
    const budgetedMinutes = acc['budgetedMinutes'] + (task.budgeted_minutes || 0);

    acc['actualMinutes'] = actualMinutes;
    acc['budgetedMinutes'] = budgetedMinutes;

    return acc;
  }, {actualMinutes: 0, budgetedMinutes: 0});
};

const selectLargestTaskMinutes = projectId => state => {
  const tasks = selectTaskList(projectId)(state);

  const eachTasksLargest = tasks.map(task => Math.max(task.actual_minutes || 0, task.budgeted_minutes || 0));
  const orderedMinutes = eachTasksLargest.sort((a, b) => b - a);

  if (orderedMinutes.length > 0) {
    return orderedMinutes[0];
  } else {
    return 0;
  }

};

export {
  selectTaskList,
  selectSelectedTask,
  selectIsLoadingTasks,

  selectActualAndBudgetedMinutes,
  selectLargestTaskMinutes,
}