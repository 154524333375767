import * as React from 'react'
import { ActuallyIntlContext } from './IntlProviderWrapper'
import { supportedLocaleOptions } from '../intl';
import Select from 'react-select'
import useUserAPI from '../../components/people/user-hooks';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/ducks/auth/selectors';

export type LocaleSelectInputProps = {
  onChange?: (locale: any) => void;
};

export const LocaleSelectInput: React.FunctionComponent<LocaleSelectInputProps> = ({ onChange }) => {
  const { updateMe } = useUserAPI();
  const currentUser = useSelector(selectCurrentUser);

  const intlContext = React.useContext(ActuallyIntlContext)

  // FIXME: These styles are copied from TenantChange. Make these reusable
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: '#829AB1',
      backgroundColor: 'transparent',
      border: 0,
      fontWeight: 700,
      boxShadow: 'none',
    }),
    indicatorsContainer: classes => ({
      ...classes,
      display: "flex",
      justifyContent: "center",
      width: "50px"
    }),
    container: provided => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      border: 0,
    }),
    valueContainer: (c: any) => ({
      ...c,
      padding: 0,
    }),
    singleValue: provided => {
      return {
        ...provided,
        color: '#829AB1',
      };
    },
  };

  const handleChange = async (locale: any) => {
    const localeKey = locale.value
    if (currentUser.id) {
      const { response } = await updateMe({ locale: localeKey });
      if(response) {
        intlContext.changeLocale(localeKey);  
      }
    } else {
      intlContext.changeLocale(localeKey);
    }
  }

  const options = Object.keys(supportedLocaleOptions).map(locale => supportedLocaleOptions[locale])
  return (
    <Select
      value={supportedLocaleOptions[intlContext.locale]}
      options={options}
      menuPlacement='auto'
      isSearchable={false}
      styles={customStyles}
      onChange={handleChange}
    />
  )
}