import {createPostRequest, createGetRequest} from "../api";

function postCheckout(data) {
  return createPostRequest({url: 'checkout', data});
}

function getCheckoutDetails() {
  return createGetRequest({url: 'checkout-details'});
}

function getBillingPortal() {
  return createGetRequest({url: 'billing-portal'})
}

export {
  postCheckout,
  getCheckoutDetails,
  getBillingPortal
}
