const projectSagaTypes = Object.freeze({
  ADD_SINGLE_PROJECT: 'ADD_SINGLE_PROJECT',
  ADD_MULTIPLE_PROJECTS: 'ADD_MULTIPLE_PROJECTS',
  FETCH_PROJECT: 'FETCH_PROJECT',
  FETCH_PROJECT_SUCCESS: 'FETCH_PROJECT_SUCCESS',

  FETCH_PROJECTS: 'FETCH_PROJECTS',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',

  RECEIVE_PROJECT: 'RECEIVE_PROJECT',
  REMOVE_PROJECT: 'REMOVE_PROJECT'
});

export default projectSagaTypes;
