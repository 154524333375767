import React, {useCallback} from "react";
import styled from "styled-components";
import {DefaultBlock} from "../../components/layout/LayoutHelpers";
import DailyProgressBar from "../mobile/timesheet/DailyProgressBar";
import TimeValue from "../../components/TimeValue";
import PlainTable from '../../components/ui-utility/table/PlainTable';
import {
  selectLargestTaskMinutes,
  selectSelectedTask,
  selectTaskList
} from "../../store/ducks/project-tasks/selectors";
import {useDispatch, useSelector} from "react-redux";
import {closeModalAction, openModalAction} from "../../components/modal/modal-redux";
import modalDefinitions from "../../components/modal/modal-definitions";
import {clearSelectedTask, selectTask} from "../../store/ducks/project-tasks/actions";
import SimpleModal from "../../components/modal/SimpleModal";
import EditTask from "./project-tasks/EditTask";
import useTaskAPI from "./project-tasks/tasks-api-hook";
import useShallowEqualSelector from "../../store/helpers";
import EmptyStatePlaceholder from "../../components/ui-utility/EmptyStatePlaceholder";
import {If} from "../../components/ui-utility/Conditional";
import TaskListNameButton from "./project-tasks/TaskListNameButton";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";
import ModalFrame from "../../components/modal/ModalFrame";


const TaskRowWrapper = styled.tr`
  cursor:pointer;
`

const TaskRow = ({task, largestTaskMinutes, onOpenTask}) => {
  const taskMax = Math.max(task.actual_minutes, task.budgeted_minutes);
  const relativeMax = Math.round(taskMax / largestTaskMinutes * 100);

  return (
    <TaskRowWrapper onClick={() => onOpenTask(task.id)}>
      <td className="py-2">
        <TaskListNameButton task={task}/>
      </td>

      <td className="align-middle text-right">
        <TimeValue minutes={task.budgeted_minutes} showHourLabel showZero/>
      </td>

      <td className="align-middle text-right">
        <TimeValue minutes={task.actual_minutes} showHourLabel/>
      </td>

      <td style={{minWidth: '200px'}}>
        <DailyProgressBar minutes={task.actual_minutes || 0}
                          capacityMinutes={task.budgeted_minutes || 0}
                          hideTime
                          style={{width: `${relativeMax}%`, fontSize: '18px'}}/>
      </td>
    </TaskRowWrapper>
  );
};


function EditTaskModal({projectId, api, onClose = undefined}) {
  const dispatch = useDispatch();
  const task = useShallowEqualSelector(selectSelectedTask(projectId));

  const handleCloseModal = useCallback(() => {
    dispatch(clearSelectedTask());
    dispatch(closeModalAction(modalDefinitions.EDIT_PROJECT_TASK));

  }, [dispatch]);

  if (!task) {
    return <></>
  }

  return (
    <>
      <SimpleModal name={modalDefinitions.EDIT_PROJECT_TASK} onClose={onClose}>
        <ModalFrame heading={<LocalizedMessage
          text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.editModalHeader}}/>}>
          <EditTask entity={task}
                    api={api}
                    onDone={handleCloseModal}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}


const ProjectTasksTable = ({tasks, largestTaskMinutes, handleOpenTask}) => {
  const totalActualMinutes = tasks.reduce((sumOfMinutes, task) => {
    return sumOfMinutes + (task.actual_minutes || 0)
  }, 0);
  const totalBudgetedMinutes = tasks.reduce((sumOfMinutes, task) => {
    return sumOfMinutes + (task.budgeted_minutes || 0)
  }, 0);

  return (
    <PlainTable showLines={true} hoverable={true} className="pb-3">
      <thead>
      <tr>
        <th className="align-middle py-2"><LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.name}}/></th>
        <th className="align-middle text-right"><LocalizedMessage
          text={{id: TRANSLATION_KEYS.commonProperties.budgeted}}/></th>
        <th className="align-middle text-right"><LocalizedMessage
          text={{id: TRANSLATION_KEYS.commonProperties.actuals}}/></th>
        <th className="align-middle"><LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.progress}}/></th>
      </tr>
      </thead>

      <tbody>
      {tasks.map(t => <TaskRow key={t.id}
                               task={t}
                               largestTaskMinutes={largestTaskMinutes}
                               onOpenTask={handleOpenTask}/>)}
      </tbody>

      <tfoot>
      <tr>
        <th className="py-2">&nbsp;</th>
        <th className="align-middle text-right">
          <TimeValue minutes={totalBudgetedMinutes} showHourLabel showZero/>
        </th>
        <th className="align-middle text-right">
          <TimeValue minutes={totalActualMinutes} showHourLabel showZero/>
        </th>
        <th>&nbsp;</th>
      </tr>
      </tfoot>
    </PlainTable>
  )
};

const ProjectTasksContainer = ({projectId}) => {
  const dispatch = useDispatch();
  const api = useTaskAPI(projectId);
  const tasks = useSelector(selectTaskList(projectId));
  const largestTaskMinutes = useSelector(selectLargestTaskMinutes(projectId));

  const handleOpenTask = useCallback(taskId => {
    dispatch(selectTask(taskId));
    dispatch(openModalAction(modalDefinitions.EDIT_PROJECT_TASK));

  }, [dispatch]);

  return (
    <>
      <EditTaskModal projectId={projectId} api={api}/>

      <DefaultBlock isTight>
        {tasks.length > 0 &&
        <ProjectTasksTable tasks={tasks} largestTaskMinutes={largestTaskMinutes} handleOpenTask={handleOpenTask}/>}

        <If condition={tasks.length === 0}>
          <EmptyStatePlaceholder iconName='list-alt' isLoading={api.isLoading}>
            <>
              <p>
                <LocalizedMessage
                  text={{id: TRANSLATION_KEYS.projects.projectDashboard.tasksTab.emptyResultsMessageHeader}}/>
              </p>
              <p>
                <LocalizedMessage
                  text={{id: TRANSLATION_KEYS.projects.projectDashboard.tasksTab.emptyResultsMessageDescription}}/>
              </p>
            </>
          </EmptyStatePlaceholder>
        </If>
      </DefaultBlock>
    </>
  );
};

export default ProjectTasksContainer;
