import { createGetRequest, createPatchRequest } from "../../api";
import { joinRouteParts } from "../../utils";

const baseUrl = 'cycle-step-user';

const patchCycleStepUser = (stepId, data) => {
  const apiUrl = joinRouteParts(baseUrl, stepId);

  return createPatchRequest({url: apiUrl, data});
};

const getUserStepRecords = stepId => {
  const url = joinRouteParts(baseUrl, stepId, 'records');

  return createGetRequest({url});
}


export {
  patchCycleStepUser,
  getUserStepRecords
};