import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {useForm} from "react-hook-form";
import {fetchPeopleAction} from "../../../store/ducks/people/actions";
import {Time} from "../../../util/Time";

import useUserAPI from "../user-hooks";
import Error from "../../notication/Error";
import FormTimeInput from "../../../forms/FormTimeInput";
import FormStateButton from "../../../forms/FormStateButton";
import FormApiSelect from "../../../forms/FormApiSelect";
import ToggleSwitch from "../../toggle-switch/ToggleSwitch";

import {TextArea} from "../../forms/TextArea";
import {TooltipOverlay} from "../../ui-utility/Tooltip";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {FormElement, SimpleFormBuilder} from "../../forms/SimpleFormBuilder";
import {BreatherRow} from "../../../screens/authentication/signup/SignUpEmailPhase";
import Col from "react-bootstrap/Col";
import {AdminPromotionLabel} from "../edit-person/EditPersonForm";
import {selectBootstrapState} from "../../../store/ducks/bootstrap/bootstrap-selectors";

function AddPeopleAdvanced({onDone}) {
  const dispatch = useDispatch();

  const {createAdvancedPeople} = useUserAPI();
  const {calendar, workday_length} = useSelector(selectBootstrapState);

  const time: Time = new Time(workday_length);

  const {register, formState, handleSubmit, setValue, control, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      userList: "",
      workdayLength: time.toColonSeparated(),
      send_invitations: false,
      calendar: calendar,
      is_superuser: true
    }
  });

  const {dirty, isValid} = formState;

  const [errorState, setErrorState] = useState(null);

  const onSubmitAdvancedForm = async formData => {
    const workdayLength = Time.FromColonSeparated(formData.workdayLength).minutes;
    const payload = {...formData, workdayLength};

    const {response, genericErrors} = await createAdvancedPeople(payload);

    genericErrors && setErrorState(genericErrors);

    if (response) {
      dispatch(fetchPeopleAction({}));
      onDone && onDone();
    }
  };

  const formElements: Array<FormElement> = [
    {
      label: <LocalizedMessage text={{id: TRANSLATION_KEYS.people.addPeoplePopover.inputPeopleEmailsLabel}}/>,
      input: (<><TextArea className="form-control" name="userList" innerRef={register({required: true})} minRows={3}
                          placeholder="sarah.jones@example.com"/>
        <small className="mb-2">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.people.addPeoplePopover.inputPeopleEmailsLabel}}/>
        </small></>)
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.formFields.workdayLengthLabel}}/>,
      input: <FormTimeInput name={"workdayLength"} control={control} setValue={setValue}/>
    },
    {
      width: 6,
      label: <LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.calendar}}/>,
      input: <FormApiSelect variant="calendar" onChange={entity => setValue('calendar', entity.id)}
                            control={control} name="calendar" watch={watch}/>
    },
    {
      width: 6,
      label: <AdminPromotionLabel/>,
      input: <ToggleSwitch innerRef={register} name="is_superuser"/>
    },
    {
      width: 6,
      label: (<TooltipOverlay tooltipContent={
        <LocalizedMessage text={{id: TRANSLATION_KEYS.people.addPeoplePopover.sendInvitationsTooltip}}/>}>
              <span>
                <LocalizedMessage text={{id: TRANSLATION_KEYS.people.addPeoplePopover.sendInvitationEmailsLabel}}/>
              </span>
      </TooltipOverlay>),
      input: <ToggleSwitch innerRef={register} name="send_invitations"/>
    },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmitAdvancedForm)}>
      <Error error={errorState}/>

      <SimpleFormBuilder formElements={formElements}/>

      <BreatherRow className="text-right">
        <Col>
          <FormStateButton isValid={isValid} dirty={dirty}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.addButton}}/>
          </FormStateButton>
        </Col>
      </BreatherRow>
    </form>
  );
}

export default AddPeopleAdvanced;