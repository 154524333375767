import {lastDaysRange} from "../../util/dates";
import {CoordinateResolver, DailyActual} from "../../screens/timesheet/MonthlyStatus";
import {RecordsWithDailyAxis} from "../people/PersonUtilization";
import React from "react";
import {SummedRecordMinutes} from "../records/SummedRecordMinutes";
import TimeValue from "../TimeValue";
import {Record} from "../../api/report/RecordEntity";

import styled from "styled-components";

const TotalTime = styled.div`
    width: 50px;
`


interface ActualCellsForTableProps {
  records: Array<Record>,
  total: number,
  capacityPerSlot: number,
}

export const RightAligned = styled.div`
  text-align: right;
`

export const CenterAligned = styled.div`
  text-align: center;
`

export const ActualCellsForTable: React.FunctionComponent<ActualCellsForTableProps> = ({total, records, capacityPerSlot}) => {
  const lastThirtyDays = lastDaysRange(30);

  const intactRecords: Array<DailyActual> = CoordinateResolver.convertRawRecordsIntoDayStatusCompliantSet(records, lastThirtyDays, capacityPerSlot);

  return (
    <React.Fragment>
      <td className="pt-2 pb-2 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <RecordsWithDailyAxis recordsWithCoordinates={intactRecords}/>
          <span className="ml-3">
            <TotalTime>
              (<SummedRecordMinutes records={records}/>)
            </TotalTime>
          </span>
        </div>
      </td>
      <td>
      <RightAligned>
        <TimeValue minutes={total} showZero={true}/>
      </RightAligned>
      </td>
    </React.Fragment>
  )
}