import { RouteComponentProps } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { WithClassName } from "./types/WithClassName";
import { useSelector } from "react-redux";
import { selectAuthenticationState } from "./store/ducks/auth/selectors";
import Login from "./screens/authentication/Login";

type AdminSectionProps = WithClassName & RouteComponentProps;

const AdminSectionImpl: React.FC<AdminSectionProps> = ({className, children}) => {
  const authState = useSelector(selectAuthenticationState);

  if (authState.isLoading) {
    return <div className={className}>Loading ...</div>
  }

  if (!authState.isLoading && authState.userId) {
    // Skip the extra div that Reach Router creates: https://github.com/reach/router/issues/145
    const renderedChildren = React.cloneElement(children as any, {component: React.Fragment});
    return (
      <div className={className}>
         {renderedChildren}
       </div>
    );

  } else {
    return <Login />
  }
}

export const AdminSection = styled(AdminSectionImpl)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  background-color: #f3f3f3;
  font-family: Lato;
  padding: 20px;
  
  a {
    color: #2dbaae;
  }
  
  h1 {
    font-family: Inter;
    font-weight: 900;
  }
`;

export const AdminDashboardImpl: React.FC<RouteComponentProps & WithClassName> = ({className}) => {
  return (
    <div className={className}>
      <p>With great power comes great responsibility.</p>
      <div><em>- Voltaire</em></div>
    </div>
  )
}

export const AdminDashboard = styled(AdminDashboardImpl)`
  display: flex;
  flex-direction: column;
  height: 80vh;
  color: #999;
  justify-content: center;
  align-items: center;
`;

