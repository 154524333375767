import React from "react";

import { formatDayShort } from "./date-formatters";

const DayShort = ({date, ...props}) => {
  const formatted = formatDayShort(date);

  return (
    <span {...props}>{formatted}</span>
  );
};

export default DayShort;
