import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../../../store/ducks/auth/selectors";
import { PlainSectionBlock, Section } from "../../../../components/layout/LayoutHelpers";

import ApproverModal from "../components/ApproverModal";
import ModalComponent from "../../../../components/modal/ModalComponent";
import ApproveUserProjectModal from "../components/ApproveUserProject";
import ApproveUserModal from "../components/ApproveUserModal";
import PageHeader from "../../../../components/text/PageHeader";
import useApprovalAPI from "../approval-hooks";
import { ActiveCycleTable, HistoryCycleTable } from "./ApproverCycleTable";

import Container from "react-bootstrap/Container";
import { RouteComponentProps } from "@reach/router";
import { TabbedContainer } from "../../../../components/layout/TabbedContainer";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import modalDefinitions from "../../../../components/modal/modal-definitions";

enum ApprovalListingTabs {
  Active = 'active',
  History = 'history',
}


type ApprovalScreenProps = RouteComponentProps;

const ApprovalScreen: React.FunctionComponent<ApprovalScreenProps> = () => {
  const currentUser = useSelector(selectCurrentUser);

  const { fetchCycleHistoryForApprover } = useApprovalAPI();

  useEffect(() => {
    fetchCycleHistoryForApprover(currentUser.id);
  }, [currentUser.id, fetchCycleHistoryForApprover]);

  return (
    <ApprovalListing userId={currentUser.id} />
  );
}

interface ApprovalListingProps {
  userId: number;
}

const ApprovalListing: React.FunctionComponent<ApprovalListingProps> = ({ userId }) => {
  const [currentTab, setCurrentTab] = useState('active');

  const tabs = [
    {
      key: ApprovalListingTabs.Active,
      label: {id: TRANSLATION_KEYS.approvalList.table.pendingTabHeader},
      contentComponent: <ActiveCycleTable approverId={userId} />
    }, {
      key: ApprovalListingTabs.History,
      label: {id: TRANSLATION_KEYS.approvalList.table.approvedTabHeader},
      contentComponent: <HistoryCycleTable approverId={userId} />
    }
  ]

  return (
    <Container fluid>
      <Section>
        <PlainSectionBlock>
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.pageHeader}} />
          </PageHeader>
        </PlainSectionBlock>
      </Section>

      <TabbedContainer tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} />

      <ModalComponent Component={ApproverModal} modalKey={modalDefinitions.APPROVE_CYCLE}/>
      <ModalComponent Component={ApproveUserProjectModal} modalKey={modalDefinitions.APPROVE_USER_PROJECT}/>
      <ModalComponent Component={ApproveUserModal} modalKey={modalDefinitions.APPROVE_USER}/>

    </Container>
  );
}


export { ApprovalScreen };
