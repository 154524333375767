import React, { useCallback, useEffect } from "react";

import { If } from "../../../../components/ui-utility/Conditional";
import { useForm } from "react-hook-form";
import { FETCH_PRESET_CALENDAR, useCalendarAPI, useCalendarManagement } from "../calendar-hooks";
import { receivePresetDatesAction } from "../holiday-dates-reducers";
import { TooltipCircle } from "../../../../components/ui-utility/Tooltip";
import { SoftLoading } from "../../../../components/loaders/SoftLoading";

import FormActionRow from "../../../../forms/FormActionRow";
import CalendarYearSelection from "./CalendarYearSelection";
import CalendarHolidays from "./CalendarHolidays";
import CalendarDateSelection from "./CalendarDateSelection";


function EditCalendarForm({calendar, onDone}) {
  const {selectHolidayDates, tempAddPresetDates, tempAddManualDate, commitCalendarChanges, dispatch} = useCalendarManagement();
  const {fetchPresetCalendar, destroyCalendar} = useCallback(useCalendarAPI(), []);

  const holidays = selectHolidayDates();

  const {register, formState, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: calendar.name,
    }
  });

  const onSubmit = async data => {
    const {response} = await commitCalendarChanges(calendar.id, data);

    response && onDone();
  };

  const onDelete = async () => {
    const {response} = await destroyCalendar(calendar.id);

    response && onDone();
  };

  useEffect(() => {
    const handlePresetCalendarFetch = async () => {
      const {response = {}} = await fetchPresetCalendar(calendar.preset);

      dispatch(receivePresetDatesAction(response));
    };

    calendar.preset && handlePresetCalendarFetch();
  }, [fetchPresetCalendar, calendar.preset, dispatch]);

  const {isValid} = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="name">Calendar name</label>
          <input className="form-control" name="name" type="text" ref={register({required: true})} tabIndex={1}/>
          <If condition={calendar.preset}>
            <small>Using calendar {calendar.preset} as a base calendar</small>
          </If>
        </div>
      </div>

      <SoftLoading actionName={FETCH_PRESET_CALENDAR}>
        <If condition={calendar.preset}>
          <label htmlFor="holidays">Select years</label>
          <TooltipCircle
            instructionText={"Calendar will receive the base calendar's holiday dates for the given year. Dates falling to weekend will not be added since they are treated as day offs by default"}/>
          <CalendarYearSelection onChange={tempAddPresetDates}/>
        </If>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <label htmlFor="new_date">Select a date</label>
            <TooltipCircle
              instructionText={"In case the chosen date is in weekend it will be treated as an extra workday and vice versa"}/>
            <CalendarDateSelection onChange={tempAddManualDate} disabledDates={holidays}/>
          </div>
        </div>

        <div className="mt-4 mb-4">
          <CalendarHolidays holidays={holidays}/>
        </div>
      </SoftLoading>

      <FormActionRow tabIndexStart={3} showDeleteButton={true} isValid={isValid} dirty={true}
                     handleCancel={onDone} handleDelete={onDelete}/>
    </form>
  )
}

export default EditCalendarForm;