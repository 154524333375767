import React from 'react';
import { useSelector } from "react-redux";

import TimeValue from "../../../../components/TimeValue";
import TimesheetPinColumn from "../../components/TimesheetPinColumn";
import ProjectAndTaskName from "../../../../components/project/ProjectAndTaskName";

import { QuoteBlock } from "../../../../components/text/DescriptionTxt";
import { If } from "../../../../components/ui-utility/Conditional";
import { selectRecordById } from "../../../../store/ducks/timesheet/selectors";
import { ActuallyButton, PenIcon, PlusIcon } from "../../../../components/buttons";
import {useCycleDateCollisionChecker, useTimetrackingAllowed} from "../../record-hooks";

import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

function DailyTimesheetViewRow({task, project, recordId=undefined, handleRecordCreation, handleRecordEdit, date}) {
  const record = useSelector(selectRecordById(recordId)) || {description: '', minutes: 0};
  const timeTrackingAllowed = useTimetrackingAllowed(task, project);

  const isDateLocked = useCycleDateCollisionChecker(date);

  const timetrackingDisabled = !timeTrackingAllowed || isDateLocked;

  return (
    <div className="dailytimesheet-record-row">
      <div className="dts-task-col">
        <span className="pr-3">
        <TimesheetPinColumn task={task}/>
        </span>
        <ProjectAndTaskName project={project} task={task}/>
        <div>
          <QuoteBlock text={record.description}/>
        </div>
      </div>
      <If condition={!timetrackingDisabled}>
        <div className="dts-time-col">
          <ActuallyButton variant="slick" onClick={handleRecordCreation}>
            <PlusIcon className="fa-1-5x light"/>
          </ActuallyButton>
          <If condition={record.id}>
            <ActuallyButton variant="slick" className="ml-2 mr-2" onClick={() => handleRecordEdit(record)}>
              <PenIcon className="fa-1-5x light"/>
            </ActuallyButton>
            <TimeValue minutes={record.minutes} showZero={true} className="bold time-value shorten-text"/>
          </If>
        </div>
      </If>
      <If condition={timetrackingDisabled}>
        <i>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.taskCannotBeEditedMessage}}/>
        </i>
      </If>
    </div>
  )
}

export default DailyTimesheetViewRow;