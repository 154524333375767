import React, {useMemo} from 'react';
import {useActualTableDataFetching} from "../timesheet/record-hooks";
import PlainTable from "../../components/ui-utility/table/PlainTable";
import {Person} from '../people/PersonEntity';
import {ReportAPIResponseParser} from "../../api/report/report-api-implementation";
import PersonFullName from '../../components/people/PersonFullName';
import {ActualCellsForTable, CenterAligned} from "../../components/table/ActualDataTable";
import {If} from "../../components/ui-utility/Conditional";
import EmptyStatePlaceholder from "../../components/ui-utility/EmptyStatePlaceholder";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";


function ProjectRecordsByUsersTable({projectId}) {
  const params = useMemo(() => ({
    "project.id": projectId,
    "group_by": ['project', 'user'],
  }), [projectId]);

  const {allRecords, summedRecords} = useActualTableDataFetching(params);

  const reducedTotalRecords = ReportAPIResponseParser.reduceToSingleKeyValueMap(summedRecords, 'user');
  const {users, records} = ReportAPIResponseParser.extractUserRecords(allRecords);

  const CAPACITY_PER_SLOT = 480;

  return (
    <>
      <If condition={!users.length}>
        <EmptyStatePlaceholder iconName="table">
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.emptyResultsMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
      <If condition={users.length}>
        <PlainTable showLines={true} hoverable={true}>
          <thead>
          <tr>
            <td className="bold" style={{width: '30%'}}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabTableHeaders.user}}/>
            </td>
            <td className="bold" style={{width: '50%'}}>
              <CenterAligned>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabTableHeaders.timeForLast30Days}}/>
              </CenterAligned>
              </td>
            <td className="bold text-right" style={{width: '20%'}}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabTableHeaders.totalTime}}/>
            </td>
          </tr>
          </thead>
          <tbody>
          {users.map((user: Person) => (
            <tr key={user.id}>
              <td>
                <PersonFullName id={user.id} first_name={user.first_name} last_name={user.last_name} addLink={true}/>
              </td>
              <ActualCellsForTable total={reducedTotalRecords[user.id]} records={records[user.id]}
                                   capacityPerSlot={CAPACITY_PER_SLOT}/>
            </tr>
          ))}
          </tbody>
        </PlainTable>
      </If>
    </>
  )
}

export default ProjectRecordsByUsersTable;
