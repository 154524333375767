import { createGetRequest, createPostRequest } from "../api";
import { joinRouteParts } from "../utils";

const getCurrentUser = () => createGetRequest({url: 'me'});

const logout = () => createPostRequest({url: 'logout'});

const authenticate = (email, password) => createPostRequest({url: 'login', data: {email, password}});

const authenticateIntoTenant = (id, asDefault) => {
  const url = joinRouteParts('tenants', id, 'authenticate_into_tenant');

  return createPostRequest({url: url, data: {asDefault, id}});
};

const getAvailableTenants = () => createGetRequest({url: 'tenants'});

const postTenantWithUser = data => {
  return createPostRequest({url: 'signup', data});
};

const getUserAvailable = params => createGetRequest({url: 'user_available', params});

const postConfirmPasswordReset = (uid, token, new_password1, new_password2) => {
  const url = 'password/reset/confirm/';
  const data = {token, uid, new_password1, new_password2};

  return createPostRequest({url, data: data});
};

const postPasswordChange = (new_password1, new_password2, old_password) => {
  const url = 'password/change/';
  const data = {old_password, new_password1, new_password2};

  return createPostRequest({url, data: data});
};

const requestPasswordReset = (email) => {
  const url = 'password/reset/';

  return createPostRequest({url, data: {email}});
};

export {
  getCurrentUser,
  logout,
  authenticate,
  getAvailableTenants,
  authenticateIntoTenant,
  postConfirmPasswordReset,
  postPasswordChange,
  requestPasswordReset,
  postTenantWithUser,
  getUserAvailable
};