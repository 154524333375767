import {createSlice} from "@reduxjs/toolkit";
import {Customer} from "../../../api/customers/CustomerEntity";
import {
  createAdvancedCustomersAction,
  fetchCustomersAction,
  fetchSingleCustomersAction, removeCustomerAction,
} from "./customer-actions";
import {createActuallyAdapter} from "../../adapters";

export const customerAdapter = createActuallyAdapter<Customer>();

const initialState = customerAdapter.getInitialState();

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomersSuccessAction: customerAdapter.upsertMany,
    fetchSingleCustomerSuccessAction: customerAdapter.upsertOne
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersAction.fulfilled, customerAdapter.setAll);
    builder.addCase(fetchSingleCustomersAction.fulfilled, customerAdapter.upsertMany);
    builder.addCase(createAdvancedCustomersAction.fulfilled, customerAdapter.addManyToFront);
    builder.addCase(removeCustomerAction.fulfilled, customerAdapter.removeOne);
  }
});

const {actions, reducer} = customerSlice;

export const {
  fetchCustomersSuccessAction,
  fetchSingleCustomerSuccessAction
} = actions;

export default reducer;
