import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import VirtualList from "../../../components/virtual-list/VirtualList";
import ToggleSwitch from "../../../components/toggle-switch/ToggleSwitch";

import { PersonFullNameSelector } from "../../../components/people/PersonFullName";
import { membershipSelectors } from "./project-members-reducer";
import { useProjectMembershipAPI } from "./project-members-api";
import { If } from "../../../components/ui-utility/Conditional";
import ModalFrame from "../../../components/modal/ModalFrame";
import { receivePeopleAction } from "../../../store/ducks/people/actions";
import { initialState } from "../../../store/ducks/people/reducers";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

function ProjectMembershipList({users, projectId}) {
  const dispatch = useDispatch();

  const {
    createProjectMembership,
    destroyProjectMembership,
    updateProjectMembership,
    fetchMemberCandidates
  } = useProjectMembershipAPI();

  const [viewableUsers, setViewableUsers] = useState([]);

  const onMemberStatusChange = (userId, memberId, toMember) => {
    if (toMember) {
      createProjectMembership(projectId, userId);
    } else {
      destroyProjectMembership(memberId, userId);
    }
  };

  const onProjectManagerStatusChange = (memberId, toProjectManager) => {
    updateProjectMembership(memberId, {is_projectlead: toProjectManager});
  };

  // FIXME: Cannot add users as dependency. It changes the behaviour on a unwanted way(when adding new members, list gets ordered automatically)
  const fetchEssentialInformation = useCallback(async () => {
    const fetchedMemberCandidates = await fetchMemberCandidates(users);

    setViewableUsers(fetchedMemberCandidates);
  },[fetchMemberCandidates]);

  useEffect(() => {
    fetchEssentialInformation();

    return () => {
      dispatch(receivePeopleAction(initialState));
    };
  }, [dispatch, fetchEssentialInformation]);

  return (
    <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectMembersModal.modalHeader}} />}>
      <div className="row mb-3">
        <div className="col-lg-6 bold">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectMembersModal.tableHeaders.isMember}} />
        </div>
        <div className="col-lg-6 text-right bold">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectMembersModal.tableHeaders.isProjectManager}} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <VirtualList items={viewableUsers} height={810} defaultRowHeight={26} component={props =>
            <ProjectMembershipListRow
              item={props.item}
              onMemberSet={onMemberStatusChange}
              onProjectManagerSet={onProjectManagerStatusChange}/>
          }/>
        </div>
      </div>
    </ModalFrame>
  );
}

function ProjectMembershipListRow({item, onMemberSet, onProjectManagerSet}) {
  const memberity = useSelector(membershipSelectors.selectUserMemberId(item)) || {};

  const handleProjectManagerChange = (value) => {
    onProjectManagerSet(memberity.id, value)
  };

  const handleProjectMemberChange = (event) => {
    onMemberSet(item, memberity.id, event.target.checked)
  };

  return (
    <div className="row no-gutters">
      <div className="col-lg-6">
        <div className="form-check">
          <input type="checkbox"
                 id={item}
                 className="form-check-input"
                 checked={!!memberity.id}
                 onChange={handleProjectMemberChange}/>
          <label htmlFor={item}>
            <PersonFullNameSelector userId={item}/>
          </label>
        </div>
      </div>
      <div className="col-lg-6 text-right">
        <If condition={memberity.id}>
          <ToggleSwitch isChecked={memberity.is_projectlead} onChangeOfValue={handleProjectManagerChange}/></If>
      </div>
    </div>
  )
}


export default ProjectMembershipList;