import React from 'react';
import classNames from 'classnames';

import styled from 'styled-components';
import { ProfileBadgeIcon } from "../buttons";

import TenantChange from "../../screens/authentication/tenant-selection/TenantChange";
import { If } from "../ui-utility/Conditional";

import HeaderLink from "./components/HeaderLink";
import routes from "./route-definitions";

import './Header.css';
import { LocalizedMessage } from "../../intl/components/LocalizedMessage";
import { TRANSLATION_KEYS } from "../../intl/intl";
import { LocaleSelectInput } from '../../intl/components/LocaleSelectInput';

const Navigation = styled.nav`
  && {
    min-height: calc(100vh - 59px);
  }
`

const NavigationTop = styled.div`
  height: 100%;
`

const LocaleSelectorWrapper = styled.div`
  width: 100%;
`

const FlexHeader = ({ className, isCollapsed, ...props }) => {
  const navBarClasses = classNames("navbar", className,
    { "actually-navbar-collapsed": isCollapsed, "actually-navbar-uncollapsed": !isCollapsed });

  return (
    <Navigation className={navBarClasses}>
      <NavigationTop className="actually-nav-top">
        <ul className="navbar-nav">
          {routes.map(r =>
            <li className="actually-nav-section" key={r.key}>
              <HeaderLink key={r.path} to={r.path}>
                <div className="nav-item-row d-flex justify-content-start">
                  <span className="nav-icon-gutter">{r.icon()}</span>
                  <span className="nav-item-row-label">{r.name}</span>
                </div>
              </HeaderLink>
            </li>)}
          <li className="actually-nav-section mt-4">
            <HeaderLink to={'home'} exact={true}>
              <div className="nav-item-row d-flex justify-content-start">
                <span className="nav-icon-gutter"><ProfileBadgeIcon /></span>
                <span className="nav-item-row-label">
                  <LocalizedMessage text={{ id: TRANSLATION_KEYS.sidebar.myDashboard }} />
                </span>
              </div>
            </HeaderLink>
          </li>
          <If condition={!isCollapsed}>
            <li className="actually-nav-section mt-4">
              <div className="px-3">
                <TenantChange />
              </div>
            </li>
          </If>
        </ul>
        <If condition={!isCollapsed}>
          <LocaleSelectorWrapper className="px-3">
            <LocaleSelectInput />
          </LocaleSelectorWrapper>
        </If>
      </NavigationTop>
    </Navigation>
  )
};

const Header = styled(FlexHeader)`
  z-index: 2;
  flex-direction:column;
  min-height: 100vh;
  background-color: #102A43;
  background-color: #FFF;
  border-right: 1px solid #D9E2EC;
`;

export default Header;
