import React from "react";
import {useForm} from "react-hook-form";
import {TooltipCircle, TooltipOverlay} from "../../ui-utility/Tooltip";
import {HideFromSelf, useIsSelf} from "../../ui-utility/Conditional";
import {extractOnlyChangedAttributes} from "../../../api/utils";

import WithErrorInput from "../../../screens/timesheet/components/WithErrorInput";
import ToggleSwitch from "../../toggle-switch/ToggleSwitch";
import FormApiSelect from "../../../forms/FormApiSelect";
import FormActionRow from "../../../forms/FormActionRow";
import DeletePersonButton from "../delete-person/DeletePersonButton";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {FormElement, SimpleFormBuilder} from "../../forms/SimpleFormBuilder";
import Col from "react-bootstrap/Col";
import {BreatherRow} from "../../../screens/authentication/signup/SignUpEmailPhase";
import {ActuallyInput} from "../../forms/ActuallyInput";


export const AdminPromotionLabel = () => (<TooltipOverlay tooltipContent={<LocalizedMessage
  text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.isAdminLabelHelp}}/>}>
                <span>
                  <LocalizedMessage
                    text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.isAdminLabel}}/>
                </span>
</TooltipOverlay>);

function EditPersonForm({user, onSubmit, onCancel, errors}) {
  const isSelfEditing = useIsSelf(user.id);

  const {register, formState, handleSubmit, control, setValue, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      is_active: user.is_active,
      is_superuser: user.is_superuser,
      calendar: user.calendar,
      is_balance_visible: user.is_balance_visible,
      approver: user.approver
    }
  });
  const {dirty, isValid} = formState;

  const onSubmitProxy = (data) => {
    const changedAttributes = extractOnlyChangedAttributes(user, data);

    onSubmit(changedAttributes);
  };

  const userSelectQueryParameters = {
    "user.is_active": true
  }

  const formElements: Array<FormElement> = [
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.firstNameLabel}}/>
      ,
      input: <WithErrorInput errors={errors.first_name}>
        <input className="form-control" name="first_name" type="text" ref={register} tabIndex={1}/>
      </WithErrorInput>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.lastNameLabel}}/>
      ,
      input: <WithErrorInput errors={errors.last_name}>
        <input className="form-control" name="last_name" type="text" ref={register} tabIndex={2}/>
      </WithErrorInput>
    },
    {
      width: 12,
      label: <LocalizedMessage text={{ id: TRANSLATION_KEYS.commonProperties.email }} />
      ,
      input: <ActuallyInput type="email" disabled={true} value={user.email}/>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.isUserActiveLabel}}/>
      ,
      input: <ToggleSwitch innerRef={register} name="is_active" tabIndex={3}/>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.isBalanceVisibleLabel}}/>
      ,
      input: <ToggleSwitch innerRef={register} name="is_balance_visible" tabIndex={4}/>
    },
    {
      width: 6,
      isHidden: isSelfEditing,
      label: <AdminPromotionLabel/>,
      input: <ToggleSwitch innerRef={register} name="is_superuser" tabIndex={5}/>
    },
    {
      width: 12,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.calendarLabel}}/>
      ,
      input: <FormApiSelect variant="calendar" onChange={entity => setValue('calendar', entity.id)}
                            control={control} name="calendar" watch={watch}/>
    },
    {
      width: 12,
      label: (
        <>
          <LocalizedMessage
            text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.approver}}/>
          <TooltipCircle instructionText={
            <LocalizedMessage
              text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.approverTooltip}}/>
          }/>
        </>)
      ,
      input: <FormApiSelect variant="user" onChange={entity => setValue('approver', entity.id)}
                            control={control} name="approver" watch={watch} params={userSelectQueryParameters}/>
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmitProxy)}>
      <SimpleFormBuilder formElements={formElements}/>

      <BreatherRow>
        <Col className="text-right">
          <FormActionRow showDeleteButton={false} tabIndexStart={6}
                         isValid={isValid} dirty={dirty} handleCancel={onCancel}>
            <HideFromSelf id={user.id}>
              <DeletePersonButton userId={user.id} onDeleted={onCancel}/>
            </HideFromSelf>
          </FormActionRow>
        </Col>
      </BreatherRow>
    </form>
  )
}

EditPersonForm.defaultProps = {
  errors: {},
  user: {}
};

export default EditPersonForm;
