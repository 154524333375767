import {
  mergeObjectIntoEntities,
  mergeObjectIntoNestedResult,
  removeObjectFromEntities,
  removeObjectFromObjectResult,
} from "../../../store/helpers";

const authRightTypes = Object.freeze({
  'RECEIVE_AUTH_RIGHTS': 'RECEIVE_AUTH_RIGHTS',
  'NEW_GRANTED_RIGHT': 'NEW_GRANTED_RIGHT',
  'DELETE_GRANTED_RIGHT': 'DELETE_GRANTED_RIGHT'
});

const initialState = {
  entities: {
    authRights: {},
    grantedRights: {},
    authGroups: {},
    authRoles: {},
  },
  result: {
    auth_rights: [],
    granted_rights: [],
    auth_groups: [],
    auth_roles: [],
  }
};

const authRightReducer = (state = initialState, action) => {
  switch (action.type) {

    case authRightTypes.RECEIVE_AUTH_RIGHTS:
      return {...action.payload};

    case authRightTypes.NEW_GRANTED_RIGHT: {
      const {grantedRight} = action;

      const entities = mergeObjectIntoEntities(state, grantedRight, 'grantedRights');
      const result = mergeObjectIntoNestedResult(state, grantedRight, 'granted_rights');

      return {
        ...state,
        entities,
        result
      };
    }
    case authRightTypes.DELETE_GRANTED_RIGHT: {
      const entities = removeObjectFromEntities(state, action.grantedId, 'grantedRights');
      const result = removeObjectFromObjectResult(state, action.grantedId, 'granted_rights');

      return {
        ...state,
        entities,
        result
      };
    }
    default:
      return state;
  }
};

export {
  authRightReducer as default,

  initialState,
  authRightTypes
}