import { useDispatch } from "react-redux";

import { types } from "./project-members-reducer";
import { sortBy, keyBy } from 'lodash';

import { sendRequest, sendRequestSimple } from "../../../api/api";
import { getPeople, PeopleAPIResponseParser } from '../../../api/people/people-api';
import {
  deleteProjectMembership,
  getProjectMemberships, patchProjectMembership,
  postProjectMembership
} from '../../../api/projects/projects-api';
import { receivePeopleAction } from "../../../store/ducks/people/actions";
import { useCallback } from "react";

// FIXME: TB-160
const useProjectMembershipAPI = (): any => {
  const dispatch = useDispatch();

  const fetchProjectMemberships = async projectId => {
    const {response: members} = await sendRequestSimple(getProjectMemberships(projectId));

    if (members) {
      const responseWithNormalization = PeopleAPIResponseParser.parseNormalizedResponse({data: members.users});
      const user_ids = members.memberships.map(member => member.user);
      const user_member_map = keyBy(members.memberships, memberity => memberity.user);

      dispatch({type: types.MEMBERSHIPS_UPDATE_SUCCESS, payload: {user_ids, user_member_map}});
      dispatch(receivePeopleAction(responseWithNormalization));
    }
    return members;
  };

  const createProjectMembership = async (projectId, userId) => {
    const {response} = await sendRequestSimple(postProjectMembership(projectId, userId));

    if (response) {
      const [newMember] = response;

      dispatch({type: types.MEMBERSHIPS_NEW_MEMBER, newMember});
    }
  };

  const destroyProjectMembership = async (memberId, userId) => {
    const {error} = await sendRequestSimple(deleteProjectMembership(memberId));

    if (!error) {
      dispatch({type: types.MEMBERSHIPS_DELETE_MEMBER, ...{memberId, userId}});
    }
  };

  const updateProjectMembership = async (memberId, payload) => {
    const {response} = await sendRequestSimple(patchProjectMembership(memberId, payload));

    if (response) {
      dispatch({type: types.MEMBERSHIPS_PATCH_MEMBER, ...{userId: response.user, member: response}});
    }
  };

  const fetchMemberCandidates = useCallback(async (members) => {
    const params = {page_size: 0, 'user.is_active': true, fields: ['id', 'first_name', 'last_name']};

    const {response} = await sendRequest(getPeople(params));
    const responseWithNormalization = PeopleAPIResponseParser.parseNormalizedResponse(response);

    responseWithNormalization.result = sortBy(responseWithNormalization.result, id => {
      const indexOf = members.indexOf(id);
      return indexOf === -1 ? members.length : indexOf;
    });

    dispatch(receivePeopleAction(responseWithNormalization));

    return responseWithNormalization.result;
  },[dispatch]);

  return {
    fetchProjectMemberships,
    createProjectMembership,
    destroyProjectMembership,
    updateProjectMembership,

    fetchMemberCandidates,
  }

};


export {
  useProjectMembershipAPI,
};
