import { useState } from 'react';
import {
  postConfirmPasswordReset, postPasswordChange,
  requestPasswordReset
} from "../../../api/authentication/authentication-api";
import { sendRequestSimple } from "../../../api/api";
import ErrorResponse from "../../../api/errors";
import { setApiInfo } from "../../../store/ducks/api-notifier/api-notifier-actions";
import { useDispatch } from "react-redux";


export const usePasswordResetState = () => {

  const [state, setState] = useState({password: null, rePassword: null, oldPassword: null});

  return {
    password: state.password,
    rePassword: state.rePassword,
    oldPassword: state.oldPassword,

    setPassword: event => setState({...state, password: event.target.value}),
    setRePassword: event => setState({...state, rePassword: event.target.value}),
    setOldPassword: event => setState({...state, oldPassword: event.target.value}),
  }
};

// FIXME: TB-160
export const usePasswordResetApi = (): any => {
  const dispatch = useDispatch();

  const extractErrors = (errors) => {
    const errorFields = ['new_password1', 'new_password2', 'token', 'uid', 'old_password'];
    const extractedErrors = new ErrorResponse(errors).extractAllErrors(errorFields);

    return {error: extractedErrors};
  };

  const postReset = async (uid, token, password, rePassword) => {
    const {response, error} = await sendRequestSimple(postConfirmPasswordReset(uid, token, password, rePassword));

    if (error && error.data) {
      return extractErrors(error.data);
    }

    return {response: {user: response.user, password: password}};
  };

  const requestForLink = async (email) => {
    const {response} = await sendRequestSimple(requestPasswordReset(email));

    if (response) {
      dispatch(setApiInfo(true, 'Password reset e-mail has been sent.'));
    }

    return {response};
  };

  const postPassword = async (newPassword, rePassword, currentPassword) => {
    const {response, error} = await sendRequestSimple(postPasswordChange(newPassword, rePassword, currentPassword));

    if (error && error.data) {
      return extractErrors(error.data);
    } else {
      dispatch(setApiInfo(true, 'New password has been saved.'));
    }

    return {response};
  };

  return {
    postReset,
    requestForLink,
    postPassword
  }
};
