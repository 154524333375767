import React from 'react';

import filterSelectors from "../../store/ducks/filter/filter-selectors";

import FilterBlockEntity from './entities/FilterBlockEntity';

import ActiveFilterField from "./components/ActiveFilterField";
import useShallowEqualSelector from "../../store/helpers";
import styled from 'styled-components';
import { LocalizedMessage } from '../../intl/components/LocalizedMessage';
import { TRANSLATION_KEYS } from '../../intl/intl';

const StyledActiveBlock = styled.div`
  display: inline-block;
  width: 100%;
`

const ActiveBlockLabel = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`

const ActiveFilterFieldWrapper = styled.div`
  margin-top: .5rem;
  white-space: normal;
`;

//FIXME: TB-160
interface OwnPropTypes {
  filterBlock: FilterBlockEntity;
}

export type ActiveFiltersProps = OwnPropTypes;

export const ActiveFilters: React.FunctionComponent<ActiveFiltersProps> = ({
  filterBlock
}) => {

  const activeFilters = useShallowEqualSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock));

  const renderIfActiveFilters = () => {
    if (activeFilters.length)
      return template;
    return null;
  };

  const template = (
    <StyledActiveBlock>
      <ActiveBlockLabel>
        <LocalizedMessage text={{ id: TRANSLATION_KEYS.search.activeFiltersHeader }} />
      </ActiveBlockLabel>
      {activeFilters.map((item, index) => (
        <ActiveFilterFieldWrapper key={index}>
          <ActiveFilterField filter={item} />
        </ActiveFilterFieldWrapper>))
      }
    </StyledActiveBlock>
  );

  return renderIfActiveFilters();
}