import React from "react";
import DateFormatter from "../../../../components/ui-utility/DateFormatter";
import { DescriptionTxt } from "../../../../components/text/DescriptionTxt";
import TimeValue from "../../../../components/TimeValue";
import { PersonFullNameSelector } from "../../../../components/people/PersonFullName";
import { ActuallyButton } from "../../../../components/buttons";

function BalanceFixRow({balanceFix, onSelect}) {
  return (
    <tr>
      <td>
        <ActuallyButton variant="link" className="btn-plain" onClick={onSelect}>
          <DateFormatter date={balanceFix.date}/>
        </ActuallyButton>
      </td>
      <td><PersonFullNameSelector userId={balanceFix.user}/></td>
      <td><DescriptionTxt text={balanceFix.description} cutLimit={30}/></td>
      <td className="text-right"><TimeValue minutes={balanceFix.minutes}/></td>
    </tr>
  )
}


export default BalanceFixRow;