import {createDeleteRequest, createGetRequest, createPatchRequest, createPostRequest} from "../../api";
import {joinRouteParts} from "../../utils";

const baseUrl = 'cycle-template';

const getCycleTemplates = params => createGetRequest({url: baseUrl, params});

const patchCycleTemplate = (templateId, data) => {
  const url = joinRouteParts(baseUrl, templateId);

  return createPatchRequest({url, data});
};

const postCycleTemplate = data => {
  return createPostRequest({url: baseUrl, data});
};

const deleteCycleTemplate = templateId => {
  const url = joinRouteParts(baseUrl, templateId);

  return createDeleteRequest({url});
};


export {
  getCycleTemplates,
  patchCycleTemplate,
  postCycleTemplate,
  deleteCycleTemplate
};