import {isSameDay, parseISO} from "../../../../util/dates";
import {isWithinInterval} from "date-fns";

export class TimesheetRowManager {
  static resolveRecordsForTaskInTimeInterval(records, taskId, startDate, endDate) {
    const taskRecordsInRange = Object.values(records).filter(
      // FIXME: TB-160
      (record: any) => isWithinInterval(parseISO(record.date), {
        start: startDate,
        end: endDate,
      }) && record.task === taskId
    );
    
    // FIXME: TB-160
    return taskRecordsInRange.reduce((acc, record: any) => acc + record.minutes, 0);
  }

  static extractTaskRecordsForGivenDay(records, taskId, date) {
    return Object.values(records).filter(
      // FIXME: TB-160
      (record: any) => record.task === taskId && isSameDay(parseISO(record.date), date)
    );
  }

  static sumTaskRecordsForGivenDay(records, taskId, date) {
    const taskRecords = TimesheetRowManager.extractTaskRecordsForGivenDay(records, taskId, date);

    // FIXME: TB-160
    return taskRecords.reduce((acc, record: any) => acc + record.minutes, 0);
  }

  static sumAllTaskRecordsForGivenDay(recordsMap, date) {
    // FIXME: TB-160
    const daysRecords = Object.values(recordsMap).filter((record: any) => isSameDay(parseISO(record.date), date));

    return TimesheetRowManager.sumAllRecords(daysRecords);
  }

  static sumAllRecords(records, keyForMinutes = 'minutes') {
    return records.reduce((totalMinutes, record) => totalMinutes + record[keyForMinutes], 0);
  }

  static sumRecordsBelongingToSameDate(records) {
    const recordMapByDate = records.reduce((dateRecordsSum, singleUserRecord) => {
      const {date, minutes_sum} = singleUserRecord;

      if (dateRecordsSum[date]) {
        dateRecordsSum[date].minutes_sum += minutes_sum;

        return dateRecordsSum;
      }

      dateRecordsSum[date] = {date, minutes_sum};

      return dateRecordsSum;
    }, {});

    return Object.values(recordMapByDate);
  }

}


