import * as types from './types';

const initialState = {
  isLoading: true,
  systemUserId: null,
  userId: null,
  tenantId: null,
  checkoutRequired: false,
  end_of_trial: null,
  is_on_initial_trial: false,
  notifications: 0, // Fixme: This is temporary location for this information. Move this as soon as we implement a proper notification mechanism
  tenants: [],
  locale: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_TENANT_SUCCESS:
    case types.GET_CURRENT_USER_SUCCESSFUL:
    case types.AUTHENTICATION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        userId: action.payload.id,
        systemUserId: action.payload.system_user_id,
        tenantId: action.payload.tenant_id,
        notifications: action.payload.notifications,
        end_of_trial: action.payload.end_of_trial,
        is_on_initial_trial: action.payload.is_on_initial_trial,
        locale: action.payload.locale
      };
    case types.LOGOUT_REQUESTED:
      return {
        ...state,
      };

    case types.UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        notifications: action.payload.notifications
      };

    case types.RESET_STATE:
      return {
        ...initialState,
        isLoading: false,
      };

    case types.SET_CHECKOUT_REQUIRED:
      return {
        ...state,
        checkoutRequired: action.payload.checkoutRequired
      }
    default:
      return state;
  }
}