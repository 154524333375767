import {createAsyncThunk} from "@reduxjs/toolkit";
import {sendRequestSimple} from "../../../api/api";
import {deleteCustomer, getCustomers, saveCustomersAdvanced} from "../../../api/customers/customers-api";
import {changePaginationForPageAction} from "../pagination/pagination-actions";
import {FetchCustomersActionProps} from "./customer-types";
import {paginationKeys} from "../pagination/pagination-keys";
import {ActuallyRootState} from "../../index";
import paginationSelectors from "../pagination/pagination-selectors";

export const fetchCustomersAction = createAsyncThunk("customers/fetch",
  async (params: FetchCustomersActionProps, thunkAPI) => {
    const {response} = await sendRequestSimple(getCustomers(params), true);

    thunkAPI.dispatch(
      changePaginationForPageAction(response, paginationKeys.CUSTOMER_LISTING)
    );

    return response.data;
  }
);

export const fetchSingleCustomersAction = createAsyncThunk("customers/fetchSingle",
  async (id: string) => {
    const params = {"customer.id": id};

    const {response} = await sendRequestSimple(getCustomers(params), true);

    return response.data;
  }
);


export const createAdvancedCustomersAction = createAsyncThunk("customers/createAdvanced",
  async (customersText: string, thunkAPI) => {
    const {response} = await sendRequestSimple(saveCustomersAdvanced(customersText), true);

    const customerPagination = paginationSelectors.selectPaginationForPage(paginationKeys.CUSTOMER_LISTING)(thunkAPI.getState() as ActuallyRootState);

    const updatedPagination = updateCustomerPaginationWithNewCount(customerPagination, response.length);

    thunkAPI.dispatch(
      changePaginationForPageAction(updatedPagination, paginationKeys.CUSTOMER_LISTING)
    )

    return response;
  }
);


export const removeCustomerAction = createAsyncThunk("customers/removeCustomer",
  async (customerId: number, thunkAPI) => {
    await sendRequestSimple(deleteCustomer(customerId), true);

    const customerPagination = paginationSelectors.selectPaginationForPage(paginationKeys.CUSTOMER_LISTING)(thunkAPI.getState() as ActuallyRootState);
    const updatedPagination = updateCustomerPaginationWithNewCount(customerPagination, -1);

    thunkAPI.dispatch(
      changePaginationForPageAction(updatedPagination, paginationKeys.CUSTOMER_LISTING)
    )

    return customerId;
  }
);

const updateCustomerPaginationWithNewCount = (customerPagination, increment) => {
  return {
    ...customerPagination,
    count: customerPagination.count + increment,
    current: customerPagination.current || 1,
    totalPages: customerPagination.totalPages || 1,
  };
}