import { createGetRequest, createPutRequest, createPostRequest, createDeleteRequest } from "../api";
import { joinRouteParts } from "../utils";

import { formatISO } from "../../util/dates";

const baseUrl = 'timesheet';

const fetchDailyTimesheet = (userId, date, enddate) => {
  const apiUrl = joinRouteParts(baseUrl, userId, 'user_records');
  const shortIsoDate = formatISO(date);
  const isoEnddate = enddate ? formatISO(enddate) : null;

  return createGetRequest({url: apiUrl, params: {date: shortIsoDate, enddate: isoEnddate}});
};

const fetchUserMonthlyStatus = (userId, startDate, endDate) => {
  const apiUrl = joinRouteParts(baseUrl, userId, 'user_status');

  const start = formatISO(startDate);
  const end = formatISO(endDate);

  return createGetRequest({url: apiUrl, params: {start_date: start, end_date: end}});
};

const addRecord = data => createPostRequest({url: baseUrl, data});

const updateRecord = data => {
  const apiUrl = joinRouteParts(baseUrl, data.id);

  return createPutRequest({url: apiUrl, data});
};

const deleteRecord = recordId => {
  const apiUrl = joinRouteParts(baseUrl, recordId);

  return createDeleteRequest({url: apiUrl});
};


const saveTotalForDay = data => {
  const apiUrl = joinRouteParts(baseUrl, 'save_total_for_day');

  return createPostRequest({url: apiUrl, data});
};

const addPinnedTask = data => {
  const apiUrl = joinRouteParts('pinned-user-task');

  return createPostRequest({url: apiUrl, data});
};

const removePinnedTask = userId => {
  const apiUrl = joinRouteParts('pinned-user-task', userId);

  return createDeleteRequest({url: apiUrl});
};

const addPinForEveryProjectTask = data => {
  const apiUrl = joinRouteParts('pinned-user-task', 'pin_project');

  return createPostRequest({url: apiUrl, data});
};

export {
  fetchDailyTimesheet,
  fetchUserMonthlyStatus,
  addRecord,
  updateRecord,
  deleteRecord,
  saveTotalForDay,

  removePinnedTask,
  addPinnedTask,
  addPinForEveryProjectTask
}
