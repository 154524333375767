import * as React from "react";
import { ColumnSorter } from "../../column-sorter/ColumnSorter";
import {
  LocalizedText,
  LocalizedMessage,
} from "../../../intl/components/LocalizedMessage";
import styled from "styled-components";
import { TableColumnContentAlignment } from "../types/TableColumnContentAlignment";
import { TableColumnWrapper } from "./table-util-components";

const ActuallyTableHeaderWrapper = styled(TableColumnWrapper)`
  padding-bottom: 4px;
`;

interface TableHeaderLabelWrapperProps {
  sortable: number;
}

const TableHeaderLabelWrapper = styled.span<TableHeaderLabelWrapperProps>`
  && {
    font-weight: bold;
  }
  ${(props) =>
    props.sortable
      ? `font-weight : bold;
         user-select: none;
        `
      : ""}
`;

interface OwnPropTypes {
  field: string;
  label?: LocalizedText;
  component?: React.ReactElement;
  isSortable?: boolean;
  sortBy?: string;
  ordering?: Array<string>;
  columnContentAlignment?: TableColumnContentAlignment;
  onOrderingChange?: (newOrdering: Array<string>) => void;
}

export type ActuallyTableHeaderProps = OwnPropTypes;

interface TableHeaderLabelProps {
  label?: LocalizedText;
  sortable?: boolean;
  highlighted?: boolean;
}

const TableHeaderLabel: React.FunctionComponent<TableHeaderLabelProps> = ({
  sortable = false,
  label,
}) => (
    <TableHeaderLabelWrapper sortable={sortable ? 1 : 0}>
      {label && <LocalizedMessage text={label} />}
    </TableHeaderLabelWrapper>
  );

const renderLabelComponent = (label?: LocalizedText, component?: React.ReactElement, isSortable?: boolean) =>
  component ? component : <TableHeaderLabel sortable={isSortable} label={label} />;

export const ActuallyTableHeader: React.FunctionComponent<ActuallyTableHeaderProps> = ({
  field,
  label,
  isSortable,
  sortBy,
  ordering,
  component,
  columnContentAlignment,
  onOrderingChange,
}) => {
  return <td>
    <ActuallyTableHeaderWrapper alignment={columnContentAlignment}>
      {isSortable ? (
        <ColumnSorter
          field={sortBy ? sortBy : field}
          currentOrdering={ordering}
          onOrderingChange={onOrderingChange}
        >
          {renderLabelComponent(label, component, isSortable)}
        </ColumnSorter>
      ) : (
          <>
            {renderLabelComponent(label, component)}
          </>
        )}
    </ActuallyTableHeaderWrapper>
  </td>
}
