import React, { useMemo } from "react";

import ActuallySelect from "./ActuallySelect";
import GeneralApiResponseParser from "../../api/general-api-response-parser";

import {getUnsubmittedCycles} from "../../api/approval/cycle/cycle-api";
import {formatWithCurrentLocale} from "../ui-utility/dates/format-with-locale";

function UnSubmittedUserCycleSelect({onChange, userId}) {
  const cycleApi = useMemo(() => ({fetch: () => getUnsubmittedCycles(userId)}), [userId]);

  const labelExtractor = cycle => {
    const startDate = formatWithCurrentLocale(cycle.start_date, 'P');
    const endDate = formatWithCurrentLocale(cycle.end_date, 'P');

    return `${startDate} - ${endDate}`;
  }

  const makeCycleLabels = cycles => {
    return GeneralApiResponseParser.convertRecordsIntoSelectCompatible(cycles, labelExtractor);
  };

  const entityId = null;

  return (
    <ActuallySelect api={cycleApi} onChange={onChange} entityId={entityId} name="cycle-select"
                    onSelectionPostProcess={makeCycleLabels}/>
  )
}

export default UnSubmittedUserCycleSelect;