import {useCallback, useEffect} from 'react';
import {useDispatch} from "react-redux";

import {sendRequestSimple} from "../../../api/api";
import {
  deleteCycleTemplate,
  getCycleTemplates,
  patchCycleTemplate,
  postCycleTemplate
} from "../../../api/approval/cycle-template/cycle-template-api";

import {receiveTemplatesAction, removeTemplateAction} from "../../../store/ducks/cycle/cycle-template-reducers";

import GeneralApiResponseParser from "../../../api/general-api-response-parser";

const useCycleTemplateAPI = () => {
  const dispatch = useDispatch();

  const fetchCycleTemplates = useCallback(async () => {
    const params = {"page_size": 0};

    const {response} = await sendRequestSimple(getCycleTemplates(params));

    if (response) {
      const normalized = GeneralApiResponseParser.normalizeListingNoSchema(response);

      dispatch(receiveTemplatesAction(normalized));
    }

  }, [dispatch]);

  const createCycleTemplate = async payload => {
    const {response, error} = await sendRequestSimple(postCycleTemplate(payload));

    if (response) {
      const normalized = GeneralApiResponseParser.normalizeListingNoSchema([response]);

      dispatch(receiveTemplatesAction(normalized));

      return {response};
    }

    const extractedErrors = GeneralApiResponseParser.extractAllErrors(error.data);

    return {response, error: extractedErrors};
  }

  const updateCycleTemplate = async (templateId, payload) => {
    const {response, error} = await sendRequestSimple(patchCycleTemplate(templateId, payload));

    if (response) {
      const normalized = GeneralApiResponseParser.normalizeListingNoSchema([response]);

      dispatch(receiveTemplatesAction(normalized));

      return {response};
    }

    const extractedErrors = GeneralApiResponseParser.extractAllErrors(error.data);

    return {response, error: extractedErrors};
  }

  const destroyCycleTemplate = async templateId => {
    const {error} = await sendRequestSimple(deleteCycleTemplate(templateId));

    if (error) {
      const extractedErrors = GeneralApiResponseParser.extractAllErrors(error.data);

      return {error: extractedErrors};
    }

    dispatch(removeTemplateAction({templateId}));

    return {error};
  }

  return {
    fetchCycleTemplates,
    createCycleTemplate,
    updateCycleTemplate,
    destroyCycleTemplate
  }
}

const useCycleTemplateFetching = () => {
  const {fetchCycleTemplates} = useCycleTemplateAPI();

  useEffect(() => {
    fetchCycleTemplates();
  }, [fetchCycleTemplates]);
}


export {
  useCycleTemplateAPI as default,
  useCycleTemplateFetching
}