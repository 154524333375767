import {createSlice, createEntityAdapter} from "@reduxjs/toolkit";

export const workdayLengthAdapter = createEntityAdapter();

const initialState = workdayLengthAdapter.getInitialState();

const workdayLengthSlice = createSlice({
  name: 'userWorkdayLengths',
  initialState,
  reducers: {
    userWorkdayLengthsFetchedAction: workdayLengthAdapter.upsertMany,
    userWorkdayLengthAddedAction: workdayLengthAdapter.addOne,
    userWorkdayLengthEditedAction: workdayLengthAdapter.upsertOne,
    userWorkdayLengthDeletedAction: workdayLengthAdapter.removeOne,
  }
});

const {actions, reducer} = workdayLengthSlice;

export const {
  userWorkdayLengthsFetchedAction,
  userWorkdayLengthAddedAction,
  userWorkdayLengthEditedAction,
  userWorkdayLengthDeletedAction,
} = actions;

export default reducer;
