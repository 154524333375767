import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Toast from 'react-bootstrap/Toast'

import { ExclamationTriangleIcon, CheckCircleIcon, InfoCircleIcon, BanIcon, SpinnerIcon } from "../buttons";

import './Notification.css';
import { LocalizedMessage } from "../../intl/components/LocalizedMessage";
import { TRANSLATION_KEYS } from "../../intl/intl";

function NotificationToast({variant, shouldDisplay, onDismiss, children=undefined, duration}) {
  useEffect(() => {
    if (shouldDisplay) {
      const interval = setInterval(onDismiss, duration);

      return () => clearInterval(interval);
    }
  }, [duration, onDismiss, shouldDisplay]);

  const render = () => {
    if (!shouldDisplay)
      return null;

    return (
      <Toast show={shouldDisplay} onClose={onDismiss} className="notification-toast">
        {resolveVariant()}
      </Toast>
    )
  };

  const resolveVariant = () => {
    if (variant === 'pending')
      return <PendingToast/>;
    if (variant === 'error')
      return <ErrorToast/>;
    if (variant === 'success')
      return <SuccessToast/>;
    if (variant === 'info')
      return <InfoToast>{children}</InfoToast>;
    if (variant === 'unauthorized')
      return <UnAuthorizedToast/>;
    if (variant === 'validation-error')
      return <ValidationErrorToast/>;
    return null;
  };

  return render();
}

NotificationToast.defaultProps = {
  duration: 5000
};

function PendingToast() {
  return (
    <div className="pending-toast">
      <Toast.Header>
        <SpinnerIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.pending.header}} /></strong>
      </Toast.Header>
      <Toast.Body><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.pending.message}} /></Toast.Body>
    </div>
  )
}

function ErrorToast() {
  return (
    <div className="error-toast">
      <Toast.Header>
        <ExclamationTriangleIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.error.header}} /></strong>
      </Toast.Header>
      <Toast.Body>
      <LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.error.message}} />
      </Toast.Body>
    </div>
  )
}

function ValidationErrorToast() {
  return (
    <div className="validation-error-toast">
      <Toast.Header>
        <ExclamationTriangleIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.badRequest.header}} /></strong>
      </Toast.Header>
      <Toast.Body>
      <LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.badRequest.message}} />
      </Toast.Body>
    </div>
  )
}

function SuccessToast() {
  return (
    <div className="success-toast">
      <Toast.Header>
        <CheckCircleIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.success.header}} /></strong>
      </Toast.Header>
      <Toast.Body><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.success.message}} /></Toast.Body>
    </div>
  )
}

function InfoToast(props) {
  return (
    <div className="info-toast">
      <Toast.Header>
        <InfoCircleIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.info.header}} /></strong>
      </Toast.Header>
      <Toast.Body>{props.children}</Toast.Body>
    </div>
  )
}

function UnAuthorizedToast() {
  return (
    <div className="unauthorized-toast">
      <Toast.Header>
        <BanIcon/>
        <strong className="toast-heading"><LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.unAuthorized.header}} /></strong>
      </Toast.Header>
      <Toast.Body>
      <LocalizedMessage text={{id: TRANSLATION_KEYS.toastNotifications.unAuthorized.message}} />
      </Toast.Body>
    </div>
  )
}

NotificationToast.propTypes = {
  shouldDisplay: PropTypes.bool,
  onDismiss: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success', 'pending', 'info', 'unauthorized', 'validation-error'])
};


export default NotificationToast;