import {workdayLengthAdapter} from "./workday-length-reducer";

const IMMUTABLE_LIST = [];

const {
  selectAll
} = workdayLengthAdapter.getSelectors((state: any) => state.workdayLengths);

const selectWorkdayLengthEntities = state => state.workdayLengths.entities;

const selectWorkdayLengthsByUser = userId => state => {
  const workdayLengths = selectAll(state);

  if (workdayLengths.length === 0)
    return IMMUTABLE_LIST;

  return workdayLengths.filter((workdayLength: any) => workdayLength.user === userId);
};

const selectUserWorkdayLengthList = userId => state => {
  const workdayLengths = selectWorkdayLengthEntities(state);

  if (!workdayLengths)
    return null;

  // FIXME: TB-160
  return Object.values(workdayLengths).filter((workdayLength: any) => Number(workdayLength.user) === Number(userId));
};

export {
  selectWorkdayLengthsByUser,
  selectUserWorkdayLengthList,
};
