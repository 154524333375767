import React from "react";
import { BaseIcon } from "../buttons";
import '../../screens/projects/icons/ProjectInactiveIcon.css';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InactiveIcon = ({className='', children, ...props}) => {
  const tooltip = <Tooltip id="inactive-tooltip">
    {children}
  </Tooltip>;

  return (
    <OverlayTrigger overlay={tooltip} placement="bottom">
      <BaseIcon iconName="exclamation-circle" className={classNames('inactive-icon', className)} {...props} />
    </OverlayTrigger>
  )
};

export default InactiveIcon;
