import React from 'react';

import PropTypes from 'prop-types';
import {ColumnRowEntityManager} from "./ColumnBlockManager";

import { ColumnBlock } from "./ColumnBlock";

import './ColumnBlock.css';

function ColumnBlockContainer({columns, selectedColumns, isClearable, onColumnsChanged}) {
  const columnRowManager = new ColumnRowEntityManager(columns, selectedColumns.slice());

  const availableColumns = columnRowManager.getAvailableColumns();
  const chosenColumns = columnRowManager.getUiColumns();

  const handleColumnSelection = (position, selectedColumn) => {
    columnRowManager.selectUiColumn(position, selectedColumn);
    updateColumnState();
  };

  const handleColumnDeletion = column => {
    columnRowManager.deleteUiColumn(column);
    updateColumnState();
  };

  const handleColumnMoving = (column, directionIsUp) => {
    columnRowManager.moveUiColumn(column, directionIsUp);
    updateColumnState();
  };

  const handleColumnValueChange = (column, newValue) => {
    columnRowManager.setNewUiColumnValue(column, newValue);
    updateColumnState();
  };

  const updateColumnState = () => {
    onColumnsChanged(columnRowManager.getSelectedColumns());
  };

  return (
      <ColumnBlock chosenColumns={chosenColumns}
                   availableColumns={availableColumns}
                   isClearable={isClearable}
                   handleColumnDeletion={handleColumnDeletion}
                   handleColumnMoving={handleColumnMoving}
                   handleColumnSelection={handleColumnSelection}
                   handleColumnValueChange={handleColumnValueChange}/>
  );
}

ColumnBlockContainer.propTypes = {
  columns: PropTypes.array,
  isClearable: PropTypes.bool,
  onApplyColumns: PropTypes.func
};

export default ColumnBlockContainer;