import styled from "styled-components";

const resolveStatusColor = (status: string) => {
  switch (status) {
    case "STRIPE":
    case "BILL":
      return "#69DDBE";
    case "TRIAL":
      return "#EEE767";
    default:
      return "#EE7F67";
  }
}

interface TenantStatusProps {
  status: string;
}

export const TenantStatusIndicator = styled.span<TenantStatusProps>`
  color: ${props => resolveStatusColor(props.status)};
  font-weight: 700;
`;