import React from "react";
import PropTypes from 'prop-types';

import DateFormatter from "../ui-utility/DateFormatter";

import { useSelector } from "react-redux";

import { selectProject } from "../../store/ducks/projects/selectors";
import { If } from "../ui-utility/Conditional";

import { TooltipOverlay } from "../ui-utility/Tooltip";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

export const DateRange = ({startDate, endDate}) => {
  return (
    <>
      <DateFormatter date={startDate} placeholder="Open"/> - <DateFormatter date={endDate} placeholder="Open"/>
    </>
  );
};

function ProjectSchedule({projectId, ...props}) {
  const project = useSelector(selectProject(projectId)) || {};
  const hasSchedule = !!(project.start_date || project.end_date);

  return (
    <TooltipOverlay tooltipContent="Project schedule">
      <div className="d-inline-block" {...props}>
        <If condition={hasSchedule}>
          <DateRange startDate={project.start_date} endDate={project.end_date}/>
        </If>
        <If condition={!hasSchedule}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.scheduleNotSetMessage}}/>
        </If>
      </div>
    </TooltipOverlay>
  )
}


ProjectSchedule.propTypes = {
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  id: PropTypes.number
};

export {
  ProjectSchedule as default,
};

