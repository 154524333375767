import React from "react";
import { BaseIcon } from "../buttons";

const OpenCalendarIcon = () => {
  return (
    <BaseIcon iconName="calendar" style={{ color: '#BCCCDC' }} />
  )
};

export default OpenCalendarIcon;
