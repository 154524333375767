import {TRANSLATION_KEYS} from "../../../../intl/intl";

export const authGroupTableColumns = [
  {
    key: 'name',
    label: {id: TRANSLATION_KEYS.authorizationGroupSettings.authorizationGroupManagement.groupTableHeaders.name}
  },
  {
    key: 'description',
    label: {id: TRANSLATION_KEYS.authorizationGroupSettings.authorizationGroupManagement.groupTableHeaders.description}
  },
  {
    key: 'users',
    label: {id: TRANSLATION_KEYS.authorizationGroupSettings.authorizationGroupManagement.groupTableHeaders.peopleAmount}
  },
];