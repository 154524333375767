import React from 'react';
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

interface DateGroupingSelectionProps {
  onChange: (event: Event) => void;
  value: string;
}

export const DateGroupingSelection: React.FunctionComponent<DateGroupingSelectionProps> = ({onChange, value}) => {
  const handleChange = event => onChange(event.target.value);
  const isSelected = (radioValue) => value === radioValue;

  return (
    <div>
      <input type="radio" name="timeslot" value="day"
             onChange={handleChange} checked={isSelected('day')}/>&nbsp;

      <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.columns.day}}/>

      <br/>

      <input type="radio"
             name="timeslot"
             value="week"
             onChange={handleChange}
             checked={isSelected('week')}/>&nbsp;

      <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.columns.week}}/>

      <br/>

      <input type="radio" name="timeslot" value="month"
             onChange={handleChange} checked={isSelected('month')}/> &nbsp;
      <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.columns.month}}/>

      <br/>

      <input type="radio" name="timeslot" value="year"
             onChange={handleChange} checked={isSelected('year')}/>&nbsp;

      <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.columns.year}}/>

      <br/>

    </div>
  );
}
