import React from "react";

import DualListBox from "react-dual-listbox";

import {
  AngleDownIcon,
  AngleLeftIcon,
  AngleRightIcon,
  AngleUpIcon,
  DoubleAngleLeftIcon,
  DoubleAngleRightIcon
} from "../../buttons";

import '../Select.css';

const icons = {
  moveLeft: <AngleLeftIcon/>,
  moveAllLeft: <DoubleAngleLeftIcon/>,
  moveRight: <AngleRightIcon/>,
  moveAllRight: <DoubleAngleRightIcon/>,
  moveUp: <AngleUpIcon/>,
  moveDown: <AngleDownIcon/>,
};

function DualBoxSelect({options, selected, onChange}) {

  return (
    <DualListBox options={options} selected={selected}
                 onChange={onChange} icons={icons}/>
  )
}

DualBoxSelect.defaultProps = {
  options: [],
  selected: []
};

export default DualBoxSelect;