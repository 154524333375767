import React, { useState, useEffect } from 'react';

import WeekDaySelector from "./WeekDaySelector";
import { addDays, today, subDays, dayRange, isSameDay, isBefore, differenceInDays } from '../../../../util/dates';
import { CurrentDayButton, NextWeekButton, PreviousWeekButton } from '../../../../components/buttons';

function WeekNavigator({currentDate, onChangeDate}) {
  // FIXME: TB-160
  const [prevDate, setPrevious] = useState<any>(null);
  const [curDate, setCurrentDate] = useState(currentDate);
  // FIXME: TB-160
  const [dates, setDates] = useState<any>([]);

  const getAxisDates = givenDate => {
    const startDate = subDays(givenDate, 3);

    return dayRange(startDate, 6);
  };

  const handleDateChange = destinationDate => {
    const diffInDays = Math.abs(differenceInDays(destinationDate, curDate));

    if (diffInDays > 7) {
      const shiftDirection = isBefore(curDate, destinationDate) ? -7 : 7;
      // FIXME: TB-160
      const shiftedPrevDate: any = addDays(destinationDate, shiftDirection);

      setPrevious(shiftedPrevDate);
    } else {
      setPrevious(curDate);
    }

    setCurrentDate(destinationDate);

    onChangeDate(destinationDate);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSameDay(prevDate, curDate) && prevDate) {
        const nextStepDate = isBefore(curDate, prevDate) ? subDays(prevDate, 1) : addDays(prevDate, 1);
        const dates = getAxisDates(nextStepDate);

        setPrevious(nextStepDate);
        setDates(() => dates);
      } else {
        const dates = getAxisDates(curDate);

        setDates(() => dates);
      }
    }, 50);

    return () => clearInterval(interval);

  }, [prevDate, curDate]);

  const isUpdating = (!isSameDay(prevDate, curDate) && prevDate);

  const activeDate = isUpdating ? null : curDate;

  return (
    <div className="d-flex justify-content-around align-items-center" style={{marginBottom: '20px'}}>
      <div>
        <PreviousWeekButton style={{color: '#BCCCDC'}} onClick={() => handleDateChange(addDays(curDate, -7))}
                            disabled={isUpdating}/>
      </div>

      <div>
        <CurrentDayButton style={{color: '#BCCCDC'}} onClick={() => handleDateChange(today())}
                          disabled={isUpdating}/>
      </div>

      <WeekDaySelector currentDate={activeDate} dates={dates} onChangeDate={handleDateChange}/>

      <div>
        <NextWeekButton style={{color: '#BCCCDC'}} onClick={() => handleDateChange(addDays(curDate, 7))}
                        disabled={isUpdating}/>
      </div>
    </div>
  );
}

export default WeekNavigator;