import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-bootstrap/Collapse'

import { AngleDownIcon, AngleUpIcon } from "../buttons";
import './Collapsible.css';
import styled from 'styled-components';

const CollapsibleLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

function Collapsible(props) {
  const [isOpen, setIsOpen] = useState(false);

  const renderAngle = isOpen ? <AngleUpIcon /> : <AngleDownIcon />;

  return (
    <>
      <CollapsibleLabel onClick={() => setIsOpen(!isOpen)}>
        {props.label}
        {renderAngle}
      </CollapsibleLabel>
      <Collapse in={isOpen}>
        <div>{props.content}</div>
      </Collapse>
    </>
  );
}


Collapsible.propTypes = {
  label: PropTypes.element,
  content: PropTypes.element
};


export default Collapsible;