import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { parseISO } from "../../../../util/dates";
import { PersonFullNameSelector } from "../../../../components/people/PersonFullName";
import { extractOnlyChangedAttributes } from "../../../../api/utils";
import { If } from "../../../../components/ui-utility/Conditional";
import { Time } from "../../../../util/Time";

import ActuallyDatePicker from "../../../../components/datepicker/ActuallyDatePicker";
import FormTimeInput from "../../../../forms/FormTimeInput";
import WithErrorInput from "../../../timesheet/components/WithErrorInput";
import FormActionRow from "../../../../forms/FormActionRow";
import FormApiSelect from "../../../../forms/FormApiSelect";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";

function AddEditBalanceFix({entity, api, onDone=()=>undefined, userId}) {
  // FIXME: TB-160
  const [errors, setErrors] = useState<any>({});

  const {handleSubmit, register, control, formState, setValue, watch} = useForm({
    mode: "onChange",
    defaultValues: {
      user: (entity.user || userId),
      description: entity.description,
      date: entity.date && parseISO(entity?.date),
      minutes: Time.FromMinutes(entity.minutes)?.toColonSeparated(true),
    }
  });

  const onSubmit = async data => {
    const minutes = Time.FromUserInput(data.minutes).minutes;
    const attributes = extractOnlyChangedAttributes(entity, {user:userId, ...data, minutes});

    const {response, error} = await api.saveBalanceFix(entity.id, attributes);

    error && setErrors(error);
    response && onDone();
  };

  const handleDelete = async () => {
    await api.destroyBalanceFix(entity.id);

    onDone && onDone();
  };

  const {isValid, dirty} = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="user">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.person}} />
          </label>
          <If condition={!entity.id && !userId}>
            <FormApiSelect variant="user" onChange={entity => setValue('user', entity.id)}
                           rules={{required: true}} control={control} watch={watch}/>
          </If>
          <If condition={entity.id || userId}>
            <div><PersonFullNameSelector userId={entity.user || userId}/></div>
          </If>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="date">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.date}} />
          </label>
          <WithErrorInput errors={errors.date}>
            <ActuallyDatePicker control={control} tabIndex={1} name="date" required/>
          </WithErrorInput>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="description">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.description}} />
          </label>
          <WithErrorInput errors={errors.description}>
            <input className="form-control" name="description" type="text" ref={register({required: true})} tabIndex={2} />
          </WithErrorInput>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="minutes">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.fixAmount}} />
          </label>
          <WithErrorInput errors={errors.minutes}>
            <FormTimeInput name={"minutes"} className="form-control"
                           control={control} setValue={setValue}
                           tabIndex={3}/>
          </WithErrorInput>
        </div>
      </div>

      <FormActionRow showDeleteButton={!!(entity && entity.id)} tabIndexStart={3}
                     handleDelete={handleDelete}
                     isValid={isValid}
                     dirty={dirty}
                     handleCancel={onDone}/>

    </form>
  );
}

AddEditBalanceFix.defaultProps = {
  entity: {}
};

export default AddEditBalanceFix;