import React from "react";
import classNames from 'classnames';

import PlainTable from "../../../../components/ui-utility/table/PlainTable";
import DateFormatter from "../../../../components/ui-utility/DateFormatter";
import { DescriptionTxt } from "../../../../components/text/DescriptionTxt";
import DayOfWeek from "../../../../components/ui-utility/dates/DayOfWeek";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";

import { ActuallyButton, TrashIcon } from "../../../../components/buttons";
import { useCalendarManagement } from "../calendar-hooks";
import { removeHolidayDateAction, moveToRemovedAction } from "../holiday-dates-reducers";
import { If } from "../../../../components/ui-utility/Conditional";
import { isWeekend, parseISO } from "../../../../util/dates";

import '../Calendar.css';


function CalendarHolidayRow({date}) {
  const {selectHolidayDate, selectIsRemoved, selectIsAdded, selectDescription, dispatch} = useCalendarManagement();

  const holiday = selectHolidayDate(date);
  const isRemoved = selectIsRemoved(date);
  const isAdded = selectIsAdded(date);
  const description = selectDescription(date) || 'User generated';

  const weekendDate = isWeekend(parseISO(date));
  const hasRowBeenAltered = isAdded || isRemoved;

  const handleHolidayRemoval = () => {
    if (holiday.id)
      dispatch(moveToRemovedAction(date));
    else
      dispatch(removeHolidayDateAction(date));
  };

  return (
    <tr className={classNames({"strikeout": isRemoved, "cursived": hasRowBeenAltered})}>
      <td>
        <DateFormatter date={holiday.date}/>
        <If condition={hasRowBeenAltered}>*</If>
      </td>
      <td>
        <DescriptionTxt text={description}/>
      </td>
      <td>
        <DayOfWeek date={holiday.date} fromIso={true}/>
      </td>
      <td>
        <If condition={weekendDate}>Workday</If>
        <If condition={!weekendDate}>Holiday</If>
      </td>
      <td className="text-right">
        <ActuallyButton onClick={handleHolidayRemoval} variant="slick"
                        disabled={isRemoved}><TrashIcon/></ActuallyButton>
      </td>
    </tr>
  )
}

function CalendarHolidays({holidays}) {
  return (
    <>
      <If condition={holidays.length}>
        <PlainTable>
          <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Weekday</th>
            <th>Effect</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {holidays.map(holidayDate => (
            <CalendarHolidayRow date={holidayDate} key={holidayDate}/>
          ))}
          </tbody>
        </PlainTable>
      </If>
      <If condition={holidays.length === 0}>
        <EmptyStatePlaceholder iconName="calendar">
          <p>This calendar is empty. Try adding few holidays by clicking the <i>Add holidays</i> button above</p>
        </EmptyStatePlaceholder>
      </If>
    </>
  )
}

CalendarHolidays.defaultProps = {};

export default CalendarHolidays;