import React, { useEffect, useState } from 'react';

import { PeopleListing } from './components/PeopleListing';
import PageHeader from "../../components/text/PageHeader";

import { PlainSectionBlock, Section } from "../../components/layout/LayoutHelpers";

import FilterRegistry from "../../components/filter/configuration/filter-registry";
import PeoplePageFilterBlock from "../../components/filter/configuration/filter-blocks-for-page/PeoplePageFilterBlock";

import { RouteComponentProps } from '@reach/router';
import { useDispatch } from 'react-redux';
import { useFiltersAndPagination } from '../../components/filter/filter-hooks';
import { paginationKeys } from '../../store/ducks/pagination/pagination-keys';
import { useColumnOrdering } from '../../components/column-sorter/use-column-ordering';
import useShallowEqualSelector from "../../store/helpers";
import { fetchPeopleAction } from '../../store/ducks/people/actions';
import peopleSelectors from "../../store/ducks/people/selectors";
import filterSelectors from '../../store/ducks/filter/filter-selectors';
import paginationSelectors from '../../store/ducks/pagination/pagination-selectors';
import peopleSagaTypes from '../../store/ducks/people/types';
import loadingSelectors from '../../store/ducks/loading/loading-selectors';
import { useSelector } from "react-redux";
import { changePageAction } from '../../store/ducks/pagination/pagination-actions';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

type PeopleProps = RouteComponentProps;

export const People: React.FunctionComponent<PeopleProps> = () => {
  const filterBlock = FilterRegistry.getFilterBlockForName(PeoplePageFilterBlock.blockKey);
  const activeFilters = useShallowEqualSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock))

  const dispatch = useDispatch();

  const [peopleFetched, setPeopleFetched] = useState<boolean>(false);
  const [fetchCount, setFetchCount] = useState<number>(0)
  const uiParams = useFiltersAndPagination(PeoplePageFilterBlock.blockKey, paginationKeys.PEOPLE_LISTING);
  const pagination = useShallowEqualSelector(paginationSelectors.selectPaginationForPage(paginationKeys.PEOPLE_LISTING));
  const [ordering, setOrdering] = useColumnOrdering(paginationKeys.PEOPLE_LISTING);
  const peopleFetchingLoading = useSelector(loadingSelectors.selectLoadingForRequest(peopleSagaTypes.FETCH_PEOPLE));
  const snapshotFetchingLoading = useSelector(loadingSelectors.selectLoadingForRequest(peopleSagaTypes.RECEIVE_PEOPLE_SNAPSHOT))
  // FIXME: TB-160
  const results: any = useShallowEqualSelector(peopleSelectors.selectPeopleResult);
  const records: any = useShallowEqualSelector(peopleSelectors.selectPeopleEntities);

  useEffect(() => {
    dispatch(fetchPeopleAction({}));
    setFetchCount(prevState => prevState+1)
  }, [dispatch, ordering, uiParams.page, uiParams.filters]);

  // NOTE: Using this causes warning about missing fetchCount dependency. 
  // This is intentionally done this way. You can remove fetchCount and peopleFetched 
  // after this ticket is ready https://silverbucket.atlassian.net/browse/TB-200
  useEffect(() => {
    if(fetchCount > 0) {
      setPeopleFetched(true);
    }
  },[results])

  const isLoading = peopleFetchingLoading?.loading ||snapshotFetchingLoading?.loading;
  return (
    <>
      <Section>
        <PlainSectionBlock>
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.pageHeader}} />
          </PageHeader>
        </PlainSectionBlock>
      </Section>
      <PeopleListing 
        filterBlock={filterBlock}
        // NOTE: just pass the results after TB-200 is fixed 
        results={(!isLoading || peopleFetched) ? results : []}
        pagination={pagination}
        activeFilters={activeFilters}
        records={records.users}
        ordering={ordering}
        isLoading={isLoading}
        onPageChange={(newPage) => dispatch(changePageAction(paginationKeys.PEOPLE_LISTING, newPage))}
        onOrderingChange={setOrdering}
      /> 
    </>
  );
}
