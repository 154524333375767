import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {useModalAsRoute} from "../../../components/modal/modal-hooks";
import {extractUrlParameter} from "../../timesheet/daily/timesheet-utility";
import {selectCustomerById} from "../../../store/ducks/customer/customer-selectors";

import modalDefinitions from "../../../components/modal/modal-definitions";
import SimpleModal from "../../../components/modal/SimpleModal";
import ModalFrame from "../../../components/modal/ModalFrame";

import {TRANSLATION_KEYS} from "../../../intl/intl";
import {RouteComponentProps} from "@reach/router";
import {fetchSingleCustomersAction} from "../../../store/ducks/customer/customer-actions";
import {EditCustomerForm} from "./EditCustomerForm";

export const EditCustomerModal: React.FC<RouteComponentProps> = ({location}) => {
  const dispatch = useDispatch();
  const customerId = extractUrlParameter(location, 'modal.customerId') as String;

  const {isOpen, onClose} = useModalAsRoute(modalDefinitions.EDIT_CUSTOMER, location);

  useEffect(() => {
    dispatch(fetchSingleCustomersAction(String(customerId)));
  }, [dispatch, customerId]);

  const customer = useSelector(selectCustomerById(customerId));

  return (
    <SimpleModal manualControl={isOpen} onClose={onClose}>
      <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.customers.editCustomer}}/>}>
        {customer && <EditCustomerForm customer={customer} onDone={onClose}/>}
      </ModalFrame>
    </SimpleModal>
  )
}
