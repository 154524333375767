import {createSlice} from "@reduxjs/toolkit";
import {getBootstrapConfigurationsAction} from "./bootstrap-actions";

interface BootstrapState {
  workday_length: number,
  calendar: number | null
}

export const bootstrapSliceState: BootstrapState = {
  workday_length: 480,
  calendar: null
};

const bootstrapSlice = createSlice({
  name: "bootstrap",
  initialState: bootstrapSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBootstrapConfigurationsAction.fulfilled, (state: BootstrapState, action) => {
      const {workday_length, calendar} = action.payload;

      state.workday_length = workday_length;
      state.calendar = calendar;
    });
  },
});

const {reducer} = bootstrapSlice;

export default reducer;