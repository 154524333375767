import {createAsyncThunk} from "@reduxjs/toolkit";
import {sendRequestSimple} from "../../../api/api";
import {getBootstrapConfiguration} from "../../../api/systemsettings/systemsettings-api";

export const getBootstrapConfigurationsAction = createAsyncThunk("bootstrap/getBootstrap",
  async () => {
    const {response} = await sendRequestSimple(getBootstrapConfiguration(), true);

    return response;
  }
);
