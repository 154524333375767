import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import filterSelectors, { extractFieldsForGivenFilterBlock } from "../../store/ducks/filter/filter-selectors";
import FilterRegistry from "./configuration/filter-registry";
import { getBaseURL } from "../../api/api-configuration";
import { joinQueryParams, joinRouteParts } from "../../api/utils";
import { usePagination } from "../pagination/pagination-hooks";
import { changePageAction } from "../../store/ducks/pagination/pagination-actions";


export const useFiltersAndPagination = (filterBlockName, paginationKey) => {
  /* Once filters change page needs to be reseted to 1. Otherwise we might introduce a query which does not exist. */
  const dispatch = useDispatch();

  const filters = useFilterFromRegistry(filterBlockName);
  const page = usePagination(paginationKey);
  const [uiParams, setUiParams] = useState({filters, page});

  useEffect(() => {
    dispatch(changePageAction(paginationKey, 1));

    setUiParams(prevState => ({...prevState, filters, page: 1}));
  }, [dispatch, paginationKey, filters]);

  useEffect(() => {
    setUiParams(prevState => ({...prevState, page: page}));
  }, [page]);

  return uiParams;
};

export const useFilterFromRegistry = (filterBlockName) => {
  const filterBlock = FilterRegistry.getFilterBlockForName(filterBlockName);

  return useFilterSelect(filterBlock);
};

export const useFilterSelect = (filterBlock) => {
  const stateOfEveryFilter = useSelector(filterSelectors.selectFilterFields);

  return useMemo(() => extractFieldsForGivenFilterBlock(filterBlock, stateOfEveryFilter),
    [filterBlock, stateOfEveryFilter]);
};

export const useFiltersAsExportUrl = (filterBlockName, endpointUrl, additionalParameters) => {
  const filterBlock = FilterRegistry.getFilterBlockForName(filterBlockName);
  const filterValues = useFilterSelect(filterBlock);

  const url = useMemo(() => {
    const normalizedValues = filterBlock.formatQueryParameters(filterValues);
    const withExtraParams = {...normalizedValues, ...additionalParameters};

    const baseUrl = getBaseURL();
    const completeUrl = joinRouteParts(baseUrl, endpointUrl);

    return joinQueryParams(completeUrl, withExtraParams);
  }, [filterValues, filterBlock, endpointUrl, additionalParameters]);

  return url;
};