import { createActuallyAdapter } from "../../../adapters";
import { TenantStatus, TenantSubscription } from "../../../../types/entities/Tenant";
import { createSlice } from "@reduxjs/toolkit";
import { changeTenantStatusFilterAction, fetchTenantsAction, updateTenantSubscriptionAction } from "./tenant-actions";

export const tenantAdapter = createActuallyAdapter<TenantSubscription>();

const initialState = tenantAdapter.getInitialState({
  statusFilterType: TenantStatus.TRIAL,
});

const tenantSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    fetchTenantsSuccessAction: tenantAdapter.upsertMany,
    fetchSingleTenantSuccessAction: tenantAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenantsAction.fulfilled, tenantAdapter.setAll);
    builder.addCase(updateTenantSubscriptionAction.fulfilled, (state, action) => {
      tenantAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(changeTenantStatusFilterAction, (state, action) => {
      return {
        ...state,
        statusFilterType: action.payload,
      }
    })
  }
});

const {actions, reducer} = tenantSlice;

export const {
  fetchTenantsSuccessAction,
  fetchSingleTenantSuccessAction
} = actions;

export default reducer;
