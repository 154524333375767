import React from "react";
import CornerAction from "../../../components/layout/CornerAction";

interface OverallReportLockActionProps {
  isLocked: boolean;
  setIsLocked: (isLocked: boolean) => void
}

export const OverallReportLockAction: React.FunctionComponent<OverallReportLockActionProps> = ({isLocked, setIsLocked}) => {
  const iconName = isLocked ? "lock" : "lock-open";

  return (
    <CornerAction onClick={() => setIsLocked(!isLocked)} iconName={iconName} />
  );
};
