import React from "react";
import PropTypes from 'prop-types';

import { useSelectorWithDefault } from "../../../util/redux-utils";

import {
  selectProjectForId,
  selectTaskForId,
} from "../../../store/ducks/timesheet/selectors";

import WeeklyDayInput from "./WeeklyDayInput";

import TimesheetPinColumn from "../components/TimesheetPinColumn";
import ProjectAndTaskName from "../../../components/project/ProjectAndTaskName";

import { TimesheetTotalMinutesForRange } from "../daily/components/TimesheetTotalMinutes";
import styled from "styled-components";
import {useTimetrackingAllowed} from "../record-hooks";

const WeeklyTimesheetRowHeader = styled.div`
  display: flex;
`

const WeeklyTimesheetRow = ({ taskId, dates }) => {
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];

  const task = useSelectorWithDefault(selectTaskForId(taskId), {});
  const project = useSelectorWithDefault(selectProjectForId(task.project), {});

  const timeTrackingAllowed = useTimetrackingAllowed(task, project);

  return (
    <tr>
      <td className="align-middle  pl-3">
        <WeeklyTimesheetRowHeader>
          <span className="pr-3">
            <TimesheetPinColumn task={task} />
          </span>
          <ProjectAndTaskName project={project} task={task} />
        </WeeklyTimesheetRowHeader>
      </td>

      {dates.map(d => <WeeklyDayInput date={d}
        isTimetrackingAllowed={timeTrackingAllowed}
        taskId={task.id}
        key={d} />)}
      <td className="align-middle text-right pr-3" style={{ minWidth: '80px' }}>
        <strong>
          <TimesheetTotalMinutesForRange taskId={task.id} startDate={startDate} endDate={endDate} />
        </strong>
      </td>
    </tr>
  )
};

WeeklyTimesheetRow.propTypes = {
  taskId: PropTypes.string.isRequired,
  // FIXME: TB-160 -- Date
  // dates: PropTypes.arrayOf(Date),
};

export default WeeklyTimesheetRow;