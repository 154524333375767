import React from "react";
import {UserBalance} from "../people/user-details/UserDetailsBasicInformation";
import classNames from 'classnames';

const TimesheetBalanceToday = ({minutes}) => {
  const classes = classNames(
    'badge',
    minutes === null ? 'badge-light' : minutes >= 0 ? 'badge-success' : 'badge-danger',
  );

  return (
    <span className="text-center">
      <span className={classes}>
        <UserBalance minutes={minutes}/>
      </span>
    </span>
  );
};

export default TimesheetBalanceToday;
