import { normalize } from "normalizr";
import { createProjectSchema } from "./projects-schemas";

import ProjectEntity from "../../components/project/ProjectEntity";

export class ProjectsAPIResponseParser {
  static normalizeListing = (response) => {
    const schema = createProjectSchema();

    return normalize(response, [schema]);
  };

  static convertProjectsIntoSelectCompatible = response => {
    return response.map(project => ({...project, label: ProjectEntity.parseName(project), value: project.id}));
  };

}
