export enum TypographyVariant {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    Subtitle1 = 'subtitle1',
    Subtitle2 = 'subtitle2',
    Body1 = 'body1',
    Body2 = 'body2',
    Button = 'button',
    Caption = 'caption',
    Overline = 'overline',
}

export const allTypographyVariants = Object.keys(TypographyVariant);