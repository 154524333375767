import React from "react";
import styled from "styled-components";
import Bold from "../ui-utility/Bold";
import {Link} from "@reach/router";
import Badge from "react-bootstrap/Badge";
import {useSelector} from "react-redux";
import {selectAuthenticationState} from "../../store/ducks/auth/selectors";
import {differenceInHours, differenceInMinutes, fromUnixTime} from "../../util/dates";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";


const Uppercase = styled.span`
 text-transform: uppercase;
 `

const UpgradeBadge = styled(Badge)`
  background: #388086;
`

const TrialingBannerWrapper = styled.div`
    background-color: #33a2ab;
    color: white;
    padding: 10px;
`

export const TrialingBannerSelector = () => {
  const authState = useSelector(selectAuthenticationState);

  const isOnInitialTrial = authState.is_on_initial_trial;
  const endOfTrialEpoch = authState.end_of_trial;

  if (!isOnInitialTrial || !endOfTrialEpoch)
    return null;

  return (<TrialingBanner endOfTrial={endOfTrialEpoch}/>);
}

const TrialingBanner = ({endOfTrial}) => {
  const endOfTrialDate: any = fromUnixTime(endOfTrial);

  const renderRemainingTime = () => {
    const now: any = new Date();
    const diff: any = endOfTrialDate - now;

    const remainingDays = Math.round(diff / (1000 * 60 * 60 * 24));

    if (remainingDays > 0) {
      return (<span>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.banner.trialEndsInDaysMessage, values: {amount: remainingDays}}}/>
      </span>)
    }
    const remainingHours = differenceInHours(endOfTrialDate, new Date());

    if (remainingHours > 0) {
      return (<span>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.banner.trialEndsInHoursMessage,values: {amount: remainingHours}}}/>
      </span>)
    }

    const remainingMinutes = differenceInMinutes(endOfTrialDate, new Date());

    if (remainingMinutes > 0)
      return (<span>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.banner.trialEndsInMinutesMessage, values: {amount: remainingMinutes}}}/>
      </span>)

    return (<span>
      <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.banner.trialEndedMessage}} />
    </span>);
  }

  const remainingTime = renderRemainingTime();

  return (
    <TrialingBannerWrapper>
      <Bold>
        {remainingTime}

        <Link to={"upgrade/"} className="ml-4">
          <UpgradeBadge variant="secondary">
            <Uppercase>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.banner.upgradeNowButton}}/>
            </Uppercase>
          </UpgradeBadge>
        </Link>
      </Bold>

    </TrialingBannerWrapper>
  )
};
