import React from 'react';
import { Time } from "../../../util/Time";
import classNames from 'classnames';

function getWidthAndPercentages(minutes, capacityMinutes) {
  const isOverCapacity = minutes > capacityMinutes;

  if (isOverCapacity) {
    const percentages = Math.floor(capacityMinutes / minutes * 100);
    const width = `${percentages}%`;

    return {width, isOverCapacity};

  } else {
    const percentages = Math.floor(minutes / capacityMinutes * 100);
    const width = `${Math.min(percentages, 100)}%`;

    return {width, isOverCapacity};
  }

}

const TimeColumn = ({minutes, showZero=false}) => {
  return (
    <div className="text-right pl-2 text-nowrap">
      <strong>{Time.FromMinutes(minutes).toColonSeparated(showZero)}</strong>
    </div>
  )
};


// FIXME: TB-160
interface DailyProgressBarProps {
  minutes: any;
  capacityMinutes: any;
  className?: string;
  hideTime?: boolean; 
  style?: any;
  defaultBgColor?: string;
}

/* TODO: Make another version of progress bar where the over capacity makes the bar longer, not scaling "inside". */
const DailyProgressBar: React.FunctionComponent<DailyProgressBarProps> = ({minutes, capacityMinutes, className, hideTime, style, defaultBgColor='#eef2f6'}) => {
  const { isOverCapacity, width } = getWidthAndPercentages(minutes, capacityMinutes);

  const normalRed = '#E66A6A';
  const overRed = '#D64545';


  const bgColor = isOverCapacity ? {backgroundColor: normalRed} : {backgroundColor: defaultBgColor };
  const frontColor = isOverCapacity ? overRed : '#3EBD93';

  const barClasses = classNames(
    'd-flex align-items-center justify-content-around',
    className,
  );

  return (
    <div className={barClasses} style={style}>
      <div className="d-block w-100">
        <div className="progress" style={{ height: '10px', ...bgColor }}>
          <div className="progress-bar"
               role="progressbar"
               style={{ width: width, backgroundColor: frontColor, borderRadius: '0.25rem' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
        </div>
      </div>

      {!hideTime && <TimeColumn minutes={minutes} showZero={true} />}
    </div>
  );
}

export default DailyProgressBar;
