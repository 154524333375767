import React from "react";
import {TimesheetRowManager} from "../../store/ducks/mobile/timesheet/entities";
import TimeValue from "../TimeValue";


const SummedRecordMinutes = ({records, keyForMinutes = 'minutes_sum'}) => {
  const totalRecordMinutes = TimesheetRowManager.sumAllRecords(records, keyForMinutes);

  return (
      <TimeValue minutes={totalRecordMinutes} showZero={true}/>
  );
};

export {
  SummedRecordMinutes
}