import React, { useState } from "react";

import { useCalendarAPI } from "../calendar-hooks";
import { useForm } from "react-hook-form";
import { TooltipCircle } from "../../../../components/ui-utility/Tooltip";

import FormActionRow from "../../../../forms/FormActionRow";
import FormApiSelect from "../../../../forms/FormApiSelect";
import WithErrorInput from "../../../timesheet/components/WithErrorInput";
import { SupportAddress } from "../../../../components/ui-utility/SupportAddress";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";


// Error handling similar everywhere, solution?
function AddCalendarForm({onDone}) {
  // FIXME: TB-160
  const [errors, setErrors] = useState<any>({});

  const {createCalendar} = useCalendarAPI();

  const {register, formState, control, setValue, handleSubmit, watch} = useForm({mode: 'onChange'});

  const {isValid, dirty} = formState;

  const onSubmit = async data => {
    const {response, error} = await createCalendar(data);

    error && setErrors(error);
    response && onDone(response);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="name">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.calendarSettings.addCalendarModal.calendarNameFieldLabel}} />
          </label>
          <WithErrorInput errors={errors.name}>
            <input className="form-control" name="name" type="text" ref={register} tabIndex={1}/>
          </WithErrorInput>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-lg-12">
          <label htmlFor="preset">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.calendarSettings.addCalendarModal.selectPresetCalendarLabel}} />
          </label>
          <TooltipCircle instructionText={<LocalizedMessage text={{id: TRANSLATION_KEYS.calendarSettings.addCalendarModal.selectPresetCalendarTooltip}} />}/>
          <FormApiSelect
            variant="preset-calendars"
            onChange={entity => setValue('preset', entity.iso_code)}
            control={control}
            name="preset"
            watch={watch}
          />
          <small>
            <LocalizedMessage text={{
              id: TRANSLATION_KEYS.calendarSettings.addCalendarModal.helpMessage,
              values: {emailAddress: <SupportAddress/>}
            }} />
          </small>
        </div>
      </div>
      <FormActionRow tabIndexStart={2} showDeleteButton={false}
                     isValid={isValid} dirty={dirty} handleCancel={onDone}/>
    </form>
  )
}

export default AddCalendarForm;