import React from "react";
import { useForm } from "react-hook-form";
import { Time } from "../../../../util/Time";
import { formatISO, parseISO } from "../../../../util/dates";
import ActuallyDatePicker from "../../../../components/datepicker/ActuallyDatePicker";
import FormTimeInput from "../../../../forms/FormTimeInput";
import FormActionRow from "../../../../forms/FormActionRow";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

// FIXME: TB-160
const AddEditItem = ({entity={} as any, api, onDone=()=>undefined}) => {
  const {handleSubmit, register, control, formState, setValue} = useForm({
    mode: "onChange",
    defaultValues: {
      description: entity?.description,
      date: entity?.date ? new Date(entity.date) : undefined,
      time: Time.FromMinutes(entity?.minutes)?.toColonSeparated(true),
    }
  });

  const onSubmit = async formData => {
    const {id=undefined, description=undefined, date=undefined, time=undefined} = {...formData, ...(entity && {id: entity.id})};
    const minutes = Time.FromUserInput(time).minutes;

    await api.addEditWorkdayLength({
      id,
      description,
      date: formatISO(date),
      minutes,
    });

    if (onDone) {
      onDone();
    }
  };

  const handleDelete = async workdayLengthId => {
    await api.deleteWorkdayLength(workdayLengthId);

    onDone && onDone();
  };

  const disabledDates = api.allWorkdayLengths.filter(
    workdayLength => !entity || workdayLength.id !== entity.id
  ).map(
    workdayLength => parseISO(workdayLength.date)
  );

  const {isValid, dirty} = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label className="label-default" htmlFor="description">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.reason}}/>
        </label>
        <input className="form-control" type="text" name="description" ref={register({required: true})}/>
      </div>

      <div className="form-row">
        <div className="form-group col-6">
          <label className="label-default" htmlFor="date">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.formFields.dateLabel}}/>
          </label>

          <div>
            <ActuallyDatePicker name={'date'}
                                control={control}
                                disabledDates={disabledDates}
                                required
                                className="d-block"/>
          </div>
        </div>

        <div className="form-group col-6">
          <label className="label-default" htmlFor="time">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.formFields.workdayLengthLabel}}/>
          </label>

          <FormTimeInput name={"time"} control={control} setValue={setValue}/>
        </div>
      </div>

      <FormActionRow showDeleteButton={!!(entity && entity.id)}
                     handleDelete={() => handleDelete(entity.id)} tabIndexStart={1}
                     isValid={isValid}
                     dirty={dirty}
                     handleCancel={onDone}/>
    </form>
  );
};

export {
  AddEditItem
};
