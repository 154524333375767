import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  createDeleteRequest,
  createGetRequest,
  createPostRequest,
  createPatchRequest,
  sendRequestSimple
} from '../../../../api/api';
import {joinRouteParts} from '../../../../api/utils';
import {
  userWorkdayLengthAddedAction,
  userWorkdayLengthDeletedAction,
  userWorkdayLengthEditedAction,
  userWorkdayLengthsFetchedAction
} from "../../../../store/ducks/workday-length/workday-length-reducer";

import {selectUserWorkdayLengthList} from "../../../../store/ducks/workday-length/workday-length-selectors";
import {fetchUserEmploymentAction} from "../../../../store/ducks/people/actions";


const addEditWorkdayLengthRequest = data => {
  const creator = data.id ? createPatchRequest : createPostRequest;

  let url;

  if (data.id) {
    url = joinRouteParts('workday-lengths', data.id);
  } else {
    url = 'workday-lengths';
  }

  return creator({url, data});
};

const fetchUserWorkdayLengthsRequest = userId => {
  const baseUrl = '/workday-lengths/';
  return createGetRequest({url: baseUrl, params: {'workdaylength.user_id': userId, page_size: 0}})
};

const deleteWorkdayLengthRequest = workdayLengthId => {
  const url = joinRouteParts('/workday-lengths/', workdayLengthId);
  return createDeleteRequest({url});
};


const useWorkdayLengthAPI = userId => {
  const dispatch = useDispatch();
  const allWorkdayLengths = useSelector(selectUserWorkdayLengthList(userId)) || [];

  const fetchWorkdayLengths = useCallback(async () => {
    const {response} = await sendRequestSimple(fetchUserWorkdayLengthsRequest(userId));

    response && dispatch(userWorkdayLengthsFetchedAction(response));
  }, [dispatch, userId]);

  const addEditWorkdayLength = async workdayLengthData => {
    const isAdd = workdayLengthData.id;
    const {response, error} = await sendRequestSimple(addEditWorkdayLengthRequest({
      ...workdayLengthData,
      user: userId
    }));

    if (!error) {
      if (isAdd)
        dispatch(userWorkdayLengthAddedAction(response));
      else {
        dispatch(userWorkdayLengthEditedAction(response));
      }

      dispatch(fetchUserEmploymentAction(userId));
    }

  };

  const deleteWorkdayLength = async workdayLengthId => {
    const {error} = await sendRequestSimple(deleteWorkdayLengthRequest(workdayLengthId));

    !error && dispatch(userWorkdayLengthDeletedAction(workdayLengthId));

    dispatch(fetchUserEmploymentAction(userId));
  };

  return {
    addEditWorkdayLength,
    fetchWorkdayLengths,
    deleteWorkdayLength,
    allWorkdayLengths,
  }
};

export default useWorkdayLengthAPI;
