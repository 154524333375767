import { schema } from 'normalizr';


const createOverallSchema = () => {
  const userSchema = new schema.Entity('records');

  return [userSchema];
};

export default createOverallSchema;
