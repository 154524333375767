import {createSelector} from "reselect";
import {isUserCycleDeclined} from "../../../screens/timesheet/approval/approval-constants";

const cycleSelectors = Object.freeze({
  selectCycleState: state => state.cycles,

  selectCycles: state => cycleSelectors.selectCycleState(state).cycles,
  selectCycleForId: cycleId => state => {
    const cycles = cycleSelectors.selectCycles(state);

    return cycles[cycleId];
  },

  selectCycleIds: state => cycleSelectors.selectCycleState(state).cycleIds,

  selectCyclesCompletedByApprovers: state => cycleSelectors.selectCycleState(state).cyclesCompletedByApprovers,
  selectCyclesCompletedByApproversForApprover: approverId => state => {
    const cyclesForApprover = cycleSelectors.selectCyclesCompletedByApprovers(state);

    return cyclesForApprover[approverId];
  },

  selectUserCycles: state => cycleSelectors.selectCycleState(state).userCycles,
  selectCycleIdsByUser: state => cycleSelectors.selectCycleState(state).cycleIdsByUser,
});

const deriveActiveCycleDateRanges = userId => (cycleIdsByUser, cycles, userCycles) => {
  let activeCycleIds = cycleIdsByUser[userId];

  if (!activeCycleIds)
    return null;

  activeCycleIds = activeCycleIds.filter(cycleId => !isUserCycleDeclined(userCycles[cycleId]));

  return activeCycleIds.map(userCycleId => {
    const userCycleInstance = userCycles[userCycleId];
    const cycleInstance = cycles[userCycleInstance.cycle];

    return [cycleInstance.start_date, cycleInstance.end_date];
  });
}

const deriveCyclesForApprover = (approverId, onlyHistory) => (cycleIds, cyclesCompletedByApprovers) => {
  const approverCycleStatuses = cyclesCompletedByApprovers[approverId] || {};

  return cycleIds.filter(cycleId => {
    if (cycleId in approverCycleStatuses)
      return approverCycleStatuses[cycleId] === onlyHistory;
    return false;
  });
}

export const selectDateRangesUserHasCyclesActive = userId => createSelector(
    [
      cycleSelectors.selectCycleIdsByUser,
      cycleSelectors.selectCycles,
      cycleSelectors.selectUserCycles
    ],
    deriveActiveCycleDateRanges(userId)
);

export const selectActiveCyclesForApprover = approverId => createSelector(
    [
      cycleSelectors.selectCycleIds,
      cycleSelectors.selectCyclesCompletedByApprovers
    ],
    deriveCyclesForApprover(approverId, false)
);

export const selectHistoryCyclesForApprover = approverId => createSelector(
    [
      cycleSelectors.selectCycleIds,
      cycleSelectors.selectCyclesCompletedByApprovers
    ],
    deriveCyclesForApprover(approverId, true)
);

export default cycleSelectors;