import React from "react";

import TextareaAutosize from "react-textarea-autosize";

// FIXME: TB-160
const TextArea = ({innerRef=undefined, ...props}: any) => {
  return (
    <TextareaAutosize {...props} inputRef={innerRef}/>
  )
};

export {
  TextArea
};
