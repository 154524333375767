import { shallowEqual, useSelector } from "react-redux";
import { uniq } from 'lodash';

// FIXME: TB-160
const useShallowEqualSelector: any = selector => {
  return useSelector(selector, shallowEqual);
};

// Merges and replaces partially the single object into entities
const mergeObjectIntoEntities = (state, newEntity, entityKey, identifier = 'id') => {
  const {entities} = state;

  const objectEntities = entities[entityKey] || {};

  const mergedEntities = mergeObjectIntoFlatEntities(objectEntities, newEntity, identifier);

  return {
    ...entities,
    [entityKey]: mergedEntities
  }
};

const mergeObjectIntoFlatEntities = (existingObjects, newObject, identifier = 'id') => {
  const existing = existingObjects || {};
  const oldObject = existing[newObject[identifier]] || {};

  return {
    ...existingObjects,
    [newObject[identifier]]: {...oldObject, ...newObject}
  }
};

// Merges complete entitity map into existing entity map
const mergeObjectMapIntoEntities = (state, newEntities, entityKey) => {
  const {entities} = state;

  const existingEntities = entities[entityKey];
  const toBeMergedEntities = newEntities[entityKey];
  const newStatePart = mergeObjectIntoState(existingEntities, toBeMergedEntities, entityKey);

  return {
    ...entities,
    ...newStatePart
  }
};

const mergeObjectIntoState = (state, mergedState, entityKey) => {
  return {
    [entityKey]: {
      ...state,
      ...mergedState
    }
  }
};

const removeObjectFromEntities = (state, entityId, entityKey) => {
  const {entities} = state;

  const objectEntities = entities[entityKey];

  const remaining = removeObjectFromMapOfObjects(objectEntities, entityId);

  return {
    ...entities,
    [entityKey]: remaining
  }
};

const removeObjectFromMapOfObjects = (objects, objectId) => {
  const {[objectId]: omitted, ...remaining} = objects;

  return remaining
};

const mergeObjectIntoNestedResult = (state, newEntity, entityKey, identifier = 'id') => {
  const {result} = state;
  const objectResult = result[entityKey];

  return {
    ...result,
    [entityKey]: uniq([...objectResult, newEntity[identifier]])
  }
};

const mergeObjectIntoResult = (state, newEntity, identifier = 'id') => {
  const {result} = state;

  return uniq([...result, newEntity[identifier]]);
};

const removeObjectFromObjectResult = (state, entityId, entityKey) => {
  const {result} = state;
  const objectResult = result[entityKey];

  return {
    ...result,
    [entityKey]: removeObjectFromResult(objectResult, entityId)
  }

};

const removeObjectFromResult = (result, entityId) => {
  return result.filter(identifier => identifier !== entityId);
};

export {
  useShallowEqualSelector as default,

  mergeObjectIntoEntities,
  mergeObjectMapIntoEntities,
  mergeObjectIntoFlatEntities,

  mergeObjectIntoResult,
  mergeObjectIntoNestedResult,
  mergeObjectIntoState,

  removeObjectFromEntities,
  removeObjectFromResult,
  removeObjectFromObjectResult,
  removeObjectFromMapOfObjects
};
