import React from "react";
import { BaseIcon } from "../buttons";

const ClearCalendarValueIcon = () => {
  return (
    <BaseIcon iconName="times" style={{ color: '#BCCCDC' }} />
  )
};

export default ClearCalendarValueIcon;
