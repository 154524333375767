import React from "react";

import EmptyStatePlaceholder from "../../components/ui-utility/EmptyStatePlaceholder";

function NotAuthorizedPlaceholder() {
  return (
    <EmptyStatePlaceholder iconName="ban">
      <p>Permission denied</p>
      <p>You are not authorized to view this page</p>
    </EmptyStatePlaceholder>
  )
}

export default NotAuthorizedPlaceholder;
