const types = Object.freeze({
  'MEMBERSHIPS_UPDATE_SUCCESS': 'MEMBERSHIPS_UPDATE_SUCCESS',
  'MEMBERSHIPS_NEW_MEMBER': 'MEMBERSHIPS_NEW_MEMBER',
  'MEMBERSHIPS_DELETE_MEMBER': 'MEMBERSHIPS_DELETE_MEMBER',
  'MEMBERSHIPS_PATCH_MEMBER': 'MEMBERSHIPS_PATCH_MEMBER',
});

const membershipSelectors = Object.freeze({
  selectMemberships: state => state.memberships,
  selectUserIds: state => membershipSelectors.selectMemberships(state).user_ids,
  selectUserMemberMap: state => membershipSelectors.selectMemberships(state).user_member_map,
  selectUserMemberId: userId => state => membershipSelectors.selectUserMemberMap(state)[userId]
});

const initialState = {
  user_ids: [],
  user_member_map: {},
};

const membershipReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.MEMBERSHIPS_UPDATE_SUCCESS:
      return {
        ...action.payload
      };
    case types.MEMBERSHIPS_NEW_MEMBER:
      return {
        user_ids: [...state.user_ids, action.newMember.user],
        user_member_map: {...state.user_member_map, [action.newMember.user]: action.newMember},
      };
    case types.MEMBERSHIPS_DELETE_MEMBER:
      return {
        user_ids: state.user_ids.filter(id => id !== action.userId),
        user_member_map: {...state.user_member_map, [action.userId]: null},
      };
    case types.MEMBERSHIPS_PATCH_MEMBER:
      return {
        user_ids: [...state.user_ids],
        user_member_map: {...state.user_member_map, [action.userId]: action.member},
      };
    default:
      return state;
  }
};

export {
  membershipReducer as default,
  initialState,
  types,
  membershipSelectors
}