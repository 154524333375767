import React, {useMemo} from 'react';

import {useActualTableDataFetching} from "../../timesheet/record-hooks";
import ProjectName from "../../../components/project/ProjectName";

import {Project} from "../../../components/project/ProjectEntity";

import {
  ReportAPIResponseParser
} from "../../../api/report/report-api-implementation";

import {useEmployment} from "../user-details/UserDetails";
import {ActuallyTable} from '../../../components/table/ActuallyTable';
import {RecordsWithDailyAxis} from '../../../components/people/PersonUtilization';
import {SummedRecordMinutes} from '../../../components/records/SummedRecordMinutes';
import {DefaultBlock} from '../../../components/layout/LayoutHelpers';
import styled from 'styled-components';
import {DailyActual, CoordinateResolver} from '../../timesheet/MonthlyStatus';
import TimeValue from '../../../components/TimeValue';
import {lastDaysRange} from "../../../util/dates";
import {getPersonRecordsByProjectTableColumnConfiguration} from './person-records-by-project-table-columns';
import {TRANSLATION_KEYS} from "../../../intl/intl";

const LastMonth = styled.div`
  display: flex;
  align-items: center;
`

const LastMonthTotalTime = styled.div`
    width: 50px;
    margin-left: 1rem;
`

// FIXME: TB-160
const getPersonRecordsByProjectTableData = (records: any, projects: Project[], workdayLength: number, reducedTotalRecords) => {
  const lastThirtyDays = lastDaysRange(30);

  return projects.map(project => {
    const intactRecords: Array<DailyActual> = CoordinateResolver.convertRawRecordsIntoDayStatusCompliantSet(records[project.id], lastThirtyDays, workdayLength);
    return ({
      project: <ProjectName name={project.name} id={project.id}/>,
      lastMonth:
        <LastMonth>
          <RecordsWithDailyAxis recordsWithCoordinates={intactRecords}/>
          <LastMonthTotalTime>
            (<SummedRecordMinutes records={records[project.id]}/>)
          </LastMonthTotalTime>
        </LastMonth>,
      totalTime: <TimeValue minutes={reducedTotalRecords[project.id]} showZero={true}/>
    })
  })
}

interface OwnPropTypes {
  userId: number;
}

export type PersonRecordsByProjectsTableProps = OwnPropTypes;

export const PersonRecordsByProjectsTable: React.FunctionComponent<PersonRecordsByProjectsTableProps> = ({userId}) => {
  const params = useMemo(() => ({
    "record.user": userId,
    "group_by": ['project', 'user'],
  }), [userId]);

  const {workdayLength} = useEmployment(userId);

  const {allRecords, summedRecords} = useActualTableDataFetching(params);

  const reducedTotalRecords = ReportAPIResponseParser.reduceToSingleKeyValueMap(summedRecords, 'project');
  const {projects, records} = ReportAPIResponseParser.extractProjectRecords(allRecords);

  return (
    <>
      <DefaultBlock withBackground={true} isTight={true}>
        <ActuallyTable
          evenCells={true}
          emptyResultProps={
            {
              paragraphs: [
                {id: TRANSLATION_KEYS.people.personDashboard.emptyRecordsTableMessage}
              ]
            }
          }
          tableColumns={getPersonRecordsByProjectTableColumnConfiguration}
          tableData={getPersonRecordsByProjectTableData(records, projects, workdayLength.currentMinutes, reducedTotalRecords)}
        />

      </DefaultBlock>
    </>
  )
}
