import {useCallback, useEffect, useState} from "react";
import {Time} from "./Time";
import {debounce} from "lodash";

export const useTimeInput = (minutes, onSave) => {
  const isZero = minutes === 0;
  const [value, setValue] = useState(isZero ? "" : Time.FromMinutes(minutes).toColonSeparated());
  const [isInvalid, setIsInvalid] = useState(false);

  const onBlur = e => {
    setNewMinutes(e);
  };

  const onFocus = e => {
    e.target.select();
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      setNewMinutes(e);
    }
  };

  const setNewMinutes = e => {
    const time = Time.FromUserInput(e.target.value);

    if (time) {
      setIsInvalid(false);
      setValue(time.minutes !== 0 ? time.toColonSeparated() : "");

      if (time.minutes !== minutes) {
        onSave(time.minutes);
      }
    } else {
      setIsInvalid(true);
    }
  };

  useEffect(() => {
    if (minutes !== 0) {
      // Prevent "blinking". Not sure if using custom hook in the best way.
      setValue(Time.FromMinutes(minutes).toColonSeparated());
    } else {
      setValue("");
    }
  }, [minutes]);

  return {
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    value,
    isInvalid,
  }
};

export const useOnBlurTrigger = (value: string | number, onFinishedEditing: (value: string) => void) => {
  const [internalValue, setInternalValue] = useState(value);
  
  const onBlur = (event) => {
    const updatedValue = event.target.value;

    if (updatedValue !== value)
      onFinishedEditing(event.target.value);
  };

  const onFocus = event => {
    event.target.select();
  };

  const onChange = (event) => {
    setInternalValue(event.target.value);
  };

  return {
    onBlur,
    onFocus,
    onChange,
    internalValue
  }
};


export const useInputDebounce = (initialValue, bouncedFn) => {
  const [value, setValue] = useState(initialValue);

  const bigBadBouncer = useCallback(debounce(bouncedFn, 1000), []);

  const onChange = event => {
    setValue(event.target.value);
    bigBadBouncer(event.target.value);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  return [value, onChange];
}