import * as React from 'react';
import EmptyStatePlaceholder from '../../ui-utility/EmptyStatePlaceholder';
import {LocalizedMessage, LocalizedText} from '../../../intl/components/LocalizedMessage';
import {TRANSLATION_KEYS} from "../../../intl/intl";

interface ActuallyTableEmptyResultsBlockProps {
  isLoading?: boolean;
  paragraphs?: LocalizedText[];
  iconName?: string;
}

const defaultParagraphs: LocalizedText[] = [
  {id: TRANSLATION_KEYS.defaultTableMessages.emptyTableHeader},
  {id: TRANSLATION_KEYS.defaultTableMessages.emptyTableBody},
]

export const ActuallyTableEmptyResultsBlock: React.FunctionComponent<ActuallyTableEmptyResultsBlockProps> = ({isLoading, paragraphs, iconName}) => (
  <EmptyStatePlaceholder iconName={iconName || "table"} isLoading={isLoading} delay={0}>
    {(paragraphs || defaultParagraphs).map((paragraph, index) => (
      <p key={index}>
        <LocalizedMessage text={paragraph}/>
      </p>
    ))}
  </EmptyStatePlaceholder>
)