import React from "react";

import modalDefinitions from "../../../../components/modal/modal-definitions";

import {useModalAsRoute} from "../../../../components/modal/modal-hooks";
import {extractUrlParameter} from "../../../timesheet/daily/timesheet-utility";

import SimpleModal from "../../../../components/modal/SimpleModal";
import ModalFrame from "../../../../components/modal/ModalFrame";
import CycleTemplateFormManager from "./CycleTemplateFormManager";

import {useCycleTemplateFetching} from "../cycle-template-hooks";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";


function CycleTemplateModal({location}) {
  const templateId = extractUrlParameter(location, 'modal.templateId');
  const {isOpen, onClose} = useModalAsRoute(modalDefinitions.CYCLE_TEMPLATE, location);

  useCycleTemplateFetching();

  return (
      <SimpleModal manualControl={isOpen} onClose={onClose}>
        <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.addEditTemplateModal.pageHeader}} />}>
          <CycleTemplateFormManager onDone={onClose} templateId={templateId}/>
        </ModalFrame>
      </SimpleModal>
  )
}

export default CycleTemplateModal;