class FilterRegistry {
  static registeredBlocks = {};

  static getFilterBlockForName(filterBlockName) {

    if (!FilterRegistry.registeredBlocks[filterBlockName])
      throw new Error(`No filters registered for the name ${filterBlockName}`);

    return FilterRegistry.registeredBlocks[filterBlockName];
  }

  static registerFilterBlock(filterBlockName, filterBlock) {
    FilterRegistry.registeredBlocks[filterBlockName] = filterBlock;
  }
}

export default FilterRegistry;