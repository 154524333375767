import React, { useMemo } from "react";
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { selectRecords } from "../../../../store/ducks/timesheet/selectors";
import { TimesheetRowManager } from "../../../../store/ducks/mobile/timesheet/entities";
import TimeValue from "../../../../components/TimeValue";


const TimesheetTotalMinutesForRange = ({taskId, startDate, endDate}) => {
  const records = useSelector(selectRecords);

  const taskWeeklyMinutes = useMemo(() =>
    TimesheetRowManager.resolveRecordsForTaskInTimeInterval(records, taskId, startDate, endDate), [records, taskId, startDate, endDate]);

  return (
    <TimeValue minutes={taskWeeklyMinutes}/>
  );
};

TimesheetTotalMinutesForRange.propTypes = {
  taskId: PropTypes.number.isRequired,
};

const TimesheetTotalMinutesForDay = ({date}) => {
  const records = useSelector(selectRecords);

  const taskTotalMinutesForDay = useMemo(() =>
    TimesheetRowManager.sumAllTaskRecordsForGivenDay(records, date), [date, records]);

  return (
    <TimeValue minutes={taskTotalMinutesForDay}/>
  );
};

TimesheetTotalMinutesForDay.propTypes = {
  date: PropTypes.any.isRequired,
};

export {
  TimesheetTotalMinutesForRange,
  TimesheetTotalMinutesForDay
};