import Filter from "./Filter";

import FILTER_TYPES from '../configuration/filter-type-definations';

class InputFilterField extends Filter {
  filterType = FILTER_TYPES.INPUT;

  getActiveFilterSentence = () => {
    return this.getActiveFilterLabel();
  };

  formatValue = (value) => {
    return {[this.filterKey]: value};
  };

}

export default InputFilterField;