import { createGetRequest, createPatchRequest } from "../../api";
import { joinRouteParts } from "../../utils";

const baseUrl = 'cycle-step-project';

const getStepRecords = stepId => {
  const url = joinRouteParts(baseUrl, stepId, 'records');

  return createGetRequest({url});
}

const patchCycleStepProject = (stepId, data) => {
  const apiUrl = joinRouteParts(baseUrl, stepId);

  return createPatchRequest({url: apiUrl, data});
};


export {
  getStepRecords,
  patchCycleStepProject
};