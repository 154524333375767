import React, { useCallback, useEffect, useReducer } from "react";
import { useCalendarAPI } from "../calendar-hooks";
import holidayDatesReducer, { receiveHolidayDatesAction, initialState } from "../holiday-dates-reducers";

import EditCalendarForm from "./EditCalendarForm";

export const CalendarContext = React.createContext({});

function EditCalendarManager({calendar, onDone}) {
  const [state, dispatch] = useReducer(holidayDatesReducer, initialState);
  const {fetchPresetCalendar, fetchHolidayRules} = useCallback(useCalendarAPI(), []);

  useEffect(() => {
    const fetchCalendarHolidays = async () => {
      const {response} = await fetchHolidayRules(calendar.id);

      dispatch(receiveHolidayDatesAction(response));
    };

    fetchCalendarHolidays();
  }, [fetchHolidayRules, fetchPresetCalendar, calendar.id]);

  return (
    <CalendarContext.Provider value={{state, dispatch}}>
      <EditCalendarForm calendar={calendar} onDone={onDone}/>
    </CalendarContext.Provider>
  )
}

export default EditCalendarManager;