import React from "react";
import { DecreaseTimeButton, IncreaseTimeButton } from "../../../../components/buttons";
import { Time } from "../../../../util/Time";


function RecordTimeEditor({minutes, onUpdateMinutes}) {
  const time = new Time(minutes);

  return (
    <div className="row no-gutters d-flex align-items-center">
      <div className="col-1">&nbsp;</div>
      <div className="col-4">
        <div className="text-center">
          <IncreaseTimeButton className="mb-1"
                              onClick={() => onUpdateMinutes(time.addMinutes(60).minutes)}/>
          <input type="number"
                 className="form-control text-center"
                 onFocus={e => e.target.select()}
                 value={time.hourValue(true)}
                 onChange={e => onUpdateMinutes(time.setHours(e.target.value).minutes)}/>

          <DecreaseTimeButton className="mt-1"
                              onClick={() => onUpdateMinutes(time.addMinutes(-60).minutes)}/>
        </div>
      </div>
      <div className="col-1 pl-2 pr-3"><strong>h</strong></div>
      <div className="col-4">
        <div className="text-center">
          <IncreaseTimeButton className="mb-1"
                              onClick={() => onUpdateMinutes(time.addMinutes(15).minutes)}/>

          <input type="number"
                 className="form-control text-center"
                 onFocus={e => e.target.select()}
                 value={time.minuteValue(time.hourValue() === 0)}
                 onChange={e => onUpdateMinutes(time.setMinutes(e.target.value).minutes)}/>

          <DecreaseTimeButton className="mt-1"
                              onClick={() => onUpdateMinutes(time.addMinutes(-15).minutes)}/>
        </div>
      </div>
      <div className="col-1 pl-2"><strong>min</strong></div>
      <div className="col-1">&nbsp;</div>
    </div>
  );
}

export default RecordTimeEditor;