import { Link, RouteComponentProps } from "@reach/router";
import React, { useEffect, useMemo } from "react";
import { selectTenantById } from "../../store/ducks/admin/tenants/tenant-selectors";
import { fetchTenantsAction, updateTenantSubscriptionAction } from "../../store/ducks/admin/tenants/tenant-actions";
import { CenteredSection, ResponsiveCol } from "../../components/layout/LayoutHelpers";
import PageHeader from "../../components/text/PageHeader";
import { TenantRightsTable } from "./tenant-details/TenantRightsTable";
import styled from "styled-components";
import { AdminWhiteBlock } from "./layout/AdminWhiteBlock";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSimpleFetch } from "../../api/use-simple-fetch";
import { getTenantStats } from "../../api/admin/admin-tenants-api";
import { Typography } from "../../theme/typography/Typography";
import { TypographyVariant } from "../../theme/typography/types/TypographyVariant";
import { ActuallyButton, BaseIcon } from "../../components/buttons";
import { WithClassName } from "../../types/WithClassName";
import { TenantStatusIndicator } from "../../components/tenant-admin/TenantStatusIndicator";
import { differenceInDays, parseISO, today, tryParseISO } from "../../util/dates";
import ActuallyDatePicker from "../../components/datepicker/ActuallyDatePicker";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Time } from "../../util/Time";

interface TenantDetailsProps extends RouteComponentProps {
  tenantId?: string;
}

const StyledTenantRightsTable = styled(TenantRightsTable)`
`;

const StatsNumber = styled.span`
  font-size: 48px;
  font-weight: 700;
`;

const BackLink: React.FC<WithClassName> = ({className}) => {
  return (
    <Link to="/admin/tenants" className={className}>
      <BaseIcon iconName={['fal', 'arrow-left']}/>
    </Link>
  )
}

const StyledBackLink = styled(BackLink)`
  padding-right: 15px;
`;

const TenantFactBox = ({fact, label}) => {
  return (
    <ResponsiveCol xs={12} md={3}>
      <AdminWhiteBlock>
        <CenteredSection>
          <StatsNumber>{fact}</StatsNumber>
          <Typography variant={TypographyVariant.Body2}>{label}</Typography>
        </CenteredSection>
      </AdminWhiteBlock>
    </ResponsiveCol>
  );
}

const TenantDetails = ({tenant, facts}) => {
  const dispatch = useDispatch();
  const {reset, handleSubmit, control, formState} = useForm({
    mode: 'onChange',
    defaultValues: {
      expiryDate: parseISO(tenant.trial_ending_date),
    }
  });

  const {dirty} = formState;

  const expiresInDays = Math.max(0, differenceInDays(new Date(tenant.trial_ending_date), today()));

  const handleForm = (data) => {
    const expiryDate = tryParseISO(data.expiryDate);

    dispatch(updateTenantSubscriptionAction({
      id: tenant.id,
      trial_ending_date: expiryDate
    }));

    reset({expiryDate});
  }

  const last30DaysRecordsTime = Time.FromMinutes(facts.last_30_days_record_minutes).toColonSeparated();

  return (
    <Container fluid>
      <Row>
        <ResponsiveCol>
          <PageHeader>
            <StyledBackLink/>
            {tenant?.name}
            &nbsp;
            <small>
              <TenantStatusIndicator status={tenant?.status}>{tenant?.status}</TenantStatusIndicator>

            </small>
          </PageHeader>

          <div>

            <p>Expires in {expiresInDays} days</p>

            <form onSubmit={handleSubmit(handleForm)}>
              <ActuallyDatePicker control={control} name="expiryDate"/>
              &nbsp;
              <ActuallyButton disabled={!dirty} type="submit" variant="primary">Update expiry date</ActuallyButton>
            </form>
          </div>
        </ResponsiveCol>
      </Row>

      <Row>
        <TenantFactBox fact={facts.active_people_count} label="Active user accounts" />
        <TenantFactBox fact={facts.active_projects_count} label="Active projects" />
        <TenantFactBox fact={Math.round(facts.task_count / facts.active_projects_count)} label="Tasks per project" />
        <TenantFactBox fact={last30DaysRecordsTime} label="Last 30 days records" />
      </Row>

      <Row>
        <ResponsiveCol>
          {
            tenant &&
            <StyledTenantRightsTable tenant={tenant}/>
          }
        </ResponsiveCol>
      </Row>
    </Container>
  )
}

export const TenantDetailsContainer: React.FC<TenantDetailsProps> = ({tenantId, children}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenantsAction());

  }, [dispatch, tenantId])

  const tenant = useSelector(selectTenantById(tenantId));
  const facts = useSimpleFetch(useMemo(() => getTenantStats(Number(tenantId)), [tenantId]), []);

  if (!tenant) {
    return <div>Loading ...</div>
  }

  return <TenantDetails facts={facts} tenant={tenant} />
}
