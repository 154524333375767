const timesheetTypes = Object.freeze({
  FETCH_PROJECT_TASKS: 'FETCH_PROJECT_TASKS',
  SET_TIMESHEET_DATE_SUCCESSFUL: 'SET_TIMESHEET_DATE_SUCCESSFUL',
  FETCH_DAILY_TIMESHEET: 'FETCH_DAILY_TIMESHEET',
  FETCH_DAILY_TIMESHEET_SUCCESS: 'FETCH_DAILY_TIMESHEET_SUCCESS',
  FETCH_TIMESHEET_STATUS: 'FETCH_TIMESHEET_STATUS',
  FETCH_TIMESHEET_STATUS_SUCCESS: 'FETCH_TIMESHEET_STATUS_SUCCESS',
  SAVE_RECORD_SUCCESS: 'SAVE_RECORD_SUCCESS',
  SAVE_NEW_TOTAL_FOR_DAY: 'SAVE_NEW_TOTAL_FOR_DAY',
  DELETE_RECORD: 'DELETE_RECORD',
  DELETE_RECORD_SUCCESS: 'DELETE_RECORD_SUCCESS',
  CHANGE_TASK_VISILITY: 'CHANGE_TASK_VISIBILITY',
  REMOVE_PINNED_TASK: 'REMOVE_TASK',
  ADD_PINNED_TASK: 'ADD_TASK',
  PIN_ALL_PROJECT_TASKS: 'PIN_ALL_PROJECT_TASKS',
  UPDATE_PINNED_TASKS: 'UPDATE_PINNED_TASKS',
  START_EDITING: 'START_EDITING',
  CANCEL_EDITING: 'CANCEL_EDITING',
  CREATE_RECORD: 'CREATE_RECORD',
  PIN_SINGLE_TASK: 'PIN_SINGLE_TASK'
});

export default timesheetTypes;
