import { call, fork, take } from "@redux-saga/core/effects";

export function takeEveryUnique(actionName, uniqueExtractor, flow, ...args) {
  const runningQueue = {};

  return fork(function* () {
    while (true) {
      const action = yield take(actionName);
      const identifier = uniqueExtractor(action);

      if (!runningQueue[identifier]) {
        runningQueue[identifier] = true;
        yield fork(function* () {
          yield call(flow, ...args.concat(action));
          runningQueue[identifier] = false;
        });
      }
    }
  });
}