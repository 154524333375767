import {LABELS, ACTIVE_TEXTS} from '../configuration/label-definitions';
import FILTER_TYPES from '../configuration/filter-type-definations';

class Filter {
  // FIXME: TB-160
  filterKey: any;
  filterType = "";

  constructor(filterKey) {
    this.filterKey = filterKey;
  }

  getFilterKey = () => {
    return this.filterKey;
  };

  getFilterLabel = () => {
    return LABELS[this.filterKey];
  };

  getActiveFilterLabel = () => {
    return ACTIVE_TEXTS[this.filterKey];
  };

  // FIXME: TB-160
  getActiveFilterSentence: any = () => {
    throw Error('Not implemented');
  };

  formatValue = (value) => {
    return {[this.filterKey]: value}
  };

  isBooleanFilter = () => {
    return this.filterType === FILTER_TYPES.BOOLEAN;
  };

  isSelectFilter = () => {
    return this.filterType === FILTER_TYPES.SELECT;
  };

  isDateFilter = () => {
    return this.filterType === FILTER_TYPES.DATE;
  };

  isDateRangeFilter = () => {
    return this.filterType === FILTER_TYPES.DATERANGE;
  };

  isInputFilter = () => {
    return this.filterType === FILTER_TYPES.INPUT;
  }

}

export default Filter;