import React from "react";
import { today, parseISO, formatDistance, differenceInDays } from "../../../util/dates";
import { getCurrentUserDateLocale, TRANSLATION_KEYS } from "../../../intl/intl";
import { LocalizedMessage } from "../../../intl/components/LocalizedMessage";
import styled from "styled-components";

const DateAgoText = styled.span`
  text-transform: lowercase;
`

const DateAgoISO = ({ date, ...props }) => {
  const todaysDate = today();
  const otherDate = parseISO(date);
  const dateLocale = getCurrentUserDateLocale()
  const isUnderOneDay = differenceInDays(todaysDate, otherDate) === 0;

  return (
    <DateAgoText {...props}>
      {isUnderOneDay
        ? <LocalizedMessage text={{ id: TRANSLATION_KEYS.commonProperties.today}} />
        : formatDistance(otherDate, todaysDate, { addSuffix: true, locale: dateLocale })}
    </DateAgoText>
  )
};

export default DateAgoISO;
