import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { TooltipCircle } from "../../../../components/ui-utility/Tooltip";
import { useAuthGroupAPI } from "../auth-group-hooks";

import FormActionRow from "../../../../forms/FormActionRow";
import WithErrorInput from "../../../timesheet/components/WithErrorInput";
import { FormDualBoxUserSelect } from "../../../../components/select/dual-box-select/DualBoxUserSelect";
import { TextArea } from "../../../../components/forms/TextArea";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {useSimpleLocalizedMessage} from "../../../../intl/use-localized-message";


function AddAuthGroupForm({onDone}) {
  const {createAuthGroup} = useAuthGroupAPI();

  const handleSaving = async authGroup => {
    const {response, error} = await createAuthGroup(authGroup);

    response && onDone();

    return {response, error};
  };

  return (
    <AuthGroupForm onCancel={onDone} onSave={handleSaving}/>
  )
}

function EditAuthGroupForm({onDone, authGroup}) {
  const {removeAuthGroup, updateAuthGroup} = useAuthGroupAPI();

  const handleSaving = async data => {
    const {response, error} = await updateAuthGroup(authGroup.id, data);

    response && onDone(response);

    return {response, error};
  };

  const handleDeleting = async () => {
    removeAuthGroup(authGroup.id);

    onDone && onDone();
  };

  return (
    <AuthGroupForm onCancel={onDone} onSave={handleSaving} onDelete={handleDeleting}
                   authGroup={authGroup}/>
  )
}

// FIXME: TB-160
interface AuthGroupFormProps {
  onSave: any;
  onCancel: any;
  onDelete?: any;
  authGroup?: any;
}

const AuthGroupForm: React.FunctionComponent<AuthGroupFormProps> = ({onSave, onCancel, onDelete, authGroup}) => {
  // FIXME: TB-160
  const [errors, setErrors] = useState<any>({});

  const {register, formState, handleSubmit, setValue, control, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: authGroup.name,
      description: authGroup.description,
      users: authGroup.users
    }
  });

  const onSubmit = async data => {
    const {error} = await onSave(data);

    error && setErrors(error);
  };

  const {isValid, dirty} = formState;

  const nameFieldPlaceholder = useSimpleLocalizedMessage(
    TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.groupNameFieldPlaceholder
  );
  const descriptionFieldPlaceholder = useSimpleLocalizedMessage(
    TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.descriptionFieldHelpText
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <label className="label-default" htmlFor="name">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.groupNameFieldLabel}}/>
          </label>
          <WithErrorInput errors={errors.name}>
            <input className="form-control" name="name" type="text" ref={register({required: true})} tabIndex={1}
                   placeholder={nameFieldPlaceholder}/>
          </WithErrorInput>
        </div>

        <div className="form-group col-lg-12">
          <label htmlFor="description">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.descriptionFieldLabel}}/>
          </label>
          <TextArea name="description"
                    placeholder={descriptionFieldPlaceholder}
                    innerRef={register} tabIndex={2}
                    className="form-control" minRows={2}/>
        </div>

        <div className="form-group col-lg-12">
          <label htmlFor="preset">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.addPeopleFieldLabel}}/>
          </label>
          <TooltipCircle
            instructionText={<LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.addPeopleFieldTooltip}}/>}/>
          <FormDualBoxUserSelect setValue={setValue} watch={watch} control={control}/>
        </div>
      </div>

      <FormActionRow showDeleteButton={!!onDelete} handleDelete={onDelete} tabIndexStart={3}
                     isValid={isValid} dirty={dirty} handleCancel={onCancel}/>
    </form>
  )
}

AuthGroupForm.defaultProps = {
  authGroup: {
    users: []
  }
};

export {
  AddAuthGroupForm,
  EditAuthGroupForm
};