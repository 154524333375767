import React from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from 'classnames';

import Modal from "react-bootstrap/Modal";

import { selectModalForName, closeModalAction } from "./modal-redux";
import { If } from "../ui-utility/Conditional";

import './SimpleModal.css';

interface SimpleModalProps {
  name?: string;
  children: any;
  onClose?: () => void;
  className?: string;
  useBackDrop?: boolean;
  centered?: boolean; 
  manualControl?: any;
}

const SimpleModal: React.FunctionComponent<SimpleModalProps> = ({name, children, onClose, className, useBackDrop, centered, manualControl}) =>{
  const dispatch = useDispatch();
  const isOpen = useSelector(selectModalForName(name)) || manualControl;

  const handleClosing = () => {
    onClose && onClose();

    dispatch(closeModalAction(name));
  };

  const classes = classNames('simple-modal', className);

  const backDropClasses = classNames({"d-none": !useBackDrop, "modal-backdrop simple-backdrop": useBackDrop});

  return (
    <Modal show={isOpen}
           onHide={handleClosing}
           centered={centered}
           backdropClassName={backDropClasses}
           dialogClassName={classes}>
      <If condition={isOpen}>
        <div className="simple-modal-close" onClick={handleClosing}>
          <button type="button" className="close">
            <span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        {children}
      </If>
    </Modal>);
}

SimpleModal.defaultProps = {
  useBackDrop: false,
  centered: false
};

export default SimpleModal;