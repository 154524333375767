import React, { useState } from "react";
import peopleSelectors from "../../../store/ducks/people/selectors";

import useShallowEqualSelector from "../../../store/helpers";
import useUserAPI from "../user-hooks";
import EditPersonForm from "./EditPersonForm";

function EditPerson({userId, onEditingDone}) {
  const [formErrors, setFormErrors] = useState({});
  const {updateUser} = useUserAPI();

  const user = useShallowEqualSelector(peopleSelectors.selectUser(userId));

  const onSubmit = async (payload) => {
    const {response, error = {}} = await updateUser(userId, payload);

    if (response)
      onEditingDone && onEditingDone();
    else
      setFormErrors(error);
  };

  return (
    <EditPersonForm user={user} onSubmit={onSubmit} errors={formErrors} onCancel={onEditingDone}/>
  )
}

export default EditPerson;