import React from 'react';
import { isSameDay } from "../../../../util/dates";
import DayShort from "../../../../components/ui-utility/dates/DayShort";

import styled from 'styled-components';
import DateShort from "../../../../components/ui-utility/dates/DateShort";
import { If } from "../../../../components/ui-utility/Conditional";

interface ChangeDayButtonProps {
  isActive: boolean;
}

const ChangeDayButton = styled.button<ChangeDayButtonProps>`
  font-size: 0.8rem;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  // line-height: 2.5rem;
  text-align: center;
  border-radius: 50%;
  // vertical-align: middle;
  color: #102A43;
  position: relative;
  ${props => props.isActive ? 'box-shadow: 0 2px 4px #D9E2EC;' : ''}
  font-weight: ${props => props.isActive ? 700 : 400};
  background-color: ${props => props.isActive ? 'white' : 'initial'};
`;

function Day({date, isActive, onChangeDate}) {
  return (
    <ChangeDayButton className="btn btn-link2 btn-text p-2 text-uppercase touch-action-manipulation"
                     isActive={isActive}
            onClick={e => onChangeDate(date)}>
       <div><DayShort date={date} style={{ verticalAlign: 'middle' }} /></div>

      <If condition={isActive}>
        <div style={{ position: 'absolute', left: '50%', top: '100%', width: '50px', marginLeft: '-25px', paddingTop:'5px'}}>
          <DateShort date={date} />
        </div>
      </If>
    </ChangeDayButton>
  );
}

function WeekDaySelector({dates, currentDate, onChangeDate}) {

  return (
    <>
      {dates.map(date => <div key={date}>
        <Day date={date} isActive={isSameDay(date, currentDate)} onChangeDate={onChangeDate} />
      </div>)}
    </>
  );
}

export default WeekDaySelector;