import React, {useState} from 'react';

import {TabbedContainer} from "../../components/layout/TabbedContainer";

import ProjectTasksContainer from "./ProjectTasksContainer";
import ProjectRecordsByUsersTable from "./ProjectRecordsByUsersTable";
import {TRANSLATION_KEYS} from "../../intl/intl";
import RequireContextRight from "../../components/rights/RequireContextRight";
import RIGHTS from "../../components/rights/right-definitions";
import AddTasksModal from "./project-tasks/AddTasksModal";
import {closeModalAction} from "../../components/modal/modal-redux";
import modalDefinitions from "../../components/modal/modal-definitions";
import {useDispatch} from "react-redux";
import styled from "styled-components";

enum ProjectDetailsTabsEnum {
  Tasks = 'tasks',
  People = 'people',
}

interface ProjectComponentProps {
  projectId: number
}

const ActionWrapper = styled.div`
  padding-bottom: 0.5em;
`

export const ProjectDetailsTabs: React.FunctionComponent<ProjectComponentProps> = ({projectId}) => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<ProjectDetailsTabsEnum | string>(ProjectDetailsTabsEnum.Tasks);

  const tabs = [
    {
      key: ProjectDetailsTabsEnum.Tasks,
      label: {id: TRANSLATION_KEYS.projects.projectDashboard.tasksTab.tabHeader},
      contentComponent: <ProjectTasksContainer projectId={projectId}/>
    },
    {
      key: ProjectDetailsTabsEnum.People,
      label: {id: TRANSLATION_KEYS.projects.projectDashboard.actualsTab.tabHeader},
      contentComponent: <ProjectRecordsByUsersTable projectId={projectId}/>
    }
  ]

  const handleCloseTasksModal = () => {
    dispatch(closeModalAction(modalDefinitions.PROJECT_MANAGE_TASKS));
  };

  const TabActions = (
    <ActionWrapper>
      <RequireContextRight rightName={RIGHTS.manageProjectTasks} objectId={projectId} contextType="projects">
        <AddTasksModal projectId={projectId} onClose={handleCloseTasksModal}/>
      </RequireContextRight>
    </ActionWrapper>
  );

  return (
    <TabbedContainer tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} actions={TabActions}/>
  );
}