import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { selectProjectForId, selectActiveTasksForProjectId } from "../../../store/ducks/timesheet/selectors";
import { pinAllProjectTasksAction } from "../../../store/ducks/mobile/timesheet/actions";

import VisibleTaskSection from "./VisibleTaskSection";
import ProjectName from "../../project/ProjectName";
import Conditional from "../../ui-utility/Conditional";

import './VisibleTasks.css'
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

function VisibleTaskProjectSection({item}) {
  const dispatch = useDispatch();
  const project = useSelector(selectProjectForId(item));
  const taskIds = useSelector(selectActiveTasksForProjectId(item)) || [];

  const handleAllSelection = () => {
    dispatch(pinAllProjectTasksAction(item));
  };

  return (
    <div className="visible-task-section">
      <div className="row">
        <div className="col-12 text-right">
          <Conditional condition={taskIds.length > 1} onTrue={
            <button type="button" className="btn btn-slick" onClick={handleAllSelection}>
              <span className="text-primary">
                <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.selectAll}}/>
              </span>
            </button>} 
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <ProjectName {...project} addLink={false} className="bold"/>
        </div>
        <div className="col-8">
          {taskIds.map(taskId => (<VisibleTaskSection taskId={taskId} key={taskId}/>))}
        </div>
      </div>
    </div>
  )
}


export default VisibleTaskProjectSection;