class UserDetailsBalanceEntity {
  //  FIXME: TB-160
  balance: any;
  minutes: any;
  constructor(balance) {
    this.balance = balance;
    this.minutes = balance ? balance.balance_minutes : null;
  }
}

export default UserDetailsBalanceEntity;
