import {css} from "styled-components";

export const getPulsatingEffect = (r, g, b) => css`
   box-shadow: 0 0 0 0 rgba(${r}, ${g}, ${b}, 0.7);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  transition: all 300ms ease-in-out;
  
 @-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(${r}, ${g}, ${b}, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(${r}, ${g}, ${b}, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(${r}, ${g}, ${b}, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(${r}, ${g}, ${b}, 0);
  }
}
`