import React from 'react';
import PropTypes from 'prop-types';

import Filter from './entities/Filter';

import Collapsible from '../ui-utility/Collapsible';

import BooleanFilter from "./components/BooleanFilter";
import SelectFilter from "./components/SelectFilter";
import DateFilter from "./components/DateFilter";
import DateRangeFilter from "./components/DateRangeFilter";
import InputFilter from "./components/InputFilter";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";

function FilterField(props) {
  const {filter} = props;

  const filterLabel = <LocalizedMessage text={filter.getFilterLabel()} />

  const makeCollapsible = (component) => {
    return (
      <Collapsible label={<span>{filterLabel} </span>} content={component}/>
    )
  };

  const renderCorrectComponentForFilter = () => {
    if (filter.isBooleanFilter())
      return makeCollapsible(<BooleanFilter filter={filter}/>);
    if (filter.isSelectFilter())
      return makeCollapsible(<SelectFilter filter={filter}/>);
    if (filter.isDateFilter())
      return makeCollapsible(<DateFilter filter={filter}/>);
    if(filter.isDateRangeFilter())
      return makeCollapsible(<DateRangeFilter filter={filter}/>);
    if(filter.isInputFilter())
      return makeCollapsible(<InputFilter filter={filter}/>);

    return (<div/>)
  };

  return renderCorrectComponentForFilter();
}

FilterField.propTypes = {
  filter: PropTypes.instanceOf(Filter),
};

export default FilterField;