export interface Person {
  id: number,
  first_name: string,
  last_name: string,
  email?: string
}

class PersonEntity implements Person{
  id: number;
  first_name: string;
  last_name: string;

  constructor({id, first_name, last_name}) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
  }

  hasName = () => {
    return this.first_name || this.last_name;
  };

  getFullName = () => {
    return PersonEntity.parseFullName(this.first_name, this.last_name);
  };

  static parseFullName(first_name, last_name) {
    return `${first_name} ${last_name}`;
  }

}

export default PersonEntity;