import React from "react";
import styled from "styled-components";

import {CustomerName} from "../../components/customers/CustomerName";
import {constructModalUrl} from "../../components/modal/modal-hooks";

import {TableColumnConfiguration} from "../../components/table/types/TableColumnConfiguration";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {ActuallyButton} from "../../components/buttons";
import {navigate} from "@reach/router";

import modalDefinitions from "../../components/modal/modal-definitions";
import {TableColumnContentAlignment} from "../../components/table/types/TableColumnContentAlignment";
import {LatestRecord} from "../../components/people/PersonLatestRecord";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";


export const CustomerTableColumns: TableColumnConfiguration[] = [
  {
    key: 'name',
    label: {id: TRANSLATION_KEYS.commonProperties.name},
    isSortable: true,
    sortBy: 'name'
  },
  {
    key: 'latestRecord',
    label: {id: TRANSLATION_KEYS.people.tableHeaders.latestRecord},
  },
  {
    key: 'projectCount',
    label: {id: TRANSLATION_KEYS.customers.projectCount},
    columnContentAlignment: TableColumnContentAlignment.Right
  },
];

const CustomeEditButton = styled(ActuallyButton)`
  :focus {
    box-shadow: none;
  }
  display:block;
`

export const createCustomerTableData = (customers) => customers.map((customer) => {
  const handleNavigation = () => {
    const url = constructModalUrl(modalDefinitions.EDIT_CUSTOMER, {"modal.customerId": customer.id});

    navigate(url);
  }

  return {
    name: (
      <CustomeEditButton variant="slick" className="btn-block" onClick={handleNavigation}>
        <CustomerName {...customer} disableTooltip={true}/>
      </CustomeEditButton>
    ),
    latestRecord: (
      <LatestRecord latestDate={customer.latestRecord}
                    placeholder={<LocalizedMessage
                      text={{id: TRANSLATION_KEYS.customers.noLatestRecordFoundMessage}}/>}/>
    ),
    projectCount: (
      <span>{customer.projectCount}</span>
    )
  }
});