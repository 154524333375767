import { includes } from "lodash";

interface ColumnSorterEntityParams {
  field: string;
  currentOrdering?: Array<string>;
}

class ColumnSorterEntity {
  field: string;
  currentOrdering: Array<string>;
  invertedField: string;
  constructor({ field, currentOrdering }: ColumnSorterEntityParams) {
    this.field = field;
    this.currentOrdering = currentOrdering ? [...currentOrdering] : [];
    this.invertedField = this.invertField();
  }

  invertField = () => {
    if (this.field.charAt(0) === "-") return this.field.substring(1);
    return `-${this.field}`;
  };

  isBeingOrderedAsc = () => {
    return includes(this.currentOrdering, this.field);
  };

  isBeingOrderedDesc = () => {
    return includes(this.currentOrdering, this.invertedField);
  };

  changeOrdering = () => {
    if (this.isBeingOrderedAsc()) {
      let indexOf = this.currentOrdering.indexOf(this.field);

      this.currentOrdering[indexOf] = this.invertedField;
    } else if (this.isBeingOrderedDesc()) {
      let indexOf = this.currentOrdering.indexOf(this.invertedField);

      this.currentOrdering.splice(indexOf, 1);
    } else {
      this.currentOrdering.push(this.field);
    }

    return this.currentOrdering;
  };

  getOrderingPosition = () => {
    const indexOf = this.currentOrdering.indexOf(this.field);
    const indexOfInverted = this.currentOrdering.indexOf(this.invertedField);

    if (indexOf !== -1) return indexOf + 1;
    if (indexOfInverted !== -1) return indexOfInverted + 1;

    return undefined;
  };

  static asQueryString = (currentOrdering) => (currentOrdering || []).join(",");
}

export default ColumnSorterEntity;
