import {sendRequestSimple} from "../../api/api";
import {useCallback,} from "react";
import {getCountries} from "../../api/country/country-api";

export const useCountryAPI = () => {
  const fetchCountries = useCallback(async () => {
    return await sendRequestSimple(getCountries());
  }, []);

  return {
    fetchCountries,
  }
};
