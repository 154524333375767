import React from "react";
import {calendarTableColumns} from "./calendar-table";

import {If} from "../../../../components/ui-utility/Conditional";

import {ActuallyTable} from "../../../../components/table/ActuallyTable";
import EditCalendarModal from "../edit/EditCalendarModal";
import DateFormatter from "../../../../components/ui-utility/DateFormatter";
import {CalendarResponse} from "../../../../api/calendars/CalendarEntity";
import {TRANSLATION_KEYS} from "../../../../intl/intl";


const calendarTableRowCreator = calendars => {
  return calendars.map(calendar => {
    return {
      name: <EditCalendarModal calendar={calendar}/>,
      users: calendar.user_count,
      holidays: (
        <If condition={calendar.min_date}>
          <i>
            <DateFormatter date={calendar.min_date}/> to <DateFormatter date={calendar.max_date}/>
          </i>
        </If>
      )
    }
  })
}

interface CalendarListingProps {
  calendars: Array<CalendarResponse>;
}

export const CalendarListing: React.FunctionComponent<CalendarListingProps> = ({calendars}) => {
  const calendarRows = calendarTableRowCreator(calendars);

  return (
    <ActuallyTable
      tableColumns={calendarTableColumns}
      tableData={calendarRows}
      emptyResultProps={{
        paragraphs: [
          {id: TRANSLATION_KEYS.calendarSettings.noCalendarsMessageHeader},
          {id: TRANSLATION_KEYS.calendarSettings.noCalendarsMessageBody},
        ]
      }}
    />
  );
}

