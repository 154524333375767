import loadingTypePrefixes from "./loading-types";

export const defaultLoadingElement = {
  callCount: 0,
  loading: true
};

function loadingReducer(state = {}, action) {
  switch (action.type) {
    case loadingTypePrefixes.PENDING:
      const existingElement = state[action.actionName] || defaultLoadingElement;

      return {
        ...state, [action.actionName]: {
          loading: true,
          callCount: existingElement.callCount + 1
        }
      };
    case loadingTypePrefixes.DONE:
      return {
        ...state, [action.actionName]: {
          ...state[action.actionName],
          loading: false
        }
      };
    default:
      return state;
  }
}

export default loadingReducer;