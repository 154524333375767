import executeRequest from "../../../api/api";

import { takeLatest, takeEvery, put, call, select } from "redux-saga/effects";

import {
  addMultipleProject,
  fetchProject, getProjects
} from "../../../api/projects/projects-api";

import projectSagaTypes from "./types";
import { fetchProjectsSuccess, fetchProjectSuccess, fetchProjects } from "./actions";
import { ProjectsAPIResponseParser } from "../../../api/projects/projects-api-response";
import paginationSelectors from "../pagination/pagination-selectors";
import { paginationKeys } from "../pagination/pagination-keys";
import { changePaginationForPageAction } from "../pagination/pagination-actions";
import { CustomersAPIResponseParser } from "../../../api/customers/customers-api-response";
import { fetchCustomersSuccessAction, fetchSingleCustomerSuccessAction } from "../customer/customer-reducers";
import orderingSelectors from "../ordering/ordering-selectors";
import filterSelectors from "../filter/filter-selectors";
import ProjectPageFilterBlock
  from "../../../components/filter/configuration/filter-blocks-for-page/ProjectPageFilterBlock";

function* addMultipleProjectFlow(action) {
  yield call(executeRequest, addMultipleProject(action.payload), {cancelOnFailure: true});

  yield put(fetchProjects());
}

function* fetchProjectWatch(action) {
  const {payload: projectId} = action;

  const {response} = yield call(executeRequest, fetchProject(projectId), {
    trackRequest: action.type,
    cancelOnFailure: true
  });

  yield updateProjectSuccess(response.data);
}

function* fetchProjectsWatch(action) {
  const ordering = yield select(orderingSelectors.selectOrderingAsQueryStringForPage(paginationKeys.PROJECT_LISTING));
  const page = yield select(paginationSelectors.selectCurrentPageForPage(paginationKeys.PROJECT_LISTING));
  const filters = yield select(filterSelectors.selectActiveFiltersAsQueryParams(ProjectPageFilterBlock.blockKey));

  const {response} = yield call(executeRequest, getProjects({page, ordering, ...filters}), {
      cancelOnFailure: true,
      trackRequest: action.type
    }
  );

  const responseWithNormalization = ProjectsAPIResponseParser.normalizeListing(response.data.data);
  const normalizedCustomers = CustomersAPIResponseParser.extractCustomersFromProjectResponse(responseWithNormalization);

  yield put(fetchCustomersSuccessAction(normalizedCustomers));
  yield put(fetchProjectsSuccess(responseWithNormalization));
  yield put(changePaginationForPageAction(response.data, paginationKeys.PROJECT_LISTING));
}

function* receiveProject(action) {
  yield updateProjectSuccess(action.response);
}

function* updateProjectSuccess(project) {
  const normalizedProject = {...project, customer: project.customer?.id};

  yield put(fetchSingleCustomerSuccessAction(project.customer || {}));
  yield put(fetchProjectSuccess(normalizedProject));
}


export const projectSagas = [
  takeEvery(projectSagaTypes.RECEIVE_PROJECT, receiveProject),
  takeEvery(projectSagaTypes.ADD_MULTIPLE_PROJECTS, addMultipleProjectFlow),
  takeLatest(projectSagaTypes.FETCH_PROJECT, fetchProjectWatch),
  takeLatest(projectSagaTypes.FETCH_PROJECTS, fetchProjectsWatch),
];
