import timesheetTypes from './types';


export const fetchDailyTimesheet = (userId, date, enddate?) => {
  return {
    type: timesheetTypes.FETCH_DAILY_TIMESHEET,
    payload: {userId, date, enddate},
  }
};

export const fetchDailyTimesheetSuccess = (startDate, data) => {
  return {
    type: timesheetTypes.FETCH_DAILY_TIMESHEET_SUCCESS,
    payload: {
      startDate,
      ...data,
    },
  }
};

export const fetchTimesheetStatus = (userId, startDate, endDate) => {
  return {
    type: timesheetTypes.FETCH_TIMESHEET_STATUS,
    payload: {
      userId,
      startDate,
      endDate,
    },
  }
};

export const fetchTimesheetStatusSuccess = (userId, startDate, endDate, monthlyRecords) => {
  return {
    type: timesheetTypes.FETCH_TIMESHEET_STATUS_SUCCESS,
    payload: {
      userId,
      startDate,
      endDate,
      values: monthlyRecords,
    },
  }
};


export const saveNewTotalForDay = record => {
  return {
    type: timesheetTypes.SAVE_NEW_TOTAL_FOR_DAY,
    payload: record,
  }
};

export const saveRecordSuccess = record => {
  return {
    type: timesheetTypes.SAVE_RECORD_SUCCESS,
    payload: {
      ...record,
    },
  }
};

export const createRecord = record => {
  return {
    type: timesheetTypes.CREATE_RECORD,
    payload: {
      ...record,
    },
  }
};

export const deleteRecord = record => {
  return {
    type: timesheetTypes.DELETE_RECORD,
    payload: record,
  }
};

export const startRecordEdit = recordId => {
  return {
    type: timesheetTypes.START_EDITING,
    payload: recordId
  }
};

export const cancelRecordEdit = recordId => {
  return {
    type: timesheetTypes.CANCEL_EDITING,
    payload: recordId
  }
};

export const deleteRecordSuccess = record => {
  return {
    type: timesheetTypes.DELETE_RECORD_SUCCESS,
    payload: {
      recordId: record.id,
      taskId: record.task,
    },
  }
};

export const changeTaskVisibilityStatusAction = (taskId, status?) => {
  return {
    type: timesheetTypes.CHANGE_TASK_VISILITY,
    payload: {taskId, status},
  }
};

export const pinSingleTaskAction = taskId => {
  return {
    type: timesheetTypes.PIN_SINGLE_TASK,
    payload: {taskId},
  }
};

export const removePinnedTaskAction = taskId => {
  return {
    type: timesheetTypes.REMOVE_PINNED_TASK,
    taskId,
  }
};

export const addPinnedTaskAction = data => {
  return {
    type: timesheetTypes.ADD_PINNED_TASK,
    data,
  }
};

export const pinAllProjectTasksAction = projectId => {
  return {
    type: timesheetTypes.PIN_ALL_PROJECT_TASKS,
    projectId
  }
};

export const updatePinnedTaskAction = data => {
  return {
    type: timesheetTypes.UPDATE_PINNED_TASKS,
    data
  }
};