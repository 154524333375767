import { createSlice } from "@reduxjs/toolkit";
import { mergeObjectIntoState } from "../../../store/helpers";
import { uniq } from 'lodash';

const initialState = {
  holidays: {},
  holidayDates: [],

  added: [],
  removed: [],

  presets: {},
  presetsPerYear: {}
};

const receivePresetDates = (state, action) => {
  const {presets = {}, presetsPerYear = {}} = action.payload;

  return {
    ...state,
    presets,
    presetsPerYear
  }
};

const receiveHolidayDates = (state, action) => {
  const {entities, result} = action.payload;

  const holidays = mergeObjectIntoState(state.holidays, entities.holidays, 'holidays');
  const holidayDates = [...result, ...state.holidayDates];

  return {
    ...state,
    ...holidays,
    holidayDates
  }
};

const receiveAddedDays = (state, action) => {
  const added = uniq([...state.added, ...action.payload]);

  return {
    ...state,
    added
  }
};

const removeHolidayDate = (state, action) => {
  const added = state.added.filter(addedDate => addedDate !== action.payload);
  const holidayDates = state.holidayDates.filter(holidayDate => holidayDate !== action.payload);

  return {
    ...state,
    holidayDates,
    added,
  }
};

const moveToRemoved = (state, action) => {
  const added = state.added.filter(addedDate => addedDate !== action.payload);
  const removed = [...state.removed, action.payload];

  return {
    ...state,
    added,
    removed,
  }
};

const localCalendarSlice = createSlice({
  name: 'holidayDates',
  initialState,
  reducers: {
    receiveHolidayDatesAction: receiveHolidayDates,
    receiveAddedDaysAction: receiveAddedDays,
    removeHolidayDateAction: removeHolidayDate,
    moveToRemovedAction: moveToRemoved,
    receivePresetDatesAction: receivePresetDates,
  }
});

const {actions, reducer} = localCalendarSlice;

const {
  receiveHolidayDatesAction,
  receiveAddedDaysAction,
  removeHolidayDateAction,
  receivePresetDatesAction,
  moveToRemovedAction,
} = actions;

export default reducer;

export {
  initialState,
  receiveHolidayDatesAction,
  receiveAddedDaysAction,
  receivePresetDatesAction,
  removeHolidayDateAction,
  moveToRemovedAction,
};
