import FilterFactory from "../../entities/FilterFactory";
import FilterBlockEntity from "../../entities/FilterBlockEntity";
import FilterRegistry from "../filter-registry";

class ProjectPageFilterBlock extends FilterBlockEntity {
  static blockKey = 'projectPage';

  createFilters() {
    return [
      FilterFactory.createBooleanFilter('project.is_active'),
      FilterFactory.createInputFilter('project.q'),
      FilterFactory.createApiSelectFilter('customer.id', 'customers', false),
    ];
  };

}

FilterRegistry.registerFilterBlock(ProjectPageFilterBlock.blockKey, new ProjectPageFilterBlock());

export default ProjectPageFilterBlock;
