import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { selectTaskForId, selectPinnedTaskByTaskId } from "../../../store/ducks/timesheet/selectors";
import { changeTaskVisibilityStatusAction } from "../../../store/ducks/mobile/timesheet/actions";

import TaskName from "../TaskName";
import ToggleSwitch from "../../toggle-switch/ToggleSwitch";

function VisibleTaskSection({taskId}) {
  const dispatch = useDispatch();
  const task = useSelector(selectTaskForId(taskId));
  const pinnedTask = useSelector(selectPinnedTaskByTaskId(taskId));

  const handleVisibleStatusChange = newValue => {
    dispatch(changeTaskVisibilityStatusAction(taskId, newValue));
  };

  return (
    <div className="row mb-1">
      <div className="col-8">
        <TaskName {...task}/></div>
      <div className="col-4 text-right">
        <ToggleSwitch onChangeOfValue={handleVisibleStatusChange} isChecked={!!pinnedTask}/></div>
    </div>
  )
}

export default VisibleTaskSection;