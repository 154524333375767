import React from "react";
import styled from "styled-components";
import {If} from "../../../components/ui-utility/Conditional";
import {Alert} from "react-bootstrap";
import {SupportAddress} from "../../../components/ui-utility/SupportAddress";
import {getTaxRateForCountry} from "./TaxRateResolver";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

const BorderedDiv = styled.div`
    border: 1px solid #f0f4f8;
    border-radius: 5px;
`

export const SpaceBetweenItem = styled.div`
  display:flex;
  justify-content: space-between;
  padding: 0.5em 1em;
`

const SpaceBetweenBordered = styled(SpaceBetweenItem)`
  border-bottom: 1px solid #f0f4f8;
`

export const CardCouldNotBeCharged = ({show}) => {
  return (
    <If condition={show}>
      <Alert variant="warning">
        <LocalizedMessage text={{
          id: TRANSLATION_KEYS.trial.checkout.form.cardChargeErrorMessage,
          values: {
            emailAddress: <SupportAddress/>
          }
        }} />
      </Alert>
    </If>
  )
}

export const PlanDetails = ({planDetails, country}) => {
  const taxRateForCountry = getTaxRateForCountry(country);

  let plusTaxes = 0;

  if (taxRateForCountry) {
    plusTaxes = planDetails.total_cost * (taxRateForCountry / 100);
  }

  return (
    <BorderedDiv className="mb-4">
      <SpaceBetweenBordered>
        <span>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.usersLabel}} />
        </span>
        <span className="bold">{planDetails.quantity}</span>
      </SpaceBetweenBordered>
      <SpaceBetweenBordered>
        <span>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.unitPriceLabel}} />
        </span>
        <span className="bold">{planDetails.unit_cost} {planDetails.currency}</span>
      </SpaceBetweenBordered>
      <SpaceBetweenBordered>
        <span className="bold">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.totalPerMonthLabel}} />
        </span>
        <span className="bold">{planDetails.total_cost}
          <If condition={taxRateForCountry}> + {plusTaxes.toFixed(2)}
          </If> {planDetails.currency}</span>
      </SpaceBetweenBordered>
    </BorderedDiv>
  )
}
