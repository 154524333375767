import {Time} from "../util/Time";

const validTimeValidator = value => {
  return Time.FromUserInput(value) !== null;
};

const hasActualCharacters = (stringValue = "") => {
  return stringValue && stringValue.trim().length > 0;
};

const hasSelections = collection => {
  return collection.length > 0;
}

export {
  validTimeValidator,
  hasActualCharacters,
  hasSelections
}
