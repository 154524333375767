import React from "react";

import { TimesheetTotalMinutesForDay } from "../daily/components/TimesheetTotalMinutes";

const TotalHeader = ({date}) => {
  return (
    <th className="py-2" style={{backgroundColor: 'white', textAlign: 'center'}}>
      <TimesheetTotalMinutesForDay date={date}/>
    </th>
  )
};

const TotalHeaders = ({dates}) => {
  return (
    dates.map(date => <TotalHeader key={date} date={date}/>)
  );
};

TotalHeaders.propTypes = {
  // FIXME: TB-160 -- Date
  // dates: PropTypes.arrayOf(Date).isRequired,
};

TotalHeader.propTypes = {
  // FIXME: TB-160 -- Date
  // date: PropTypes.objectOf(Date).isRequired,
};

export default TotalHeaders;