import React from "react";

import SimpleModal from "../../../../components/modal/SimpleModal";
import ModalFrame from "../../../../components/modal/ModalFrame";

import { useModal } from "../../../../components/modal/modal-hooks";
import { AddAuthGroupForm } from "./AddAuthGroupForm";

import { ActuallyButton } from "../../../../components/buttons";

import modalDefinitions from "../../../../components/modal/modal-definitions";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";


function AddAuthGroupButton() {
  const [openModal, closeModal] = useModal(modalDefinitions.ADD_AUTH_GROUP);

  return (
    <>
      <ActuallyButton variant="link" onClick={openModal} className="btn-sm">
        <strong>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.authorizationGroupManagement.addAuthGroupLink}}/>
        </strong>
      </ActuallyButton>
      <SimpleModal name={modalDefinitions.ADD_AUTH_GROUP}>
        <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.addPageHeader}}/>}>
          <AddAuthGroupForm onDone={closeModal}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default AddAuthGroupButton;