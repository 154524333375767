import { useEffect, useState } from "react";
import { sendRequestSimple } from "./api";

export function useSimpleFetch(requestObject, initialState?) {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    async function fetchData() {
      const {response} = await sendRequestSimple(requestObject);
      setData(response);
    }

    fetchData();
  }, [requestObject]);

  return data;
}
