import React from "react";
import ColumnSorterEntity from "./ColumnSorterEntity";
import styled from "styled-components";
import { ColumnSorterCaret, ColumnSorterCaretDirection } from "./ColumnSorterCaret";

const ColumnSorterWrapper = styled.span`
  display: flex;
  align-items: center;
  text-align: right;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
`

const DoubleCaretHolder = styled.div`
  display: inline-block;
  line-height: 0;
  height: 20px;
  width: 17px;
  padding-left: 4px;
  position: relative;
`

const ColumnOrderingPositionIndicator = styled.div`
  position: absolute;
  font-size: 0.8em;
  right: 0;
  top: 0;
`

const ColumnOrderingPosition = styled.strong`
  color: #627D98;
`

interface OwnPropTypes {
  className?: string;
  field: string;
  currentOrdering?: Array<string>;
  onOrderingChange?: (newOrdering: Array<string>) => void;
}

type ColumnSorterProps = OwnPropTypes;

export const ColumnSorter: React.FunctionComponent<ColumnSorterProps> = ({
  className,
  onOrderingChange,
  ...props
}) => {
  const columnSorterEntity = new ColumnSorterEntity(props);

  const handleOrderingChange = () => {
    const newOrdering = columnSorterEntity.changeOrdering();
    onOrderingChange && onOrderingChange(newOrdering);
  };

  const columnOrderingPosition = columnSorterEntity.getOrderingPosition();

  return (
    <ColumnSorterWrapper className={className} onClick={handleOrderingChange}>
      {props.children}
      <DoubleCaretHolder>
        <ColumnSorterCaret bold={columnSorterEntity.isBeingOrderedAsc()} direction={ColumnSorterCaretDirection.Up} />
        <ColumnSorterCaret bold={columnSorterEntity.isBeingOrderedDesc()} direction={ColumnSorterCaretDirection.Down} />
        <ColumnOrderingPositionIndicator>
          {columnOrderingPosition && <ColumnOrderingPosition>{columnOrderingPosition}</ColumnOrderingPosition>}
        </ColumnOrderingPositionIndicator>
      </DoubleCaretHolder>
    </ColumnSorterWrapper>
  );
};
