import { createDeleteRequest, createGetRequest, createPostRequest } from "../api";
import { joinRouteParts } from "../utils";

const baseUrl = 'calendars';

function getPresetCalendars(params) {
  const url = 'calendar_presets'

  return createGetRequest({url, params})
}

const getCalendars = () => createGetRequest({url: baseUrl});

function getHolidayRules(params) {
  const url = 'holidayrules';

  return createGetRequest({url, params})
}

function retrievePresetCalendar(isoCode) {
  const url = joinRouteParts(baseUrl, isoCode, 'preset_detail');

  return createGetRequest({url})
}

const postCalendar = data => createPostRequest({url: baseUrl, data});

const deleteCalendar = calendarId => {
  const url = joinRouteParts(baseUrl, calendarId);

  return createDeleteRequest({url});
};

const postCalendarAndRules = (calendarId, data) => {
  const url = joinRouteParts('holidayrules', calendarId, 'calendar');

  return createPostRequest({url, data});
};

export {
  getPresetCalendars,
  getCalendars,
  getHolidayRules,

  retrievePresetCalendar,
  postCalendar,
  deleteCalendar,
  postCalendarAndRules
}
