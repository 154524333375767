import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth/selectors";
import { extractInitialDateFromQueryParams, formatDailyRoute } from "./timesheet-utility";
import TimesheetDailyActions from "./components/TimesheetDailyActions";
import { SoftLoading } from "../../../components/loaders/SoftLoading";
import { selectWeeklyTimesheetRows } from "../../../store/ducks/timesheet/selectors";
import DailyTimesheetSection from "./components/DailyTimesheetSection";
import { fetchDailyTimesheet } from "../../../store/ducks/mobile/timesheet/actions";
import { DefaultBlock } from "../../../components/layout/LayoutHelpers";
import { If } from "../../../components/ui-utility/Conditional";
import EmptyTimesheetPlaceholder from "../EmptyTimesheetPlaceholder";
import PageHeader from "../../../components/text/PageHeader";
import { Container } from "react-bootstrap";
import { RouteComponentProps } from "@reach/router";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {TIMESHEET_LOADING} from "../../../store/ducks/loading/loading-types";

type TimesheetDailyProps = RouteComponentProps;

export const TimesheetDaily: React.FunctionComponent<TimesheetDailyProps> = ({location, navigate}) => {
  const dispatch = useDispatch();
  const timesheetUser = useSelector(selectCurrentUser);
  const timesheetUserId = timesheetUser.id;

  const locationSearch = location?.search;

  const startDate = useMemo(() => extractInitialDateFromQueryParams(locationSearch), [locationSearch]);

  const visibleTasks = useSelector(selectWeeklyTimesheetRows);
  const hasVisibleTasks = visibleTasks.length > 0;

  const navigateToDate = (date) => {
    navigate && navigate(formatDailyRoute(date));
  };

  useEffect(() => {
    dispatch(fetchDailyTimesheet(timesheetUserId, startDate, startDate));
  }, [dispatch, timesheetUserId, startDate]);

  return (
    <Container fluid>
      <div className="row">
        <div className="col">
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.dailyPageHeader}}/>
          </PageHeader>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TimesheetDailyActions startDate={startDate} setStartDate={navigateToDate} userId={timesheetUser.id}/>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-8">
          <SoftLoading actionName={TIMESHEET_LOADING}>
            <If condition={hasVisibleTasks}>
              <DefaultBlock>
                <DailyTimesheetSection taskIds={visibleTasks} userId={timesheetUser.id} date={startDate}/>
              </DefaultBlock>
            </If>

            <If condition={!hasVisibleTasks}>
              <EmptyTimesheetPlaceholder/>
            </If>
          </SoftLoading>
        </div>
      </div>
    </Container>
  );
}