const rightSelectors = Object.freeze({
  selectRights: state => state.rights,
  selectUserRights: state => rightSelectors.selectRights(state).users,
  selectProjectRights: state => rightSelectors.selectRights(state).projects,
  selectGlobalRights: state => rightSelectors.selectRights(state).global,

  selectUserRightsById: userId => state => rightSelectors.selectUserRights(state)[userId],
  selectProjectRightsById: projectId => state => rightSelectors.selectProjectRights(state)[projectId],
});

export default rightSelectors;