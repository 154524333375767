import { createDeleteRequest, createGetRequest, createPostRequest, createPatchRequest } from "../api";
import { joinRouteParts } from "../utils";

const baseUrl = 'projects';

const getProjects = params => createGetRequest({url: baseUrl, params});

const fetchProject = projectId => {
  const apiUrl = joinRouteParts(baseUrl, projectId);

  return createGetRequest({url: apiUrl});
};

const patchProject = (projectId, data) => {
  const apiUrl = joinRouteParts(baseUrl, projectId);

  return createPatchRequest({url: apiUrl, data});
};

const deleteProject = projectId => {
  const apiUrl = joinRouteParts(baseUrl, projectId);

  return createDeleteRequest({url: apiUrl});
};

const addMultipleProject = data => {
  const apiUrl = joinRouteParts(baseUrl, 'create_multiple');

  return createPostRequest({url: apiUrl, data});
};

const fetchProjectTasksWithProgress = (projectId, page = 1) => {
  const url = joinRouteParts(baseUrl, projectId, 'tasks_with_progress');

  if (page === 0) {
    return createGetRequest({url, params: {page_size: 0}})
  }

  return createGetRequest({url, params: {page}});
};

const getProjectsLargestTaskMinutes = projectId => {
  const url = joinRouteParts(baseUrl, projectId, 'largest_task');
  return createGetRequest({url});
};

const getSingleProjectProgress = params => {
  const url = joinRouteParts(baseUrl, params.projectId, 'progress');
  return createGetRequest({url});
};

const postProjectMembership = (project, user) => {
  const url = 'project-memberships';
  return createPostRequest({url, data: {project, user}});
};

const patchProjectMembership = (memberId, data) => {
  const apiUrl = joinRouteParts('project-memberships', memberId);

  return createPatchRequest({url: apiUrl, data});
};


const deleteProjectMembership = (memberId) => {
  const url = joinRouteParts('project-memberships', memberId);

  return createDeleteRequest({url});
};

const getProjectMemberships = projectId => {
  const url = 'project-memberships/with_users';
  return createGetRequest({url, params: {'projectmembership.project': projectId, page_size: 0}});
};


export {
  getProjects,
  fetchProject,
  patchProject,
  deleteProject,

  fetchProjectTasksWithProgress,
  getProjectsLargestTaskMinutes,
  getSingleProjectProgress,
  addMultipleProject,

  getProjectMemberships,
  postProjectMembership,
  deleteProjectMembership,
  patchProjectMembership
};
