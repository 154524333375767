import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setApiError, setApiInfo,
  setApiPending,
  setApiSuccess, setApiUnAuthorized, setApiValidationError,
} from "../../store/ducks/api-notifier/api-notifier-actions";

import apiNotifierSelectors from "../../store/ducks/api-notifier/api-notifier-selectors";

import NotificationToast from "./NotificationToast";

import './Notification.css';

function Notification(props) {
  const dispatch = useDispatch();

  const handleErrorToastDismiss = useCallback(() => dispatch(setApiError(false)), [dispatch]);
  const handleSuccessToastDismiss = useCallback(() => dispatch(setApiSuccess(false)), [dispatch]);
  const handlePendingToastDismiss = useCallback(() => dispatch(setApiPending(false)), [dispatch]);
  const handleInfoDismiss = useCallback(() => dispatch(setApiInfo(false, "")), [dispatch]);
  const handleUnAuthorizedDismiss = useCallback(() => dispatch(setApiUnAuthorized(false)), [dispatch]);
  const handleValidationErrorDismiss = useCallback(() => dispatch(setApiValidationError(false)), [dispatch]);

  const pendingNotifications = useSelector(apiNotifierSelectors.selectPendingNotifications);
  const errorNotifications = useSelector(apiNotifierSelectors.selectErrorNotifications);
  const successNotifications = useSelector(apiNotifierSelectors.selectSuccessNotifications);
  const infoNotifications = useSelector(apiNotifierSelectors.selectInfoNotifications);
  const unAuthorizedNotifications = useSelector(apiNotifierSelectors.selectUnAuthorizedNotifications);
  const validationNotifications = useSelector(apiNotifierSelectors.selectValidationNotifications);

  return (
    <div className="notification-panel">
      <NotificationToast variant="pending" shouldDisplay={pendingNotifications.shown}
                         onDismiss={handlePendingToastDismiss}/>
      <NotificationToast variant="error" shouldDisplay={errorNotifications.shown}
                         onDismiss={handleErrorToastDismiss}/>
      <NotificationToast variant="success" shouldDisplay={successNotifications.shown} duration={1000}
                         onDismiss={handleSuccessToastDismiss}/>
      <NotificationToast variant="info" shouldDisplay={infoNotifications.shown}
                         onDismiss={handleInfoDismiss}>{infoNotifications.msg}</NotificationToast>
      <NotificationToast variant="unauthorized" shouldDisplay={unAuthorizedNotifications.shown}
                         onDismiss={handleUnAuthorizedDismiss}>{unAuthorizedNotifications.msg}</NotificationToast>
      <NotificationToast variant="validation-error" shouldDisplay={validationNotifications.shown}
                         onDismiss={handleValidationErrorDismiss}/>
    </div>
  );
}

export default Notification;