import {TRANSLATION_KEYS} from "../../../../intl/intl";

export const calendarTableColumns = [
  {
    key: 'name',
    label: {id: TRANSLATION_KEYS.calendarSettings.addCalendarModal.calendarNameFieldLabel},
  },
  {
    key: 'users',
    label: {id: TRANSLATION_KEYS.calendarSettings.calendarManagement.calendarTableHeaders.user},
  },
  {
    key: 'holidays',
    label: {id: TRANSLATION_KEYS.calendarSettings.calendarManagement.calendarTableHeaders.holiday},
  },
];
