import { createDeleteRequest, createGetRequest, createPatchRequest, createPostRequest } from "../../api";
import { joinRouteParts } from "../../utils";

const authGroupsUrl = 'authgroups';
const authGroupUsersUrl = 'authgroupusers';

const getAuthGroups = params => createGetRequest({url: authGroupsUrl, params});

const postAuthGroup = data => {
  return createPostRequest({url: authGroupsUrl, data});
};

const postAuthGroupUsers = data => {
  return createPostRequest({url: authGroupUsersUrl, data});
};

const patchAuthGroup = (authGroupId, data) => {
  const url = joinRouteParts(authGroupsUrl, authGroupId);

  return createPatchRequest({url, data});
};

const deleteAuthGroup = authGroupId => {
  const url = joinRouteParts(authGroupsUrl, authGroupId);

  return createDeleteRequest({url});
};

export {
  getAuthGroups,
  postAuthGroup,
  postAuthGroupUsers,
  deleteAuthGroup,
  patchAuthGroup
};