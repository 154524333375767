import { useDispatch } from "react-redux";

import { sendRequest, sendRequestSimple } from "../../api/api";
import {
  addPeopleAdvanced,
  patchUser,
  deleteUser,
  PeopleAPIErrorParser, getPeople, updateMeRequest
} from "../../api/people/people-api";
import { fetchUserDetailsSuccess, fetchUserEmploymentAction, removeUserAction } from "../../store/ducks/people/actions";

import GeneralApiResponseParser from "../../api/general-api-response-parser";
import { authenticate } from "../../api/authentication/authentication-api";
import { completeAuthentication } from "../../store/ducks/auth/actions";

// FIXME: TB-160
const useUserAPI = (): any => {
  const dispatch = useDispatch();

  const fetchUsers = async params => {
    return await sendRequestSimple(getPeople(params));
  };

  const updateUser = async (userId, payload) => {
    const {response, error = {}} = await sendRequestSimple(patchUser(userId, payload));

    if (error.data) {
      const formattedErrors = GeneralApiResponseParser.extractAllErrors(error.data);

      return {error: formattedErrors};
    }
    response && dispatch(fetchUserDetailsSuccess(response));

    if ('calendar' in payload) {
      dispatch(fetchUserEmploymentAction(userId));
    }

    return {response};
  };

  const updateMe = async(payload) => {
    const {response, error} = await sendRequestSimple(updateMeRequest(payload));

    response && dispatch(fetchUserDetailsSuccess(response));

    return {response, error};
  }

  const createAdvancedPeople = async payload => {
    const {response, error} = await sendRequest(addPeopleAdvanced(payload));

    if (error) {
      const errorParser = new PeopleAPIErrorParser(error.response);

      if (errorParser.basicFormHasGenericErrors()) {
        const genericErrors = errorParser.parseBasicFormGenericErrors();

        return {genericErrors};
      }
    }

    return {response};
  };

  const authenticateUser = async (email, password) => {
    const {response, error = {}} = await sendRequestSimple(authenticate(email, password));

    if(response) {
      if(localStorage.locale && response.locale !== localStorage.locale) {

        // We need to be authenticated into tenant in order to update user attributes.
        if(response.tenant_id)
          await updateMe({locale: localStorage.locale});

        dispatch(completeAuthentication({...response, locale: localStorage.locale}));
      } else {
        dispatch(completeAuthentication(response));
      }
    }

    return {response, error};
  };

  const removeUser = async userId => {
    const {error} = await sendRequestSimple(deleteUser(userId));

    !error && dispatch(removeUserAction(userId));

    return {error};
  };

  return {
    fetchUsers,
    updateUser,
    updateMe,
    removeUser,

    createAdvancedPeople,
    authenticateUser
  }
};

export default useUserAPI;