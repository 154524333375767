import * as types from './types';

export const completeAuthentication = userInfo => {
  return {
    type: types.AUTHENTICATION_SUCCESSFUL,
    payload: {...userInfo},
  }
};

export const logout = () => {
  return {
    type: types.LOGOUT_REQUESTED
  }
};

export const resetState = () => {
  return {
    type: types.RESET_STATE,
  }
};

export const changeTenantSuccess = userInfo => {
  return {
    type: types.CHANGE_TENANT_SUCCESS,
    payload: userInfo
  }
};


export const getCurrentUserRequested = () => {
  return {
    type: types.GET_CURRENT_USER_REQUESTED,
  }
};

export const getCurrentUserSuccessful = user => {
  return {
    type: types.GET_CURRENT_USER_SUCCESSFUL,
    payload: user,
  }
};

export const authenticateIntoTenantAction = (id, asDefault) => {
  return {
    type: types.AUTHENTICATE_INTO_TENANT,
    payload: {id, asDefault},
  }
};

export const changeTenantAction = (id) => {
  return {type: types.CHANGE_TENANT, payload: {id}};
};

export const updateNotificationCount = (notifications) => {
  return {type: types.UPDATE_NOTIFICATION_COUNT, payload: {notifications}};
};

export const setCheckoutRequired = checkoutRequired => {
  return {type: types.SET_CHECKOUT_REQUIRED, payload: {checkoutRequired}};
};
