import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const FlexContent = ({className, children, ...props}) => {
  return (
    <div className={classNames('w-100 flex-row', className)}>
      <div className="p-3">
        {children}
      </div>
    </div>
  )
};

const Content = styled(FlexContent)`
  margin: 0 auto;
`;

export default Content;
