import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {getBootstrapConfigurationsAction} from "../../store/ducks/bootstrap/bootstrap-actions";

export const useSystemSettings = () => {
  const dispatch = useDispatch();

  const fetchBootstrapConfiguration = useCallback(() => {
    dispatch(getBootstrapConfigurationsAction());
  }, [dispatch]);

  return {
    fetchBootstrapConfiguration
  }
}
