import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { PlainSectionBlock } from "../../components/layout/LayoutHelpers";
import { Table } from "./table-blocks";
import { useDispatch, useSelector } from "react-redux";
import { changeTenantStatusFilterAction, fetchTenantsAction } from "../../store/ducks/admin/tenants/tenant-actions";
import { selectTenants, selectTenantStatusFilter } from "../../store/ducks/admin/tenants/tenant-selectors";
import DateAgoISO from "../../components/ui-utility/dates/DateAgo";
import { TenantStatusIndicator } from "../../components/tenant-admin/TenantStatusIndicator";
import { TenantStatus } from "../../types/entities/Tenant";

type TenantListProps = RouteComponentProps;


const FirstCellStyled = styled.span`
  text-transform: uppercase;
  font-size: 0.9em;
  color: #2dbaae;
  font-weight: 700;
`;

const FilterForm = styled.form`
  padding: 10px 0;
`;

const RadioButton = styled.label`
  padding-right: 15px;
  cursor: pointer;
`;

const RadioButtonLabel = styled.span`
  padding-left: 3px;
`;

const StatusFilterInput = ({onChange, value, currentValue}) => {
  return (
    <>
      <RadioButton>
        <input type="radio"
               name="statusFilter"
               value={value}
               onChange={onChange}
               defaultChecked={value === currentValue} />
        <RadioButtonLabel>{value}</RadioButtonLabel>
      </RadioButton>
    </>
  )
}

export const TenantList: React.FC<TenantListProps> = () => {
  const dispatch = useDispatch();
  const tenantStatusFilter = useSelector(selectTenantStatusFilter);

  const tenants = useSelector(selectTenants)

  useEffect(() => {
    dispatch(fetchTenantsAction());
  }, [dispatch]);

  const handleStatusFilterChange = e => dispatch(changeTenantStatusFilterAction(e.target.value));
  const filteredTenants = tenants.filter(
    tenant => tenantStatusFilter !== TenantStatus.ALL ? tenant.status === tenantStatusFilter : true
  );

  return (
    <PlainSectionBlock>
      <FilterForm>
        <StatusFilterInput value={TenantStatus.TRIAL} onChange={handleStatusFilterChange} currentValue={tenantStatusFilter} />
        <StatusFilterInput value={TenantStatus.STRIPE} onChange={handleStatusFilterChange} currentValue={tenantStatusFilter} />
        <StatusFilterInput value={TenantStatus.BILL} onChange={handleStatusFilterChange} currentValue={tenantStatusFilter} />
        <StatusFilterInput value={TenantStatus.EXPIRED} onChange={handleStatusFilterChange} currentValue={tenantStatusFilter} />
        <StatusFilterInput value={TenantStatus.ALL} onChange={handleStatusFilterChange} currentValue={tenantStatusFilter} />
      </FilterForm>

      <Table cellPadding="15px">
        <thead>
        <tr>
          <th>TENANT</th>
          <th>CREATED BY</th>
          <th>STARTED</th>
          <th>STATUS</th>
        </tr>
        </thead>

        <tbody>
        {filteredTenants.map(tenant => (
          <TenantListRow tenant={tenant} key={tenant.id}/>
        ))}
        </tbody>

      </Table>
    </PlainSectionBlock>
  )
}

const TenantListRow = ({tenant}) => {
  return (
    <tr>
      <td>
        <FirstCellStyled>
          <Link to={`/admin/tenants/${tenant.id}`}>
            {tenant.name}
          </Link>
        </FirstCellStyled>
      </td>
      <td>{tenant.subscriber_email}</td>
      <td><DateAgoISO date={tenant.created_time}/></td>
      <td>
        <TenantStatusIndicator status={tenant.status}>
          {tenant.status}
        </TenantStatusIndicator>
      </td>
    </tr>
  )
}