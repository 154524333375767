import split from 'lodash/split';
import map from 'lodash/map';
import filter from 'lodash/filter';
import identity from 'lodash/identity';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { minTwoDigits } from './time-utils';

export class Time {
  // FIXME: TB-160
  minutes: any;
  constructor(minutes) {
    if (isNaN(minutes)) {
      throw new Error(
        `Please provide the time value always in minutes (${minutes})`,
      );
    }

    if (minutes instanceof Time) {
      this.minutes = minutes.minutes;
    } else {
      this.minutes = minutes;
    }
  }

  hourValue = (zeroAsEmpty = false) => {
    const func = this.minutes >= 0 ? Math.floor : Math.ceil;

    return func(this.minutes / 60) || (zeroAsEmpty ? '' : 0);
  };

  minuteValue = (zeroAsEmpty = false) => this.minutes % 60 || (zeroAsEmpty ? '' : 0);

  toColonSeparated(showZero = false) {
    // FIXME: TB-160 - as number assumes it is always a number, which it might not be - Fix the hourValue and minuteValue methods
    const hours = Math.abs(this.hourValue() as number);
    let minutes = Math.abs(this.minuteValue() as number);
    const positiveOrNegativePrefix = this.minutes < 0 ? "-" : "";

    if (!showZero && (this.minutes === undefined || this.minutes === 0)) {
      return '';
    }

    return `${positiveOrNegativePrefix}${hours}:${minTwoDigits(minutes)}`;
  }

  setHours(hours) {
    const minuteValue = this.minuteValue();
    return Time.FromHoursAndMinutes(Math.max(0, Number(hours)), minuteValue);
  }

  setMinutes(_minutes) {
    const hourValue = this.hourValue();
    return Time.FromHoursAndMinutes(hourValue, Math.max(0, Number(_minutes)));
  }

  addMinutes(minutes) {
    return Time.FromMinutes(Math.max(0, this.minutes + minutes));
  }

  static FromMinutes(minutes) {
    return new Time(minutes);
  }

  static FromHoursAndMinutes(hourValue, minuteValue) {
    // TODO: This can't be used for creating a negative Time with less than 60 minutes of time... fix?
    const hourMinutes = Number(hourValue) * 60;
    const minuteMinutes = Number(hourValue) < 0 ? Number(minuteValue) * -1 : Number(minuteValue);
    const minutes = hourMinutes + minuteMinutes;

    return new Time(minutes);
  }

  static FromColonSeparated(hoursColonMinutes) {
    const isNegative = hoursColonMinutes.startsWith('-');
    const [hourPart, minutesPart] = split(hoursColonMinutes, ':');

    if (minutesPart.startsWith('-')) {
      throw Error("Minute part of time is not valid.");
    }

    if (minutesPart.length !== 2) {
      throw Error("Please provide minutes with two digits.");
    }

    const absMinutes = Math.abs(Math.abs(Number(hourPart)) * 60 + Math.abs(Number(minutesPart)));
    const minutes = isNegative ? -1 * absMinutes : absMinutes;

    return new Time(minutes);
  }

  static FromDecimalNumber(valueWithCommaOrDot) {
    const value = valueWithCommaOrDot.replace(',', '.');
    const valueInMinutes = toNumber(value) * 60;
    const minutes = Math.round(valueInMinutes);
    return new Time(minutes);
  }

  // FIXME: TB-160
  static FromUserInput(value): any {
    const pipe = [Time.FromColonSeparated, Time.FromDecimalNumber];

    const parsedValues = map(pipe, parser => {
      try {
        return parser(value);
      } catch (e) {
        return null;
      }
    });

    const validInput = filter(parsedValues, identity);

    if (validInput.length > 0) {
      return validInput[0];
    }

    return null;
  }
}
