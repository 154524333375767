import React, { useState } from "react";

import AddProjectsMultiple from './AddProjectsMultiple';
import { DialogOverlay } from "../../ui-utility/Tooltip";
import { PlusIcon } from "../../buttons";
import { WithClassName } from "../../../types/WithClassName";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export type AddProjectsProps = WithClassName;

const AddProjects: React.FunctionComponent<AddProjectsProps> = ({className}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className={className}>
      <DialogOverlay isShown={isShown} setIsShown={setIsShown}>
        <DialogOverlay.Trigger variant="primary">
          <PlusIcon />&nbsp;
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.addProjectsButton}} />
      </DialogOverlay.Trigger>
        <DialogOverlay.Dialog>
          <AddProjectsMultiple onDone={() => setIsShown(false)} />
        </DialogOverlay.Dialog>
      </DialogOverlay>
    </div>
  );
}

export default AddProjects;
