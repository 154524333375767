class ErrorResponse {
  // FIXME: TB-160
  errors: any;
  constructor(errors = {}) {
    this.errors = errors;
  }

  hasFieldError = (fieldKey) => (this.errors[fieldKey]);

  getErrorsForField = (fieldKey) => {
    if (this.hasFieldError(fieldKey)) {
      const fieldErrors = this.errors[fieldKey];

      return this.extractErrors(fieldErrors);
    }
    return null;
  };

  extractErrors = (errors) => {
    if (Array.isArray(errors))
      return errors.join("\r\n");
    return errors;
  };

  extractAllErrors = (fieldKeys) => {
    return fieldKeys.reduce((accumulated, fieldKey) => {
      accumulated[fieldKey] = this.getErrorsForField(fieldKey);

      return accumulated;
    }, {})
  }
}

export default ErrorResponse;