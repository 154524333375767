import React, { useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { SelectFilterField } from '../entities/SelectFilter';
import { changeFilterValueAction, fetchFilterValuesAction } from "../../../store/ducks/filter/filter-actions";

import BaseSelect from "../../select/BaseSelect";

import filterSelectors from "../../../store/ducks/filter/filter-selectors";

function SelectFilter({filter}) {
  const dispatch = useDispatch();

  const filterKey = filter.getFilterKey();

  const filterValue = useSelector(filterSelectors.selectFilterForField(filterKey)) || "";
  const dropdownOptions = useSelector(filterSelectors.selectDropdownOptions(filter.getEntityKey()));

  const handleChange = selectedOptions => {
    dispatch(changeFilterValueAction(filterKey, selectedOptions));
  };

  useEffect(() => {
    const fetchEntities = () => {
      dispatch(fetchFilterValuesAction(filter));
    };

    fetchEntities();
  }, [dispatch, filter]);

  return <BaseSelect handleChange={handleChange} value={filterValue} dropdownOptions={dropdownOptions}/>;
}

SelectFilter.propTypes = {
  filter: PropTypes.instanceOf(SelectFilterField).isRequired
};

export {
  SelectFilter as default,
};