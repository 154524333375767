import FilterFactory from "../../entities/FilterFactory";
import FilterBlockEntity from "../../entities/FilterBlockEntity";
import FilterRegistry from "../filter-registry";

export class CustomerPageFilterBlock extends FilterBlockEntity {
  static blockKey = 'customerPage';

  createFilters() {
    return [
      FilterFactory.createInputFilter('customer.q'),
    ];
  };
}

export interface CustomerQueryParameters {
  "customer.q": string
}

FilterRegistry.registerFilterBlock(CustomerPageFilterBlock.blockKey, new CustomerPageFilterBlock());
