import React from "react";

import PasswordChange from "./components/PasswordChange";
import BillingPortal from "./components/BillingPortal";

import {RouteComponentProps} from "@reach/router";
import {Row, Col} from "react-bootstrap";

import './Profile.css';
import {TRANSLATION_KEYS} from "../../intl/intl";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import PageHeader from "../../components/text/PageHeader";

type ProfileProps = RouteComponentProps;

export const Profile: React.FunctionComponent<ProfileProps> = () => {

  return (
    <div className="container">
      <Row>
        <Col>
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.pageHeader}}/>
          </PageHeader>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <div className="blockquote-section">
            <PasswordChange/>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="blockquote-section">
            <BillingPortal/>
          </div>
        </Col>
      </Row>
    </div>
  );
}