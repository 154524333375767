import React from "react";

import {ApprovedCycleBadge, PendingCycleBadge} from "../../approve/components/StepStatusBadge";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";

function CycleStatusBadge({requiresAttention}) {

  if (requiresAttention) {
    return (
      <PendingCycleBadge>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.table.actionRequiredLabel}}/>
      </PendingCycleBadge>
    )
  }

  return (
    <ApprovedCycleBadge>
      <LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.approved}}/>
    </ApprovedCycleBadge>
  );
}


export default CycleStatusBadge;
