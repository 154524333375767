import apiNotifierTypes from "./api-notifier-types";
import { makeToasterState } from "./api-notifier-reducers";

const setApiError = isShown => ({type: apiNotifierTypes.API_ERROR, payload: makeToasterState(isShown)});
const setApiSuccess = isShown => ({type: apiNotifierTypes.API_SUCCESS, payload: makeToasterState(isShown)});
const setApiPending = isShown => ({type: apiNotifierTypes.API_PENDING, payload: makeToasterState(isShown)});
const setApiInfo = (isShown, msg) => ({type: apiNotifierTypes.API_INFO, payload: makeToasterState(isShown,msg)});
const setApiUnAuthorized = isShown => ({type: apiNotifierTypes.API_UNAUTHORIZED, payload: makeToasterState(isShown)});
const setApiValidationError = isShown => ({type: apiNotifierTypes.API_VALIDATION_ERROR, payload: makeToasterState(isShown)});

export {
  setApiError,
  setApiSuccess,
  setApiPending,
  setApiInfo,
  setApiUnAuthorized,
  setApiValidationError
}