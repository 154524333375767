import React from 'react';
import PropTypes from 'prop-types';

import {If} from "../ui-utility/Conditional";
import {ActuallyButton, IndentIcon} from "../buttons";

import filterSelectors from "../../store/ducks/filter/filter-selectors";
import FilterBlockEntity from "./entities/FilterBlockEntity";


import '../column/ActiveColumnsIndicator.css';
import useShallowEqualSelector from "../../store/helpers";
import styled from 'styled-components';

const ActiveFiltersIndicatorWrapper = styled.div`
  position: relative;
`

const Dot = styled.span`
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
`

export const ChangesIndicator = styled(Dot)`
    position: absolute;
    top: 0;
    background-color: #007bff!important;
    font-size: 0.5em;
    color: white;
`

function ActiveFiltersIndicator({onClick, filterBlock}) {
  const filters = useShallowEqualSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock));

  return (
    <ActiveFiltersIndicatorWrapper>
      <ActuallyButton variant="transparent" onClick={onClick}>
        <span>Filters</span>
        <span className="ml-2"><IndentIcon/></span>
        <If condition={filters.length}>
          <ChangesIndicator/>
        </If>
      </ActuallyButton>
    </ActiveFiltersIndicatorWrapper>
  );
}

ActiveFiltersIndicator.propTypes = {
  onClick: PropTypes.func,
  filterBlock: PropTypes.instanceOf(FilterBlockEntity)
};


export default ActiveFiltersIndicator;