import React from "react";

import {useModal} from "../../modal/modal-hooks";

import SimpleModal from "../../modal/SimpleModal";

import {ActuallyButton, PenIcon} from "../../buttons";
import modalDefinitions from "../../modal/modal-definitions";
import EditPerson from "./EditPerson";
import ModalFrame from "../../modal/ModalFrame";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";


function EditPersonButton({userId}) {
  const [openModal, closeModal] = useModal(modalDefinitions.EDIT_PERSON);

  return (
    <>
      <ActuallyButton variant="slick" onClick={openModal} className="btn-sm">
        <PenIcon light={true}/>
      </ActuallyButton>
      <SimpleModal name={modalDefinitions.EDIT_PERSON}>
        <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.modalHeader}} />}>
          <EditPerson userId={userId} onEditingDone={closeModal}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default EditPersonButton;
