import React from "react";
import TimeValue from "../../components/TimeValue";
import DailyProgressBar from "../mobile/timesheet/DailyProgressBar";
import { TooltipOverlay } from "../../components/ui-utility/Tooltip";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";

const ProjectProgressView = ({actualMinutes, budgetedMinutes}) => {
  return (
    <>
      <h2 className="mb-2"><LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.progress}} /></h2>

      {/* TODO: Make a wrapper component... */}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <strong>
            <TooltipOverlay tooltipContent={<LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.progressTotalActualsTooltipMessage}} />}>
              <TimeValue minutes={actualMinutes} showZero={true}/>
            </TooltipOverlay>

            &nbsp; / &nbsp;

            <TooltipOverlay tooltipContent={<LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.progressTotalBudgetedTooltipMessage}} />}>
              <TimeValue minutes={budgetedMinutes} showZero={true}/>
            </TooltipOverlay>
          </strong>
        </div>

        <div className="flex-grow-1 pl-3 pr-3">
          <DailyProgressBar minutes={actualMinutes} hideTime={true} capacityMinutes={budgetedMinutes}/>
        </div>
      </div>
    </>
  )
};

export default ProjectProgressView;
