import { AnyAction } from "redux";

/* Type declarations */
const overallTypes = {
  INITIATE_RECORD_FETCH: 'INITIATE_RECORD_FETCH',
  RECEIVE_RECORDS: 'RECEIVE_RECORDS',
  PATCH_RECORD: 'PATCH_RECORD',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SET_ERROR: 'SET_ERROR'
};

/* Action declarations */
const receiveRecordsAction = (response) => ({type: overallTypes.RECEIVE_RECORDS, response});
const patchRecordAction = (recordId, patched) => ({type: overallTypes.PATCH_RECORD, payload: {recordId, patched}});
const clearErrorAction = (recordId, field) => ({type: overallTypes.CLEAR_ERROR, payload: {recordId, field}});
const setErrorAction = (recordId, errors) => ({type: overallTypes.SET_ERROR, payload: {recordId, errors}});


/* Reducer declarations */
const initialState = {
  pagination: {},
  records: {},
  result: [],
  errors: {},
  loading: true
};

function patchById(error = {}, patchedAttributes) {
  return {...error, ...patchedAttributes};
}

function handleRecordEntityPatching(state, payload) {
  const {recordId, patched} = payload;

  const records = selectRawRecords(state);
  const record = selectRecordById(state, recordId);

  return {...records, [recordId]: patchById(record, patched)};
}

// This could be general.
function errorReducer(state = {}, action) {
  switch (action.type) {
    case overallTypes.CLEAR_ERROR: {
      const {recordId, field} = action.payload;

      return {
        ...state,
        [recordId]: patchById(state[recordId], {[field]: null})
      };
    }
    case overallTypes.SET_ERROR: {
      const {recordId, errors} = action.payload;

      return {
        ...state,
        [recordId]: patchById(state[recordId], errors)
      };
    }
    default:
      return state;
  }
}

// FIXME: TB-160
function overallReducer(state = initialState, action: AnyAction) {
  switch (action.type) {

    case overallTypes.RECEIVE_RECORDS:
      return {...action.response, loading: false};
    case overallTypes.PATCH_RECORD:
      const patchedEntities = handleRecordEntityPatching(state, action.payload);
      return {...state, records: {...patchedEntities}};
    case overallTypes.CLEAR_ERROR:
      return {...state, errors: errorReducer(state.errors, action)};
    case overallTypes.SET_ERROR:
      return {...state, errors: errorReducer(state.errors, action)};
    default:
      return state;
  }
}

/* Selector declarations */
const selectIds = state => state.result || [];
const selectRawRecords = state => state.records || {};
const selectRecordById = (state, recordId) => selectRawRecords(state)[recordId] || {};

const selectRecords = state => {
  const [ids, entities] = [selectIds(state), selectRawRecords(state)];

  return ids.map(recordId => entities[recordId]);
};

const selectErrors = state => state.errors || {};
const selectPagination = state => state.pagination || {};

export {
  overallTypes,

  receiveRecordsAction,
  patchRecordAction,
  clearErrorAction,
  setErrorAction,

  initialState,
  overallReducer,

  selectRecords,
  selectErrors,
  selectPagination
};