import * as React from 'react';
import {useSelector} from "react-redux";
import {ControlWrapper, ControlWrapperControl} from "../../components/layout/ControlWrapper";
import {DefaultBlock} from "../../components/layout/LayoutHelpers";
import {ActuallyTable} from "../../components/table/ActuallyTable";
import {createCustomerTableData, CustomerTableColumns} from "./customers-table-configuration";
import {AddCustomers} from "./add-customers/AddCustomers";

import RequireGlobalRight from "../../components/rights/RequireGlobalRight";
import ActiveFilterBlock from "../../components/filter/ActiveFilterBlock";

import RIGHTS from "../../components/rights/right-definitions";
import filterSelectors from "../../store/ducks/filter/filter-selectors";
import {TRANSLATION_KEYS} from "../../intl/intl";

import FilterRegistry from "../../components/filter/configuration/filter-registry";
import {CustomerPageFilterBlock} from "../../components/filter/configuration/filter-blocks-for-page/CustomerPageFilterBlock";
import {Customer} from "../../api/customers/CustomerEntity";
import {PaginationData} from "../../components/pagination/Pagination";

interface CustomerListingProps {
  customers: Array<Customer>,
  onPageChange: (page: number) => void;
  onOrderingChange: (newOrdering: Array<string>) => void;
  ordering: Array<string>,
  pagination: PaginationData
}

export const CustomerListing: React.FC<CustomerListingProps> = ({customers, onOrderingChange, onPageChange, ordering, pagination}) => {
  const filterBlock = FilterRegistry.getFilterBlockForName(CustomerPageFilterBlock.blockKey);
  const activeFilters = useSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock))

  const customerListingControls: ControlWrapperControl[] = [
    {
      name: 'filtering',
      label: {id: TRANSLATION_KEYS.search.filtersButton},
      component: <ActiveFilterBlock filterBlock={filterBlock}/>,
      isActive: !!activeFilters.length
    },
  ]

  const actions = [
    <RequireGlobalRight rightName={RIGHTS.manageCustomer}>
      <AddCustomers/>
    </RequireGlobalRight>
  ]

  return (
    <ControlWrapper
      controls={customerListingControls}
      actions={actions}>
      <DefaultBlock withBackground={true}>
        <ActuallyTable
          tableColumns={CustomerTableColumns}
          tableData={createCustomerTableData(customers)}
          ordering={ordering}
          pagination={pagination}
          onPageChange={onPageChange}
          onOrderingChange={onOrderingChange}/>
      </DefaultBlock>
    </ControlWrapper>
  )
}



