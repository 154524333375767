import React from "react";
import {Time} from "../../util/Time";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";

function previousMonthProgressText(styledHours: JSX.Element) {
  return <LocalizedMessage text={{
    id: TRANSLATION_KEYS.projects.projectDashboard.progressPreviousMonthHoursMessage,
    values: {
      hours: styledHours
    }
  }}/>;
}

const ProjectPreviousMonthActuals = ({minutes}) => {
  const hours = Time.FromMinutes(minutes).toColonSeparated(true);
  const styledHours = <strong>{hours}</strong>;

  return (
    <>
      {previousMonthProgressText(styledHours)}
    </>
  );
};

export default ProjectPreviousMonthActuals;
