import React from "react";
import {formatWithCurrentLocale} from "./format-with-locale";

const DateShort = ({date, ...props}) => {
  const formatted = formatWithCurrentLocale(date, 'P');

  return (
    <span {...props}>{formatted}</span>
  )
};

export default DateShort;
