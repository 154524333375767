import React from 'react';
import { Link } from "@reach/router";

import './AppLogo.css';

const AppLogo = () => {
  return <Link to="/" className="logo logo-text" tabIndex={-1}>Actually</Link>;
};

const AppLogoLetter = () => {
  return <span className="logo logo-letter">A</span>;
};

export {
  AppLogo,
  AppLogoLetter,
}
