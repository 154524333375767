import React from "react";
import { useDispatch } from "react-redux";

import PropTypes from 'prop-types';

import Datepicker from "../../datepicker/Datepicker";
import {DateFilterField} from '../entities/DateFilter';
import filterSelectors from "../../../store/ducks/filter/filter-selectors";
import { changeFilterValueAction } from "../../../store/ducks/filter/filter-actions";
import useShallowEqualSelector from "../../../store/helpers";

function DateFilter(props) {
  const dispatch = useDispatch();

  const {filter} = props;
  const filterKey = filter.getFilterKey();

  const selectedDay = useShallowEqualSelector(filterSelectors.selectFilterForField(filterKey)) || "";

  const onDateChange = (newDate) => {
    dispatch(changeFilterValueAction(filterKey, newDate));
  };

  return (
    <div className="datepicker-wrapper">
      <Datepicker onDateChange={onDateChange} selected={selectedDay}/>
    </div>
  )
}

DateFilter.propTypes = {
  filter: PropTypes.instanceOf(DateFilterField).isRequired,
};

export default DateFilter;