class PaginationController {
  current: number;
  totalPages: number;
  constructor({current, totalPages}) {
    this.current = current;
    this.totalPages = totalPages;
  }

  changePageTo = (page) => {
    return (this.current = page);
  };

  goToNextPage = () => {
    if (this.current < this.totalPages)
      return this.changePageTo(this.current + 1);
    return this.current;
  };

  goToPreviousPage = () => {
    if (this.current > 1)
      return this.changePageTo(this.current - 1);
    return this.current;
  };

  goToFirstPage = () => {
    return this.changePageTo(1);
  };

  goToLastPage = () => {
    return this.changePageTo(this.totalPages);
  };

}

export default PaginationController;