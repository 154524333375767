import React from "react";

import { useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { ActuallyButton } from "../../buttons";

import peopleSelectors from "../../../store/ducks/people/selectors";
import modalDefinitions from "../../modal/modal-definitions";

import { useModal } from "../../modal/modal-hooks";

import DeletePersonConfirmation from "./DeletePersonConfirmation";
import SimpleModal from "../../modal/SimpleModal";
import ModalFrame from "../../modal/ModalFrame";
import useUserAPI from "../user-hooks";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";


function DeletePersonButton({userId, onDeleted}) {
  const {removeUser} = useUserAPI();

  const [openModal, closeModal] = useModal(modalDefinitions.DELETE_USER);

  const user = useSelector(peopleSelectors.selectUser(userId));

  const handleUserDelete = async () => {
    const {error} = await removeUser(userId);

    if (!error) {
      closeModal();
      onDeleted();

      navigate('/people');
    }
  };

  return (
    <>
      <ActuallyButton variant="delete-pre" onClick={openModal}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.deleteUserButton}}/>
      </ActuallyButton>
      <SimpleModal name={modalDefinitions.DELETE_USER} useBackDrop={true} centered={true}>
        <ModalFrame heading={<LocalizedMessage text={{ id: TRANSLATION_KEYS.commonActions.confirmDeleteButton }} />}>
          <DeletePersonConfirmation user={user} onBail={closeModal} onConfirm={handleUserDelete}/>
        </ModalFrame>
      </SimpleModal>
    </>
  );
}

export default DeletePersonButton;
