import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { If } from "../../../components/ui-utility/Conditional";
import { CrownIcon } from "../../../components/buttons";

import PersonFullName from "../../../components/people/PersonFullName";
import peopleSelectors from "../../../store/ducks/people/selectors";
import { membershipSelectors } from "./project-members-reducer";
import { TooltipOverlay } from "../../../components/ui-utility/Tooltip";

export const AvatarPlusXMore = ({collection, renderFn, limit = 10}) => {
  const [spliceCollection, setSpliceCollection] = useState({items: [], overlapCount: 0});

  useEffect(() => {
    if (collection.length > limit) {
      setSpliceCollection({items: collection.slice(0, limit), overlapCount: collection.length - limit});
    } else {
      setSpliceCollection({items: collection.slice(), overlapCount: 0});
    }
  }, [collection, limit]);

  return (
    <span>
      {spliceCollection.items.map((item, index) => renderFn(item, index))}
      <If condition={spliceCollection.overlapCount > 0}>
        <div className="avatar">
          +{spliceCollection.overlapCount}
        </div>
      </If>
    </span>
  )
};


const ProjectMembershipAvatarSelector = ({userId}) => {
  const user = useSelector(peopleSelectors.selectUser(userId)) || {};

  return <MemberAvatar user={user}/>;
};

const MemberAvatar = ({user, ...props}) => {
  const memberity = useSelector(membershipSelectors.selectUserMemberId(user.id)) || {};

  return (
    <div className="avatar">
      <If condition={memberity.is_projectlead}>
        <span className="projectlead-indicator"><CrownIcon/></span>
      </If>
      <TooltipOverlay tooltipContent={<PersonFullName id={user.id} first_name={user.first_name} last_name={user.last_name}/>}>
        <div {...props}>{user.first_name[0]}{user.last_name[0]}</div>
      </TooltipOverlay>
    </div>
  );
};

export default ProjectMembershipAvatarSelector;
