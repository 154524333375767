import createOverallSchema from "./report-schema";
import GeneralApiResponseParser from "../general-api-response-parser";

import {groupBy} from 'lodash';
import {normalize} from "normalizr";
import {sendRequest} from "../api";

import {patchOverallRecord} from "./report-api";

import {Person} from "../../screens/people/PersonEntity";
import {IndexedObject} from "../../types/generics";
import {Project} from "../../components/project/ProjectEntity";
import {Customer} from "../customers/CustomerEntity";
import {Task} from "../../components/task/TaskEntity";
import {Record} from "./RecordEntity";
import {PaginationData} from "../../components/pagination/Pagination";

export interface RecordsData {
  records?: Array<Record>,
  users?: IndexedObject<Person>,
  tasks?: IndexedObject<Task>,
  projects?: IndexedObject<Project>,
  customers?: IndexedObject<Customer>
}

export const emptyRecordsData = {
  records: [],
  users: {},
  tasks: {},
  projects: {},
  customers: {}
}

export interface PaginatedRecordsApiResponse<T> extends PaginationData {
  data: Array<T>
}

// Response contains the direct data or the paginated one, depending on the sent page_size parameter
export type RecordsApiResponse = PaginatedRecordsApiResponse<RecordsData> | RecordsData | undefined;

const processGetOverallRecordListing = response => {
  const recordsIntact = ReportAPIResponseParser.mergeEntitiesIntoRecords(response.data);
  const records = ReportAPIResponseParser.normalizeOverallRecords(recordsIntact);
  const pagination = GeneralApiResponseParser.extractPaginationData(response);

  return {pagination, ...records};
};

const processGetOverallRecordGrouping = response => {
  const records = ReportAPIResponseParser.mergeEntitiesIntoRecords(response.data);
  const pagination = GeneralApiResponseParser.extractPaginationData(response);

  return {pagination, records, loading: false};
};

const patchExistingRecord = async (entityId, changed) => {
  return await sendRequest(patchOverallRecord(entityId, changed));
};


export class ReportAPIResponseParser {

  static mergeEntitiesIntoRecords = (response) => {
    const {projects, tasks, users, records, customers} = response;

    return records.map((recordRow) => {
      return {
        ...recordRow,
        task: tasks[recordRow.task],
        project: projects[recordRow.project],
        customer: customers[recordRow.customer],
        user: users[recordRow.user]
      }
    });
  };

  static extractProjectRecords = (response): { records: IndexedObject<Array<Record>>, projects: Array<Project> } => {
    const {projects = {}, records = []} = response;

    const listOfProjects: Array<Project> = Object.values(projects);
    const recordsGroupedByProjects: any = groupBy(records, 'project');

    return {
      records: recordsGroupedByProjects,
      projects: listOfProjects
    }
  };

  static extractUserRecords = (response): { records: IndexedObject<Array<Record>>, users: Array<Person> } => {
    const {users = {}, records = []} = response;

    const listOfUsers: Array<Person> = Object.values(users);
    const recordsGroupedByProjects: any = groupBy(records, 'user');

    return {
      records: recordsGroupedByProjects,
      users: listOfUsers
    }
  };

  static reduceToSingleKeyValueMap = (response, key) => {
    const {records = []} = response;

    return records.reduce((recordMap, totalRecords) => ({[totalRecords[key]]: totalRecords.minutes_sum, ...recordMap}), {});
  }

  static normalizeOverallRecords = (intactRecords) => {
    const schema = createOverallSchema();
    const normalized = normalize(intactRecords, schema);

    return {result: normalized.result, ...normalized.entities};
  };

}

export {
  processGetOverallRecordListing,
  processGetOverallRecordGrouping,
  patchExistingRecord
}