import React from "react";
import { mergeStyles } from '../../../api/utils';

import { useOnBlurTrigger } from "../../../util/hooks";

export enum BlurInputVariant {
  Input = 'input',
  Textarea = 'textarea'
}

export enum BlurInputInputType {
  Text = 'text',
  Number = 'number'
}


interface BlurInputProps {
  value?: string | number;
  onSave: (value: string) => void;
  inputType?: BlurInputInputType;
  variant?: BlurInputVariant;
  className?: string;
  disabled: boolean;
  autoFocus: boolean;
}

export const BlurInput: React.FunctionComponent<BlurInputProps> = ({value = '', onSave, inputType = BlurInputInputType.Text, className='', disabled, variant = BlurInputVariant.Input, autoFocus}) => {
  const {onBlur, onFocus, onChange, internalValue} = useOnBlurTrigger(value, onSave);

  const styles = mergeStyles("form-control", className);

  const Component = variant;

  return (
    <Component type={inputType}
               className={styles}
               value={internalValue}
               disabled={disabled}
               onFocus={onFocus}
               autoFocus={autoFocus}
               onBlur={onBlur}
               onChange={onChange}/>
  )
}
