import React from "react";
import PropTypes from 'prop-types';

import ProjectName from "./ProjectName";
import TaskName from "../task/TaskName";

import { RightAngleLightIcon } from "../icons";


function ProjectAndTaskName({project, task, link}) {
  return (
    <span>
      <ProjectName name={project.name} addLink={link} id={project.id} className="bold"/>
      <span className="pl-2 pr-2">
          <RightAngleLightIcon/>
        </span>
      <TaskName name={task.name}/>
    </span>
  );
}

ProjectAndTaskName.propTypes = {
  project: PropTypes.object,
  task: PropTypes.object,
};

ProjectAndTaskName.defaultProps = {
  link: true
}

export default ProjectAndTaskName;

