import React from 'react';

import { useSelector } from "react-redux";
import { BlockButton, LockIcon } from "../../../components/buttons";

import { TooltipOverlay } from "../../../components/ui-utility/Tooltip";
import { selectTaskForId, selectTasksForProjectId } from "../../../store/ducks/timesheet/selectors";
import { If } from "../../../components/ui-utility/Conditional";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RecordEntity from "../../../api/report/RecordEntity";


import MobilePageHeading from "../../../components/header/MobilePageHeading";
import TaskName from "../../../components/task/TaskName";

export function TimetrackingNotAllowed() {
  return (
    <TooltipOverlay tooltipContent={"Can't be edited"}>
      <LockIcon className="mr-2"/>
    </TooltipOverlay>
  )
}

function SelectTaskRow({taskId, onTaskSelect}) {
  const task = useSelector(selectTaskForId(taskId));

  const timeTrackingAllowedByTask = RecordEntity.timeTrackingAllowedByTask(task);

  const handleOnClick = () => {
    timeTrackingAllowedByTask && onTaskSelect(task.id);
  };

  return (
    <BlockButton onClick={handleOnClick}>
      <If condition={!timeTrackingAllowedByTask}>
        <TimetrackingNotAllowed/>
      </If>
      <TaskName {...task}/>
    </BlockButton>
  )
}

function RecordFlowSelectTask({projectId, onTaskSelect}) {
  const taskIds = useSelector(selectTasksForProjectId(projectId)) || [];

  return (
    <Container fluid>
      <MobilePageHeading showBackBtn={true}>Add a time entry</MobilePageHeading>
      <Row>
        <Col>
          <p>Then select a task</p>
        </Col>
      </Row>
      {taskIds.map(taskId => (
        <Row key={taskId} className="mb-2">
          <Col>
            <SelectTaskRow taskId={taskId} onTaskSelect={onTaskSelect}/>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default RecordFlowSelectTask;