import { normalize, schema } from "normalizr";
import { chain } from 'lodash';

class AuthRightAPIResponseParser {

  // Store it as normalized since it is easier to operate
  static normalizeListing = response => {
    const authRightSchema = new schema.Entity('authRights');
    const grantedRightSchema = new schema.Entity('grantedRights');
    const authGroupSchema = new schema.Entity('authGroups');
    const authRolesSchema = new schema.Entity('authRoles');

    const mainSchema = {
      auth_rights: new schema.Array(authRightSchema),
      granted_rights: new schema.Array(grantedRightSchema),
      auth_groups: new schema.Array(authGroupSchema),
      auth_roles: new schema.Array(authRolesSchema)
    };

    return normalize(response, mainSchema);
  };

  // Denormalize state back for ui
  static deNormalizeListing = normalized => {
    const denormalizedGrantedRights = normalized.result.granted_rights.map(grantedId => normalized.entities.grantedRights[grantedId]);
    const grantedRightsByAuthRights = chain(denormalizedGrantedRights).groupBy('right').value();

    const {entities, result} = normalized;

    return result.auth_rights.map(authRightId => {
      const authRightInstance = entities.authRights[authRightId];
      const grantedRightsByAuthRight = grantedRightsByAuthRights[authRightId] || [];

      const grantedRightsDeNormalized = grantedRightsByAuthRight.map(grantedRight => {
        const authRoleInstance = entities.authRoles[grantedRight.role];
        const authGroupInstance = (entities.authGroups || {})[grantedRight.group];

        return {
          id: grantedRight.id,
          role: authRoleInstance,
          group: authGroupInstance
        }
      });

      return {...authRightInstance, grantedRights: grantedRightsDeNormalized};
    });
  };
}

export default AuthRightAPIResponseParser;
