import {createPatchRequest, createGetRequest, createPostRequest, createDeleteRequest} from "../api";
import {joinRouteParts} from "../utils";

const baseUrl = 'customers';

export const getCustomers = params => createGetRequest({url: baseUrl, params});

export const getCustomerDetails = params => {
  const url = joinRouteParts(baseUrl, 'details');

  return createGetRequest({url, params});
}

export const saveCustomer = data => createPostRequest({url: baseUrl, data});

export const saveCustomersAdvanced = customersText => {
  const url = joinRouteParts(baseUrl, 'advanced');

  return createPostRequest({url, data: {customers_text: customersText}});
}

export const patchCustomer = (customerId, data) => {
  const url = joinRouteParts(baseUrl, customerId);

  return createPatchRequest({url, data});
}

export const deleteCustomer = (customerId) => {
  const url = joinRouteParts(baseUrl, customerId);

  return createDeleteRequest({url});
}
