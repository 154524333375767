import React from "react";

import './Error.css';
import './Notification.css';
import { LocalizedMessage } from "../../intl/components/LocalizedMessage";
import { TRANSLATION_KEYS } from "../../intl/intl";

function ErrorElement({ children }) {
  return (
    <div className="alert alert-danger-line fade show" role="alert">
      {children}
    </div>
  )
}

function Error({ error }) {

  const makeErrorArray = () => {
    if (Array.isArray(error))
      return error;

    return error ? [error] : [];
  }

  const renderErrorBlock = () => {
    if (React.isValidElement(error))
      return error;

    return (<ul className="form-errors">{errors.map((item, index) => (<li key={index}>
      <LocalizedMessage text={TRANSLATION_KEYS.backendErrors[item] ? { id: TRANSLATION_KEYS.backendErrors[item] } : item} />
    </li>))}</ul>)
  };

  const errors = makeErrorArray();

  if (errors.length)
    return (
      <ErrorElement>
        <div className="pb-1">
          <strong className="d-block"><LocalizedMessage text={{ id: TRANSLATION_KEYS.validation.fixItemsMessage }} /></strong>
        </div>

        {renderErrorBlock()}
      </ErrorElement>
    );

  return null;
}

export {
  Error as default,
  ErrorElement
};