import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TimesheetTotalMinutesForDay } from "./TimesheetTotalMinutes";
import DailyTimesheetRowConnector from "./DailyTimesheetRow";
import ActuallyHr from "../../../../components/ui-utility/ActuallyHr";
import MonthlyStatus from "../../MonthlyStatus";
import { useSelectorWithDefault } from "../../../../util/redux-utils";
import { selectUserDayStatus } from "../../../../store/ducks/timesheet/selectors";


function DailyTimesheetSection({taskIds, userId, date}) {
  const data = useSelectorWithDefault(selectUserDayStatus(userId), {});

  return (
    <div className="daily-timesheet-section">
      <Row>
        <Col lg="6">
          <MonthlyStatus highlightWeekDate={date}
                         startDate={date}
                         data={data}
                         colorOptions={{default: '#D9E2EC'}}/>

        </Col>
        <Col lg="6" className="d-flex bold justify-content-end align-items-end">
          <TimesheetTotalMinutesForDay date={date}/>
        </Col>
        <ActuallyHr/>
      </Row>
      {
        taskIds.map(taskId => (
          <Row key={taskId}>
            <Col>
              <DailyTimesheetRowConnector taskId={taskId} date={date}/>
            </Col>
            <ActuallyHr/>
          </Row>
        ))
      }
    </div>
  );
}

export default DailyTimesheetSection;