import React from "react";
import { useDispatch } from "react-redux";

import PropTypes from 'prop-types';
import Filter from '../entities/Filter';

import Alert from 'react-bootstrap/Alert';

import { TimesIcon } from "../../buttons";

import filterSelectors from "../../../store/ducks/filter/filter-selectors";
import { changeFilterValueAction } from "../../../store/ducks/filter/filter-actions";
import useShallowEqualSelector from "../../../store/helpers";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

function ActiveFilterField(props) {
  const dispatch = useDispatch();

  const {filter} = props;
  const filterKey = filter.getFilterKey();

  const filterValue = useShallowEqualSelector(filterSelectors.selectFilterForField(filterKey));

  const onRemoveFilter = () => {
    dispatch(changeFilterValueAction(filterKey, null));
  };

  return (
    <div>
      <Alert variant="primary">
        <div className="flex-spacebetween">
          <span>
            <LocalizedMessage text={filter.getActiveFilterSentence(filterValue)} />
          </span>
          <button type="button" className="btn btn-transparent" onClick={onRemoveFilter}><TimesIcon/></button>
        </div>
      </Alert>
    </div>
  );
}

ActiveFilterField.propTypes = {
  filter: PropTypes.instanceOf(Filter),
};

export default ActiveFilterField;