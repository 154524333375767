import {createGetRequest} from "../api";
import {joinRouteParts} from "../utils";

const baseUrl = 'system-settings';

export const getBootstrapConfiguration = () => {
  const url = joinRouteParts(baseUrl, 'bootstrap');

  return createGetRequest({url});
}
