import { createSlice } from "@reduxjs/toolkit";
import { mergeObjectIntoState } from "../../helpers";

const initialState = {
  users: {},
  projects: {},
  global: {},
};

const userRights = (state, action) => {
  const {userId, rights} = action.payload;
  const newUsers = mergeObjectIntoState(state.users, {[userId]: rights}, 'users');

  return {
    ...state,
    ...newUsers
  }
};

const projectRights = (state, action) => {
  const {projectId, rights} = action.payload;
  const newProjects = mergeObjectIntoState(state.projects, {[projectId]: rights}, 'projects');

  return {
    ...state,
    ...newProjects
  }
};

const globalRights = (state, action) => {
  const newGlobal = mergeObjectIntoState(state.global, action.payload.rights, 'global');

  return {
    ...state,
    ...newGlobal
  }
};

const rightsSlice = createSlice({
  name: 'rights',
  initialState,
  reducers: {
    fetchUserRightsAction: userRights,
    fetchGlobalRightsAction: globalRights,
    fetchProjectRightsAction: projectRights,
  }
});

const {actions, reducer} = rightsSlice;

const {
  fetchUserRightsAction,
  fetchGlobalRightsAction,
  fetchProjectRightsAction,
} = actions;

export {
  fetchUserRightsAction,
  fetchGlobalRightsAction,
  fetchProjectRightsAction,
};

export default reducer;
