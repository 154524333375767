import apiNotifierTypes from './api-notifier-types';

export const makeToasterState = (shown, msg = "") => ({shown, msg});

const initialState = {
  [apiNotifierTypes.API_PENDING]: makeToasterState(false, ""),
  [apiNotifierTypes.API_ERROR]: makeToasterState(false, ""),
  [apiNotifierTypes.API_SUCCESS]: makeToasterState(false, ""),
  [apiNotifierTypes.API_INFO]: makeToasterState(false, ""),
  [apiNotifierTypes.API_VALIDATION_ERROR]: makeToasterState(false, ""),
  [apiNotifierTypes.API_UNAUTHORIZED]: makeToasterState(false, ""),
};

function apiNotifierReducer(state = initialState, action) {
  switch (action.type) {
    case apiNotifierTypes.API_PENDING:
    case apiNotifierTypes.API_ERROR:
    case apiNotifierTypes.API_SUCCESS:
    case apiNotifierTypes.API_INFO:
    case apiNotifierTypes.API_VALIDATION_ERROR:
    case apiNotifierTypes.API_UNAUTHORIZED:
      return {...state, [action.type]: action.payload};
    default:
      return state;
  }
}

export default apiNotifierReducer;