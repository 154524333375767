import React, {useCallback, useState} from "react";

import {useDispatch} from "react-redux";
import {TextArea} from "../../../components/forms/TextArea";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {hasActualCharacters} from "../../../forms/validators";
import {FormElement, SimpleFormBuilder} from "../../../components/forms/SimpleFormBuilder";

import {BreatherRow} from "../../authentication/signup/SignUpEmailPhase";
import {ActuallyButton} from "../../../components/buttons";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {createAdvancedCustomersAction} from "../../../store/ducks/customer/customer-actions";

import Col from "react-bootstrap/Col";

interface AddCustomersFormProps {
  onDone: () => void
}

export const AddCustomersForm: React.FC<AddCustomersFormProps> = ({onDone}) => {
  const dispatch = useDispatch();

  const [customersText, setCustomersText] = useState("");

  const onSubmitAdvancedForm = useCallback(event => {
    event.preventDefault();

    dispatch(createAdvancedCustomersAction(customersText));

    onDone && onDone();
  }, [onDone, dispatch, customersText]);

  const formElements: Array<FormElement> = [
    {
      label: <LocalizedMessage text={{id: TRANSLATION_KEYS.customers.inputCustomersNamesLabel}}/>,
      input: <TextArea className="form-control" id="customer-text"
                       onChange={event => setCustomersText(event.target.value)} minRows={3}/>
    }
  ]

  return (
    <form onSubmit={onSubmitAdvancedForm}>

      <SimpleFormBuilder formElements={formElements}/>

      <BreatherRow className="text-right">
        <Col>
          <ActuallyButton variant="primary" type="submit" disabled={!hasActualCharacters(customersText)}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.addButton}}/>
          </ActuallyButton>
        </Col>
      </BreatherRow>
    </form>
  );
}
