import React from "react";
import { useForm } from "react-hook-form";
import ToggleSwitch from "../../../components/toggle-switch/ToggleSwitch";
import FormTimeInput from "../../../forms/FormTimeInput";
import { Time } from "../../../util/Time";
import FormActionRow from "../../../forms/FormActionRow";
import { changeTaskVisibilityStatusAction } from "../../../store/ducks/mobile/timesheet/actions";
import { useDispatch } from "react-redux";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

const EditDenied = () => (
  <div className="form-group">
    <div className="alert alert-warning">
      This task has been locked therefore it cannot be edited.
    </div>
  </div>
);

// FIXME: TB-160
interface EditTaskProps { 
  entity?: any;
  onDone?: () => void;
  api: any
}

const EditTask = ({entity: task, onDone, api}: EditTaskProps) => {
  const disabled = !task.can_edit;
  const dispatch = useDispatch();

  const {register, handleSubmit, control, setValue, formState} = useForm({
    mode: 'onChange',
    defaultValues: {
      is_active: task.is_active,
      budgeted_minutes: Time.FromMinutes(task.budgeted_minutes).toColonSeparated(),
    }
  });

  const onSubmit = async formData => {
    const {id=undefined, name=undefined, project=undefined, budgeted_minutes=undefined, is_active=undefined} = {...task, ...formData};
    const budgetedMinutes = budgeted_minutes === "" ? null : Time.FromUserInput(budgeted_minutes).minutes;

    await api.editTask({
      id,
      name,
      project,
      is_active,
      budgeted_minutes: budgetedMinutes,
    });

    if(!is_active) {
      dispatch(changeTaskVisibilityStatusAction(id));
    }

    if (onDone) {
      onDone();
    }
  };

  const handleDelete = async taskId => {
    await api.deleteTask(taskId);

    if (onDone) {
      onDone();
    }
  };

  const {isValid, dirty} = formState;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!task.can_edit && <EditDenied/>}

        <div className="form-group">
          <label className="label-default" htmlFor="name">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.tasksTab.tabTableHeaders.taskName}}/>
          </label>
          <input ref={register}
                 defaultValue={task.name}
                 type="text"
                 name="name"
                 className="form-control"
                 required
                 disabled={disabled}
                 onFocus={e => e.target.select()}
                 tabIndex={1} autoFocus={true}/>
        </div>

        <div className="form-row">
          <div className="form-group col-sm-12">
            <label className="label-default" htmlFor="budgeted_minutes">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.tasksBudgetedHoursFieldLabel}}/>
            </label>
            <div className="row">
              <div className="col-sm-6">
                <FormTimeInput name={"budgeted_minutes"}
                               control={control}
                               setValue={setValue}
                               tabIndex={2}
                               disabled={disabled}/>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-sm-6">
            <label className="label-default" htmlFor="is_active">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.isTaskActiveFieldLabel}}/>
            </label>

            <div>
              <ToggleSwitch innerRef={register}
                            disabled={disabled}
                            tabIndex={3}
                            name="is_active"/>
            </div>
          </div>
        </div>

        <FormActionRow showDeleteButton={!!(!task.actual_minutes)}
                       handleDelete={() => handleDelete(task.id)} tabIndexStart={3}
                       isValid={isValid}
                       dirty={dirty}
                       handleCancel={onDone}/>
      </form>
    </>
  )
};

export default EditTask;
