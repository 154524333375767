import React from "react";
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";

import { If } from "../../../../components/ui-utility/Conditional";
import { formatISO } from "../../../../util/dates";
import { useSelectorWithDefault } from "../../../../util/redux-utils";
import {
  selectProjectForId,
  selectRecordsByTaskId,
  selectTaskForId
} from "../../../../store/ducks/timesheet/selectors";
import { DefaultBlock } from "../../../../components/layout/LayoutHelpers";

import useTimesheetEditing from "../../../timesheet/daily/components/daily-timesheet-hooks";
import MobileTimesheetEditRow from "./MobileTimesheetEditRow";
import MobileTimesheetViewRow from "./MobileTimesheetViewRow";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MobileTimesheetListingRowConnector = ({taskId, date}) => {
  const isoDate = formatISO(date);

  const taskRecords = useSelector(selectRecordsByTaskId(taskId)) || [];

  return <MobileTimesheetListingRow taskId={taskId} date={isoDate} records={taskRecords}/>
};

const MobileTimesheetListingRow = ({taskId, date, records}) => {
  const {
    apiErrors,
    recordIdsInEdit,

    handleRecordCreation,
    handleRecordDeleting,
    handleRecordEdit,
    handleRecordCanceling,
    handleRecordSaving
  } = useTimesheetEditing();

  const taskInstance = useSelectorWithDefault(selectTaskForId(taskId), {});
  const projectInstance = useSelectorWithDefault(selectProjectForId(taskInstance.project), {});

  const handleRecordRowClick = record => {
    if (record.id) {
      handleRecordEdit(record)
    } else {
      handleRecordCreation(taskId, date);
    }
  };

  const resolveRow = (record, index) => {
    if (recordIdsInEdit.includes(record.id))
      return (
        <MobileTimesheetEditRow task={taskInstance}
                                project={projectInstance}
                                record={record}
                                errors={apiErrors[record.id]}
                                handleRecordDeleting={handleRecordDeleting}
                                handleRecordSaving={handleRecordSaving}
                                handleRecordCanceling={handleRecordCanceling}/>
      );
    return (
      <MobileTimesheetViewRow task={taskInstance}
                              project={projectInstance}
                              recordId={record.id}
                              onClick={handleRecordRowClick}/>
    )
  };

  return (
    <>
      <If condition={records.length === 0}>
        <Row className="mb-2">
          <Col xs={12}>
            <DefaultBlock>
              <MobileTimesheetViewRow task={taskInstance}
                                      project={projectInstance}
                                      onClick={handleRecordRowClick}/>
            </DefaultBlock>
          </Col>
        </Row>
      </If>
      {
        records.map((record, index) => (
          <Row key={record.id} className="mb-2">
            <Col xs={12}>
              <DefaultBlock>
                {resolveRow(record, index)}
              </DefaultBlock>
            </Col>
          </Row>
        ))
      }
    </>
  )
};

MobileTimesheetListingRow.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default MobileTimesheetListingRowConnector;