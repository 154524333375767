import { isSameDay, parseISO } from "../../../../util/dates";

const keepRecordIfTaskOrDateDifferent = updatedRecord => record => {
  return updatedRecord.task !== record.task || !isSameDay(parseISO(updatedRecord.date), parseISO(record.date));
};

export default class SaveRecordStateManager {
  static updateRecord = (state, record) => {
    if (!record.id) {
      const keepRecords = Object.values(state.records).filter(keepRecordIfTaskOrDateDifferent(record));

      // FIXME: TB-160
      const keepRecordIds = keepRecords.map((r: any) => r.id);

      return keepRecordIds.reduce((acc, recordId) => {
        acc[recordId] = state.records[recordId];
        return acc;
      }, {});
    }

    return {  
      ...state.records,
      [record.id]: record
    };
  };

  static deleteRecord = (state, recordId) => {
    const { [recordId]: deletedRecordNotUsed, ...restOfRecords } = state.records;

    return restOfRecords;
  };
}
