import React from "react";

import VisibleTasks from "../../../../components/task/VisibleTasks";
import DailyTimesheetNavigator from "./DailyTimesheetNavigator";

import { formatWeeklyRoute } from "../timesheet-utility";
import { useEmployment } from "../../../people/user-details/UserDetails";
import TimesheetBalanceToday from "../../TimesheetBalanceToday";
import useShallowEqualSelector from "../../../../store/helpers";
import peopleSelectors from "../../../../store/ducks/people/selectors";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {ActuallyLink} from "../../../../components/routing/ActuallyLink";

function TimesheetDailyActions({startDate, setStartDate, userId}) {
  const {balance} = useEmployment(userId);
  const user: any = useShallowEqualSelector(peopleSelectors.selectUser(userId));

  const weeklyRoute = formatWeeklyRoute(startDate);

  return (
    <div className="d-flex justify-content-between flex-grow-1">
      <div>
        <div className="d-flex align-items-center">
          <DailyTimesheetNavigator startDate={startDate} onChangeStartDate={setStartDate}/>
          <VisibleTasks className="ml-2 mr-2"/>
          {user.is_balance_visible && <TimesheetBalanceToday minutes={balance.minutes} />}
        </div>
        <div>
          <ActuallyLink to={weeklyRoute}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.goToWeekViewLink}} />
          </ActuallyLink>
        </div>
      </div>
    </div>
  )
}

export default TimesheetDailyActions;