const filterDefaults = {
  'record.is_billable': false,
  'record.user': null,
  'record.date': null,

  'project.id': null,
  'project.is_active': true,
  'project.q': '',

  'user.is_active': true,
  'user.q': '',

  'customer.q': ''
};

export default filterDefaults;