import ErrorResponse from "./errors";
import { normalize, schema } from "normalizr";
import { isFunction } from 'lodash';

class GeneralApiResponseParser {

  static extractPaginationData = (response) => {
    const {data, ...rest} = response;
    return rest;
  };

  static extractAllErrors = error => {
    const fieldKeys = Object.keys(error);

    return new ErrorResponse(error).extractAllErrors(fieldKeys)
  };

  static extractErrorData = error => {
    return (error.response && error.response.data) || {};
  };


  // FIXME: TB-160
  static convertRecordsIntoSelectCompatible = (response, keyOrExtractorFn: any = 'name') => {
    const labelExtractor = isFunction(keyOrExtractorFn) ? keyOrExtractorFn : record => record[keyOrExtractorFn];

    return response.map(record => ({...record, label: labelExtractor(record), value: record.id}));
  };

  // => {result: [], entities:  schemaKey: {1: {}, 2: {}}}}
  static normalizeListing = (response, schemaKey, opts = {}) => {
    const inputSchema = new schema.Entity(schemaKey, {}, opts);

    return normalize(response, [inputSchema]);
  };

  // => {result: [], entities: {1: {}, 2: {}}}
  static normalizeListingNoSchema = (response, opts?) => {
    const {result, entities} = GeneralApiResponseParser.normalizeListing(response, 'schema', opts);

    return {result, entities: entities.schema};
  }
}

export default GeneralApiResponseParser;