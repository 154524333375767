import React, { useState } from "react";

import YearSelect from "../../../../components/select/YearSelect";

import { thisYear } from "../../../../util/dates";
import { ActuallyButton } from "../../../../components/buttons";

import '../Calendar.css';


function CalendarYearSelection({onChange}) {
  const onGoingYear = thisYear();

  // FIXME: TB-160
  const [selected, setSelected] = useState<any>({value: onGoingYear, year: onGoingYear, label: onGoingYear});

  const handleYearSelect = () => {
    onChange(selected.year);
    setSelected(null);
  };

  return (
    <div className="row align-items-center">
      <div className="col-lg-9">
        <YearSelect min={2000} max={2024} onChange={setSelected} value={selected}/>
      </div>
      <div className="col-lg-3 text-right">
        <ActuallyButton variant="primary" onClick={handleYearSelect} className="btn-block"
                        disabled={!(!!selected?.year)}>Add holidays</ActuallyButton>
      </div>
    </div>
  )
}

CalendarYearSelection.defaultProps = {};

export default CalendarYearSelection;