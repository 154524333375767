import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import NotAuthorizedPlaceholder from "./NotAuthorizedPlaceholder";

import { useSelector } from "react-redux";
import { If } from "../ui-utility/Conditional";
import { useContextRights } from "./right-hooks";
import { RouteComponentProps } from '@reach/router';

// FIXME: TB-160
interface OwnPropTypes {
  rightName: any;
  contextType: any;
  componentFn: any;
  objectId?: any;
  showBanner?: boolean;
}

type RestrictAccessProps = OwnPropTypes & RouteComponentProps;

const RestrictAccess: React.FunctionComponent<RestrictAccessProps> = ({rightName, contextType, componentFn, objectId, showBanner}) => {
  const {fetchObjectRights, selectObjectRightsById} = useContextRights(contextType);

  const rights = useSelector(selectObjectRightsById(objectId)) || {};

  useEffect(() => {
    fetchObjectRights(objectId);
  }, [objectId, fetchObjectRights]);

  const hasNotRight = rights[rightName] === false; // Check needs to be explicit, no coercion.
  const hasRight = rights[rightName] === true;

  return (
    <>
      <If condition={hasRight}>{componentFn(objectId)}</If>
      <If condition={hasNotRight && showBanner}><NotAuthorizedPlaceholder/></If>
    </>
  );
}

RestrictAccess.propTypes = {
  rightName: PropTypes.string.isRequired,
  showBanner: PropTypes.bool
};

RestrictAccess.defaultProps = {
  showBanner: true
};

export { RestrictAccess };