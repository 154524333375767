import * as React from 'react';
import { TypographyVariant } from './types/TypographyVariant';
import styled from 'styled-components';

interface StyledTypographyProps {
    variant: TypographyVariant;
}

const StyledTypography = styled.div<StyledTypographyProps>`
    font-family: ${props => props.theme.typography[props.variant].fontFamily};
    font-weight: ${props => props.theme.typography[props.variant].fontWeight};
    font-size: ${props => props.theme.typography[props.variant].fontSize};
    line-height: ${props => props.theme.typography[props.variant].lineHeight};
    letter-spacing: ${props => props.theme.typography[props.variant].letterSpacing};
    text-transform: ${props => props.theme.typography[props.variant].textTransform};
`

interface OwnPropTypes {
    variant: TypographyVariant;
}

export type TypographyProps = OwnPropTypes; 

export const Typography: React.FunctionComponent<TypographyProps> = ({variant, children}) => (
    <StyledTypography variant={variant}>
        {children}
    </StyledTypography>
)