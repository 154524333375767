/* CSS Declarations */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';

/* JS Declarations */
import 'bootstrap';
import WebFont from 'webfontloader';
import { library } from "@fortawesome/fontawesome-svg-core";

/* Initializations */
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowRight,
  faArrowUp,
  faBan,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCamera,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faColumns,
  faCopy,
  faDivide,
  faEllipsisV,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faFileChartLine,
  faFileExport,
  faHeart,
  faHistory,
  faHome,
  faIndent,
  faInfoCircle,
  faListAlt,
  faLock,
  faLockOpen,
  faMinus,
  faPen,
  faPenAlt,
  faPlus,
  faPlusCircle,
  faSignIn,
  faSignOut,
  faSpinner,
  faStopwatch,
  faTable,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faUserClock,
  faUsers,
  faCrown,
  faQuestionCircle,
  faUndo,
  faTools,
  faUsersClass,
  faBell,
  faThumbtack as fasThumbtack
} from "@fortawesome/pro-solid-svg-icons";

import {
  faAngleRight as falAngleRight,
  faArrowLeft as falArrowLeft,
  faChartLine as falChartLine,
  faHorizontalRule as falHorizontalRule,
  faFileAlt as falFileAlt,
  faUser as falUser,
  faUserCircle as falUserCircle,
  faUserClock as falUserClock,
  faUserFriends as falUserFriends,
  faQuoteRight,
  faThumbtack,
  faClock as falClock,
  faBriefcase as falBriefcase,
  faUsers as falUsers,
  faFileChartLine as falFileChartLine,
  faIdBadge as falIdBadge,
  faBars as falBars,
  faTimes as falTimes,
  faTools as falTools,
  faCog as falCog,
  faSignOut as falSignOut,
  faCalendarEdit as falCalendarEdit,
  faUsersSlash as falUsersSlash,
  faUsersClass as falUsersClass,
  faLightbulbExclamation as falLightbulbExclamation,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faPen as falPen,
  faBell as falBell,
  faIndustryAlt
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowRight,
  faArrowUp,
  faBan,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCamera,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faColumns,
  faCopy,
  faDivide,
  faEllipsisV,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faFileChartLine,
  faFileExport,
  faHeart,
  faHistory,
  faHome,
  faIndent,
  faInfoCircle,
  faListAlt,
  faLock,
  faLockOpen,
  faMinus,
  faPen,
  faPenAlt,
  faPlus,
  faPlusCircle,
  faSignIn,
  faSignOut,
  faSpinner,
  faStopwatch,
  faTable,
  faThumbtack,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faUserClock,
  faUsers,
  falAngleRight,
  falArrowLeft,
  falChartLine,
  falHorizontalRule,
  falFileAlt,
  falUser,
  falUserCircle,
  falUserClock,
  falUserFriends,
  falClock,
  falBriefcase,
  falUsers,
  falFileChartLine,
  falIdBadge,
  falBars,
  falTimes,
  falTools,
  falCog,
  falSignOut,
  falCalendarEdit,
  falUsersSlash,
  falUsersClass,
  faCrown,
  faQuestionCircle,
  faUndo,
  faTools,
  faQuoteRight,
  faUsersClass,
  falLightbulbExclamation,
  falAngleUp,
  falAngleDown,
  falPen,
  faBell,
  falBell,
  fasThumbtack,
  faIndustryAlt
);

WebFont.load({
  google: {
    families: [
      'Inter:900',
      // 'Work Sans:300,400,600,700',
      // 'Source Sans Pro:300,400,600,700',
      'Nunito Sans:300,400,600,700,800,900',
      // 'Open Sans:300,400,600,700',
      // 'Roboto:300,400,700', 'Pacifico',
      // 'Rubik:700,900',
      'Barlow Condensed:500',
    ]
  }
});

// process.env.REACT_APP_GA on future, maybe?
export const googleAnalyticsTrackingId = "UA-150664441-1";

