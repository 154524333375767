import loadingTypePrefixes from "./loading-types";


const setRequestPending = actionName => ({type: loadingTypePrefixes.PENDING, actionName});

const setRequestError = actionName => ({type: loadingTypePrefixes.ERROR, actionName});

const setRequestDone = actionName => ({type: loadingTypePrefixes.DONE, actionName});

export {
  setRequestPending,
  setRequestError,
  setRequestDone
}