import React, {useState} from "react";
import styled from "styled-components";

import {useForm, Controller} from "react-hook-form";

import FormStateButton from "../../../forms/FormStateButton";

import {Row, Col} from "react-bootstrap";

import Form from "react-bootstrap/Form";

import '../authentication.css';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {FormElement, SimpleFormBuilder} from "../../../components/forms/SimpleFormBuilder";
import CountrySelect from "../../../components/select/CountrySelect";


export const BreatherRow = styled(Row)`
  margin-top: 1em;
`;

function SignUpEmailPhase({onSubmit}) {
  const {register, formState, handleSubmit, setValue, control} = useForm({mode: 'onChange'});
  const [isTosAccepted, setIsTosAccepted] = useState(false);

  const {isValid, dirty} = formState;

  const emailRegistration = {
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
      message: "invalid email address"
    }
  };

  const formElements: Array<FormElement> = [
    {
      width: 6,
      label: <LocalizedMessage text={{
        id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.firstNameLabel
      }}/>,
      input: <input className="form-control" name="first_name" type="text" ref={register({required: true})}
                    tabIndex={1}/>
    },
    {
      width: 6,
      label: <LocalizedMessage text={{
        id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.editFormFields.lastNameLabel
      }}/>,
      input: <input className="form-control" name="last_name" type="text" ref={register({required: true})}
                    tabIndex={2}/>

    },
    {
      label: <LocalizedMessage text={{id: TRANSLATION_KEYS.login.inputEmail}}/>,
      input: <input className="form-control" name="email"
                    type="email" ref={register(emailRegistration)} tabIndex={3} required/>
    },
    {
      label: <LocalizedMessage text={{
        id: TRANSLATION_KEYS.commonProperties.country
      }}/>,
      input: (
        <Controller as={<CountrySelect value={null} onChange={entity => setValue('country', entity?.id)}/>} name="country" control={control}
                    rules={{required: true}}/>)
    },
    {
      label: <LocalizedMessage text={{
        id: TRANSLATION_KEYS.commonProperties.phone
      }}/>,
      input: <input className="form-control" name="phone" type="text" ref={register({required: false})} tabIndex={4}/>
    },
  ];

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data))}>
      <div className="p-3 text-center" style={{color: '#334E68'}}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.login.signup.setUpUser.header}}/>
      </div>

      <SimpleFormBuilder formElements={formElements}/>

      <BreatherRow>
        <Col>
          <Form.Check custom type="checkbox"
                      className="noselect"
                      onChange={event => setIsTosAccepted(event.target.checked)}
                      checked={isTosAccepted}
                      tabIndex={4}
                      id="tos_accepted_checker"
                      label={
                        <LocalizedMessage text={{
                          id: TRANSLATION_KEYS.login.termsOfServiceConfirmationLabel,
                          values: {
                            linkToTOS: <a tabIndex={5} href="https://www.getactually.com/terms-of-service"
                                          target="_blank"><LocalizedMessage
                              text={{id: TRANSLATION_KEYS.login.termsOfServiceConfirmationLinkLabel}}/></a>
                          }
                        }}/>
                      }/>
        </Col>
      </BreatherRow>

      <BreatherRow className="text-center">
        <Col>
          <FormStateButton dirty={dirty} isValid={isValid} tabIndex={6} className="btn-block"
                           disabled={!isTosAccepted}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.nextButton}}/>
          </FormStateButton>
        </Col>
      </BreatherRow>
    </form>
  )
}

export default SignUpEmailPhase;