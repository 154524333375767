import React from "react";
import {useIntl} from "react-intl";
import {localizeMessage} from "../../../../intl/components/LocalizedMessage";

function TimeModeConverter({duration, unit}) {
  const intl = useIntl();
  const prefix = 'timesheetApprovalCycleSettings.approvalCyclesManagement.cycleDurations';
  let message: string | null = null;

  if (unit === 'WEEKS')
    message = localizeMessage(
      {id: `${prefix}.weeks`, values: {duration}},
      intl
    );
  if (unit === 'DAYS')
    message = localizeMessage(
      {id: `${prefix}.days`, values: {duration}},
      intl
    );
  if (unit === 'MONTHS')
    message = localizeMessage(
      {id: `${prefix}.months`, values: {duration}},
      intl
    );

  if (message) {
    return <span>{message}</span>
  }

  return (<span>{unit}</span>)
}

export default TimeModeConverter;