import React from "react";
import {ActuallyButton} from "../../buttons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ProjectName from "../../project/ProjectName";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";


function DeleteProjectConfirmation({project, onConfirm, onBail}) {
  const projectName = <ProjectName {...project} addLink={false}/>;

  return (
    <div className="delete-project-confirmation-block">
      <Row>
        <Col>
          <p>
            <LocalizedMessage text={{
              id: TRANSLATION_KEYS.projects.projectDashboard.deleteProjectModal.confirmMessageWithProjectName,
              values: {
                projectName,
                strong: chunk => <strong>{chunk}</strong>
              }
            }}/>
            <br/>
            <LocalizedMessage text={{
              id: TRANSLATION_KEYS.projects.projectDashboard.deleteProjectModal.relatedItemsWillBeDeletedText,
              values: {
                strong: chunk => <strong>{chunk}</strong>
              }
            }}/>
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <ActuallyButton variant="link" onClick={onBail}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.deleteProjectModal.cancelButton}} />
          </ActuallyButton>
          <ActuallyButton variant="delete" onClick={onConfirm}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.deleteProjectModal.confirmDeleteButton}} />
          </ActuallyButton>
        </Col>
      </Row>
    </div>
  );
}

export default DeleteProjectConfirmation;
