import React, { useEffect, useState } from "react";
import { If } from "../../../../components/ui-utility/Conditional";

import PlainTable from "../../../../components/ui-utility/table/PlainTable";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";
import BalanceFixRow from "./BalanceFixRow";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {useLocalizedMessage} from "../../../../intl/use-localized-message";

// FIXME: TB-160
function BalanceFix({api, onSelect=(balanceFix: any)=>undefined, userId}) {
  const [state, setState] = useState({records: [], fetched: false});

  useEffect(() => {
    const fetchBalanceFixes = async () => {
      const records = await api.fetchBalanceFixes(userId) || [];

      setState({records, fetched: true});
    };

    fetchBalanceFixes();
  }, [api, userId]);

  const addBalanceFixButtonText = useLocalizedMessage({
    id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.addBalanceFixButton
  });

  const emptyListMessage = <LocalizedMessage text={{
    id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.emptyListMessage,
    values: {
      buttonText: addBalanceFixButtonText
    }
  }} />;

  return (
    <>
      <If condition={state.records.length > 0}>
        <PlainTable showLines={false}>
          <thead>
          <tr>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.date}} />
            </th>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.person}} />
            </th>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.description}} />
            </th>
            <th className="text-right">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.formAndListFieldLabels.fixAmount}} />
            </th>
          </tr>
          </thead>
          <tbody>
          {/*} FIXME: TB-160 {*/}
          {state.records.map((balanceFix:any) => (
            <BalanceFixRow balanceFix={balanceFix} key={balanceFix.id} onSelect={() => onSelect(balanceFix)}/>
          ))}
          </tbody>
        </PlainTable>
      </If>

      <If condition={state.records.length === 0 && state.fetched}>
        <EmptyStatePlaceholder iconName={'clock'}>
          <p>
            {emptyListMessage}
          </p>
        </EmptyStatePlaceholder>
      </If>
    </>
  )
}


export default BalanceFix;