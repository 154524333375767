import React from "react";
import { formatDayOfWeek } from "./date-formatters";
import { parseISO, isValid } from "../../../util/dates";

const DayOfWeek = ({date, fromIso=false, ...props}) => {
  let dateObj = date;

  if (fromIso)
    dateObj = parseISO(date);

  if (!isValid(dateObj))
    return <>-</>;

  const formatted = formatDayOfWeek(dateObj);

  return (
    <span {...props}>{formatted}</span>
  )
};

DayOfWeek.defaultProps = {
  fromIso: false
};

export default DayOfWeek;
