import React, {useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import modalDefinitions from "../../../../components/modal/modal-definitions";

import {ActuallyButton} from "../../../../components/buttons";
import {useModal} from "../../../../components/modal/modal-hooks";

import SimpleModal from "../../../../components/modal/SimpleModal";
import ModalFrame from "../../../../components/modal/ModalFrame";
import SendApprovalForm from "../send/SendApprovalForm";
import useApprovalAPI from "../approval-hooks";
import {If} from "../../../../components/ui-utility/Conditional";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

function SendToApprovalBtn({userId}) {
  const [openModal, closeModal] = useModal(modalDefinitions.SEND_RECORDS_TO_APPROVAL);
  const [shouldShowButton, setShouldShowButton] = useState(false);

  const {fetchUnsubmittedCycles} = useCallback(useApprovalAPI(), [userId]);

  useEffect(() => {
    const initialize = async () => {
      const {response} = await fetchUnsubmittedCycles(userId);

      response && setShouldShowButton(response.length);
    }

    initialize();
  }, [fetchUnsubmittedCycles, userId]);

  return (
      <If condition={shouldShowButton}>
        <ActuallyButton variant="primary" onClick={openModal}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitRecordsForApprovalButton}}/>
        </ActuallyButton>
        <SimpleModal name={modalDefinitions.SEND_RECORDS_TO_APPROVAL}>
          <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.modalHeader}} />}>
            <SendApprovalForm userId={userId} onDone={closeModal}/>
          </ModalFrame>
        </SimpleModal>
      </If>
  )

}

SendToApprovalBtn.propTypes = {
  userId: PropTypes.number,
};

export default SendToApprovalBtn;