import React from "react";
import {useSelector, useDispatch} from "react-redux";
import Select from 'react-select'

import {useAvailableTenantFetching} from "./tenant-selection-helpers";
import {selectCurrentTenantId} from "../../../store/ducks/auth/selectors";
import {changeTenantAction} from "../../../store/ducks/auth/actions";
import {If} from "../../../components/ui-utility/Conditional";

function TenantChange() {
  const dispatch = useDispatch();
  const currentTenantId = useSelector(selectCurrentTenantId);

  const tenants = useAvailableTenantFetching();

  const selected = tenants.filter((tenant:any) => tenant.id === currentTenantId);

  // FIXME: refactor...
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: '#829AB1',
      border: 0,
      fontWeight: 700,
      boxShadow: 'none',
    }),
    indicatorsContainer: classes => ({
      ...classes,
      display: "flex",
      justifyContent: "center",
      width: "50px"
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    indicatorSeparator: e => ({
      border: 0,
    }),
    valueContainer: c => ({
      ...c,
      padding: 0,
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#829AB1',
      };
    }
  };

  return (
      <If condition={tenants.length > 1}>
        {/*} FIXME: TB-160 {*/}
        <Select value={selected}
                options={tenants}
                styles={customStyles}
                onChange={(newSelection:any) => dispatch(changeTenantAction(newSelection?.id))}/>
      </If>)
}

export default TenantChange;