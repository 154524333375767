import React, {InputHTMLAttributes} from "react";

interface ActuallyInputPros extends InputHTMLAttributes<HTMLInputElement> {
}

// Just some base component for our inputs.
export const ActuallyInput: React.FC<ActuallyInputPros> = ({type, value, disabled}) => {
  return (
    <input className="form-control" type={type} value={value} disabled={disabled}/>
  )
}
