import React from "react";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export const PaymentSolutionProvider = () => {
  return (
    <small>
      <LocalizedMessage text={{
        id: TRANSLATION_KEYS.trial.checkout.form.disclaimerAboutUsingStripe,
        values: {
          stripeWebpageLink: <a href="https://stripe.com">Stripe</a>,
        }
      }} />
    </small>
  )
}
