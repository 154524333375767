import Filter from "./Filter";
import FILTER_TYPES from '../configuration/filter-type-definations';

import {get} from 'lodash';
import {getPeople, PeopleAPIResponseParser} from '../../../api/people/people-api';
import {getProjects} from "../../../api/projects/projects-api";
import {getCustomers} from "../../../api/customers/customers-api";
import {getAuthRoles} from "../../../api/authorization/rights/auth-rights-api";
import {getCalendars, getPresetCalendars} from "../../../api/calendars/calendar-api";
import {getAuthGroups} from "../../../api/authorization/groups/auth-group-api";

import {ProjectsAPIResponseParser} from '../../../api/projects/projects-api-response';
import {CustomersAPIResponseParser} from '../../../api/customers/customers-api-response';
import GeneralApiResponseParser from "../../../api/general-api-response-parser";

class SelectFilterField extends Filter {
  isMultiSelect: boolean;
  filterType = FILTER_TYPES.SELECT;

  constructor(filterKey, isMultiSelect) {
    super(filterKey);
    this.isMultiSelect = isMultiSelect;
  }

  shouldAllowMultipleSelections = () => {
    return this.isMultiSelect;
  };

  getActiveFilterSentence = (value) => {
    const localizationObject = this.getActiveFilterLabel();
    const labelValue = get(value, 'label', '');

    return {
      ...localizationObject,
      values: {value: labelValue},
    };
  };

  formatValue = (filterValue = {}) => {
    const id = get(filterValue, 'id', "");

    return {[this.filterKey]: id};
  };

}

class APISelectFilterField extends SelectFilterField {
  // FIXME: TB-160
  entityKey: any;

  constructor(filterKey, entityKey, isMultiSelect) {
    super(filterKey, isMultiSelect);
    this.entityKey = entityKey;
  }

  getEntityKey = () => {
    return this.entityKey;
  };

  // Factories...
  fetch = (params) => {
    if (this.entityKey === 'projects') {
      const withExtra = {fields: ['id', 'name'], ...params, page_size: 0};

      return getProjects(withExtra);
    }
    if (this.entityKey === 'users') {
      const withExtra = {fields: ['id', 'first_name', 'last_name'], ...params, page_size: 0};

      return getPeople(withExtra);
    }

    if (this.entityKey === 'customers') {
      const withExtra = {fields: ['id', 'name', 'code'], ...params, page_size: 0};

      return getCustomers(withExtra);
    }

    if (this.entityKey === 'authroles') {
      const withExtra = {fields: ['id', 'name', 'short_desc'], ...params, page_size: 0};

      return getAuthRoles(withExtra);
    }

    if (this.entityKey === 'authgroups') {
      const withExtra = {fields: ['id', 'name'], ...params, page_size: 0};

      return getAuthGroups(withExtra);
    }

    if (this.entityKey === 'preset-calendars') {
      return getPresetCalendars({});
    }

    if (this.entityKey === 'calendars') {
      return getCalendars();
    }

    throw Error('ActuallyErr2 : Entity not specified');
  };

  handleEntityResponse = (response) => {
    const converted = this.convertResponse(response);

    if (this.entityKey === 'projects') {
      return ProjectsAPIResponseParser.normalizeListing(converted);
    }
    if (this.entityKey === 'users') {
      return PeopleAPIResponseParser.normalizeResponse(converted, 'users');
    }

    if (this.entityKey === 'customers') {
      return CustomersAPIResponseParser.normalizeListing(converted);
    }

    if (this.entityKey === 'authroles' || this.entityKey === 'authgroups') {
      return GeneralApiResponseParser.normalizeListing(converted, this.entityKey);
    }

    if (this.entityKey === 'preset-calendars') {
      return GeneralApiResponseParser.normalizeListing(converted, this.entityKey);
    }

    if (this.entityKey === 'calendars') {
      return GeneralApiResponseParser.normalizeListing(converted, this.entityKey);
    }

    throw Error('ActuallyErr1 : Entity not specified');
  };

  convertResponse = response => {
    if (this.entityKey === 'projects') {
      return ProjectsAPIResponseParser.convertProjectsIntoSelectCompatible(response);
    }
    if (this.entityKey === 'users') {
      return PeopleAPIResponseParser.convertRecordsIntoSelectCompatible(response);
    }

    if (this.entityKey === 'customers') {
      return CustomersAPIResponseParser.convertRecordsIntoSelectCompatible(response);
    }

    if (this.entityKey === 'authroles' || this.entityKey === 'authgroups' || this.entityKey === 'preset-calendars'
      || this.entityKey === 'calendars') {
      return GeneralApiResponseParser.convertRecordsIntoSelectCompatible(response);
    }

    throw Error('ActuallyErr1 : Entity not specified');
  }

}

export {
  SelectFilterField,
  APISelectFilterField,
};