import React from "react";

import { useModal } from "../../../components/modal/modal-hooks";

import modalDefinitions from "../../../components/modal/modal-definitions";

import SimpleModal from "../../../components/modal/SimpleModal";
import ProjectMembershipList from "./ProjectMembershipList";
import RequireContextRight from "../../../components/rights/RequireContextRight";

import RIGHTS from "../../../components/rights/right-definitions";
import {ActuallyButton, CogIcon} from "../../../components/buttons";

export const ProjectMembershipSelect = ({projectId, members}) => {
  const [openModal] = useModal(modalDefinitions.PROJECTMEMBERS);

  return (
    <React.Fragment>
      <RequireContextRight rightName={RIGHTS.manageProjectMembers} objectId={projectId} contextType="projects">
        <ActuallyButton variant="slick" onClick={openModal} className="btn-sm">
          <CogIcon light={true}/>
        </ActuallyButton>
      </RequireContextRight>

      <SimpleModal name={modalDefinitions.PROJECTMEMBERS}>
        <ProjectMembershipList users={members} projectId={projectId}/>
      </SimpleModal>
    </React.Fragment>
  );
};
