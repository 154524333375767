import {createSlice} from "@reduxjs/toolkit";
import {uniq} from "lodash";
import {removeObjectFromMapOfObjects, removeObjectFromResult} from "../../helpers";

const initialState = {
  templates: {},
  templateIds: [],
};

const receiveTemplates = (state, action) => {
  const templates = {...state.templates, ...action.payload.entities};
  const templateIds = uniq([...state.templateIds, ...action.payload.result]);

  return {
    ...state,
    templates,
    templateIds
  }
};

const removeTemplate = (state, action) => {
  const templates = removeObjectFromMapOfObjects(state.templates, action.payload.templateId);
  const templateIds = removeObjectFromResult(state.templateIds, action.payload.templateId);

  return {
    ...state,
    templates,
    templateIds
  }
}


const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    receiveTemplatesAction: receiveTemplates,
    removeTemplateAction: removeTemplate,
  }
});

const {actions, reducer} = templateSlice;

const {
  receiveTemplatesAction,
  removeTemplateAction
} = actions;

export {
  receiveTemplatesAction,
  removeTemplateAction
};

export default reducer;
