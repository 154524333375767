import React from "react";
import { BaseIcon } from "../../../components/buttons";
import './ProjectInfoIcons.css';

const CustomerIndicatorIcon = () => {
  return (
    <BaseIcon iconName="building" className="project-info-icon" />
  );
};

const ScheduleIndicatorIcon = () => {
  return (
    <BaseIcon iconName="calendar" className="project-info-icon" />
  );
};

export {
  CustomerIndicatorIcon,
  ScheduleIndicatorIcon,
}
