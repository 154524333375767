import React, { useCallback } from "react";
import propTypes from 'prop-types';

import BaseSelect from "./BaseSelect";

function YearSelect({onChange, min, max, value}) {
  const makeIntervalCallback = useCallback(() => {
    const yearInterval = [...Array(max - min + 1)].map((item, index) => min + index).reverse();

    return yearInterval.map(year => ({value: year, year, label: year}));
  }, [min, max]);

  const dropdownOptions = makeIntervalCallback();

  return <BaseSelect handleChange={onChange} dropdownOptions={dropdownOptions} value={value}/>
}

YearSelect.propTypes = {
  min: propTypes.number.isRequired,
  max: propTypes.number.isRequired
};

export default YearSelect;