import React, { useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import RIGHTS from "../../../components/rights/right-definitions";

import {
  formatISO,
  getWeekDates,
  isSameWeek,
  startOfWeek,
  today
} from "../../../util/dates";
import {
  selectUserDayStatus,
  selectWeeklyTimesheetRows,
  selectWeeklyTotal
} from "../../../store/ducks/timesheet/selectors";
import {
  extractInitialDateFromQueryParams,
  formatWeeklyRoute
} from "../daily/timesheet-utility";
import { fetchDailyTimesheet } from "../../../store/ducks/mobile/timesheet/actions";
import { useSelectorWithDefault } from "../../../util/redux-utils";
import { selectTimesheetUser } from "../../../store/ducks/mobile/timesheet/selectors";
import { SoftLoading } from "../../../components/loaders/SoftLoading";
import { If } from "../../../components/ui-utility/Conditional";
import { useEmployment } from "../../people/user-details/UserDetails";

import TimeValue from "../../../components/TimeValue";
import EmptyTimesheetPlaceholder from "../EmptyTimesheetPlaceholder";
import SendToApprovalBtn from "../approval/components/SendToApprovalBtn";
import TimesheetBalanceToday from "../TimesheetBalanceToday";
import PageHeader from "../../../components/text/PageHeader";
import WeeklyTimesheetNavigator from "./WeeklyTimesheetNavigator";
import TimesheetDayHeader from "./TimesheetDayHeader";
import WeeklyTimesheetRow from "./WeeklyTimesheetRow";
import TotalHeaders from "./TotalHeaders";
import VisibleTasks from "../../../components/task/VisibleTasks";
import MonthlyStatus from "../MonthlyStatus";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RestrictAccess } from "../../../components/rights/RestrictAccess";

import useShallowEqualSelector from "../../../store/helpers";
import peopleSelectors from "../../../store/ducks/people/selectors";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {TIMESHEET_LOADING} from "../../../store/ducks/loading/loading-types";
import {ActuallyLink} from "../../../components/routing/ActuallyLink";

const WeeklyTotalsRow = ({dates, weekTotal, className='', skipFirstColumn = false}) => {
  return (
    <tr className={className}>
      {!skipFirstColumn &&
      <th className="py-2 align-middle" style={{minWidth: '450px', backgroundColor: 'white'}}>&nbsp;</th>}

      <TotalHeaders dates={dates}/>

      <th className="py-2 pr-3 text-right align-middle" style={{backgroundColor: 'white'}}>
        <TimeValue minutes={weekTotal}/>
      </th>
    </tr>
  );
};

const TimesheetTableHeader = ({dates, weekTotal, monthlyStatusData}) => {

  return (
    <thead>
    <tr className="table-top-row">
      <th className="align-middle pl-3 py-2" style={{minWidth: '450px', backgroundColor: 'white'}} rowSpan={2}>
        <MonthlyStatus highlightWeekDate={dates[0]}
                       startDate={dates[0]}
                       data={monthlyStatusData}
                       colorOptions={{default: '#D9E2EC'}}/>
      </th>

      {dates.map(d => <TimesheetDayHeader key={d} date={d}/>)}

      <th style={{backgroundColor: 'white'}}>&nbsp;</th>
    </tr>

    <WeeklyTotalsRow dates={dates} weekTotal={weekTotal} skipFirstColumn/>

    </thead>
  );
};

TimesheetTableHeader.propTypes = {
  // FIXME: TB-160 -- Date
  // dates: PropTypes.arrayOf(Date).isRequired,
};

const TimesheetTableBody = ({taskIds, dates}) => {

  return (
    <tbody style={{backgroundColor: 'white', borderRadius: '15px'}}>
    {taskIds.map(taskId => <WeeklyTimesheetRow taskId={taskId} key={taskId} dates={dates}/>)}
    </tbody>
  );
};

TimesheetTableBody.propTypes = {
  taskIds: PropTypes.array.isRequired,
  // FIXME: TB-160 -- Date
  // dates: PropTypes.arrayOf(Date).isRequired,
};

export const TimesheetActions = ({startDate, setStartDate, userId}) => {
  const {balance} = useEmployment(userId);
  const user: any = useShallowEqualSelector(peopleSelectors.selectUser(userId));

  const isThisWeek = isSameWeek(today(), startDate);
  const jumpToDate = isThisWeek ? formatISO(today()) : formatISO(startDate);

  return (
    <Row>
      <Col lg={8}>
        <WeeklyTimesheetNavigator startDate={startDate}
                                  onChangeStartDate={setStartDate} className="mr-4"/>

        <VisibleTasks className="mr-4"/>

        {user.is_balance_visible && <TimesheetBalanceToday minutes={balance.minutes}/>}

        <div>
          <ActuallyLink to={`/timesheet/daily/?startdate=${jumpToDate}`}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.goToDayViewLink}}/>
          </ActuallyLink>
        </div>
      </Col>
      <Col lg={4} className="text-right">
        <RestrictAccess rightName={RIGHTS.manageUserCycle} objectId={userId} contextType="users" showBanner={false}
                        componentFn={userId => <SendToApprovalBtn userId={userId}/>}/>
      </Col>
    </Row>
  )
};

type TimesheetProps = RouteComponentProps;

const Timesheet: React.FunctionComponent<TimesheetProps> = ({location, navigate}) => {
  const dispatch = useDispatch();
  const timesheetUser = useSelector(selectTimesheetUser);

  const locationSearch = location?.search;

  const initialDate = useMemo(() => extractInitialDateFromQueryParams(locationSearch), [locationSearch]);
  const startDate = useMemo(() => startOfWeek(initialDate), [initialDate]);

  const dates = getWeekDates(startDate);

  useEffect(() => {
    dispatch(fetchDailyTimesheet(timesheetUser.id, startDate));
  }, [dispatch, timesheetUser.id, startDate]);

  const navigateToDate = (date) => navigate && navigate(formatWeeklyRoute(date));

  const visibleTasks = useSelector(selectWeeklyTimesheetRows);
  const hasVisibleTasks = visibleTasks.length > 0;

  const data = useSelectorWithDefault(selectUserDayStatus(timesheetUser.id), {});

  const weekTotal = useSelector(selectWeeklyTotal);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.weeklyPageHeader}} />
          </PageHeader>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TimesheetActions startDate={startDate} setStartDate={navigateToDate} userId={timesheetUser.id}/>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm">
          <div style={{display: 'block', width: '100%', overflowX: 'auto'}}>
            <SoftLoading actionName={TIMESHEET_LOADING}>
              <If condition={hasVisibleTasks}>
                <div className="table-responsive">
                  <table className="table table-borderless table-lines">
                    <TimesheetTableHeader dates={dates} weekTotal={weekTotal} monthlyStatusData={data}/>

                    <TimesheetTableBody taskIds={visibleTasks} dates={dates}/>

                    <tfoot className="table-last-section">
                    <WeeklyTotalsRow dates={dates} weekTotal={weekTotal}/>
                    </tfoot>
                  </table>
                </div>
              </If>

              <If condition={!hasVisibleTasks}>
                <EmptyTimesheetPlaceholder/>
              </If>
            </SoftLoading>
          </div>
        </div>
      </div>
    </div>
  );
};


export { Timesheet };
