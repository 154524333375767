import { normalize } from "normalizr";
import { assign } from "lodash";

import { createDeleteRequest, createGetRequest, createPatchRequest, createPostRequest } from "../api";

import createUserSchema from "./people-schemas";

import PersonEntity from "../../screens/people/PersonEntity";
import { joinRouteParts } from "../utils";

const baseUrl = 'users';

function getPeople(params) {
  return createGetRequest({url: baseUrl, params})
}

function getPeopleSnapshot(params) {
  const url = joinRouteParts(baseUrl, 'snapshot');

  return createGetRequest({url, params})
}

function getUser(userId) {
  const url = joinRouteParts(baseUrl, userId);
  return createGetRequest({url});
}

function getUserEmploymentInformation(userId) {
  const url = joinRouteParts(baseUrl, userId, 'employment_information');
  return createGetRequest({url});
}

function addPeopleAdvanced(data) {
  const url = joinRouteParts(baseUrl, 'create_advanced');

  return createPostRequest({url, data});
}

const patchUser = (userId, data) => {
  const apiUrl = joinRouteParts(baseUrl, userId);

  return createPatchRequest({url: apiUrl, data});
};

export const updateMeRequest = (data) => {
  const apiUrl = joinRouteParts(baseUrl, 'update_me');

  return createPostRequest({url: apiUrl, data});
};

const deleteUser = userId => {
  const apiUrl = joinRouteParts(baseUrl, userId);

  return createDeleteRequest({url: apiUrl});
};

class PeopleAPIResponseParser {

  static convertRecordsIntoSelectCompatible = (response) => {
    return response.map(record => {
      return {...record, label: PersonEntity.parseFullName(record.first_name, record.last_name), value: record.id};
    });
  };

  static parseNormalizedResponse = (response, schemaKey = 'users') => {
    const normalizedPeople = PeopleAPIResponseParser.normalizeResponse(response.data, schemaKey);

    return assign({}, response, normalizedPeople);
  };

  static normalizeResponse = (response, schemaKey = 'users') => {
    const schema = createUserSchema(schemaKey);

    return normalize(response, schema);
  };
}

class PeopleAPIErrorParser {
  // FIXME: TB-160
  private errors: any;
  private errorFields: Array<string>;

  constructor(errorResponse) {
    this.errors = this.extractDataFromResponse(errorResponse);
    this.errorFields = ["email", "general"];
  }

  extractDataFromResponse = errorResponse => {
    if (errorResponse?.status === 500)
      return [];

    return errorResponse?.data || [];
  };

  basicFormHasGenericErrors = () => {
    return this.errorFields.some(errorKey => !!this.errors[errorKey]);
  };

  parseBasicFormGenericErrors = () => {
    const errorsForFields = this.errorFields.map(errorKey => this.errors[errorKey]).filter(Boolean);

    return errorsForFields.flat();
  };

}

export {
  getPeople,
  getPeopleSnapshot,
  getUser,
  getUserEmploymentInformation,
  addPeopleAdvanced,
  patchUser,
  deleteUser,

  PeopleAPIErrorParser,
  PeopleAPIResponseParser
}