import React, {useRef, useImperativeHandle, useState} from 'react';

import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {ActuallyButton, QuestionCircleIcon} from "../buttons";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import styled from "styled-components";
import {If} from "./Conditional";


interface PositionAttributes {
  left?: number;
  top?: number;
}

const ControllableTooltipWrapper = styled.span<PositionAttributes>`
  position:fixed;
  ${props => props.left ? `left: ${props.left}px;` : ''};
  ${props => props.top ? `top: ${props.top}px;` : ''};
  width: 100px;
`

export interface IControllableTooltip {
  show: (element: HTMLElement, content: string | React.ReactElement) => void,
  hide: () => void
}

interface ControllableTooltipState {
  element: HTMLElement | null;
  content: string | React.ReactElement | null;
}

export const ControllableTooltip = React.forwardRef<IControllableTooltip>((_props, ref) => {
  const [state, setState] = useState<ControllableTooltipState>({element: null, content: null});

  useImperativeHandle(ref, () => ({
    show: (element, content) => {
      setState({element, content});
    },
    hide: () => {
      setState({element: null, content: null});
    }
  }));

  const extractDimensions = (element) => {
    if (!element)
      return {};

    const boundingBox = element.getBoundingClientRect();

    const left = boundingBox.x - (boundingBox.width / 2);
    const top = boundingBox.y - 50;

    return {
      top, left
    }
  }

  const dimensions: PositionAttributes = extractDimensions(state.element);

  return (
    <If condition={state.element}>
      <ControllableTooltipWrapper left={dimensions.left} top={dimensions.top}>
        <Tooltip id="overlay-example" style={{opacity: 1}} placement="right-end">
          {state.content}
        </Tooltip>
      </ControllableTooltipWrapper>
    </If>
  );
});

function TooltipOverlay({tooltipContent, children}) {
  const tooltipComponent = (<Tooltip id="TooltipOverlay"> {tooltipContent}</Tooltip>);

  return (
    <OverlayTrigger overlay={tooltipComponent}><span>{children}</span></OverlayTrigger>
  );
}

function TooltipCircle({instructionText}) {
  return (
    <TooltipOverlay tooltipContent={instructionText}>
      <QuestionCircleIcon className="ml-1 mr-1"/>
    </TooltipOverlay>
  );
}

function DialogOverlay({children, isShown, setIsShown}) {
  // FIXME: TB-160
  const target: any = useRef(null);

  const triggerComponent = children.find(({type}) => type === DialogOverlayTrigger);
  const dialogComponent = children.find(({type}) => type === DialogOverlayDialog);

  const triggerAugmented = React.cloneElement(triggerComponent, {onClick: () => setIsShown(!isShown)});

  return (
    <div className="actually-dialog" ref={target}>
      {triggerAugmented}
      <Overlay target={target.current} show={isShown} placement="bottom-end" rootClose
               onHide={() => setIsShown(false)}>
        <Popover id="dialogOverlay" className="actually-popover people-add-popover mt-1">
          {dialogComponent}
        </Popover>
      </Overlay>
    </div>
  )
}

const DialogOverlayTrigger = ({children, variant, onClick = undefined}) => (
  <ActuallyButton variant={variant} onClick={onClick}>{children}</ActuallyButton>
);

const DialogOverlayDialog = ({children}) => (
  <div className="p-2">
    {children}
  </div>
);

DialogOverlay.Trigger = DialogOverlayTrigger;
DialogOverlay.Dialog = DialogOverlayDialog;


export {
  TooltipOverlay,
  TooltipCircle,
  DialogOverlay
};