import { createGetRequest, createPatchRequest } from "../api";
import { joinRouteParts } from "../utils";

const url = 'overall-records';
const overallRecordsListingExportUrl = joinRouteParts(url, "export_list");
const overallRecordsGroupingExportUrl = joinRouteParts(url, "export_grouping");

const getOverallRecordListing = params => createGetRequest({url, ...params});

const getOverallRecordGrouping = params => {
  const apiUrl = joinRouteParts(url, 'grouping');

  return createGetRequest({url: apiUrl, ...params});
};

const getTotalRow = params => {
  const apiUrl = joinRouteParts(url, 'total_row');

  return createGetRequest({url: apiUrl, params});
};

const patchOverallRecord = (entityId, data) => {
  const apiUrl = joinRouteParts(url, entityId);

  return createPatchRequest({url: apiUrl, data});
};


export {
  getOverallRecordListing,
  getOverallRecordGrouping,
  getTotalRow,
  patchOverallRecord,

  overallRecordsListingExportUrl,
  overallRecordsGroupingExportUrl
}