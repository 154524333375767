import React from "react";
import RIGHTS from "../components/rights/right-definitions";

import UserDetails from "./people/user-details/UserDetails";
import { RestrictAccess } from "../components/rights/RestrictAccess";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth/selectors";
import { RouteComponentProps } from "@reach/router";

type HomePropTypes = RouteComponentProps;

export const Home: React.FunctionComponent<HomePropTypes> = () => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <RestrictAccess objectId={currentUser.id} rightName={RIGHTS.viewUser} contextType="users"
                    componentFn={userId => <UserDetails userId={userId}/>}/>
  )
};