import React from "react";

import VirtualList from "../../virtual-list/VirtualList";

import { useSelector } from "react-redux";
import { selectProjectIdsForTaskSelection } from "../../../store/ducks/timesheet/selectors";
import { useHeightMeasurement } from "../../modal/modal-hooks";
import { If } from "../../ui-utility/Conditional";

import EmptyStatePlaceholder from "../../ui-utility/EmptyStatePlaceholder";
import VisibleTaskProjectSection from "./VisibleTaskProjectSection";

import './VisibleTasks.css';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {selectProjectsAsMember} from "../../../store/ducks/mobile/timesheet/selectors";

function VisibleTasksContainer() {
  const visibleProjects = useSelector(selectProjectIdsForTaskSelection);
  const projectsImMemberOf = useSelector(selectProjectsAsMember);

  const projectIds = visibleProjects.filter(projectId => projectsImMemberOf.includes(projectId));

  const {elementRef, height} = useHeightMeasurement();

  return (
    <div className="h-100" ref={elementRef}>
      <If condition={projectIds.length > 0}>
        <VirtualList items={projectIds} height={height} component={VisibleTaskProjectSection}/>
      </If>
      <If condition={projectIds.length === 0}>
        <EmptyStatePlaceholder iconName={'briefcase'}>
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.visibleTasksModal.emptyListPlaceholder}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
    </div>
  )
}


export default VisibleTasksContainer;