import {format, tryParseISO} from "../../../util/dates";
import { getCurrentUserDateLocale } from "../../../intl/intl";

const formatWithCurrentLocale = (date: Date | string, dateFormat) => {
  const parsedDate = tryParseISO(date);
  const locale = getCurrentUserDateLocale();

  return format(parsedDate, dateFormat, {
    locale,
  });
};

export {
  formatWithCurrentLocale
};
