import { useDispatch } from "react-redux";

import { sendRequestSimple } from "../../api/api";
import { patchProject, deleteProject } from "../../api/projects/projects-api";
import { removeProjectAction } from "../../store/ducks/projects/actions";

import ErrorResponse from "../../api/errors";
import projectSagaTypes from "../../store/ducks/projects/types";

// FIXME: TB-160
const useProjectAPI = (): any => {
  const dispatch = useDispatch();

  const extractErrors = (errors) => {
    const extractedErrors = new ErrorResponse(errors).extractAllErrors(['name', 'customer', 'start_date', 'end_date']);

    return {error: extractedErrors};
  };

  const updateProject = async (projectId, payload) => {
    const {response, error} = await sendRequestSimple(patchProject(projectId, payload));

    if (error)
      return extractErrors(error.data);

    dispatch({type: projectSagaTypes.RECEIVE_PROJECT, response});

    return {response, error};
  };

  const removeProject = async projectId => {
    const {error} = await sendRequestSimple(deleteProject(projectId));

    !error && dispatch(removeProjectAction(projectId));

    return {error};
  };


  return {
    updateProject,
    removeProject
  }
};

export default useProjectAPI;