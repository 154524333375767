import React from 'react';
import styled from 'styled-components';
import { LocalizedText, LocalizedMessage } from '../../intl/components/LocalizedMessage';
import { IndentIcon, ActuallyButton } from '../buttons';

interface ControlButtonWrapperProps {
    is_active: number;
}

const ControlButtonWrapper = styled.div<ControlButtonWrapperProps>`
  position: relative;
  && {
    ${props => props.is_active ? 'background-color: #D9E2EC;' : ''}
  }
`

const IndentIconWrapper = styled.span`
    && {
        margin-left: .5rem;
    }
`

const ControlActiveIndicator = styled.span`
    position: absolute;
    top: 2px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
`

interface ControlButtonProps {
    onClick: () => void;
    label: LocalizedText;
    isControlActive?: boolean;
    isControlOpen?: boolean;
    className?: string;
}

export const ControlButton: React.FunctionComponent<ControlButtonProps> = ({ onClick, label, isControlActive, isControlOpen, className }) => (
    <ControlButtonWrapper className={className} is_active={isControlOpen ? 1 : 0}>
        <ActuallyButton variant="transparent" onClick={onClick}>
            <LocalizedMessage text={label} />
            <IndentIconWrapper><IndentIcon /></IndentIconWrapper>
            {isControlActive && <ControlActiveIndicator className="bg-primary" />}
        </ActuallyButton>
    </ControlButtonWrapper>
);