import React from 'react';
import PageHeader from "../../components/text/PageHeader";
import { PlainSectionBlock, Section } from "../../components/layout/LayoutHelpers";
import { RouteComponentProps } from '@reach/router';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

type ReportsProps = RouteComponentProps; 

export const Reports: React.FunctionComponent<ReportsProps> = ({ children }) => (
  <>
    <Section>
      <PlainSectionBlock>
        <PageHeader>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.pageHeader}}/>
        </PageHeader>
      </PlainSectionBlock>
    </Section>

    {children}
  </>
);
