const peopleSagaTypes = Object.freeze({
  FETCH_PEOPLE: 'FETCH_PEOPLE',
  FETCH_ALL_PEOPLE: 'FETCH_ALL_PEOPLE',
  RECEIVE_PEOPLE: 'RECEIVE_PEOPLE',
  RECEIVE_PEOPLE_SNAPSHOT: 'RECEIVE_PEOPLE_SNAPSHOT',
  FETCH_PEOPLE_SUCCESS: 'FETCH_PEOPLE_SUCCESS',
  FETCH_PEOPLE_FAILURE: 'FETCH_PEOPLE_FAILURE',
  ADD_BASIC_PEOPLE: 'ADD_BASIC_PEOPLE',
  ADD_ADVANCED_PEOPLE: 'ADD_ADVANCED_PEOPLE',

  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  FETCH_USER_EMPLOYMENT: 'FETCH_USER_EMPLOYMENT',
  FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_USER_EMPLOYMENT_SUCCESS: 'FETCH_USER_EMPLOYMENT_SUCCESS',
  REMOVE_USER: 'REMOVE_USER'
});

export default peopleSagaTypes;
