import React from "react";

import { DeleteLinkToButton } from "../components/buttons";
import { If } from "../components/ui-utility/Conditional";

import FormStateButton from "./FormStateButton";
import {useSimpleLocalizedMessage} from "../intl/use-localized-message";
import {TRANSLATION_KEYS} from "../intl/intl";
import {LocalizedMessage} from "../intl/components/LocalizedMessage";

// FIXME: TB-160
interface FormActionRowProps {
  showDeleteButton?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  handleDelete?: any;
  handleCancel: any;
  tabIndexStart?: any;
  dirty: boolean;
  isValid: boolean;
  children?: any;
}
const FormActionRow: React.FunctionComponent<FormActionRowProps> = ({
                         showDeleteButton, showCancelButton, showConfirmButton,
                         handleDelete, handleCancel, tabIndexStart,
                         dirty, isValid, children
                       }) => {

  const confirmDeleteButtonLabel = useSimpleLocalizedMessage(
    TRANSLATION_KEYS.commonActions.confirmDeleteButton
  );
  const deleteButtonLabel = useSimpleLocalizedMessage(
    TRANSLATION_KEYS.commonActions.deleteButton
  );

  return (
    <div className="d-flex justify-content-end">
      {children}
      <If condition={showDeleteButton}>
        <DeleteLinkToButton label={deleteButtonLabel}
                            tabIndex={tabIndexStart + 3}
                            confirmLabel={confirmDeleteButtonLabel}
                            onConfirm={handleDelete}/>
      </If>

      <If condition={showCancelButton}>
        <button type="button"
                className="btn btn-link"
                onClick={handleCancel}
                tabIndex={tabIndexStart + 2}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
        </button>
      </If>

      <If condition={showConfirmButton}>
        <FormStateButton dirty={dirty} isValid={isValid} tabIndex={tabIndexStart + 1}>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.saveChangesButton}}/>
        </FormStateButton>
      </If>
    </div>
  );
}

FormActionRow.defaultProps = {
  showDeleteButton: true,
  showCancelButton: true,
  showConfirmButton: true,
  tabIndexStart: 0
};

export default FormActionRow;
