import * as React from 'react';
import { IntlProvider, IntlConfig } from 'react-intl';
import { getCurrentUserLocale, SupportedLocale, getMessages, defaultLocale, setCurrentLocale, convertSupportedLocaleToBrowserLocale, convertBrowserLocaleToSupportedLocale } from '../intl'
import { useKeyEventListener } from '../../util/use-key-event-listener';
import { environment } from '../../config';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectAuthenticationState } from '../../store/ducks/auth/selectors';

interface ActuallyIntlConfig extends Partial<Omit<IntlConfig, 'locale'>> {
    locale: string;
    showKeys: boolean;
    numberLocale?: SupportedLocale;
}

interface ActuallyIntlContext {
    changeLocale: (locale: SupportedLocale) => void;
    locale: SupportedLocale;
    showKeys: boolean;
}

const getIntlConfig = (locale: SupportedLocale): ActuallyIntlConfig => ({
    locale: convertSupportedLocaleToBrowserLocale(locale),
    showKeys: false,
    messages: getMessages(locale),
})

const ActuallyIntlContext = React.createContext<ActuallyIntlContext>({
    changeLocale: () => undefined,
    showKeys: false,
    locale: defaultLocale
})

const changeLocale = (locale: SupportedLocale, setIntlConfig: React.Dispatch<React.SetStateAction<ActuallyIntlConfig>>, shouldReload = true) => {
    setCurrentLocale(locale);
    // FIXME: shouldReload is here to prevent reload loop when logging 
    // We can get of reload if we can manage to fetch the dateLocale from state
    // Currently if app is not reloaded the page where the user is doesn't convert the dates to correct locale
    if(shouldReload) {
        window.location.reload();
    } else {
    setIntlConfig(getIntlConfig(locale))
    }
}

const IntlProviderWrapper: React.FunctionComponent = ({ children }) => {
    const authState = useSelector(selectAuthenticationState);
    const [intlConfig, setIntlConfig] = React.useState(getIntlConfig(getCurrentUserLocale(authState.locale)));
    const {keysPressed, allPressed} = useKeyEventListener(['Control','Shift','L'], {preventInProduction: true});

    React.useEffect(() => {
        if(allPressed) {
            setIntlConfig(currentIntlConfig => ({
                ...currentIntlConfig,
                showKeys: !currentIntlConfig.showKeys
            }))
        }
    }, [keysPressed])

    React.useEffect(() => {
        authState.locale && changeLocale(authState.locale, setIntlConfig, false)
    },[authState.locale])

    const intlContextValue = {
        changeLocale: (locale: SupportedLocale) => changeLocale(locale, setIntlConfig),
        locale: convertBrowserLocaleToSupportedLocale(intlConfig.locale),
        showKeys: environment === 'development' ? intlConfig.showKeys : false
    }

    return (
        <ActuallyIntlContext.Provider value={intlContextValue}>
            <IntlProvider {...intlConfig}>
                {children}
            </IntlProvider>
        </ActuallyIntlContext.Provider>
    )
}

export { IntlProviderWrapper, ActuallyIntlContext }