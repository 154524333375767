class UserDetailsWorkdayLengthEntity {
  // FIXME: TB-160
  workdayLength: any;
  currentMinutes: any;
  currentDate: any;
  nextMinutes: any;
  nextDate: any;

  constructor(workdayLength) {
    this.workdayLength = workdayLength;
    this.currentMinutes = workdayLength && workdayLength.current ? workdayLength.current.minutes : null;
    this.currentDate = workdayLength && workdayLength.current ? workdayLength.current.date : null;
    this.nextMinutes = workdayLength && workdayLength.next ? workdayLength.next.minutes : null;
    this.nextDate = workdayLength && workdayLength.next ? workdayLength.next.date : null;
  }

  hasNext = () => {
    return this.nextMinutes !== null;
  }
}

export default UserDetailsWorkdayLengthEntity;
