import {createSlice, createEntityAdapter} from "@reduxjs/toolkit";

export const calendarAdapter = createEntityAdapter();

const initialState = calendarAdapter.getInitialState();

const calendarSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    receiveCalendarsAction: calendarAdapter.upsertMany,
    removeCalendarAction: calendarAdapter.removeOne,
  }
});

const {actions, reducer} = calendarSlice;

export const {
  receiveCalendarsAction,
  removeCalendarAction,
} = actions;

export default reducer;
