import React from "react";
import { useTimeInput } from "../util/hooks";
import classNames from 'classnames';

import PropTypes from 'prop-types';

const TimeInput = ({minutes, onSave, style, innerRef=undefined, className='', ...props}) => {
  const { onBlur, onFocus, onChange, value, isInvalid } = useTimeInput(minutes, onSave);

  const styles = {
    ...(isInvalid ? {backgroundColor: '#ffe3e3', borderTop: '#ffbdbd'} : {}),
  };

  const classes = classNames(className, 'form-control', 'time-input');

  return (
    <input type="text"
           className={classes}
           style={{...styles, ...style}}
           value={value}
           ref={innerRef}
           {...props}
           onFocus={onFocus}
           onBlur={onBlur}
           onChange={onChange} />
  )
};

TimeInput.propTypes = {
  minutes: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default TimeInput;