import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';

const BaseIcon = ({iconName, className='', ...props}) => {
  const classes = classNames(
    'fa-2x',
    className,
  );

  return (
    <FontAwesomeIcon icon={['fal', iconName]} className={classes} style={{ color: '#14919B' }} {...props} />
  )
};

const BasicInformationIcon = ({...props}) => {
  return (
    <BaseIcon iconName="file-alt" {...props} />
  )
};

const ProjectProgressIcon = ({...props}) => {
  return (
    <BaseIcon iconName="chart-line" {...props} />
  )
};

const ProjectMembersIcon = ({...props}) => {
  return (
    <BaseIcon iconName="user-friends" {...props} />
  )
};

export {
  BasicInformationIcon,
  ProjectProgressIcon,
  ProjectMembersIcon,
};
