import React from "react";
import {useSelector} from "react-redux";

import {selectAll} from "../../../../store/ducks/authorization/auth-group-selectors";

import {AuthGroup} from "../../../../api/authorization/groups/AuthGroupEntity";
import {authGroupTableColumns} from "./authgroup-table";
import {ActuallyTable} from "../../../../components/table/ActuallyTable";
import {DescriptionTxt} from "../../../../components/text/DescriptionTxt";
import EditAuthGroupModal from "../edit/EditAuthGroupModal";


const authGroupTableRowCreator = authGroups => {
  return authGroups.map(authGroup => ({
    name: (
      <EditAuthGroupModal authGroup={authGroup}/>
    ),
    description: (
      <DescriptionTxt text={authGroup.description} cutLimit={255} defaultValue={"-"}/>
    ),
    users: authGroup.users.length || 0
  }));
}

function AuthGroupListing() {
  // FIXME: TB-160
  const authGroups: Array<AuthGroup> = useSelector<any, any>(selectAll);

  const authGropRows = authGroupTableRowCreator(authGroups);

  return (
    <ActuallyTable tableColumns={authGroupTableColumns} tableData={authGropRows}/>
  );
}

export default AuthGroupListing;