import React from 'react';

import PropTypes from 'prop-types';

import BaseSelect from "../../select/BaseSelect";
import {If} from "../../ui-utility/Conditional";

import {AngleDownIcon, AngleUpIcon, TrashIcon} from "../../buttons";
import {makeRecordsLabelSelectCompatible, makeSelectionNullOnLackOfLabeling} from "../../select/select-utils";


import '../ColumnBlock.css';
import {useSimpleLocalizedMessage} from "../../../intl/use-localized-message";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {IntlShape, useIntl} from "react-intl";
import {localizeMessage} from "../../../intl/components/LocalizedMessage";

function useLocalizeColumnOptions(columnOptions, intl: IntlShape) {
  const optionsWithLabel = makeRecordsLabelSelectCompatible(columnOptions, 'name');

  return optionsWithLabel.map(option => {
    return {
      ...option,
      label: localizeMessage(option.label, intl),
    }
  });
}

function ColumnRow({column, columnOptions, isClearable, onColumnSelection, onRemoveColumn, onMoveColumn, onColumnValueChange}) {
  const selectColumnPlaceholder = useSimpleLocalizedMessage(TRANSLATION_KEYS.actualsReport.columns.selectColumn);

  const renderColumnComponent = () => {
    return column.component ? createColumnComponent(column) : null;
  };

  const createColumnComponent = (column) => {
    let Component = column.component;
    return (<div className="column-moving-handle-gutter">
      <Component value={column.value} onChange={(newValue) => onColumnValueChange(column, newValue)}/>
    </div>)
  };

  const intl = useIntl();


  const localizedOptions = useLocalizeColumnOptions(columnOptions, intl);
  const columnValue = makeSelectionNullOnLackOfLabeling(column, 'name');
  const translatedValue = columnValue ? {label: localizeMessage(columnValue.label, intl)} : null;
  
  return (
      <div>
        <div className="column-selection-row mb-2">
          <div className="column-moving-handle">
            <If condition={column.value}>
              <button type="button" className="btn btn-slick mr-1" onClick={() => onMoveColumn(column, true)}>
                <AngleUpIcon light={true} size="lg"/>
              </button>
              <button type="button" className="btn btn-slick" onClick={() => onMoveColumn(column, false)}>
                <AngleDownIcon light={true} size="lg"/>
              </button>
            </If>
          </div>
          <div className="column-selection-handle">
            <BaseSelect value={translatedValue}
                        handleChange={(selectedColumn) => onColumnSelection(selectedColumn)}
                        placeholder={selectColumnPlaceholder}
                        isClearable={isClearable}
                        dropdownOptions={localizedOptions}/>
          </div>
          <div className="column-removal-handle">
            <If condition={column.value}>
              <button type="button" className="btn btn-slick d-inline-block" onClick={() => onRemoveColumn(column)}>
                <TrashIcon/>
              </button>
            </If>
          </div>
        </div>
        {renderColumnComponent()}
      </div>
  );
}

ColumnRow.propTypes = {
  column: PropTypes.object,
  columnOptions: PropTypes.array,

  isClearable: PropTypes.bool,

  onColumnValueChange: PropTypes.func,
  onColumnSelection: PropTypes.func,
  onRemoveColumn: PropTypes.func,
  onMoveColumn: PropTypes.func,
};

ColumnRow.defaultProps = {
  column: {}
};

export default ColumnRow;