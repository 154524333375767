import React, { useState, useCallback, useEffect, useReducer } from "react";

import { OverallReportListing } from "./OverallReportListing";

import {
  getTotalRow
} from "../../../api/report/report-api";

import FilterRegistry from "../../../components/filter/configuration/filter-registry";
import RecordPageFilterBlock
  from "../../../components/filter/configuration/filter-blocks-for-page/RecordPageFilterBlock";

import { PlainSectionBlock } from "../../../components/layout/LayoutHelpers";
import { useFilterSelect } from "../../../components/filter/filter-hooks";
import { sendRequestSimple } from "../../../api/api";
import { RouteComponentProps } from "@reach/router";
import { useCancellableFetch } from "../../../api/hooks";
import { getOverallRecordGrouping } from "../../../api/report/report-api";
import { processGetOverallRecordGrouping, patchExistingRecord } from "../../../api/report/report-api-implementation";
import useRecordAPI from "../../timesheet/record-hooks";
import ColumnSorterEntity from "../../../components/column-sorter/ColumnSorterEntity";
import { receiveRecordsAction, overallReducer, setErrorAction, patchRecordAction, clearErrorAction, selectErrors } from "../../../store/ducks/report/overall/overall-reducer";
import { initialState } from "../../../store/ducks/report/overall/overall-reducer";
import filterSelectors from "../../../store/ducks/filter/filter-selectors";
import useShallowEqualSelector from "../../../store/helpers";
import { OverallReportLockAction } from "./OverallReportLockAction";
import GeneralApiResponseParser from "../../../api/general-api-response-parser";

const DEFAULT_ORDERING = ['-date']

// FIXME: TB-160
interface OverallReportStateTypes {
  ordering: Array<string>;
  selectedColumns: any;
  totalRow: any;
  isLoading: boolean;
}

type OverallReportProps = RouteComponentProps;

export const OverallReport: React.FunctionComponent<OverallReportProps> = () => {
  const filterBlock = FilterRegistry.getFilterBlockForName(RecordPageFilterBlock.blockKey);

  const { fetchOverallRecords } = useRecordAPI();
  const getRecords = useCancellableFetch(getOverallRecordGrouping);
  const currentFilters = useFilterSelect(filterBlock);
  const activeFilters = useShallowEqualSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock))

  // FIXME: TB-160
  const [overallReportState, setOverallReportState] = useState<OverallReportStateTypes>({ ordering: DEFAULT_ORDERING, selectedColumns: {}, totalRow: {}, isLoading: false });
  const [reportState, localDispatch] = useReducer(overallReducer, initialState);
  const [isLocked, setIsLocked] = useState<boolean>(true);

  const fetchTotalRow = useCallback(async () => {
    const filters = filterBlock.formatQueryParameters(currentFilters);

    const { response: totalRow } = await sendRequestSimple(getTotalRow(filters));

    setOverallReportState(prevState => ({ ...prevState, totalRow }));
  }, [filterBlock, currentFilters]);

  const handleRecordChange = async (recordRowId, updatedField, updatedValue) => {
    const { response, error } = await patchExistingRecord(recordRowId, { [updatedField]: updatedValue });

    if (error) {
      localDispatch(setErrorAction(recordRowId, GeneralApiResponseParser.extractErrorData(error)));
      localDispatch(patchRecordAction(recordRowId, { [updatedField]: updatedValue }));
    } else {
      localDispatch(clearErrorAction(recordRowId, updatedField));
      localDispatch(patchRecordAction(recordRowId, { [updatedField]: response?.data[updatedField] }));
    }
  };


  const parseQueryParameters = useCallback((page) => {
    const ordering = ColumnSorterEntity.asQueryString(overallReportState.ordering);
    const filterBlockParams = filterBlock.formatQueryParameters(currentFilters);

    return { ...filterBlockParams, ordering, page: page, ...overallReportState.selectedColumns }
  }, [currentFilters, overallReportState.ordering, overallReportState.selectedColumns, filterBlock]);

  const fetchGroupedOverallHours = useCallback(async (params) => {
    const { response } = await getRecords({ params });
    const processedResponse = processGetOverallRecordGrouping(response.data);
    // FIXME: This is a hack to make the grouping to work the same way as regular endpoint 
    return { ...processedResponse, result: processedResponse.records.map((_record, index: number) => index) };
  }, [getRecords]);

  const fetchOverallHours = useCallback(async (page = 1) => {
    setOverallReportState((prevState) => ({ ...prevState, isLoading: true }))
    const params = parseQueryParameters(page);

    const processed = overallReportState.selectedColumns.activeCount > 0 ? await fetchGroupedOverallHours(params) : await fetchOverallRecords(params);

    localDispatch(receiveRecordsAction(processed));
    setOverallReportState((prevState) => ({ ...prevState, isLoading: false }))

  }, [fetchOverallRecords, fetchGroupedOverallHours, parseQueryParameters,overallReportState.selectedColumns.activeCount]);

  const getOrderingAfterGroupingChange = (prevState: OverallReportStateTypes, selectedColumns) => (
    !!!prevState.selectedColumns.activeColumns?.length ? [] : !!!selectedColumns.activeColumns.length ? DEFAULT_ORDERING : prevState.ordering
  )

  const handleGroupingChange = (selectedColumns) => {
    setOverallReportState((prevState) => ({ ...overallReportState, ordering: getOrderingAfterGroupingChange(prevState, selectedColumns), selectedColumns: selectedColumns }))
  }

  useEffect(() => {
    fetchOverallHours()
  }, [fetchOverallHours]);

  useEffect(() => {
    fetchTotalRow();
  }, [fetchTotalRow]);

  const toggleEditAction = <OverallReportLockAction isLocked={isLocked} setIsLocked={setIsLocked} />;

  const setErrorsForEntity = (entity) => {
    const errors = selectErrors(reportState);
    if (errors[entity.id])
      entity.setErrorsForFields(errors[entity.id]);
  };

  return (
    <PlainSectionBlock>
      <OverallReportListing
        pagination={reportState.pagination}
        onGroupingChange={handleGroupingChange}
        results={reportState.result || []}
        records={reportState.records}
        totalRow={overallReportState.totalRow}
        filterBlock={filterBlock}
        activeFilters={activeFilters}
        cornerAction={toggleEditAction}
        isLocked={isLocked}
        isLoading={overallReportState.isLoading}
        onRecordChange={handleRecordChange}
        selectedColumns={overallReportState.selectedColumns}
        ordering={overallReportState.ordering}
        onOrderingChange={(newOrdering) => setOverallReportState({ ...overallReportState, ordering: newOrdering })}
        onPageChange={(page) => fetchOverallHours(page)}
        setErrorsForEntity={setErrorsForEntity}
      />
    </PlainSectionBlock>
  );
}