import React, {useState, useEffect} from "react";
import {useCountryAPI} from "./country-hooks";

import BaseSelect from "./BaseSelect";

function CountrySelect({onChange, value}) {
  const [options, setOptions] = useState<any>([]);

  const {fetchCountries} = useCountryAPI();

  useEffect(() => {
    const doFetch = async () => {
      const {response} = await fetchCountries();

      response && setOptions(response);
    }

    doFetch();
  }, [fetchCountries]);

  const selected = options.find(record => record.value === value);

  return <BaseSelect handleChange={entity => onChange(entity?.value)} dropdownOptions={options} value={selected}/>
}

export default CountrySelect;