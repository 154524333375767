export interface Customer {
  id: number | string,
  code?: string,
  name?: string
}

class CustomerEntity implements Customer {
  id: number | string;
  code?: string;
  name?: string;

  constructor(code?: string, name?: string, id?: any) {
    this.id = id;
    this.code = code;
    this.name = name;
  }

  static parseHRN(code, name) {
    const codePart = code ? `[${code}] ` : "";

    return `${codePart}${name}`;
  }

  getName = () => CustomerEntity.parseHRN(this.code, this.name);

  exists = () => (!!this.id);

}

export default CustomerEntity;