import React from "react";
import modalDefinitions from "../../../components/modal/modal-definitions";
import {ActuallyButton} from "../../../components/buttons";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PageHeader from "../../../components/text/PageHeader";
import {useCycleTemplateFetching} from './cycle-template-hooks';
import CycleTemplateListing from "./list/CycleTemplateListing";
import ModalComponent from "../../../components/modal/ModalComponent";
import CycleTemplateModal from "./add/CycleTemplateModal";

import {constructModalUrl} from "../../../components/modal/modal-hooks";
import {navigate, RouteComponentProps} from "@reach/router";
import {DefaultBlock} from "../../../components/layout/LayoutHelpers";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";


function AddCycleTemplateButton() {
  const onNavigate = () => {
    const url = constructModalUrl(modalDefinitions.CYCLE_TEMPLATE,'');

    navigate(url);
  }

  return (
      <ActuallyButton variant="link" onClick={onNavigate} className="btn-sm">
        <strong>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.addTemplateLink}}/>
        </strong>
      </ActuallyButton>
  )
}

type CycleTemplateProps = RouteComponentProps;

export const CycleTemplate: React.FunctionComponent<CycleTemplateProps> = () => {
  useCycleTemplateFetching();

  return (
      <Container>
        <Row className="mb-2">
          <Col>
            <PageHeader className="align-middle">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.pageHeader}}/>
            </PageHeader>
          </Col>
          <Col className="text-right">
            <AddCycleTemplateButton/>
          </Col>
        </Row>
        <Row>
          <Col>
            <DefaultBlock>
              <CycleTemplateListing/>
            </DefaultBlock>
          </Col>
        </Row>
        <ModalComponent Component={CycleTemplateModal} modalKey={modalDefinitions.CYCLE_TEMPLATE}/>
      </Container>
  );
}