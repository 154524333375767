import axios from "axios";

import {
  setApiError, setApiPending,
  setApiSuccess, setApiUnAuthorized, setApiValidationError
} from "./store/ducks/api-notifier/api-notifier-actions";
import {setCheckoutRequired} from "./store/ducks/auth/actions";

export const environment = process.env.NODE_ENV || 'development';

const setInterceptors = (store) => {
  let requestPending = 0;

  axios.interceptors.request.use(function (config) {
    requestPending = requestPending + 1;

    store.dispatch(setApiPending(true));

    if (config.method === 'post')
      store.dispatch(setApiSuccess(false));
    return config;
  }, function (error) {
    store.dispatch(setApiError(true));

    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    requestPending = dissmisAndDecrementPendingCount(requestPending, store);

    if (shouldDisplaySuccess(response))
      store.dispatch(setApiSuccess(true));
    return response;
  }, function (error) {
    requestPending = dissmisAndDecrementPendingCount(requestPending, store);

    if (!axios.isCancel(error)) {
      if (statusIsUnAuthorized(error)) {
        store.dispatch(setApiUnAuthorized(true));
      } else if (statusIsValidationError(error)) {
        store.dispatch(setApiValidationError(true));
      } else if (statusIsCheckoutRequired(error)) {
        store.dispatch(setCheckoutRequired(true))
      } else
        store.dispatch(setApiError(true));
    }
    return Promise.reject(error);
  });
};

const statusIsCheckoutRequired = error => {
  const hasCorrectStatusCode = error.response && error.response.status === 307;
  const hasCorrectPayload = error.response && error.response.data?.checkout_required;

  return hasCorrectStatusCode && hasCorrectPayload;
};

const statusIsUnAuthorized = error => {
  return error.response && error.response.status === 401;
};

const statusIsValidationError = error => {
  const statuses = [400,];

  return statuses.includes(error.response.status);
};

const shouldDisplaySuccess = response => {
  const {config = {}, status} = response;

  const methods = ['post', 'put', 'patch', 'delete'];
  const statuses = [200, 201, 202, 204];

  return methods.includes(config.method) && statuses.includes(status);
};

const dissmisAndDecrementPendingCount = (pendingCount, store) => {
  pendingCount = pendingCount - 1;

  if (pendingCount === 0)
    store.dispatch(setApiPending(false));

  return pendingCount;
};


export {
  setInterceptors
}
