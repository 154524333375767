import React from "react";
import PropTypes from 'prop-types';

import PlainTable from "../../../../components/ui-utility/table/PlainTable";
import TimeValue from "../../../../components/TimeValue";
import DateFormatter from "../../../../components/ui-utility/DateFormatter";
import PersonFullName from "../../../../components/people/PersonFullName";
import ProjectName from "../../../../components/project/ProjectName";
import TaskName from "../../../../components/task/TaskName";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";

import {QuoteBlock} from "../../../../components/text/DescriptionTxt";
import {If} from "../../../../components/ui-utility/Conditional";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";

function TotalRecordHours({records}) {
  const totalMinutes = records.reduce((sum, record) => record.minutes + sum, 0);

  return (<><TimeValue minutes={totalMinutes}/>h</>)
}

function SimpleRecordListingRow({record, hideUserColumn}) {
  return (
      <tr>
        <If condition={!hideUserColumn}>
          <td>
            <PersonFullName {...record.user}/>
          </td>
        </If>
        <td>
          <ProjectName name={record.project.name} className="bold" addLink={false}/>
          <span className="pl-1 pr-1"/>
          <TaskName name={record.task.name}/>
        </td>
        <td>
        <span className="pl-2 pr-2">
          <DateFormatter date={record.date}/>
        </span>
        </td>
        <td>
          <QuoteBlock text={record.description} cutLimit={25}/>
        </td>
        <td className="text-right">
          <TimeValue minutes={record.minutes}/>h
        </td>
      </tr>
  )
}

function SimpleRecordListing({records, hideUserColumn}) {
  return (
      <div className="simple-record-container">
        <If condition={!records.length}>
          <EmptyStatePlaceholder iconName="table">
            <p>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.noRecordsAvailableMessage}}/>
            </p>
          </EmptyStatePlaceholder>
        </If>
        <If condition={records.length}>
          <Row>
            <Col className="text-right">
              <label className="bold"><TotalRecordHours records={records}/></label>
            </Col>
          </Row>
          <PlainTable showLines={false} hoverable={true} alignToSides={true} className="simple-record-table">
            <tbody>
            {
              records.map(record => (
                  <SimpleRecordListingRow record={record} hideUserColumn={hideUserColumn} key={record.id}/>
              ))
            }
            </tbody>
          </PlainTable>
        </If>
      </div>
  )

}

SimpleRecordListing.propTypes = {
  records: PropTypes.array,
};

SimpleRecordListing.defaultProps = {
  hideUserColumn: false,
}

export default SimpleRecordListing;