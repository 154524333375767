import React from 'react';
import {
  AccessRightsIcon,
  ActuallyButton,
  AuthorizationGroupsIcon,
  CalendarEditIcon,
  StopWatchIcon,
  ToolsIcon
} from "../../buttons";

import { Dropdown } from "react-bootstrap";

import HeaderLink from "./HeaderLink";
import RequireGlobalRight from "../../rights/RequireGlobalRight";
import RIGHTS from "../../rights/right-definitions";

import '../Header.css';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

// FIXME: TB-160
const ActuallyDropDownToggle = React.forwardRef<any, any>(({ children, onClick, variant }, ref) => (
  <ActuallyButton onClick={onClick} variant={variant}>{children}</ActuallyButton>
));


function AdminMenuButton() {
  const requiredRights = [RIGHTS.manageCalendar, RIGHTS.manageAuthorizationRights];

  return (
    <RequireGlobalRight rightName={requiredRights}>
      <Dropdown drop={"down"}>
        {/*} FIXME: TB-160 {*/}
        <Dropdown.Toggle id="menuButton" as={ActuallyDropDownToggle as any}>
          <ToolsIcon />
          <span className="top-nav-item-label pl-1">
            <LocalizedMessage text={{ id: TRANSLATION_KEYS.topMenu.settings }} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="admin-dropdown popover-shadow">
          <AdminMenu />
        </Dropdown.Menu>
      </Dropdown>
    </RequireGlobalRight>
  )
}

const HeaderLinkWrapper = ({ to, icon, label }) => {
  return (
    <Dropdown.Item as='div'>
      <HeaderLink to={to}>
        <div className="nav-item-row d-flex justify-content-start">
          <span className="nav-icon-gutter">{icon}</span>
          <span className="nav-item-row-label">{label}</span>
        </div>
      </HeaderLink>
    </Dropdown.Item>
  )
}

function AdminMenu() {
  return (
    <div className="actually-nav-section">
      <RequireGlobalRight rightName={RIGHTS.manageCalendar}>
        <HeaderLinkWrapper
          to={"/settings/calendars"}
          icon={<CalendarEditIcon />}
          label={<LocalizedMessage text={{id: TRANSLATION_KEYS.settingsMenuDropdown.calendars}} />} />
      </RequireGlobalRight>

      <RequireGlobalRight rightName={RIGHTS.manageAuthorizationRights}>
        <HeaderLinkWrapper
          to={"/settings/authorization-group"}
          icon={<AuthorizationGroupsIcon />}
          label={<LocalizedMessage text={{id: TRANSLATION_KEYS.settingsMenuDropdown.authGroups}} />} />

        <HeaderLinkWrapper
          to={"/settings/authorization"}
          icon={<AccessRightsIcon />}
          label={<LocalizedMessage text={{id: TRANSLATION_KEYS.settingsMenuDropdown.authRules}} />} />
      </RequireGlobalRight>

      <RequireGlobalRight rightName={RIGHTS.manageCycle}>
        <HeaderLinkWrapper
          to={"/settings/cycle-templates"}
          icon={<StopWatchIcon />}
          label={<LocalizedMessage text={{id: TRANSLATION_KEYS.settingsMenuDropdown.approvalCycles}} />} />
      </RequireGlobalRight>
    </div>
  )
}


export {
  AdminMenuButton,
  AdminMenu
};