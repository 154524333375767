import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { DefaultBlock } from "../../../components/layout/LayoutHelpers";
import { ActuallyButton } from "../../../components/buttons";

import { usePasswordResetApi } from "../../authentication/password-reset/use-password-reset";
import { selectCurrentUser } from "../../../store/ducks/auth/selectors";
import { logout } from "../../../store/ducks/auth/actions";

import PasswordChangeForm from "../../authentication/password-reset/PasswordChangeForm";

import '../Profile.css';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

function PasswordChange() {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const [errors, setErrors] = useState({});

  const {requestForLink, postPassword} = usePasswordResetApi();

  const onSendResetLink = async (event) => {
    event.preventDefault();
    dispatch(logout());

    await requestForLink(currentUser.email);
  };

  const onPasswordChangeSubmit = async (newPassword, reNewPassword, oldPassword) => {
    setErrors({});

    const {error} = await postPassword(newPassword, reNewPassword, oldPassword);

    if (error)
      setErrors(error);
  };

  return (
    <div className="row">

      <div className="col-lg-4 col-xs-12">
        <DefaultBlock withBackground={false}>
          <div className="password-change-disclaimer">
            <div>
              <h6 className="bold">
                <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.changePasswordHeadingText}} />
              </h6>
              <p>
                <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.changePasswordInfoMessage}} />
              </p>
            </div>
            <div className="text-left">
              <ActuallyButton variant="primary" onClick={onSendResetLink}>
                <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.forgotPasswordButton}} />
              </ActuallyButton>
            </div>
          </div>
        </DefaultBlock>
      </div>

      <div className="col-lg-8 col-xs-12">
        <DefaultBlock>
          <PasswordChangeForm showOldPassword={true} onSubmitForm={onPasswordChangeSubmit} errors={errors}/>
        </DefaultBlock>
      </div>
    </div>
  );
}

export default PasswordChange;