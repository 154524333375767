import React, { useState } from "react";

import { CenteredSection, SectionBlock } from "../../components/layout/LayoutHelpers";
import { AppLogo } from "../../components/ui-utility/AppLogo";

import Conditional from "../../components/ui-utility/Conditional";
import PasswordResetForm from "./password-reset/PasswordResetForm";
import LoginForm from "./components/LoginForm";
import styled from 'styled-components';

import './authentication.css';
import { LocaleSelectInput } from "../../intl/components/LocaleSelectInput";

export const TimesheetSection = styled(SectionBlock)`max-width: 450px;`;

const LocaleSection = styled.div`
  width: 450px;
`
const LocaleInputWrapper = styled.div`
  width: 41%;
`

function Login() {
  const [email, setEmail] = useState("");
  const [isResetingPassword, setReseting] = useState(false);

  return (
    <CenteredSection className="mt-5">
      <TimesheetSection>
        <h3 className="text-center mb-2">
          <AppLogo />
        </h3>

        <Conditional condition={isResetingPassword} onTrue={
          <PasswordResetForm email={email} setEmail={setEmail} onGoBack={() => setReseting(false)} />
        } onFalse={
          <LoginForm email={email} setEmail={setEmail} onGoForward={() => setReseting(true)} />
        } />
      </TimesheetSection>
      <LocaleSection>
        <LocaleInputWrapper>
          <LocaleSelectInput />
        </LocaleInputWrapper>
      </LocaleSection>
    </CenteredSection>
  )
}

export default Login;