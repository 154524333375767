import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './LoadingIndicator.css';

function Delay({delay, children}) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setReady(true);
    }, delay);

    return () => clearInterval(interval);
  }, [delay, children]);

  return ready ? children : null;
}

Delay.propTypes = {
  delay: PropTypes.number
};

export default Delay;