import React, { useState } from 'react';

import AddPeopleAdvanced from "./AddPeopleAdvanced";

import { PlusIcon } from "../../buttons";

import { DialogOverlay } from "../../ui-utility/Tooltip";

import '../People-common.css';
import { WithClassName } from '../../../types/WithClassName';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export type AddPeopleProps = WithClassName;

const AddPeople: React.FunctionComponent<AddPeopleProps> = ({ className }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className={className}>
      <DialogOverlay isShown={isShown} setIsShown={setIsShown}>
        <DialogOverlay.Trigger variant="primary">
          <PlusIcon />&nbsp;
          <LocalizedMessage text={{id: TRANSLATION_KEYS.people.addPeopleButton}}/>
      </DialogOverlay.Trigger>
        <DialogOverlay.Dialog>
          <AddPeopleAdvanced onDone={() => setIsShown(false)} />
        </DialogOverlay.Dialog>
      </DialogOverlay>
    </div>
  );
}

export default AddPeople;