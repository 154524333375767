import { all, put } from 'redux-saga/effects';
import { authSagas } from "../ducks/auth/sagas";
import peopleSagaWatchers from "../ducks/people/sagas";
import filterSagaWatches from "../ducks/filter/filter-sagas";
import { timesheetSagas } from "../ducks/mobile/timesheet/sagas";
import { projectSagas } from "../ducks/projects/sagas";
import { paginationSagas } from "../ducks/pagination/pagination-sagas";

import { getCurrentUserRequested } from "../ducks/auth/actions";


export default function* root() {
  yield all([
    ...timesheetSagas,

    ...authSagas,

    ...projectSagas,

    ...peopleSagaWatchers,
    ...filterSagaWatches,
    ...paginationSagas
  ]);

  yield put(getCurrentUserRequested());
}
