import React, { useEffect } from 'react';
import { PlainSectionBlock, Section } from "../../components/layout/LayoutHelpers";
import PageHeader from "../../components/text/PageHeader";
import { ProjectListing } from "./components/ProjectListing";
import FilterRegistry from "../../components/filter/configuration/filter-registry";
import ProjectPageFilterBlock
  from "../../components/filter/configuration/filter-blocks-for-page/ProjectPageFilterBlock";
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useFiltersAndPagination } from '../../components/filter/filter-hooks';
import { paginationKeys } from '../../store/ducks/pagination/pagination-keys';
import { useColumnOrdering } from '../../components/column-sorter/use-column-ordering';
import { selectProjectsResult, selectProjectEntities } from '../../store/ducks/projects/selectors';
import useShallowEqualSelector from "../../store/helpers";
import { fetchProjects } from "../../store/ducks/projects/actions";
import projectSagaTypes from '../../store/ducks/projects/types';
import loadingSelectors from '../../store/ducks/loading/loading-selectors';
import paginationSelectors from '../../store/ducks/pagination/pagination-selectors';
import { changePageAction } from '../../store/ducks/pagination/pagination-actions';
import filterSelectors from '../../store/ducks/filter/filter-selectors';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

type ProjectsProps = RouteComponentProps; 

export const Projects: React.FunctionComponent<ProjectsProps> = () => {
  const filterBlock = FilterRegistry.getFilterBlockForName(ProjectPageFilterBlock.blockKey);
  const activeFilters = useShallowEqualSelector(filterSelectors.selectActiveFiltersForBlock(filterBlock))

  const dispatch = useDispatch();

  const uiParams = useFiltersAndPagination(ProjectPageFilterBlock.blockKey, paginationKeys.PROJECT_LISTING);
  const [ordering, setOrdering] = useColumnOrdering(paginationKeys.PROJECT_LISTING);
  const projectFetchingLoading = useSelector(loadingSelectors.selectLoadingForRequest(projectSagaTypes.FETCH_PROJECTS));
  const pagination = useShallowEqualSelector(paginationSelectors.selectPaginationForPage(paginationKeys.PROJECT_LISTING));
  // FIXME: TB-160
  const results: any = useShallowEqualSelector(selectProjectsResult);
  const records: any = useShallowEqualSelector(selectProjectEntities);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch, ordering, uiParams.page, uiParams.filters]);

  return (
    <>
      <Section>
        <PlainSectionBlock>
          <PageHeader>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.pageHeader}} />
          </PageHeader>
        </PlainSectionBlock>
      </Section>
      <ProjectListing 
      filterBlock={filterBlock}
        // NOTE: just pass the results after TB-200 is fixed 
        results={results}
        pagination={pagination}
        activeFilters={activeFilters}
        records={records  }
        isLoading={projectFetchingLoading?.loading}
        ordering={ordering}
        onPageChange={(newPage) => dispatch(changePageAction(paginationKeys.PROJECT_LISTING, newPage))}
        onOrderingChange={setOrdering}
      /> 
    </>
  );
}