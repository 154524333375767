import projectSagaTypes from "./types";

const addMultipleProjects = (payload) => ({type: projectSagaTypes.ADD_MULTIPLE_PROJECTS, payload});

const fetchProject = projectId => {
  return {
    type: projectSagaTypes.FETCH_PROJECT,
    payload: projectId,
  };
};

const fetchProjectSuccess = normalizedProjectData => {
  return {
    type: projectSagaTypes.FETCH_PROJECT_SUCCESS,
    payload: normalizedProjectData,
  }
};

const fetchProjects = () => {
  return {
    type: projectSagaTypes.FETCH_PROJECTS,
  };
};

const fetchProjectsSuccess = normalizedProjectData => {
  return {
    type: projectSagaTypes.FETCH_PROJECTS_SUCCESS,
    payload: normalizedProjectData,
  };
};

const removeProjectAction = projectId => {
  return {
    type: projectSagaTypes.REMOVE_PROJECT,
    projectId
  };
};


export {
  addMultipleProjects,

  fetchProject,
  fetchProjectSuccess,
  removeProjectAction,

  fetchProjects,
  fetchProjectsSuccess,
}

