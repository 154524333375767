import React, { useState } from "react";
import { If } from "../../../../components/ui-utility/Conditional";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

// FIXME: this is in the wrong place in the code tree. Please refactor.
// FIXME: TB-160
const SimpleManageFlow: any = ({titles: givenTitles, children, onDone}) => {
  // FIXME: TB-160
  const [pageState, setPageState] = useState<any>({
    view: 'list',
    entity: null,
  });

  const handleSelect = entity => {
    setPageState({
      view: 'edit',
      entity: entity,
    });
  };

  const handleDone = () => {
    onDone && onDone();

    setPageState({
      view: 'list',
      entity: null,
    });
  };

  const handleAdd = () => {
    setPageState({
      view: 'add',
      entity: null,
    });
  };

  const titles = {
    ...{
      list: {id: TRANSLATION_KEYS.commonProperties.list},
      add: {id: TRANSLATION_KEYS.commonProperties.add},
      edit: {id: TRANSLATION_KEYS.commonProperties.edit},
      addButton: {id: TRANSLATION_KEYS.commonActions.navigateToAddViewButton},
    },
    ...givenTitles,
  };

  const isFlowPresent = displayName => !!(children.find(child => child.type.displayName === displayName));

  const isAddFlowPresent = isFlowPresent(ADD_FLOW);

  return (
    <>
      <h2 className="text-center">
        {pageState.view === 'list'
          ? <LocalizedMessage text={titles.list} />
          : pageState.entity && pageState.entity?.id
            ? <LocalizedMessage text={titles.edit} />
            : <LocalizedMessage text={titles.add} />
        }
      </h2>

      <hr/>

      {
        React.Children.map(children, child => {
          const displayName = child.type.displayName;
          const view = pageState.view;

          let props;

          if (view === 'add' && displayName === ADD_FLOW) {
            props = {onDone: handleDone};
            return React.cloneElement(child.props.children, props);

          } else if (view === 'edit' && displayName === EDIT_FLOW) {
            props = {onDone: handleDone, entity: pageState.entity};
            return React.cloneElement(child.props.children, props);

          } else if (view === 'list' && displayName === LIST_FLOW) {
            props = {onSelect: handleSelect, onAdd: handleAdd};
            const listElement = React.cloneElement(child.props.children, props);

            return (
              <div>
                <If condition={isAddFlowPresent}>
                  <button type="button" className="btn btn-secondary" onClick={() => handleAdd()}>
                    <LocalizedMessage text={titles.addButton} />
                  </button>
                </If>

                {listElement}
              </div>
            )
          } else
            return child;
        })
      }
    </>
  )
};

const ADD_FLOW = 'AddFlow';
const EDIT_FLOW = 'EditFlow';
const LIST_FLOW = 'ListFlow';

// FIXME: TB-160
const EditFlow = ({children=undefined}) => <></>;
const ListFlow = ({children=undefined}) => <></>;

SimpleManageFlow.Add = ({children=undefined}) => <></>;
SimpleManageFlow.Edit = EditFlow;
SimpleManageFlow.List = ListFlow;

SimpleManageFlow.Add.displayName = ADD_FLOW;
EditFlow.displayName = EDIT_FLOW;
ListFlow.displayName = LIST_FLOW;

export default SimpleManageFlow;
