import { SELECT_TASK, CLEAR_SELECTED_TASK, EDIT_SUCCESSFUL, DELETE_SUCCESSFUL, FETCH, FETCH_SUCCESSFUL, ADD_COMPLETED } from "./actions";

// FIXME: TB-160
const initialState: any = {
  entities: {
    tasks: {}
  },
  result: [],
  isLoading: false,
  selectedTask: null,
};


const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case SELECT_TASK:
      return {
        ...state,
        selectedTaskId: action.payload,
      };

    case ADD_COMPLETED:
      const addedTasks = action.payload;
      const addedTaskIds = addedTasks.map(task => task.id);

      const addedTaskEntities = addedTasks.reduce((acc, task) => {
        // Backend returns only basic info of task. But, we did get to add it,
        // so by definition we can allow also edit. Next time it will be fetched
        // from the backend, the backend will take care of populating the _can_edit_ value.
        acc[task.id] = {...task, 'can_edit': true};
        return acc;

      }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            ...addedTaskEntities,
          },
        },
        result: [...addedTaskIds, ...state.result],
        isLoading: false,
      };


    case EDIT_SUCCESSFUL:
      const oldTask = state.entities.tasks[action.payload.id];
      // This way we can keep the actual_minutes that are not returned from edit.
      const updatedTask = {...oldTask, ...action.payload};

      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            [action.payload.id]: updatedTask,
          },
        },
        isLoading: false,
      };

    case DELETE_SUCCESSFUL:
      const taskId = action.payload;
      const {[taskId]: deletedTaskNotUsed, ...entitiesWithoutDeleted} = state.entities.tasks;

      return {
        ...state,
        result: state.result.filter(tid => tid !== action.payload),
        entities: {
          tasks: entitiesWithoutDeleted,
        },
      };

    case CLEAR_SELECTED_TASK:
      return {
        ...state,
        selectedTaskId: null,
      };

    default:
      return state;
  }
};

export {
  initialState,
}

export default tasksReducer;
