import {createSlice} from "@reduxjs/toolkit";
import {uniq} from 'lodash';

const initialState = {
  userCycles: {},

  cycles: {},
  cycleIds: [],
  cyclesCompletedByApprovers: {},

  cycleIdsByUser: {},
};

const updateCycleState = (state, action) => {
  return {
    ...state,
    ...action.payload
  }
};

const updateCyclesAndIds = (state, action) => {
  const {result, entities} = action.payload;

  return {
    ...state,
    cycles: {
      ...state.cycles,
      ...entities
    },
    cycleIds: uniq([...state.cycleIds, ...result])
  }
}

const addCycle = (state, action) => {
  const {cycle, userCycle} = action.payload;

  const cycles = {...state.cycles, [cycle.id]: cycle};
  const userCycles = {...state.userCycles, [userCycle.id]: userCycle};

  const previousCyclesForUser = state.cycleIdsByUser[userCycle.user] || [];
  const cycleIdsByUser = {...state.cycleIdsByUser, [userCycle.user]: [...previousCyclesForUser, userCycle.id]};

  return {
    ...state,
    cycles,
    userCycles,
    cycleIdsByUser
  }
};

const updateCyclesCompetedByApprovers = (state, action) => {
  const {cycles_completed, approverId} = action.payload;

  return {
    ...state,
    cyclesCompletedByApprovers: {
      ...state.cyclesCompletedByApprovers,
      [approverId]: cycles_completed
    },
  }
}

const cyclesSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {
    updateCycleStateAction: updateCycleState,
    addCycleAction: addCycle,
    updateCyclesAndIdsAction: updateCyclesAndIds,
    updateCyclesCompetedByApproversAction: updateCyclesCompetedByApprovers
  }
});

const {actions, reducer} = cyclesSlice;

const {
  updateCycleStateAction,
  addCycleAction,
  updateCyclesAndIdsAction,
  updateCyclesCompetedByApproversAction
} = actions;

export {
  updateCycleStateAction,
  addCycleAction,
  updateCyclesAndIdsAction,
  updateCyclesCompetedByApproversAction
};

export default reducer;
