import React from "react";

import Select from 'react-select'
import {TRANSLATION_KEYS} from "../../intl/intl";
import {useSimpleLocalizedMessage} from "../../intl/use-localized-message";

// FIXME: TB-160
interface OwnPropTypes {
  dropdownOptions: any;
  handleChange: any;
  value: any;
  name?: string;
  isSearchable?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  tabIndex?: any;
}

type BaseSelectTypes = OwnPropTypes;

const BaseSelect: React.FunctionComponent<BaseSelectTypes> = ({dropdownOptions, handleChange, value, name, isSearchable, isMulti, isClearable, isDisabled, placeholder, tabIndex}) => {
  const selectPlaceholder = useSimpleLocalizedMessage(TRANSLATION_KEYS.actualsReport.filters.selectPlaceholder);
  const placeholderText = placeholder || selectPlaceholder;

  const controlStyles = (provided) => ({
    ...provided,
    borderRadius: '10px',
    border: 0,
    backgroundColor: '#f9fafa',
    boxShadow: 'inset 0 1px 1px #d0e2e2'
  });

  return (
    <div>
      <Select styles={{control: controlStyles}}
              value={value}
              options={dropdownOptions}
              name={name}
              isSearchable={isSearchable}
        // Note: This !!!dropdownOptions is here to make sure that the component has dropdownOptions before usage.
        // Otherwise the select will throw an error. It can be removed after types have been fixed for this
        // component
              isDisabled={isDisabled || !!!dropdownOptions}
              isClearable={isClearable}
              isMulti={isMulti}
              tabIndex={tabIndex}
              placeholder={placeholderText}
              onChange={handleChange}>
      </Select>
    </div>)
}

BaseSelect.defaultProps = {
  isClearable: true,
  isMulti: false
};

export default BaseSelect;