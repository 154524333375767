import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { navigate, RouteComponentProps } from "@reach/router";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import WeekNavigator from "./components/WeekNavigator";
import DailyProgressBar from "./DailyProgressBar";
import MobilePageHeading from "../../../components/header/MobilePageHeading";

import {
  fetchDailyTimesheet,
  pinSingleTaskAction
} from "../../../store/ducks/mobile/timesheet/actions";
import {
  selectTimesheetTotalMinutesForDate,
  selectTimesheetUser
} from "../../../store/ducks/mobile/timesheet/selectors";
import {
  extractPhaseParams,
  formatMobileDailyRoute,
  formatMobileTimesheetProjectPhase, formatMobileTimesheetTaskPhase,
  validOrToday
} from "../../timesheet/daily/timesheet-utility";
import { selectWeeklyTimesheetRows } from "../../../store/ducks/timesheet/selectors";
import { AddNewRecordButton, RecordFlowSelectProject } from "../record-creation-flow/RecordFlowSelectProject";
import { If } from "../../../components/ui-utility/Conditional";
import { DefaultBlock } from "../../../components/layout/LayoutHelpers";
import { useEmployment } from "../../people/user-details/UserDetails";

import RecordFlowSelectTask from "../record-creation-flow/RecordFlowSelectTask";
import MobileTimesheetListingRowConnector from "./components/MobileTimesheetListingRow";
import useTimesheetEditing from "../../timesheet/daily/components/daily-timesheet-hooks";

import './MobileTimesheet.css'
import { useCycleDateCollisionChecker } from "../../timesheet/record-hooks";

// FIXME: TB-160;
interface OwnPropTypes {
  date?: any;
}

type MobileTimesheetFlowProps = OwnPropTypes & RouteComponentProps;

export const MobileTimesheetFlow: React.FunctionComponent<MobileTimesheetFlowProps> = ({date, location}) => {
  const dispatch = useDispatch();

  const queryParams = extractPhaseParams(location?.search);

  const timesheetUser = useSelector(selectTimesheetUser);
  const timesheetDate = useMemo(() => validOrToday(date), [date]);

  const {handleRecordCreation} = useTimesheetEditing();

  const handlePhaseStart = () => {
    navigate(formatMobileTimesheetProjectPhase(timesheetDate));
  };

  const handleProjectPhase = projectId => {
    navigate(formatMobileTimesheetTaskPhase(projectId, timesheetDate));
  };

  const handleTaskPhase = taskId => {
    handleRecordCreation(taskId, date);

    dispatch(pinSingleTaskAction(taskId));

    navigate(formatMobileDailyRoute(timesheetDate));
  };

  useEffect(() => {
    dispatch(fetchDailyTimesheet(timesheetUser.id, timesheetDate, timesheetDate));
  }, [dispatch, timesheetDate, timesheetUser.id]);

  return (
    <>
      <If condition={!queryParams.phase}>
        <MobileTimesheet date={timesheetDate} userId={timesheetUser.id} onCreateRecord={handlePhaseStart}/>
      </If>
      <If condition={queryParams.phase === 'project'}>
        <RecordFlowSelectProject onProjectSelect={handleProjectPhase}/>
      </If>
      <If condition={queryParams.phase === 'task'}>
        <RecordFlowSelectTask onTaskSelect={handleTaskPhase} projectId={queryParams.project_id}/>
      </If>
    </>
  )
}

function MobileTimesheet({date, userId, onCreateRecord}) {
  const visibleTasks = useSelector(selectWeeklyTimesheetRows);
  const datesMinutes = useSelector(selectTimesheetTotalMinutesForDate(date));

  const {workdayLength} = useEmployment(userId);

  const handleDateChange = newDate => navigate(formatMobileDailyRoute(newDate));

  const isDateLocked = useCycleDateCollisionChecker(date);

  return (
    <Container fluid>
      <MobilePageHeading>Timesheet</MobilePageHeading>

      <Row>
        <Col>
          <WeekNavigator currentDate={date} onChangeDate={handleDateChange}/>
        </Col>
      </Row>

      <Row className="mt-2 mb-2">
        <Col>
          <DefaultBlock withBackground={false} isTight={true} className="pr-3 pl-3">
            <DailyProgressBar minutes={datesMinutes} capacityMinutes={workdayLength.currentMinutes}
                              defaultBgColor={'#fff'}/>
          </DefaultBlock>
        </Col>
      </Row>

      <Row>
        <Col>
          {
            visibleTasks.map(taskId => (
              <MobileTimesheetListingRowConnector taskId={taskId} date={date} key={taskId}/>
            ))
          }
        </Col>
      </Row>

      <Row className="mb-5">
        <Col className="text-center">
          <If condition={isDateLocked}>
            This date is locked. Cannot add new records.
          </If>
          <If condition={!isDateLocked}>
            <AddNewRecordButton onClick={onCreateRecord}/>
          </If>
        </Col>
      </Row>

    </Container>
  )
}
