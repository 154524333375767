import React from "react";
import { useDispatch } from "react-redux";

import PropTypes from 'prop-types';

import ToggleSwitch from "../../toggle-switch/ToggleSwitch";
import BooleanFilterField from '../entities/BooleanFilter';
import { useSelector } from "react-redux";
import filterSelectors from "../../../store/ducks/filter/filter-selectors";
import { changeFilterValueAction } from "../../../store/ducks/filter/filter-actions";

function BooleanFilter(props) {
  const dispatch = useDispatch();

  const {filter} = props;
  const filterKey = filter.getFilterKey();

  const filterValue = useSelector(filterSelectors.selectFilterForField(filterKey)) || false;

  const handleChange = isChecked => {
    dispatch(changeFilterValueAction(filterKey, isChecked));
  };

  return (
    <div>
      <ToggleSwitch isChecked={filterValue} onChangeOfValue={handleChange}/>
    </div>
  )
}

BooleanFilter.propTypes = {
  filter: PropTypes.instanceOf(BooleanFilterField).isRequired,
};

export default BooleanFilter;