import React from "react";

import { useModal } from "../../modal/modal-hooks";

import EditProjectFormManager from "./EditProjectFormManager";
import SimpleModal from "../../modal/SimpleModal";
import ModalFrame from "../../modal/ModalFrame";

import {ActuallyButton, PenIcon} from "../../buttons";
import modalDefinitions from "../../modal/modal-definitions";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";


function EditProjectButton({projectId}) {
  const [openModal, closeModal] = useModal(modalDefinitions.EDIT_PROJECT);

  return (
    <>
      <ActuallyButton variant="slick" onClick={openModal} className="btn-sm">
        <PenIcon light={true}/>
      </ActuallyButton>
      <SimpleModal name={modalDefinitions.EDIT_PROJECT}>
        <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.modalHeader}} />}>
          <EditProjectFormManager projectId={projectId} onEditingDone={closeModal}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default EditProjectButton;
