const RIGHTS = {
  addProject: 'add-project',
  viewProject: 'view-project',
  manageProject: 'manage-project',
  manageProjectMembers: 'manage-project-members',
  manageProjectTasks: 'manage-project-tasks',

  addUser: 'add-user',
  viewUser: 'view-user',
  viewUserEmployment: 'view-user-employment',
  editUser: 'edit-user',
  manageUserEmployment: 'manage-user-employment',

  viewRecord: 'view-record',
  manageRecord: 'manage-record',

  viewCustomer: 'view-customer',
  manageCustomer: 'manage-customer',

  manageAuthorizationRights: 'manage-authorization-rights',
  manageCalendar: 'manage-calendar',
  manageUserCycle: 'manage-user-cycle',
  manageCycle: 'manage-cycle'
};


export default RIGHTS;