import styled from "styled-components";

interface TableProps {
  cellPadding?: string;
  hasFooter?: boolean;
}

export const Table = styled.table<TableProps>`
  width: 100%;
  
  thead tr th:first-child {
    border-top-left-radius: 6px;  
  }
  thead tr th:last-child {
    border-top-right-radius: 6px;  
  }
  
  tr th:not(:last-child), tr td:not(:last-child) {
    border-right: 1px solid #F4F3F3;
  }
  
  > thead tr th {
    border-bottom: 1px solid #f4f3f3;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  
  > thead tr th {
    padding: ${props => props.cellPadding ? props.cellPadding : '15px'};
    background-color: white;
  }
  
  > tbody tr td {
    padding: ${props => props.cellPadding ? props.cellPadding : '15px'};
    background-color: white;
  }
  
  > tbody tr:nth-child(even) td {
    background-color: #fafafa;
  }
  
  ${props => props.hasFooter && `
    > tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }
    > tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
  `}
  
  ${props => !props.hasFooter && `
    > tbody tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }
    > tbody tr:last-child td:first-child {
      border-bottom-right-radius: 6px;
    }
  `}
  
  tfoot tr td {
    border-top: 1px solid #f4f3f3;
    background-color: white;  
  }
`;
