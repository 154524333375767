import React, { useState } from "react";
import uuid from "uuid";

import { STEP_STATUSES } from "../../approval-constants";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";


function PhaseActionRow({steps, onShownChange}) {
  const [checkerState, setCheckerState] = useState({pending: true, declined: true, approved: true, blocked: true});

  const onCheckerChanged = (checkerKey) => {
    const newState = {...checkerState, [checkerKey]: !checkerState[checkerKey]};
    const hiddenSteps = extractHiddenStepIds(newState);

    onShownChange(hiddenSteps);
    setCheckerState(newState);
  };

  const extractHiddenStepIds = checkers => {
    let stepIds = [];

    if (!checkers.pending)
      stepIds = stepIds.concat(stepsForGivenStatus(STEP_STATUSES.pending));
    if (!checkers.declined)
      stepIds = stepIds.concat(stepsForGivenStatus(STEP_STATUSES.declined));
    if (!checkers.approved)
      stepIds = stepIds.concat(stepsForGivenStatus(STEP_STATUSES.approved));
    if (!checkers.blocked)
      stepIds = stepIds.concat(stepsForGivenStatus(STEP_STATUSES.blocked));

    return stepIds;
  };

  const stepsForGivenStatus = status => steps.filter(step => step.status === status).map(step => step.id);

  const totalNumberOfPending = stepsForGivenStatus(STEP_STATUSES.pending).length;
  const totalNumberOfDeclined = stepsForGivenStatus(STEP_STATUSES.declined).length;
  const totalNumberOfApproved = stepsForGivenStatus(STEP_STATUSES.approved).length;
  const totalNumberOfBlocked = stepsForGivenStatus(STEP_STATUSES.blocked).length;

  return (
    <Row>
      <Col>
        <Form.Check custom type="checkbox"
                    className="noselect"
                    onChange={() => onCheckerChanged('blocked')}
                    checked={checkerState.blocked}
                    id={`blocked-(${uuid.v4()})`}
                    label={
                      <LocalizedMessage text={{
                        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.blocked,
                        values: {count: totalNumberOfBlocked},
                      }} />
                    }/>
      </Col>
      <Col>
        <Form.Check custom type="checkbox"
                    className="noselect"
                    checked={checkerState.pending}
                    onChange={() => onCheckerChanged('pending')}
                    id={`pending-(${uuid.v4()})`}
                    label={
                      <LocalizedMessage text={{
                        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.pending,
                        values: {count: totalNumberOfPending},
                      }} />
                    }/>
      </Col>
      <Col>
        <Form.Check custom type="checkbox"
                    className="noselect"
                    onChange={() => onCheckerChanged('declined')}
                    checked={checkerState.declined}
                    id={`declined-(${uuid.v4()})`}
                    label={
                      <LocalizedMessage text={{
                        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.declined,
                        values: {count: totalNumberOfDeclined},
                      }} />
                    }/>
      </Col>
      <Col>
        <Form.Check custom type="checkbox"
                    className="noselect"
                    onChange={() => onCheckerChanged('approved')}
                    checked={checkerState.approved}
                    id={`approved-(${uuid.v4()})`}
                    label={
                      <LocalizedMessage text={{
                        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.approved,
                        values: {count: totalNumberOfApproved},
                      }} />
                    }/>
      </Col>
    </Row>
  );
}


export default PhaseActionRow;
