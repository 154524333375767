export interface Tenant {
  id: number;
  name: string;
}

export interface TenantSubscription extends Tenant {
  created_time: string;
  trial_ending_date?: string;
  status: string;
  subscriber_email: string;
}

export enum TenantStatus {
  ALL = "ALL",
  STRIPE = "STRIPE",
  BILL = "BILL",
  TRIAL = "TRIAL",
  EXPIRED = "EXPIRED",
}