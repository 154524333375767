import {
  format,
  parse,
  parseISO,
  isDate,
  isValid,
  isSameDay,
  isSameWeek as _isSameWeek,
  isSameMonth as _isSameMonth,
  isFirstDayOfMonth,
  startOfWeek as originalStartOfWeek,
  startOfISOWeek,
  getWeek,
  lastDayOfMonth,
  startOfMonth,
  eachDayOfInterval,
  addDays,
  getDay,
  isWithinInterval,
  subDays,
  formatDistance,
  formatDistanceToNow,
  differenceInCalendarDays,
  formatISO as _formatISO,
  differenceInCalendarWeeks as _differenceInCalendarWeeks,
  getYear as _getYear,
  differenceInDays as _differenceInDays,
  isWeekend,
  isBefore as _isBefore,
  differenceInHours,
  differenceInMinutes,
  fromUnixTime
} from 'date-fns';
import { getCurrentUserDateLocale } from '../../intl/intl';

export function today() {
  const today = new Date();
  today.setHours(0,0,0,0)
  return today;
}

export function formatISO(date, options?) {
  const defaultOptions = {representation: 'date'}
  return _formatISO(date, {...defaultOptions, ...options});
}

const toISO = sourceDate => {
  const year = sourceDate.getFullYear();
  const month = sourceDate.getMonth() + 1;
  const date = sourceDate.getDate();

  return `${year}-${("0" + month).slice(-2)}-${date}`;
};

export function formatDate(date, formatStr = 'yyyy-MM-dd') {
  return format(date, formatStr);
}

export function getWeekDates(date) {
  const firstOfWeek = startOfWeek(date);
  const lastOfWeek = addDays(firstOfWeek, 6);

  return eachDayOfInterval({start: firstOfWeek, end: lastOfWeek});
}

function startOfWeek(date) {
  const locale = getCurrentUserDateLocale();
  return originalStartOfWeek(date, {locale});
}

function getFirstOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getMonthDates(date) {
  const firstOfMonth = getFirstOfMonth(date);
  const lastOfMonth = lastDayOfMonth(date);

  return eachDayOfInterval({start: firstOfMonth, end: lastOfMonth});
}

function getMonthStartAndEndDates(date) {
  const startDate = getFirstOfMonth(date);
  const endDate = lastDayOfMonth(date);

  return {
    startDate,
    endDate,
  }
}

function isSameWeek(date, anotherDate) {
  const locale = getCurrentUserDateLocale();
  return _isSameWeek(date, anotherDate, {locale});
}

function isSameMonth(date, anotherDate) {
  return _isSameMonth(date, anotherDate);
}


function getFirstOfPreviousMonth(date) {
  const previousMonth = getFirstOfMonth(date);

  return new Date(previousMonth.getFullYear(), previousMonth.getMonth() - 1, 1);
}

function dayRange(startDate, amount) {
  const endDate = addDays(startDate, amount);

  return eachDayOfInterval({start: startDate, end: endDate});
}

function lastDaysRange(amount) {
  const nDaysAgo = subDays(today(), amount);

  return dayRange(nDaysAgo, amount);
}

function differenceInCalendarWeeks(left, right, options) {
  return _differenceInCalendarWeeks(left,right, options)
}

function thisYear() {
  return _getYear(today());
}

function differenceInDays(dateLeft, dateRight) {
  return _differenceInDays(dateLeft, dateRight);
}

function isBefore(date, dateToCompare) {
  return _isBefore(date, dateToCompare);
}

function tryParseISO(date) {
  if (isDate(date))
    return date;
  return parseISO(date);
}

export {
  format,
  isValid,
  startOfWeek,
  startOfISOWeek,
  startOfMonth,
  getWeek,
  getFirstOfMonth,
  getFirstOfPreviousMonth,
  lastDayOfMonth,
  isSameDay,
  isSameWeek,
  isDate,
  getDay,
  addDays,
  parse,
  parseISO,
  toISO,
  isWithinInterval,
  getMonthDates,
  getMonthStartAndEndDates,
  dayRange,
  formatDistance,
  formatDistanceToNow,
  differenceInCalendarDays,
  subDays,
  lastDaysRange,
  isSameMonth,
  differenceInCalendarWeeks,
  thisYear,
  isWeekend,
  differenceInDays,
  isBefore,
  tryParseISO,
  isFirstDayOfMonth,
  eachDayOfInterval,
  fromUnixTime,
  differenceInHours,
  differenceInMinutes,
};
