import React from "react";

import {useSelector} from "react-redux";

import cycleTemplateSelectors from "../../../../store/ducks/cycle/cycle-template-selectors";

import CycleTemplateForm from "./CycleTemplateForm";

import {useCycleTemplateFetching} from "../cycle-template-hooks";


function CycleTemplateFormManager({onDone, templateId}) {
  const template = useSelector(cycleTemplateSelectors.selectTemplateForId(templateId));

  useCycleTemplateFetching();

  return (
      <CycleTemplateForm onDone={onDone} template={template}/>
  )
}

export default CycleTemplateFormManager;