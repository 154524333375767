import React from "react";
import {Link} from "@reach/router";
import modalDefinitions from "../../../../../components/modal/modal-definitions";

import {PersonFullNameSelector} from "../../../../../components/people/PersonFullName";
import {ProjectNameSelector} from "../../../../../components/project/ProjectName";
import {AngleRightIcon} from "../../../../../components/buttons";
import {constructModalUrl} from "../../../../../components/modal/modal-hooks";
import {canBeApproved} from "../../approval-constants";
import {If} from "../../../../../components/ui-utility/Conditional";

import StepStatusBadge from "./StepStatusBadge";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";

function ProjectPersonNameSelector({projectId, userId}) {
  return (
    <div>
      <ProjectNameSelector projectId={projectId} addLink={false}/>
      <span className="mr-2 ml-2">
        <AngleRightIcon light={true}/>
      </span>
      <PersonFullNameSelector userId={userId} addLink={false}/>
    </div>
  )
}

function ProjectPhaseStep({step}) {
  const url = constructModalUrl(modalDefinitions.APPROVE_USER_PROJECT, {"modal.stepId": step.id});

  return (
    <tr className="phase-step">
      <td>
        <ProjectPersonNameSelector projectId={step.project} userId={step.user_cycle_phase__user}/>
      </td>
      <td>
        <StepStatusBadge status={step.status}/>
      </td>
      <td>
        <If condition={canBeApproved(step.status)}>
          <Link to={url}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.reviewButton}}/>
          </Link>
        </If>
      </td>
    </tr>
  );
}


export default ProjectPhaseStep;
