import React from "react";


const BlockContentWithIcon = ({icon, children}) => {
  return (
    <div className="d-flex align-items-center h-100">
      <div className="p-1 pr-4 pl-1">
        {icon}
      </div>

      <div className="h-100 align-items-start flex-grow-1">
        {children}
      </div>
    </div>
  )
};

export default BlockContentWithIcon;
