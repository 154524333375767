import React from "react";
import {useSelector} from "react-redux";

import cycleTemplateSelectors from "../../../../store/ducks/cycle/cycle-template-selectors";
import modalDefinitions from "../../../../components/modal/modal-definitions";

import DateFormatter from "../../../../components/ui-utility/DateFormatter";
import TimeModeConverter from "./TimeModeConverter";

import {constructModalUrl} from "../../../../components/modal/modal-hooks";
import {navigate} from "@reach/router";
import styled from "styled-components";

const CycleTemplateTrWrapper = styled.tr`
  cursor: pointer;
`

function CycleTemplateTr({templateId}) {
  const template = useSelector(cycleTemplateSelectors.selectTemplateForId(templateId));

  const onRowClick = () => {
    const url = constructModalUrl(modalDefinitions.CYCLE_TEMPLATE, {"modal.templateId": templateId});

    navigate(url);
  }

  return (
      <CycleTemplateTrWrapper onClick={onRowClick}>
        <td><DateFormatter date={template.start_date}/></td>
        <td><DateFormatter date={template.end_date}/></td>
        <td><TimeModeConverter duration={template.duration} unit={template.unit}/></td>
      </CycleTemplateTrWrapper>
  );
}

export default CycleTemplateTr;