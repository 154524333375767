import * as React from 'react';
import {LockIcon} from "../../../components/buttons";
import styled from 'styled-components';

const StyledLockIcon = styled(LockIcon)`
  color: #BCCCDC;
`

const TaskListNameButton = ({task}) => {
  return (
    <div>
      {!task.is_active && <span className="mr-1"><StyledLockIcon/></span>}
      {task.name}
    </div>
  )
};

export default TaskListNameButton;
