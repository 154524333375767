import * as React from "react";
import { TooltipOverlay } from "../ui-utility/Tooltip";
import { If } from "../ui-utility/Conditional";

import './Text.css';

function truncate(text: string, cutLimit?: number) {
  if (cutLimit && text?.length > cutLimit)
    return text.substring(0, cutLimit) + '...';
  return text;
}

interface DescriptionTxtProps {
  text: string;
  defaultValue?: string;
  cutLimit?: number;
}
const DescriptionTxt: React.FunctionComponent<DescriptionTxtProps> = ({ text, defaultValue, cutLimit }) => {
  const truncated = truncate(text, cutLimit);

  if (cutLimit && truncated?.length < text?.length) {
    return (
      <TooltipOverlay tooltipContent={text}>
        <span className="font-italic"> {truncated}</span>
      </TooltipOverlay>
    );
  }

  return (
    <span className="font-italic">{truncated || defaultValue}</span>
  )
};

interface QuoteBlockProps {
  text: string;
  cutLimit?: number;
}

const QuoteBlock: React.FunctionComponent<QuoteBlockProps> = ({ text, cutLimit }) => {
  return (
    <If condition={text}>
      <span className="quote-block">
        <DescriptionTxt text={text} cutLimit={cutLimit} />
      </span>
    </If>
  );
}

export {
  DescriptionTxt,
  QuoteBlock
};
