import React from "react";
import { HomeIcon } from "../../../components/icons";
import {
  ActuallyButton,
  AngleLeftIcon,
  AngleRightIcon,
} from "../../../components/buttons";
import DateShort from "../../../components/ui-utility/dates/DateShort";
import styled from 'styled-components';
import DayOfWeek from "../../../components/ui-utility/dates/DayOfWeek";

const BaseButton = ({children, ...props}) => {
  return (
    <ActuallyButton variant="light" {...props}>
      {children}
    </ActuallyButton>
  )
};

export function WeeklyTimesheetPreviousWeekButton({...props}) {
  return (
    <BaseButton {...props}>
      <AngleLeftIcon/>
    </BaseButton>
  )
}

export function WeeklyTimesheetNextWeekButton({...props}) {
  return (
    <BaseButton {...props}>
      <AngleRightIcon/>
    </BaseButton>
  )
}

export function WeeklyTimesheetCurrentWeekButton({...props}) {
  return (
    <BaseButton {...props}>
      <HomeIcon/>
    </BaseButton>
  )
}

function WeeklyTimesheetRangeButton({onClick, startDate, endDate, ...props}) {
  return (
    <BaseButton {...props}>
      <DateShort date={startDate}/> - <DateShort date={endDate}/>
    </BaseButton>
  );
}

function DailyTimesheetButton({onClick, startDate, ...props}) {
  return (
    <BaseButton {...props}>
      <DayOfWeek date={startDate}/> <DateShort date={startDate}/>
    </BaseButton>
  );
}

const StyledWeeklyTimesheetRangeButton = styled(WeeklyTimesheetRangeButton)`
  min-width: 200px;
`;

const StyledDailyTimesheetButton = styled(DailyTimesheetButton)`min-width: 200px`;

export {
  StyledWeeklyTimesheetRangeButton as WeeklyTimesheetRangeButton,
  StyledDailyTimesheetButton as DailyTimesheetButton,

}