import React from "react";
import { startOfWeek, today } from "../../../util/dates";
import { addDays } from "date-fns";

import PropTypes from 'prop-types';
import {
  WeeklyTimesheetCurrentWeekButton,
  WeeklyTimesheetNextWeekButton,
  WeeklyTimesheetPreviousWeekButton, WeeklyTimesheetRangeButton
} from "./WeeklyNavigatorButtons";
import classNames from "classnames";

const WeeklyTimesheetNavigator = ({startDate, onChangeStartDate, className}) => {
  const todaysDate = today();
  const endDate = addDays(startDate, 6);

  const classes = classNames(className, 'btn-group');

  return (
    <div className={classes} role="group">
      <WeeklyTimesheetPreviousWeekButton onClick={e => onChangeStartDate(addDays(startDate, -7))} />

      <WeeklyTimesheetCurrentWeekButton onClick={e => onChangeStartDate(startOfWeek(todaysDate))} />

      <WeeklyTimesheetRangeButton startDate={startDate} endDate={endDate} />

      <WeeklyTimesheetNextWeekButton onClick={e => onChangeStartDate(addDays(startDate, 7))} />
    </div>
  )
};

WeeklyTimesheetNavigator.propTypes = {
  // FIXME: TB-160 -- Date
  // startDate: PropTypes.objectOf(Date),
  onChangeStartDate: PropTypes.func.isRequired,
};

export default WeeklyTimesheetNavigator;