import React from 'react';
import PropTypes from 'prop-types';

import DateShort from "./dates/DateShort";

import { isValid, parseISO } from "../../util/dates";

const DateFormatter = ({date, placeholder}) => {
  const dateObj = parseISO(date);

  if (isValid(dateObj))
    return <DateShort date={dateObj}/>;

  return <span>{placeholder}</span>;
};

DateFormatter.propTypes = {
  date: PropTypes.string,
};

DateFormatter.defaultProps = {
  placeholder: '-',
};


export default DateFormatter;