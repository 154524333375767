import React from 'react';
import { useSelector } from "react-redux";
import { ActuallyButton, BlockButton, PlusIcon } from "../../../components/buttons";

import { selectProjectForId, selectProjectIdsForTaskSelection } from "../../../store/ducks/timesheet/selectors";

import MobilePageHeading from "../../../components/header/MobilePageHeading";
import ProjectName from "../../../components/project/ProjectName";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RecordEntity from "../../../api/report/RecordEntity";
import { If } from "../../../components/ui-utility/Conditional";
import { TimetrackingNotAllowed } from "./RecordFlowSelectTask";


function AddNewRecordButton({onClick}) {
  return (<ActuallyButton variant="light" className="btn-block" onClick={onClick}>Add more <PlusIcon/></ActuallyButton>)
}

function SelectProjectRow({projectId, onProjectSelect}) {
  const project = useSelector(selectProjectForId(projectId));

  const timeTrackingAllowedByProject = RecordEntity.timeTrackingAllowedByProject(project);

  const handleOnClick = () => {
    timeTrackingAllowedByProject && onProjectSelect(project.id);
  };

  return (
    <BlockButton onClick={handleOnClick}>
      <If condition={!timeTrackingAllowedByProject}>
        <TimetrackingNotAllowed/>
      </If>
      <ProjectName {...project} addLink={false}/>
    </BlockButton>
  )
}

function RecordFlowSelectProject({onProjectSelect}) {
  const projectIds = useSelector(selectProjectIdsForTaskSelection);

  return (
    <Container fluid>
      <MobilePageHeading showBackBtn={true}>Add a time entry</MobilePageHeading>
      <Row>
        <Col><p>First select a project</p></Col>
      </Row>
      {projectIds.map(projectId => (
        <Row key={projectId} className="mb-2">
          <Col>
            <SelectProjectRow projectId={projectId} onProjectSelect={onProjectSelect}/>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export {
  AddNewRecordButton,
  RecordFlowSelectProject
};