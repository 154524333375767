import React, {useEffect} from "react";
import {today, formatISO} from "../../../../util/dates";
import {If} from "../../../../components/ui-utility/Conditional";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";
import {useSelector} from 'react-redux';
import {
  selectWorkdayLengthsByUser
} from '../../../../store/ducks/workday-length/workday-length-selectors';
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {ActuallyTable} from "../../../../components/table/ActuallyTable";
import {createWorkdayLengthTableData, WorkdayLengthTableColumns} from "./workdaylength-table-configuration";

export const ListContainer = ({api, userId, onSelect = () => undefined}) => {
  const {fetchWorkdayLengths} = api;

  useEffect(() => {
    fetchWorkdayLengths();
  }, [fetchWorkdayLengths]);

  const workdayLengths = useSelector(selectWorkdayLengthsByUser(Number(userId)));

  const sortedWorkdayLengths = workdayLengths.sort((a:any, b:any) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  const fromNow = sortedWorkdayLengths.filter((workdayLength: any) => workdayLength.date <= formatISO(today()));
  const activeWorkdayLength: any = fromNow.length > 0 ? fromNow[0] : {};

  const hasWorkdaysDefined = workdayLengths.length > 0;

  return (
    <>
      <If condition={hasWorkdaysDefined}>
        <ActuallyTable
          tableColumns={WorkdayLengthTableColumns}
          tableData={createWorkdayLengthTableData(workdayLengths, onSelect, activeWorkdayLength)}/>
      </If>

      <If condition={!hasWorkdaysDefined}>
        <EmptyStatePlaceholder iconName={'clock'}>
          <LocalizedMessage
            text={{id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.emptyListMessage}}/>
        </EmptyStatePlaceholder>
      </If>
    </>
  )
};