import React from "react";
import PropTypes from 'prop-types';

import PaginationController from './PaginationController';
import paginationSelectors from "../../store/ducks/pagination/pagination-selectors";

import { AngleLeftIcon, AngleRightIcon, DoubleAngleLeftIcon, DoubleAngleRightIcon } from "../buttons";
import { useDispatch } from "react-redux";
import { changePageAction } from "../../store/ducks/pagination/pagination-actions";
import { noop } from "lodash";

import './Pagination.css';
import useShallowEqualSelector from "../../store/helpers";
import styled from "styled-components";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

interface PaginationArrowGroup {
  disabled: number;
}

const PaginationArrowGroup = styled.div<PaginationArrowGroup>`
    ${props => props.disabled ? `
      pointer-events: none;
      position: relative;
      transform: translateX(0);
      opacity: 0.5;
      ` : ''
    };
`

function PaginationAsSelector({pageKey, onCommitPageChange}) {
  const dispatch = useDispatch();
  const pagination = useShallowEqualSelector(paginationSelectors.selectPaginationForPage(pageKey));

  const handlePageChange = newPageNo => {
    dispatch(changePageAction(pageKey, newPageNo));
    onCommitPageChange(newPageNo);
  };

  return <Pagination onCommitPageChange={handlePageChange} data={pagination}/>;
}

PaginationAsSelector.propTypes = {
  page: PropTypes.string,
  onCommitPageChange: PropTypes.func
};

PaginationAsSelector.defaultProps = {
  onCommitPageChange: noop
};

export interface PaginationData {
  current: number;
  totalPages: number;
  count: number;
  itemsPerPage: number;
}

interface PaginationProps {
  data: PaginationData;
  onCommitPageChange?: (newPageNumber: number) => void;
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({data, onCommitPageChange}) =>{
  const paginationController = new PaginationController(data);

  const onPageChange = paginationFn => () => {
    const newPageNumber = paginationFn();
    return onCommitPageChange && onCommitPageChange(newPageNumber);
  };

  return (
    <PaginationImpl onNextPage={onPageChange(paginationController.goToNextPage)}
                    onPrevPage={onPageChange(paginationController.goToPreviousPage)}
                    onFirstPage={onPageChange(paginationController.goToFirstPage)}
                    onLastPage={onPageChange(paginationController.goToLastPage)}
                    count={data.count}
                    itemsPerPage={data.itemsPerPage}
                    current={data.current}
                    totalPages={data.totalPages}/>
  )
}

function PaginationImpl({onNextPage, onPrevPage, onFirstPage, onLastPage, current, totalPages, count, itemsPerPage}) {
  const template = (
    <div className="pagination-main-wrapper">
      <div className="pagination-wrapper">
        <PaginationArrowGroup className="pagination-btn-group" disabled={current === 1 ? 1 : 0}>
          <button className="btn btn-pagination" onClick={onFirstPage}>
            <DoubleAngleLeftIcon/>
          </button>
          <button className="btn btn-pagination" onClick={onPrevPage}>
            <AngleLeftIcon/>
          </button>
        </PaginationArrowGroup>
        <div className="pagination-label">
          <span>
            <LocalizedMessage text={{
              id: TRANSLATION_KEYS.pagination.pageOfTotal,
              values: {currentPage: current, totalPages}}
            } />
          </span>
        </div>
        <PaginationArrowGroup className="pagination-btn-group" disabled={current === totalPages ? 1 : 0}>
          <button className="btn btn-pagination" onClick={onNextPage}>
            <AngleRightIcon/>
          </button>
          <button className="btn btn-pagination" onClick={onLastPage}>
            <DoubleAngleRightIcon/>
          </button>
        </PaginationArrowGroup>
      </div>
      <div className="pagination-total-info ml-3">
        <small className="text-muted">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.pagination.totalRows, values: {totalRows: count}}} />
        </small>
      </div>
    </div>
  );

  return count > 0 ? template : <></>;
}

PaginationImpl.propTypes = {
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  onFirstPage: PropTypes.func,
  onLastPage: PropTypes.func,

  count: PropTypes.number,
  itemsPerPage: PropTypes.number,
  current: PropTypes.number,
  totalPages: PropTypes.number
};


export {
  Pagination as default,
  PaginationAsSelector
};