import React from 'react';

import { useSelector } from "react-redux";

import { useModalAsRoute } from '../../../../components/modal/modal-hooks';
import { selectCurrentUser } from "../../../../store/ducks/auth/selectors";
import { extractUrlParameter } from "../../daily/timesheet-utility";

import modalDefinitions from '../../../../components/modal/modal-definitions';

import SimpleModal from '../../../../components/modal/SimpleModal';
import ModalFrame from "../../../../components/modal/ModalFrame";
import ApproveForm from "../approve/ApproveForm";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";


function ApproverModal({location}) {
  const cycleId = extractUrlParameter(location, 'modal.cycleId');

  const {isOpen, onClose} = useModalAsRoute(modalDefinitions.APPROVE_CYCLE, location);
  const currentUser = useSelector(selectCurrentUser);

  return (
    <SimpleModal manualControl={isOpen} onClose={onClose}>
      <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.header}} />}>
        <ApproveForm cycleId={cycleId} user={currentUser}/>
      </ModalFrame>
    </SimpleModal>
  )
}

export default ApproverModal;
