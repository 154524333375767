import React from "react";
import { useDispatch } from "react-redux";

import PropTypes from 'prop-types';

import Datepicker from "../../datepicker/Datepicker";
import filterSelectors from "../../../store/ducks/filter/filter-selectors";
import { changeFilterValueAction } from "../../../store/ducks/filter/filter-actions";
import { DateRangeFilterField } from "../entities/DateFilter";
import useShallowEqualSelector from "../../../store/helpers";

function DateRangeFilter({filter}) {
  const dispatch = useDispatch();

  const filterKey = filter.getFilterKey();
  const selectedRange = useShallowEqualSelector(filterSelectors.selectFilterForField(filterKey)) || {__lte: "", __gte: ""};

  const onDateChange = key => (newDate) => {
    dispatch(changeFilterValueAction(filterKey, {...selectedRange, [key]: newDate}));
  };

  return (
    <div className="datepicker-wrapper datepicker-range-wrapper">
      <Datepicker onDateChange={onDateChange('__gte')} selected={selectedRange.__gte}/>
      <span className="p-1">-</span>
      <Datepicker onDateChange={onDateChange('__lte')} selected={selectedRange.__lte}/>
    </div>
  )
}

DateRangeFilter.propTypes = {
  filter: PropTypes.instanceOf(DateRangeFilterField).isRequired,
};

export default DateRangeFilter;