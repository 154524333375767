import { schema } from "normalizr";


const createProjectSchema = () => {
  const customerSchema = new schema.Entity('customers');

  return new schema.Entity('projects', {customer: customerSchema});
};

export {
  createProjectSchema,
};
