import React from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-date-picker";

import OpenCalendarIcon from "./OpenCalendarIcon";
import ClearCalendarValueIcon from "./ClearCalendarValueIcon";

import { Controller } from "react-hook-form";
import { toISO } from "../../util/dates";

import "./ActuallyDatePicker.css";

const includesDate = (dates, date) => {
  const isoDates = dates.map(d => toISO(d));
  const isoDate = toISO(date);

  return isoDates.includes(isoDate);
};

// FIXME: TB-160
interface BaseDatePickerProps {
  disabledDates?: Array<any>;
  onChange: any;
  value: any;
  className?: string;
  tileDisabled?: any;
}

export function BaseDatePicker({disabledDates = [], onChange, value, className, tileDisabled}: BaseDatePickerProps) {
  const excludeDates = ({date}) => {
    return includesDate(disabledDates, date) || (tileDisabled && tileDisabled(date));
  };

  return (<DatePicker tileDisabled={excludeDates}
                      clearIcon={<ClearCalendarValueIcon />}
                      className={className}
                      onChange={onChange} value={value}
                      calendarIcon={<OpenCalendarIcon/>}/>);
}

const ActuallyDatePicker = ({name, control, required = false, ...props}) => {
  // FIXME: TB-160
  const disabledDates: any = props.disabledDates || []
  const component = BaseDatePicker(disabledDates);

  return (
    <Controller as={component}
                control={control}
                name={name}
                {...props}
                rules={{required}}
    />
  );
};

ActuallyDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  // FIXME: TB-160 Date
  // disabledDates: PropTypes.arrayOf(Date),
};

export default ActuallyDatePicker;
