import React from "react";
import FormStateButton from "../../../forms/FormStateButton";
import {useForm} from "react-hook-form";
import {TextArea} from "../../../components/forms/TextArea";
import {hasActualCharacters} from "../../../forms/validators";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import useTaskAPI from "./tasks-api-hook";
import {FormElement, SimpleFormBuilder} from "../../../components/forms/SimpleFormBuilder";

export const AddTask = ({projectId = undefined, onDone = () => undefined}) => {
  const {addTasks} = useTaskAPI(projectId);

  const {register, handleSubmit, formState} = useForm({
    mode: 'onChange',
  });

  const {dirty, isValid} = formState;

  const onSubmit = async formData => {
    const {name, budgeted_hours} = formData;

    await addTasks({name, project: projectId, budgeted_minutes: budgeted_hours ? budgeted_hours * 60 : null});

    onDone();
  };

  const formElements: Array<FormElement> = [
    {
      label: (<LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.taskNamesFieldLabel}}/>),
      input: (<><TextArea name="name"
                          innerRef={register({required: true, validate: hasActualCharacters})}
                          autoFocus
                          className="form-control"
                          tabIndex={1}
                          minRows={3}
                          maxRows={10}/>
        <div className="pt-1 input-extra">
          <LocalizedMessage
            text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.taskNamesFieldHelpText}}/>
        </div>
      </>)
    },
    {
      label: (<LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.tasksBudgetedHoursFieldLabel}}/>),
      input: (<><input ref={register}
                       type="number"
                       name="budgeted_hours"
                       className="form-control col-sm-6"
                       tabIndex={2}/>
        <div className="input-extra">
          <LocalizedMessage
            text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.tasksBudgetedHoursFieldHelpText}}/>
        </div>
      </>)
    }
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>

        <SimpleFormBuilder formElements={formElements}/>

        <div className="d-flex justify-content-end">
          <button type="button"
                  className="btn btn-link"
                  onClick={() => onDone()}
                  tabIndex={4}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
          </button>

          <FormStateButton dirty={dirty} isValid={isValid} tabIndex={3}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.saveChangesButton}}/>
          </FormStateButton>
        </div>
      </form>
    </>
  );
};
