import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/ducks/auth/selectors";

function Conditional({condition, onTrue, onFalse}) {
  return !!(condition) ? onTrue : onFalse;
}

export const If = ({condition, children}) => {
  return !!condition ? children : <></>;
};

export const HideFromSelf = ({id, children}) => {
  const isSelf = useIsSelf(id);

  return (
    <If condition={!isSelf}>
      {children}
    </If>
  )
};

export const useIsSelf = (id) => {
  const currentUser = useSelector(selectCurrentUser);

  return currentUser.id === id;
}

Conditional.propTypes = {
  condition: PropTypes.any,
  onTrue: PropTypes.any,
  onFalse: PropTypes.any
};

Conditional.defaultProps = {
  onFalse: null
};

export default Conditional;
