import { createPatchRequest, createGetRequest } from "../api";
import { joinRouteParts } from "../utils";
import { UpdateTenantProps } from "../../store/ducks/admin/tenants/tenant-actions";

const baseUrl = "/admin/tenants"

export const getTenants = () => createGetRequest({url: baseUrl});

export const updateTenant = (params: UpdateTenantProps) => {
  return createPatchRequest({
    url: joinRouteParts(baseUrl, params.id, "update_subscription"),
    data: params
  });
}

export const getTenantRights = (tenantId: number) => createGetRequest({url: joinRouteParts(
  baseUrl, tenantId, 'rights'
)});

export const getTenantStats = (tenantId: number) => createGetRequest({url: joinRouteParts(
  baseUrl, tenantId, 'stats'
)});