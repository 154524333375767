import React from 'react';

import ColumnRow from './components/ColumnRow';

import './ColumnBlock.css';

// FIXME: TB-160
interface OwnPropTypes {
  chosenColumns: Array<any>;
  uiColumns?: Array<any>;
  availableColumns: Array<any>;
  isClearable?: boolean;

  handleColumnValueChange: any,
  handleColumnDeletion: any,
  handleColumnMoving: any,
  handleColumnSelection: any
}

type ColumnBlockTypes = OwnPropTypes;

const ColumnBlock: React.FunctionComponent<ColumnBlockTypes> = (props) => {

  const {
    chosenColumns,
    availableColumns,
    isClearable,
    handleColumnValueChange,
    handleColumnDeletion,
    handleColumnMoving,
    handleColumnSelection
  } = props;

  return <>
    {chosenColumns.map((column, index) => (
      <ColumnRow key={column.identifier}
                 column={column}
                 columnOptions={availableColumns}
                 onColumnValueChange={handleColumnValueChange}
                 onRemoveColumn={handleColumnDeletion}
                 onMoveColumn={handleColumnMoving}
                 isClearable={isClearable}
                 onColumnSelection={(column) => handleColumnSelection(index, column)} />))
    }
  </>
}

ColumnBlock.defaultProps = {
  uiColumns: [],
  availableColumns: []
};

export { ColumnBlock };