import React from "react";
import { PrimaryButton } from "../components/buttons";

const FormStateButton = ({dirty, isValid, children, disabled=false, ...props}) => {
  // Note: We need to give _dirty_ and _isValid_ as individual props
  // for the form to work correctly: https://github.com/react-hook-form/react-hook-form/issues/857
  return (
    <PrimaryButton disabled={!dirty || !isValid || disabled} type="submit" {...props}>
      {children}
    </PrimaryButton>
  );
};

export default FormStateButton;
