import React from 'react';

import styled from "styled-components";
import {TooltipOverlay} from "../ui-utility/Tooltip";
import {noop} from "../../tests/tools/test-utility";
import {BarCharLogic} from "./BarChart-logic";
import {DailyActual} from "../../screens/timesheet/MonthlyStatus";

export interface OverrideableBarChartStyles {
  backgroundColor?: string
}

interface BarChartProps {
  data: Array<DailyActual>,
  retrieveExtraBarStyles?: (DailyActual) => OverrideableBarChartStyles,

  scalingValue?: number,
  tooltipContent: (any) => HTMLElement | React.ReactElement
}

interface BarChartItemProps {
  item: DailyActual,
  height: number,
  retrieveExtraBarStyles?: (DailyActual) => OverrideableBarChartStyles,
}

interface BarChartContainerStyles extends OverrideableBarChartStyles {
  width?: number,
  height?: number,
}

const BarChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const BarChartItemContainer = styled.div<BarChartContainerStyles>`
    height: 100%;
    width: ${props => props.width || 0}%;
    position:relative;
    display: inline-block;
`

const BarChartColumn = styled.div<BarChartContainerStyles>`
    height: ${props => props.height || 0}%;
    position:absolute;
    bottom:0;
    left:1px;
    right:1px;
    background-color: ${props => props.backgroundColor || "rgb(61, 153, 112)"};
    transition: height .2s ease-in-out;
    transition-delay: .06s!important;
`

const BarChartItem: React.FunctionComponent<BarChartItemProps> = ({item, height, retrieveExtraBarStyles = noop()}) => {
  const extraStyles = retrieveExtraBarStyles && retrieveExtraBarStyles(item);

  return (
    <BarChartColumn height={height} {...extraStyles}/>
  )
}


export const BarChart: React.FunctionComponent<BarChartProps> = (
  {
    data,
    scalingValue,
    retrieveExtraBarStyles,
    tooltipContent
  }
) => {
  const widthPerBar = data.length ? 100 / data.length : 0;

  const barLogic = new BarCharLogic(data, scalingValue);

  const chartObjects = barLogic.buildChartObjects();

  return (
    <BarChartContainer>
      {
        chartObjects.map((element, columnIndex) => (
          <BarChartItemContainer width={widthPerBar} key={columnIndex}>
            <TooltipOverlay tooltipContent={tooltipContent(element.item)}>
              <BarChartItem item={element.item} retrieveExtraBarStyles={retrieveExtraBarStyles} height={element.height}/>
            </TooltipOverlay>
          </BarChartItemContainer>
        ))
      }
    </BarChartContainer>
  )
}