import React from "react";

import {selectById} from "../../store/ducks/customer/customer-selectors";

import CustomerEntity from "../../api/customers/CustomerEntity";

import {useSelector} from "react-redux";
import {TooltipOverlay} from "../ui-utility/Tooltip";
import {BaseIcon} from "../buttons";

// FIXME: TB-160
interface CustomerNameProps {
  className?: string;
  placeholder?: string;
  id?: any;
  name?: string;
  code?: string;
  disableTooltip?: boolean;
}

export const CustomerIcon = () => (<BaseIcon iconName={["fal", "industry-alt"]}/>);

// TODO: Fix props thing, pass in the actual customer object instead of separate attributes
const CustomerName: React.FunctionComponent<CustomerNameProps> = (props) => {
  const customer = new CustomerEntity(props.code, props.name, props.id);

  if (!customer.exists())
    return <span>{props.placeholder}</span>

  const customerHRN = customer.getName();

  return (
    <div className={props.className}>
      {
        props.disableTooltip ? <span>{customerHRN}</span> : (
          <TooltipOverlay tooltipContent="Customer name"><span>{customerHRN}</span></TooltipOverlay>)
      }
    </div>
  );
}

// FIXME: TB-160
interface OwnPropTypes {
  customerId: any;
  placeholder?: string;
  className?: string;
}

type CustomerNameSelector = OwnPropTypes;

const CustomerNameSelector: React.FunctionComponent<OwnPropTypes> = ({className, customerId, placeholder}) => {
  const customer = useSelector(state => selectById(state, customerId));

  return <CustomerName className={className} {...customer} placeholder={placeholder}/>
}

export {
  CustomerName,
  CustomerNameSelector
};
