import { runSaga } from "@redux-saga/core";

async function executeSaga(saga, sagaParams, state = {}) {
  // FIXME: TB-160
  const dispatched: any = [];

  await runSaga({
    dispatch: dispatchedAction => dispatched.push(dispatchedAction),
    getState: () => state
  }, saga, sagaParams).toPromise;

  return dispatched;
}

const valueNoop = value => () => value;

const noop = () => undefined;

export {
  executeSaga,
  valueNoop,
  noop
}

