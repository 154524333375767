import en from './messages/en.json';
import fi from './messages/fi.json';
import fr from './messages/fr.json';
import flatten from 'flat';
import {enUS, enGB, fr as fnsFr, fi as fns_FI} from 'date-fns/locale';

const dateLocales = {enUS, enGB, fi: fns_FI, fr: fnsFr};

export enum SupportedLocale {
    enGB = 'enGB',
    enUS = 'enUS',
    fi = 'fi',
    fr = 'fr',
}

export const supportedLocaleOptions = {
    [SupportedLocale.enGB]: {
        value: SupportedLocale.enGB,
        label: 'English (UK)'
    },
    [SupportedLocale.enUS]: {
        value: SupportedLocale.enUS,
        label: 'English (US)'
    },
    [SupportedLocale.fi]: {
        value: SupportedLocale.fi,
        label: 'Suomi'
    },
    [SupportedLocale.fr]: {
        value: SupportedLocale.fr,
        label: 'Français (France)'
    }
}

const messages = {
    [SupportedLocale.enGB]: en,
    [SupportedLocale.enUS]: en,
    [SupportedLocale.fi]: fi,
    [SupportedLocale.fr]: fr,
}

interface FlattenedMessages {
    [key: string]: string;
}

export const defaultLocale = SupportedLocale.enGB;

const defaultMessages = messages[defaultLocale]

export const convertBrowserLocaleToSupportedLocale = (locale: string): SupportedLocale => SupportedLocale[locale.replace('-', '')]

export const convertSupportedLocaleToBrowserLocale = (locale: SupportedLocale) => {
    const capitalLetterIndex = locale.search(/[A-Z]/g)
    return capitalLetterIndex > 0 ? locale.substr(0, capitalLetterIndex) + '-' + locale.substr(capitalLetterIndex) : locale;
}

export const getCurrentUserLocale = (userLocale?: SupportedLocale): SupportedLocale =>
    SupportedLocale[localStorage.locale as SupportedLocale]
    || userLocale
    || SupportedLocale[convertBrowserLocaleToSupportedLocale((navigator.languages && navigator.languages[0])) as SupportedLocale]
    || SupportedLocale[convertBrowserLocaleToSupportedLocale(navigator.language) as SupportedLocale]
    || defaultLocale;

export const getCurrentUserDateLocale = () => {
    return dateLocales[getCurrentUserLocale()];
}

export const setCurrentLocale = (locale: SupportedLocale) => localStorage.locale = locale;

export const removeCurrentLocaleFromLocalStorage = () => localStorage.removeItem('locale')

function getFlattenedMessages<T = typeof defaultMessages>(messages: T) {
    return flatten<T, FlattenedMessages>(messages)
}

const getTranslationKeys = (): typeof defaultMessages => {
    const flattenedMessages = getFlattenedMessages(defaultMessages);
    Object.keys(flattenedMessages).forEach(key => flattenedMessages[key] = key)
    return flatten.unflatten<FlattenedMessages, typeof defaultMessages>(flattenedMessages);
}

export const TRANSLATION_KEYS = getTranslationKeys();

export const getMessages = (locale: SupportedLocale) => {
    const currentMessages = messages[locale];
    return locale === defaultLocale 
            ? getFlattenedMessages(defaultMessages)
            : {...getFlattenedMessages(defaultMessages), ...getFlattenedMessages(currentMessages)}
}


