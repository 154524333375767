import { useCallback } from 'react';
import {
  addCompleted,
  deleteCompleted,
  editCompleted,
  fetchCompleted,
  fetchStarted
} from "../../../store/ducks/project-tasks/actions";
import { sendRequestSimple } from "../../../api/api";
import { fetchProjectTasksWithProgress as fetchTasksAPI } from "../../../api/projects/projects-api";
import {
  addMultipleTasks,
  addOrUpdateTask,
  deleteTask as deleteTaskAPI,
} from "../../../api/projects/tasks-api";
import { normalize } from "normalizr";
import { tasksSchema } from "./tasks-schema";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoadingTasks } from "../../../store/ducks/project-tasks/selectors";
import { addPinnedTask } from "../../../api/timesheet/mobile-timesheet-api";

const normalizeTasks = tasks => {
  return normalize(tasks, tasksSchema);
};

export const useTaskAPI = projectId => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingTasks);

  const fetchTasks = useCallback(async () => {
    dispatch(fetchStarted());

    const {response: tasks, error} = await sendRequestSimple(fetchTasksAPI(projectId, 0));

    if (!error) {
      const normalized = normalizeTasks(tasks);
      dispatch(fetchCompleted(normalized));
    }
  }, [dispatch, projectId]);

  const pinTask = async (task, user) => {
    return await sendRequestSimple(addPinnedTask({user, task}));
  };

  const addTasks = useCallback(async taskData => {
    const {response: addedTasks, error} = await sendRequestSimple(addMultipleTasks(
      {...taskData, project: projectId})
    );

    if (!error)
      dispatch(addCompleted(addedTasks));

  }, [dispatch, projectId]);

  const editTask = useCallback(async task => {
    const {response: updatedTask, error} = await sendRequestSimple(addOrUpdateTask(task));

    if (!error)
      dispatch(editCompleted(updatedTask));

  }, [dispatch]);

  const deleteTask = useCallback(async taskId => {
    const {error} = await sendRequestSimple(deleteTaskAPI(taskId));

    if (!error)
      dispatch(deleteCompleted(taskId));

  }, [dispatch]);

  return {
    fetchTasks,
    addTasks,
    editTask,
    deleteTask,
    pinTask,
    isLoading,
  }
};

export default useTaskAPI;
