import React from "react";
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import peopleSelectors from "../../store/ducks/people/selectors";

import TimesheetBalanceToday from "../../screens/timesheet/TimesheetBalanceToday";

const PersonBalance = ({personId}) => {
  const userBalance = useSelector(peopleSelectors.selectSingleUserBalance(personId));

  return <TimesheetBalanceToday minutes={userBalance} />;
};

PersonBalance.propTypes = {
  personId: PropTypes.number
};

export default PersonBalance;