// This entity is a direct mapping to backend model.

import ErrorResponse from "../errors";

export interface Record {
  date: string,
  user: number,
  project: number,
  minutes_sum: number
}

class RecordEntity extends ErrorResponse implements Record {
  date: string;
  project: number;
  user: number;
  minutes_sum: number;

  private id: number;
  private description: string;
  private hourly_rate: number;
  private is_billable: boolean;
  private minutes: number;
  private task: number;
  private customer: number;
  private user_cycle: number;

  constructor({id, description, date, hourly_rate, is_billable, minutes, project, task, customer, user, user_cycle}) {
    super();

    this.id = id;
    this.date = date;
    this.description = description;
    this.hourly_rate = hourly_rate;
    this.is_billable = is_billable;
    this.minutes = minutes;
    this.project = project;
    this.task = task;
    this.customer = customer
    this.user = user;
    this.user_cycle = user_cycle;
    this.minutes_sum = 0;
  }

  getDescription = () => (this.description || "");

  setErrorsForFields = (errorObject) => (this.errors = errorObject);

  isTimeTrackingAllowed = () => {
    const allowedByTaskAndProject = RecordEntity.timeTrackingAllowedByTaskAndProject(this.task, this.project);

    return allowedByTaskAndProject && !this.user_cycle;
  }

  static timeTrackingAllowedByTaskAndProject = (task, project) => {
    return RecordEntity.timeTrackingAllowedByTask(task) &&
      RecordEntity.timeTrackingAllowedByProject(project);
  }

  static timeTrackingAllowedByTask = task => (task?.is_active && !task?.is_locked);

  static timeTrackingAllowedByProject = project => project?.is_active;
}

export default RecordEntity;