import React from "react";

import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Link } from "@reach/router";
import { isSameDay, today } from "../../../util/dates";
import { formatDailyRoute } from "../daily/timesheet-utility";

import DayShort from "../../../components/ui-utility/dates/DayShort";
import DateShort from "../../../components/ui-utility/dates/DateShort";

interface TimesheetDayColumnProps {
  isToday: boolean;
}
const TimesheetDayColumn = styled.th<TimesheetDayColumnProps>`
  background-color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 1rem;
    // border-top-left-radius: ${props => props.isToday ? '15px' : 0};
    // border-top-right-radius: ${props => props.isToday ? '15px' : 0};
    // background-color: ${props => props.isToday ? '#fff' : 'inherit'};
`;

const TimesheetDayHeader = ({date}) => {
  const isToday = isSameDay(date, today());
  const redirectUrl = formatDailyRoute(date);

  const styles = {
    fontWeight: isToday ? 900 : 400,
    color: isToday ? '#26aa83' : '#627D98',
  };

  return (
    <TimesheetDayColumn className="align-middle p-2 pt-3" isToday={isToday}>
      <Link to={redirectUrl}>
        <span style={styles}>
          <DayShort date={date}/>
          <br/>
          <small>
            <DateShort date={date}/>
          </small>
        </span>
      </Link>
    </TimesheetDayColumn>
  )
};

TimesheetDayHeader.propTypes = {
  label: PropTypes.string,
  makeBold: PropTypes.bool,
};

export default TimesheetDayHeader;