import React, { useEffect, useReducer, useCallback, useMemo } from "react";

import { useAuthRightAPI } from "./auth-right-hooks";
import authRightReducer, { initialState } from './auth-right-reducers';

import AuthRightAPIResponseParser from "../../../api/authorization/rights/auth-rights-api-response";

import SimpleManageFlow from "../../people/user-details/workday-length/SimpleCRUDFlow";

import AuthorizationList from "./AuthorizationList";
import AuthorizationEdit from "./AuthorizationEdit";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { DefaultBlock, ResponsiveCol } from "../../../components/layout/LayoutHelpers";
import PageHeader from "../../../components/text/PageHeader";
import { RouteComponentProps } from "@reach/router";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

type AuthorizationProps = RouteComponentProps;

export const Authorization: React.FunctionComponent<AuthorizationProps> = () => {
  const [state, localDispatch] = useReducer(authRightReducer, initialState);
  const authApi = useCallback(useAuthRightAPI(localDispatch), []);

  const denormalizedState = useMemo(() => AuthRightAPIResponseParser.deNormalizeListing(state), [state]);

  useEffect(() => {
    authApi.fetchAuthRights();
  }, [authApi]);

  return (
    <Container>
      <Row>
        <ResponsiveCol>
          <PageHeader className="align-middle">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationRulesSettings.pageHeader}}/>
          </PageHeader>
        </ResponsiveCol>
      </Row>

      <Row>
        <ResponsiveCol>
          <DefaultBlock>
            <div style={{minHeight: '400px'}}>
              <SimpleManageFlow titles={{
                list: {id: TRANSLATION_KEYS.authorizationRulesSettings.listHeader}
              }} onDone={() => undefined}>
                <SimpleManageFlow.Edit>
                  <AuthorizationEdit api={authApi}/>
                </SimpleManageFlow.Edit>

                <SimpleManageFlow.List>
                  <AuthorizationList rights={denormalizedState}/>
                </SimpleManageFlow.List>
              </SimpleManageFlow>
            </div>
          </DefaultBlock>
        </ResponsiveCol>
      </Row>
    </Container>
  );
}