import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ModalFrame({children, heading}) {

  return (
    <div className="modal-frame">
      <Row>
        <Col>
          <h2 className="text-center">{heading}</h2>
          <hr/>
        </Col>
      </Row>
      <Row className="flex-grow-1">
        <Col>
          {children}
        </Col>
      </Row>
    </div>
  );
}

export default ModalFrame;