import React from 'react';

import {useModal} from '../../../../components/modal/modal-hooks';
import modalDefinitions from '../../../../components/modal/modal-definitions';
import SimpleModal from '../../../../components/modal/SimpleModal';
import WorkdayLengthManager from './WorkdayLengthManager';
import RequireContextRight from "../../../../components/rights/RequireContextRight";
import RIGHTS from "../../../../components/rights/right-definitions";
import {ActuallyButton, CogIcon} from "../../../../components/buttons";

// FIXME: TB-160
interface UserWorkdayLengthModalProps {
  userId: any;
}

const UserWorkdayLengthModal: React.FunctionComponent<UserWorkdayLengthModalProps> = ({userId}) => {
  const [openModal, closeModal] = useModal(modalDefinitions.USER_WORKDAY_LENGTHS);

  return (
    <>
      <RequireContextRight rightName={RIGHTS.manageUserEmployment} objectId={userId} contextType="users">
        <ActuallyButton variant="slick" onClick={openModal} className="btn-sm">
          <CogIcon light={true}/>
        </ActuallyButton>
      </RequireContextRight>

      <SimpleModal name={modalDefinitions.USER_WORKDAY_LENGTHS} onClose={closeModal}>
        <WorkdayLengthManager userId={userId}/>
      </SimpleModal>
    </>
  )
}

export default UserWorkdayLengthModal;
