/* Type declarations */
const modalTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

/* Action declarations */
const openModalAction = name => ({type: modalTypes.OPEN_MODAL, name});
const closeModalAction = name => ({type: modalTypes.CLOSE_MODAL, name});

function modalReducer(state = {}, action) {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {...state, [action.name]: true};
    case modalTypes.CLOSE_MODAL:
      return {...state, [action.name]: false};
    default:
      return state;
  }
}

/* Selector declarations */
const selectModal = state => state.modal;
const selectModalForName = name => state => {
  const modals = selectModal(state);
  return modals[name];
};

export {
  openModalAction,
  selectModal,

  closeModalAction,
  modalReducer,
  selectModalForName,
};