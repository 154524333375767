import React, { useState, useCallback } from "react";
import { Link } from "@reach/router";

import { ActuallyButton, SignInIcon } from "../../../components/buttons";
import useUserAPI from "../../../components/people/user-hooks";
import Error from "../../../components/notication/Error";
import { TRANSLATION_KEYS } from "../../../intl/intl";
import { LocalizedMessage } from "../../../intl/components/LocalizedMessage";


function LoginForm({email, setEmail, onGoForward}) {
  const {authenticateUser} = useUserAPI();

  const [password, setPassword] = useState("");
  // FIXME: TB-160
  const [errors, setErrors] = useState<any>({});

  const onHandleAuthentication = useCallback(async event => {
      event.preventDefault();

      const {error} = await authenticateUser(email, password);

      error.data && setErrors(error.data);
    }, [authenticateUser, email, password]
  );

  return (
    <form onSubmit={onHandleAuthentication}>
      <Error error={errors.general}/>
      <div className="form-group">
        <label className="label-default"><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.inputEmail }} /></label>
        <input className="form-control" tabIndex={1}
               name="email"
               type="email"
               value={email}
               onChange={e => setEmail(e.target.value)}
               autoFocus
               required/>
      </div>

      <div className="form-group">
        <label className="label-default"><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.inputPassword }} /></label>
        <input className="form-control" tabIndex={2}
               name="password"
               type="password"
               onChange={e => setPassword(e.target.value)}
               required/>
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <ActuallyButton variant="transparent" onClick={onGoForward} tabIndex={4}>
          <Link to="" tabIndex={-1}><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.forgotYourPasswordLinkLabel }}/></Link>
        </ActuallyButton>

        <ActuallyButton variant="primary" type={"submit"} tabIndex={3}>
          <span className="pr-1"><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.loginButton }} /></span>
          <SignInIcon/>
        </ActuallyButton>
      </div>
    </form>
  )
}

export default LoginForm;