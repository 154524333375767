import React from "react";

import {useSelector} from "react-redux";

import {
  DailyActual,
  resolveStatusColor
} from "../../screens/timesheet/MonthlyStatus";

import TimeValue from "../TimeValue";

import peopleSelectors from "../../store/ducks/people/selectors";
import {BarChart, OverrideableBarChartStyles} from "../chart/BarChart";
import DateFormatter from "../ui-utility/DateFormatter";
import styled from "styled-components";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

export interface CoordinateItemConfig {
  blockSize: number,
  gutter: number,
  yOffset: number
}

interface RecordsWithDailyAxisProps {
  days?: Array<Date>,
  recordsWithCoordinates: Array<DailyActual>,
  blockConfig?: CoordinateItemConfig
}

const BarChartWrapper = styled.div`
    position: relative;
    width: 15em;
    height: 35px;
`

const BarChartPeopleListingWrapper = styled.div`
    position: relative;
    width: 15em;
    height: 35px;
`

const retrieveExtraBarStyles = (item: DailyActual): OverrideableBarChartStyles => {
  return {backgroundColor: resolveStatusColor(item.actual_minutes, item.capacity_minutes)};
}

const barChartTooltip = (column) => (
  <>
    <div>
      <TimeValue minutes={column.actual_minutes} showZero={true}/>h
    </div>
    <div>
      <DateFormatter date={column.date}/>
    </div>
  </>
);

export const RecordsWithDailyAxis: React.FunctionComponent<RecordsWithDailyAxisProps> = ({recordsWithCoordinates}) => {
  return (
    <BarChartWrapper>
      <BarChart data={recordsWithCoordinates}
                retrieveExtraBarStyles={retrieveExtraBarStyles}
                tooltipContent={barChartTooltip}/>
    </BarChartWrapper>

  )
}

interface PersonUtilizationProps {
  personId: number;
}

export const PersonUtilization: React.FunctionComponent<PersonUtilizationProps> = ({personId}) => {
  const utilizations = useSelector(peopleSelectors.selectUserUtilizationForId(personId)) || [];

  if (!utilizations.length)
    return <LocalizedMessage text={{id: TRANSLATION_KEYS.people.noLast30DaysRecordsFoundMessage}} />;

  return (
    <BarChartPeopleListingWrapper>
      <BarChart data={utilizations}
                retrieveExtraBarStyles={retrieveExtraBarStyles}
                tooltipContent={barChartTooltip}/>
    </BarChartPeopleListingWrapper>
  );
}