import React, {useState} from "react";

import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {DialogOverlay} from "../../../components/ui-utility/Tooltip";
import {PlusIcon} from "../../../components/buttons";

import {TRANSLATION_KEYS} from "../../../intl/intl";
import {AddCustomersForm} from "./AddCustomersForm";

export const AddCustomers = () => {
  const [isShown, setIsShown] = useState(false);

  return (
    <DialogOverlay isShown={isShown} setIsShown={setIsShown}>
      <DialogOverlay.Trigger variant="primary">
        <PlusIcon/>&nbsp;
        <LocalizedMessage text={{id: TRANSLATION_KEYS.customers.addCustomersButton}}/>
      </DialogOverlay.Trigger>
      <DialogOverlay.Dialog>
        <AddCustomersForm onDone={() => setIsShown(false)}/>
      </DialogOverlay.Dialog>
    </DialogOverlay>
  );
}
