import * as React from 'react';
import { ActuallyTable } from '../../../components/table/ActuallyTable';
import { GroupableTableColumnConfiguration } from '../../../components/table/types/TableColumnConfiguration';
import { PaginationData } from '../../../components/pagination/Pagination';
import { RowData } from '../../../components/table/components/ActuallyTableRow';
import PersonFullName from '../../../components/people/PersonFullName';
import { CustomerName } from '../../../components/customers/CustomerName';
import TaskName from '../../../components/task/TaskName';
import ProjectName from '../../../components/project/ProjectName';
import DateFormatter from '../../../components/ui-utility/DateFormatter';
import TimeValue from '../../../components/TimeValue';
import { CheckIcon } from '../../../components/buttons';
import styled from 'styled-components';
import { overallReportEmptyResultParagraphs } from './overall-report-listing-columns';

const StyledCheckIcon = styled(CheckIcon)`
    color: #1c7430;
`

interface OwnPropTypes {
    tableColumns: GroupableTableColumnConfiguration[];
    groupedColumns: Array<string>;
    records: Array<any>;
    ordering: Array<string>;
    pagination?: PaginationData;
    footer: RowData;
    isLoading?: boolean;
    onPageChange: (page: number) => void;
    onOrderingChange: (newOrdering: Array<string>) => void;
}

export type GroupableOverallReportListingProps = OwnPropTypes;


const getTableColumns = (tableColumns: GroupableTableColumnConfiguration[], groupedColumns: Array<string>): GroupableTableColumnConfiguration[] => {
    let currentTableColumns = groupedColumns ? groupedColumns.reduce((columns: GroupableTableColumnConfiguration[], currentColumn) => {
        const visibleColumn = tableColumns.find(col => col.key === currentColumn && !col.isAlwaysVisible);
        if (visibleColumn) {
            columns.push(visibleColumn);
        }
        return columns
    }, []) : [];
    if (currentTableColumns.length) {
        tableColumns.forEach(column => {
            if (column.isAlwaysVisible) {
                currentTableColumns.push(column);
            }
        })
    }
    return currentTableColumns.length > 0 ? currentTableColumns : tableColumns;
}


const getGroupableOverallReportListingData = (records: Array<object>) => records.map((record: any) => {
    return {
        person: <PersonFullName {...record.user} />,
        customer: <CustomerName id={record.customer?.id} name={record.customer?.name} placeholder="-" />,
        task: <TaskName {...record.task} />,
        project: <ProjectName {...record.project} />,
        is_billable: <span> {record.is_billable && <StyledCheckIcon />}</span>,
        date: <DateFormatter date={record.date} />,
        time: <TimeValue minutes={record.minutes_sum ? record.minutes_sum : record.minutes} showHourLabel={true}/>
    }
})
export const GroupableOverallReportListing: React.FunctionComponent<GroupableOverallReportListingProps> = ({
    tableColumns,
    groupedColumns,
    records,
    ordering,
    pagination,
    footer,
    isLoading,
    onPageChange,
    onOrderingChange
}) => {

    return (
        <ActuallyTable
            tableColumns={getTableColumns(tableColumns,groupedColumns)}
            tableData={getGroupableOverallReportListingData(records)}
            ordering={ordering}
            pagination={pagination}
            footer={footer}
            isLoading={isLoading}
            emptyResultProps={{
                paragraphs: overallReportEmptyResultParagraphs
            }}
            onPageChange={onPageChange}
            onOrderingChange={onOrderingChange} />
    )
}