import React from "react";
import PropTypes from 'prop-types';

import AuthorizationRow from "./components/AuthorizationRow";

function AuthorizationList({rights, onSelect=()=>undefined}) {
  return (
    <div className="edit-authorization-modal">
      {rights.map(authRight => (
        <AuthorizationRow key={authRight.id} authRight={authRight} onChangeRules={onSelect}/>
        ))}
    </div>
  );
}

AuthorizationList.propTypes = {
  rights: PropTypes.array,
};


export default AuthorizationList;
