import ColumnSorterEntity from "../../../components/column-sorter/ColumnSorterEntity";

const orderingSelectors = Object.freeze({
  selectOrdering: state => state.ordering,
  selectOrderingForPage: pageKey => state => orderingSelectors.selectOrdering(state)[pageKey],
  selectOrderingAsQueryStringForPage: pageKey => state => {
    const ordering = orderingSelectors.selectOrderingForPage(pageKey)(state);

    return ColumnSorterEntity.asQueryString(ordering);
  }
});

export default orderingSelectors;