import React from "react";
import { ActuallyButton } from "../../buttons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Bold from "../../ui-utility/Bold";
import PersonFullName from "../PersonFullName";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {strongChunk} from "../../../intl/intl-helpers";


function DeletePersonConfirmation({user, onConfirm, onBail}) {
  const nameData = <Bold><PersonFullName {...user} /></Bold>;
  return (
    <div className="delete-project-confirmation-block">
      <Row>
        <Col>
          <p>
            <LocalizedMessage text={{
              id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.deleteUserMessage,
              values: {
                personName: nameData,
                ...strongChunk,
              }
            }} />
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <ActuallyButton variant="link" onClick={onBail}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.cancelDeleteMessage}}/>
          </ActuallyButton>
          <ActuallyButton variant="delete" onClick={onConfirm}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.people.personDashboard.editPersonModal.confirmDeleteMessage}}/>
          </ActuallyButton>
        </Col>
      </Row>
    </div>
  );
}

export default DeletePersonConfirmation;
