import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { If } from "../ui-utility/Conditional";

import rightSelectors from "../../store/ducks/rights/rights-selectors";

function RequireGlobalRight({rightName, children}) {
  const allGlobalRights = useSelector(rightSelectors.selectGlobalRights);

  const resolvePermission = globalRights => {
    if (Array.isArray(rightName)) {
      return rightName.find(oneRequiredRight => globalRights[oneRequiredRight]);
    }
    return !!globalRights[rightName];
  };

  const hasRight = resolvePermission(allGlobalRights);

  return (
    <If condition={hasRight}>{children}</If>
  );
}

RequireGlobalRight.propTypes = {
  rightName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default RequireGlobalRight;