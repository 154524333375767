import React from "react";

import PropTypes from 'prop-types';

import { If } from "../../../../components/ui-utility/Conditional";

import '../Authorization.css';
import { ActuallyButton, TrashIcon } from "../../../../components/buttons";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";

function AuthorizationGrant({grantedRight, onRemove}) {
  const role = grantedRight.role?.short_desc;
  const group = grantedRight.group?.name;

  return (
    <span className="badge badge-actually">
      <If condition={role}>
        <span className="badge-label">
          <LocalizedMessage text={{id: role}} />
        </span>
      </If>
      <If condition={group}>
        [<span className="badge-label" style={{padding: 0}}>{group}</span>]
      </If>
      <If condition={onRemove}>
        <ActuallyButton variant="slick" onClick={() => onRemove(grantedRight.id)}><TrashIcon/></ActuallyButton>
      </If>
    </span>
  );
}

AuthorizationGrant.propTypes = {
  grantedRight: PropTypes.object.isRequired,
  onRemove: PropTypes.func
};


export default AuthorizationGrant;