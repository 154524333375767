import apiNotifierTypes from "./api-notifier-types";

const apiNotifierSelectors = Object.freeze({
  selectNotifications: state => state.notification,
  selectPendingNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_PENDING];
  },
  selectErrorNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_ERROR];
  },
  selectSuccessNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_SUCCESS];
  },
  selectInfoNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_INFO];
  },
  selectUnAuthorizedNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_UNAUTHORIZED];
  },
  selectValidationNotifications: state => {
    return apiNotifierSelectors.selectNotifications(state)[apiNotifierTypes.API_VALIDATION_ERROR];
  },
});

export default apiNotifierSelectors;
