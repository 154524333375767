import { createSlice } from "@reduxjs/toolkit";
import { paginationKeys } from "../pagination/pagination-keys";

const initialState = {
  [paginationKeys.PROJECT_LISTING]: ['name'],
  [paginationKeys.PEOPLE_LISTING]: ['-user__last_name']
};

const changeOrdering = (state, action) => {
  const {pageKey, ordering} = action.payload;

  return {
    ...state,
    [pageKey]: ordering
  }
};

const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    changeOrderingAction: changeOrdering,
  }
});

const {actions, reducer} = orderingSlice;

const {
  changeOrderingAction,
} = actions;

export {
  changeOrderingAction,
  initialState
};

export default reducer;
