import React from "react";
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";

import {
  selectProjectForId, selectRecordsByTaskId,
  selectTaskForId
} from "../../../../store/ducks/timesheet/selectors";

import { If } from "../../../../components/ui-utility/Conditional";
import { useSelectorWithDefault } from "../../../../util/redux-utils";
import { formatISO } from "../../../../util/dates";

import useTimesheetEditing from "./daily-timesheet-hooks";
import DailyTimesheetEditRow from "./DailyTimesheetEditRow";
import DailyTimesheetViewRow from "./DailyTimesheetViewRow";

import '../timesheet-daily.css';

const DailyTimesheetRowConnector = ({taskId, date}) => {
  const isoDate = formatISO(date);

  const taskRecords = useSelector(selectRecordsByTaskId(taskId)) || [];

  return <DailyTimesheetRow taskId={taskId} date={isoDate} records={taskRecords}/>
};

const DailyTimesheetRow = ({taskId, date, records}) => {
  const {
    apiErrors,
    recordIdsInEdit,

    handleRecordCreation,
    handleRecordDeleting,
    handleRecordEdit,
    handleRecordCanceling,
    handleRecordSaving
  } = useTimesheetEditing();

  const taskInstance = useSelectorWithDefault(selectTaskForId(taskId), {});
  const projectInstance = useSelectorWithDefault(selectProjectForId(taskInstance.project), {});

  const renderDailyRows = () => {
    return records.map((record, index) => resolveRow(record, index));
  };

  const resolveRow = (record, index) => {
    if (recordIdsInEdit.includes(record.id))
      return (
        <DailyTimesheetEditRow key={record.id}
                               task={taskInstance}
                               project={projectInstance}
                               record={record}
                               errors={apiErrors[record.id]}
                               handleRecordDeleting={handleRecordDeleting}
                               handleRecordSaving={handleRecordSaving}
                               handleRecordCanceling={handleRecordCanceling}/>);
    return (
      <DailyTimesheetViewRow key={record.id}
                             date={date}
                             task={taskInstance}
                             project={projectInstance}
                             recordId={record.id}
                             handleRecordCreation={() => handleRecordCreation(taskId, date)}
                             handleRecordEdit={handleRecordEdit}/>)
  };

  return (
    <>
      <If condition={records.length === 0}>
        <DailyTimesheetViewRow task={taskInstance}
                               project={projectInstance}
                               date={date}
                               handleRecordCreation={() => handleRecordCreation(taskId, date)}
                               handleRecordEdit={handleRecordEdit}/>
      </If>
      {renderDailyRows()}
    </>
  )
};

DailyTimesheetRow.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default DailyTimesheetRowConnector;