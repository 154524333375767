import { sendRequestSimple } from "../../../api/api";
import {
  deleteGrantedRight,
  getAuthRightsWithGrants, getAuthRoles,
  postGrantedRight
} from "../../../api/authorization/rights/auth-rights-api";
import { authRightTypes } from "./auth-right-reducers";

import AuthRightAPIResponseParser from "../../../api/authorization/rights/auth-rights-api-response";

// FIXME: TB-160
const useAuthRightAPI: any = dispatchFn => {
  const dispatch = dispatchFn;

  const fetchAuthRights = async () => {
    const {response} = await sendRequestSimple(getAuthRightsWithGrants({page_size: 0}));

    if (response) {
      const normalized = AuthRightAPIResponseParser.normalizeListing(response);

      dispatch({type: authRightTypes.RECEIVE_AUTH_RIGHTS, payload: normalized});
    }
  };

  const fetchAuthRoles = async () => {
    const withExtra = {fields: ['id', 'name'], page_size: 0};

    return await sendRequestSimple(getAuthRoles(withExtra));
  };

  const createGrantedRight = async (role, group, right) => {
    const {response, error} = await sendRequestSimple(postGrantedRight(role, group, right));

    if (response) {
      dispatch({type: authRightTypes.NEW_GRANTED_RIGHT, grantedRight: response});
    }

    return {response, error};
  };

  const destroyGrantedRight = async grantedId => {
    const {response, error} = await sendRequestSimple(deleteGrantedRight(grantedId));

    if (!error) {
      dispatch({type: authRightTypes.DELETE_GRANTED_RIGHT, grantedId});
    }

    return {response, error};
  };

  return {
    fetchAuthRights,
    fetchAuthRoles,

    createGrantedRight,
    destroyGrantedRight
  }

};

export {
  useAuthRightAPI,
};
