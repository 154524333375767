import React from 'react';
import {useModal} from "../../../components/modal/modal-hooks";

import {BalanceFix} from "./BalanceFix";
import SimpleModal from "../../../components/modal/SimpleModal";
import RequireContextRight from "../../../components/rights/RequireContextRight";

import modalDefinitions from "../../../components/modal/modal-definitions";
import RIGHTS from "../../../components/rights/right-definitions";
import {ActuallyButton, CogIcon} from "../../../components/buttons";

// FIXME: TB-160
interface BalanceFixModalProps {
  userId: any;
}

const BalanceFixModal: React.FunctionComponent<BalanceFixModalProps> = ({userId}) => {
  const [openModal, closeModal] = useModal(modalDefinitions.BALANCE_FIX);

  return (
    <>
      <RequireContextRight rightName={RIGHTS.editUser} objectId={userId} contextType="users">
        <ActuallyButton variant="slick" onClick={openModal} className="btn-sm">
          <CogIcon light={true}/>
        </ActuallyButton>
      </RequireContextRight>

      <SimpleModal name={modalDefinitions.BALANCE_FIX} onClose={closeModal}>
        <BalanceFix userId={userId}/>
      </SimpleModal>
    </>
  )
}

export default BalanceFixModal;
