import React from 'react';
import {BellIcon} from "../buttons";
import {useSelector} from "react-redux";
import {selectNotificationCount} from "../../store/ducks/auth/selectors";
import {If} from "../ui-utility/Conditional";
import {ChangesIndicator} from "../filter/ActiveFiltersIndicator";


export const NotificationIndicator: React.FunctionComponent = () => {
  const notificationCount = useSelector(selectNotificationCount);

  return (
    <span className="relative">
      <BellIcon light={true}/>
      <If condition={notificationCount}>
        <ChangesIndicator>{notificationCount}</ChangesIndicator>
      </If>
    </span>
  )
}