const peopleSelectors = Object.freeze({
  selectPeople: state => state.people,
  selectSnapshot: state => peopleSelectors.selectPeople(state).snapshot,

  selectLatestRecords: state => peopleSelectors.selectSnapshot(state).latest_records,
  selectLatestRecordForId: userId => state => {
    const latestRecords = peopleSelectors.selectLatestRecords(state);

    return latestRecords ? latestRecords[userId] : null;
  },

  selectLatestRecordDateForId: userId => state => {
    const latestRecord = peopleSelectors.selectLatestRecordForId(userId)(state);

    return latestRecord ? latestRecord.latest_record_date : null;
  },
  selectUserBalances: state => peopleSelectors.selectSnapshot(state).user_balances,
  selectUserBalancesForId: userId => state => {
    const userBalances = peopleSelectors.selectUserBalances(state);

    return userBalances ? userBalances[userId] : null;
  },
  selectSingleUserBalance: userId => state => {
    const userBalance = peopleSelectors.selectUserBalancesForId(userId)(state);

    return userBalance ? userBalance.balance_minutes : null;
  },
  selectUserUtilization: state => peopleSelectors.selectSnapshot(state).user_utilization,
  selectUserUtilizationForId: userId => state => {
    const userUtilizations = peopleSelectors.selectUserUtilization(state);

    return userUtilizations ? userUtilizations[userId] : null;
  },
  selectPeopleResult: state => {
    const {result} = peopleSelectors.selectPeople(state);

    return result;
  },
  selectPeopleEntities: state => {
    const {entities} = peopleSelectors.selectPeople(state);

    return entities;
  },
  selectUser: userId => state => {
    const {users} = peopleSelectors.selectPeopleEntities(state);
    return users[userId];
  },
  selectUserEmployment: userId => state => {
    const peopleState = peopleSelectors.selectPeople(state);
    return peopleState.employment[userId];
  },
});

export {
  peopleSelectors as default,
};
