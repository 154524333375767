import React from 'react';
import classNames from 'classnames';
import { Col } from "react-bootstrap";
import styled from 'styled-components';

const BaseSection = ({children, className, ...props}) => {
  return (
    <div className={classNames('d-flex flex-grow-1 flex-column', className)} {...props}>
      {children}
    </div>
  );
};

const Section = ({children, className='', ...props}) => {
  return (
    <BaseSection className={classNames('justify-content-between', className)} {...props}>
      {children}
    </BaseSection>
  )
};

const CenteredSection = ({children, className = "", ...props}) => {
  const classes = classNames(
    className,
    'justify-content-center',
    'flex-column',
    'align-items-center'
  );

  return (
    <BaseSection className={classes} {...props}>
      {children}
    </BaseSection>
  );
};

const SectionBlock = ({children, className='', ...props}) => {
  return (
    <div className={classNames("justify-content-center default-block content w-100 mt-2 mb-2", className)} {...props}>{children}</div>
  );
};

const PlainSectionBlock = ({children, className='', ...props}) => {
  return (
    <div className={classNames("plain-block w-100 mt-2 mb-2", className)} {...props}>{children}</div>
  );
};

const DefaultCol = ({children, className='', ...props}) => {
  const classes = classNames(
    className,
  );

  return (
    <Col {...props} className={classes}>{children}</Col>
  )
};

const ResponsiveCol = ({children, className = '', ...props}) => {
  return <DefaultCol className={classNames(className, 'mb-3')} {...props}>{children}</DefaultCol>
};


interface BaseBlockWrapperProps {
  with_background: number
}

const BaseBlockWrapper = styled.div<BaseBlockWrapperProps>`
  ${props => props.with_background ? `
    background-color: white;
    border-radius: 0.3rem;
  ` : ''}
`

interface BaseBlockProps {
  className?: string;
  withBackground?: boolean;
}

const BaseBlock: React.FunctionComponent<BaseBlockProps> = ({children, className, withBackground=true}) => {
  return (
    <BaseBlockWrapper className={className} with_background={withBackground ? 1 : 0}>
      {children}
    </BaseBlockWrapper>
  );
};

interface StyledDefaultBlockProps {
  corner_action: number;
  is_tight: number;
}

const DefaultBlockWrapper = styled(BaseBlock)<StyledDefaultBlockProps>`
  ${props => props.corner_action ? 'position: relative;' : ''}
  ${props => props.is_tight ? '' : 'padding: 1rem;'}
`

export interface DefaultBlockProps {
  className?: string;
  withBackground?: boolean;
  isTight?: boolean;
  cornerAction?: React.ReactElement;
}

const DefaultBlock: React.FunctionComponent<DefaultBlockProps> = ({children, className, withBackground=true, isTight, cornerAction=null}) => {
  return <DefaultBlockWrapper className={className} corner_action={cornerAction ? 1 : 0} is_tight={isTight ? 1 : 0} withBackground={withBackground}>
    {cornerAction !== null && cornerAction}

    {children}
  </DefaultBlockWrapper>;
};


export {
  Section,
  CenteredSection,
  SectionBlock,
  PlainSectionBlock,

  DefaultBlock,

  DefaultCol,
  ResponsiveCol,
}