import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ExclamationTriangleIcon } from "../../../components/buttons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


import './Input.css';
import {localizeMessage} from "../../../intl/components/LocalizedMessage";
import {useIntl} from "react-intl";


function WithErrorInput({children, errors, style=undefined}) {
  const inputRef = React.Children.only(children);

  const errorClasses = errors ? "input-error" : "";

  const intl = useIntl();

  const localizedErrors = errors ? localizeMessage(
    {id: errors},
    intl,
  ) : "";

  const combineClassesWithRef = (refClasses, myClasses) => {
    return classNames(refClasses, myClasses);
  };

  const mergedClasses = useMemo(() => combineClassesWithRef(inputRef.props.className, errorClasses),
    [inputRef.props.className, errorClasses]);

  const elementRef = React.cloneElement(inputRef, {className: mergedClasses});

  const makeErrorElement = () => {
    if (errors)
      return (
        <OverlayTrigger placement="top" overlay={<Tooltip id='error'>
          {localizedErrors}
        </Tooltip>}>
          <div className="input-group-append">
            <span className="input-group-text standard-input-addon error-addon"><ExclamationTriangleIcon/></span>
          </div>
        </OverlayTrigger>
      );
    return null;
  };

  return (
    <div className="input-group error-input" style={style}>
      {elementRef}
      {makeErrorElement()}
    </div>
  )
}

WithErrorInput.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

WithErrorInput.defaultProps = {
  errors: null,
};

export default WithErrorInput;