import { createDeleteRequest, createGetRequest, createPatchRequest, createPostRequest } from "../api";
import { joinRouteParts } from "../utils";

const baseUrl = 'tasks';

const addOrUpdateTask = data => {
  if (data.id) {
    const apiUrl = `${baseUrl}/${data.id}/`;

    return createPatchRequest({url: apiUrl, data});

  } else {
    return createPostRequest({url: baseUrl, data});
  }
};

const addMultipleTasks = data => {
  const apiUrl = joinRouteParts(baseUrl, 'create_multiple');

  return createPostRequest({url: apiUrl, data});
};

const deleteTask = taskId => {
  const url = joinRouteParts(baseUrl, taskId);

  return createDeleteRequest({url})
};

const fetchSingleTaskWithProgress = taskId => {
  const apiUrl = `${baseUrl}/${taskId}/with_progress/`;
  return createGetRequest({url: apiUrl})
};


export {
  addOrUpdateTask,
  addMultipleTasks,
  deleteTask,
  fetchSingleTaskWithProgress,
};