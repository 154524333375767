import React from "react";

import { ActuallyButton, AngleLeftIcon } from "../buttons";
import { If } from "../ui-utility/Conditional";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Logout } from "../../screens/authentication/Logout";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import './Header.css';

function MobileBackButton() {
  const goBack = () => window.history.back();

  return (
    <ActuallyButton variant="slick" onClick={goBack}><AngleLeftIcon/> Back</ActuallyButton>
  );
}

const MobilePageHeading = ({children, showBackBtn=false}) => {
  return (
    <div className="mobile-timesheet-header">
      <If condition={showBackBtn}>
        <Row>
          <Col>
            <MobileBackButton/>
          </Col>
        </Row>
      </If>
      <Navbar expand="lg" className="p-0">
        <span className="navbar-brand">{children}</span>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mt-1">
            <div>
              <Logout>
                Logout
              </Logout>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
};

export default MobilePageHeading;
