import React from "react";
import { ActuallyButton } from "../../buttons";
import { navigate } from "@reach/router";

import modalDefinitions from "../../modal/modal-definitions";

import { useModal } from "../../modal/modal-hooks";
import { selectProject } from "../../../store/ducks/projects/selectors";

import SimpleModal from "../../modal/SimpleModal";
import ModalFrame from "../../modal/ModalFrame";
import useShallowEqualSelector from "../../../store/helpers";
import DeleteProjectConfirmation from "./DeleteProjectConfirmation";
import useProjectAPI from "../../../screens/projects/use-project-api";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";


function DeleteProjectButton({projectId, onDeleted}) {
  const {removeProject} = useProjectAPI();
  const [openModal, closeModal] = useModal(modalDefinitions.DELETE_PROJECT);
  const project = useShallowEqualSelector(selectProject(projectId));

  const handleProjectDelete = async () => {
    const {error} = await removeProject(projectId);

    if (!error) {
      closeModal();
      onDeleted();
      navigate('/projects');
    }

  };

  return (
    <>
      <ActuallyButton variant="delete-pre" onClick={openModal}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.deleteProjectButton}}/>
      </ActuallyButton>
      <SimpleModal name={modalDefinitions.DELETE_PROJECT} useBackDrop={true} centered={true}>
        <ModalFrame heading={<LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.deleteProjectModal.confirmDeleteButton}}/>}>
          <DeleteProjectConfirmation project={project} onBail={closeModal} onConfirm={handleProjectDelete}/>
        </ModalFrame>
      </SimpleModal>
    </>
  );
}

export default DeleteProjectButton;
