import peopleSagaTypes from "./types";

const fetchPeopleAction = params => ({type: peopleSagaTypes.FETCH_PEOPLE, params});
const receivePeopleAction = payload => ({type: peopleSagaTypes.RECEIVE_PEOPLE, payload});
const receivePeopleSnapshotAction = payload => ({type: peopleSagaTypes.RECEIVE_PEOPLE_SNAPSHOT, payload});
const addBasicPeopleAction = payload => ({type: peopleSagaTypes.ADD_BASIC_PEOPLE, payload});
const addAdvancedPeopleAction = payload => ({type: peopleSagaTypes.ADD_ADVANCED_PEOPLE, payload});

const fetchUserEmploymentAction = userId => ({type: peopleSagaTypes.FETCH_USER_EMPLOYMENT, payload: userId});

const fetchUserDetails = userId => ({
  type: peopleSagaTypes.FETCH_USER_DETAILS,
  payload: userId,
});

const fetchUserDetailsSuccess = user => ({
  type: peopleSagaTypes.FETCH_USER_DETAILS_SUCCESS,
  payload: user,
});

const fetchUserEmploymentSuccess = employmentInfo => ({
  type: peopleSagaTypes.FETCH_USER_EMPLOYMENT_SUCCESS,
  payload: employmentInfo,
});

const removeUserAction = userId => {
  return {
    type: peopleSagaTypes.REMOVE_USER,
    userId
  };
};


export {
  fetchPeopleAction,
  receivePeopleAction,
  receivePeopleSnapshotAction,
  addBasicPeopleAction,
  addAdvancedPeopleAction,

  fetchUserDetails,
  fetchUserDetailsSuccess,

  fetchUserEmploymentAction,
  fetchUserEmploymentSuccess,
  removeUserAction
}