import React from "react";
import Badge from "react-bootstrap/Badge";
import { STEP_STATUSES } from "../../approval-constants";
import {CheckIcon, LightbulbExclamationIcon} from "../../../../../components/buttons";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";

export function PendingCycleBadge({children}) {
  return (
      <Badge variant="warning" className="actually-badge">
        <LightbulbExclamationIcon light={true}/> {children}
      </Badge>
  )
}

export function ApprovedCycleBadge({children}) {
  return (
      <Badge variant="success" className="actually-badge">
        <CheckIcon/> {children}
      </Badge>
  )
}

function StepStatusBadge({status}) {

  if (status === STEP_STATUSES.pending) {
    return (
      <PendingCycleBadge>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.readyForApprovalLabel}}/>
      </PendingCycleBadge>
    )
  }

  if (status === STEP_STATUSES.declined) {
    return (
      <Badge variant="danger" className="actually-badge">
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.declinedLabel}}/>
      </Badge>
    )
  }

  if (status === STEP_STATUSES.approved) {
    return (
      <ApprovedCycleBadge>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.approvedLabel}}/>
      </ApprovedCycleBadge>
    )
  }

  if (status === STEP_STATUSES.blocked) {
    return (
      <Badge variant="info" className="actually-badge">
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.table.blockedByPreviousPhaseMessage}}/>
      </Badge>
    )
  }
  return (
    <Badge variant="light" className="actually-badge">
      N/A
    </Badge>
  );
}


export default StepStatusBadge;
