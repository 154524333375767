import React, { useState, useEffect } from "react";

import FilterFactory from "../filter/entities/FilterFactory";
import GeneralApiResponseParser from '../../api/general-api-response-parser';

import BaseSelect from "./BaseSelect";
import { sendRequestSimple } from "../../api/api";

const customerFilter = FilterFactory.createApiSelectFilter('customer_dropdown.id', 'customers');
const authRolesFilter = FilterFactory.createApiSelectFilter('authroles', 'authroles');
const authAuthGroupFilter = FilterFactory.createApiSelectFilter('authgroups', 'authgroups');
const userFilter = FilterFactory.createApiSelectFilter('user_dropdown.id', 'users');
const presetCalendarFilter = FilterFactory.createApiSelectFilter('preset-calendar.id', 'preset-calendars');
const calendarFilter = FilterFactory.createApiSelectFilter('calendar.id', 'calendars');

const resolveApi = (variant, api) => {
  if (variant === 'customer')
    return customerFilter;
  if (variant === 'authroles')
    return authRolesFilter;
  if (variant === 'authgroups')
    return authAuthGroupFilter;
  if (variant === 'user')
    return userFilter;
  if (variant === 'preset-calendars')
    return presetCalendarFilter;
  if (variant === 'calendar')
    return calendarFilter;
  return api;
};

const convertResponse = (api, response) => {
  if (api.convertResponse)
    return api.convertResponse(response);

  return GeneralApiResponseParser.convertRecordsIntoSelectCompatible(response);
};

// FIXME: TB-160
function ActuallySelect({variant, api=undefined, onChange, entityId=undefined, name, onSelectionPostProcess=undefined, tabIndex=undefined, params={}}: any) {
  const selectApi = resolveApi(variant, api);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const {response} = await sendRequestSimple(selectApi.fetch({page_size: 0, ...params}));

      if (response) {
        let options = convertResponse(selectApi, response);
        options = (onSelectionPostProcess && onSelectionPostProcess(options)) || options;
        setOptions(options);
      }
    };

    fetchDropdownOptions();
  }, [selectApi, onSelectionPostProcess]);

  const findSelectedEntity = options => options.find(record => record.id === entityId);

  const selected = findSelectedEntity(options);

  return <BaseSelect handleChange={entity => onChange(entity)}
                     value={selected}
                     dropdownOptions={options}
                     name={name}
                     tabIndex={tabIndex}
  />
}

export default ActuallySelect;