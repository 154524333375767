import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import { sendRequestSimple } from "../../api/api";
import {
  getSingleProjectProgress
} from "../../api/projects/projects-api";
import { fetchProject } from "../../store/ducks/projects/actions";
import { fetchProjectProgressCompleted } from "../../store/ducks/project-details/actions";

const useProjectDetails = (projectId) => {
  const dispatch = useDispatch();

  const fetchProjectCallback = useCallback(async () => {
    dispatch(fetchProject(projectId));
  }, [dispatch, projectId]);

  const fetchProjectProgress = useCallback(async () => {
    const {response: progress} = await sendRequestSimple(getSingleProjectProgress({projectId}));

    dispatch(fetchProjectProgressCompleted(progress));

  }, [dispatch, projectId]);


  return {
    fetchProject: fetchProjectCallback,
    fetchProjectProgress,
  }
};

export default useProjectDetails;
