import { groupBy } from 'lodash';
import GeneralApiResponseParser from "../../general-api-response-parser";

class UserCycleAPIResponseParser {

  static normalizeListing = response => {
    const cyclesNormalized = GeneralApiResponseParser.normalizeListingNoSchema(response.cycle);
    const userCyclesNormalized = GeneralApiResponseParser.normalizeListingNoSchema(response.user_cycle);

    const cyclesByUser = groupBy(response.user_cycle, 'user');

    const cycleIdsByUser = Object.keys(cyclesByUser).reduce((finalized, userId) => {
      finalized[userId] = cyclesByUser[userId].map(cycle => cycle.id);

      return finalized;
    }, {});

    return {
      cycles: cyclesNormalized.entities,
      userCycles: userCyclesNormalized.entities,
      cycleIdsByUser
    };
  };

  static normalizeByCycle = response => {
    const {entities} = GeneralApiResponseParser.normalizeListingNoSchema(response, {idAttribute: 'cycle'});

    return entities;
  }
}

export default UserCycleAPIResponseParser;
