import React, { useMemo } from "react";
import { Tenant } from "../../../types/entities/Tenant";
import { Table } from "../table-blocks";
import { getTenantRights } from "../../../api/admin/admin-tenants-api";
import { PrimaryColored, PrimaryElement } from "../layout/PrimaryElement";
import { useSimpleFetch } from "../../../api/use-simple-fetch";

interface TenantRightsListProps {
  tenant: Tenant;
  className?: string;
}

const SingleRoleRow = ({roleData}) => {
  return (
    <tr>
      <td style={{verticalAlign: 'top'}}>
        <PrimaryElement>
          {roleData.role}
        </PrimaryElement>

        {
          roleData.group &&
          <PrimaryColored>
            <br/>
            when belongs to the group
            "{roleData.group}"
          </PrimaryColored>
        }
      </td>

      <td>
        {roleData.rights.map(rightName => (
          <div key={rightName}>{rightName}</div>
        ))}
      </td>
    </tr>
  )
}

export const TenantRightsTable: React.FC<TenantRightsListProps> = ({tenant, className}) => {
  const rolesWithRights = useSimpleFetch(
    useMemo(() => getTenantRights(tenant.id), [tenant.id]),
    []
  );

  return (
    <Table className={className}>
      <thead>
      <tr>
        <th style={{width: '500px'}}>Role</th>
        <th>Rights within that context</th>
      </tr>
      </thead>

      <tbody>
      {rolesWithRights.map((role: any, i) => (
        <SingleRoleRow roleData={role} key={`${role.role}-${role.group}`} />
      ))}
      </tbody>
    </Table>
  )
}