import React from "react";

import {useSelector} from "react-redux";
import cycleTemplateSelectors from "../../../../store/ducks/cycle/cycle-template-selectors";

import {If} from "../../../../components/ui-utility/Conditional";

import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";
import PlainTable from "../../../../components/ui-utility/table/PlainTable";
import CycleTemplateTr from "./CycleTemplateTr";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";

function CycleTemplateListing() {
  const templateIds = useSelector(cycleTemplateSelectors.selectTemplateIds);

  return (
    <>
      <If condition={templateIds.length}>
        <PlainTable showLines={true} hoverable={true} alignToSides={true} className="people-table">
          <thead>
          <tr>
            <th>
              <LocalizedMessage
                text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.cycleTableHeaders.activeFrom}}/>
            </th>
            <th>
              <LocalizedMessage
                text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.cycleTableHeaders.activeTo}}/>
            </th>
            <th>
              <LocalizedMessage
                text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.cycleTableHeaders.duration}}/>
            </th>
          </tr>
          </thead>
          <tbody>
          {templateIds.map(templateId => (
            <CycleTemplateTr templateId={templateId} key={templateId}/>
          ))}
          </tbody>
        </PlainTable>
      </If>
      <If condition={templateIds.length === 0}>
        <EmptyStatePlaceholder iconName="clock">
          <p>
            <LocalizedMessage
              text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.approvalCyclesManagement.emptyTableMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
    </>
  );
}

export default CycleTemplateListing;