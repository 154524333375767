import React from "react";
import {Link} from "@reach/router";

import {PersonFullNameSelector} from "../../../../../components/people/PersonFullName";
import {constructModalUrl} from "../../../../../components/modal/modal-hooks";
import {canBeApproved} from "../../approval-constants";

import StepStatusBadge from "./StepStatusBadge";
import {If} from "../../../../../components/ui-utility/Conditional";

import modalDefinitions from "../../../../../components/modal/modal-definitions";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";


function ApproverPhaseStep({step}) {
  const url = constructModalUrl(modalDefinitions.APPROVE_USER, {"modal.stepId": step.id});

  return (
      <tr className="phase-step">
        <td>
          <PersonFullNameSelector userId={step.user_cycle_phase__user} addLink={false}/>
        </td>
        <td>
          <StepStatusBadge status={step.status}/>
        </td>
        <td>
          <If condition={canBeApproved(step.status)}>
            <Link to={url}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.reviewButton}}/>
            </Link>
          </If>
        </td>
      </tr>
  );
}


export default ApproverPhaseStep;
