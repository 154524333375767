import React, { useState } from "react";

import PropTypes from 'prop-types';
import ActuallySelect from "../../../components/select/ActuallySelect";
import AuthorizationGrantRow from "./components/AuthorizationGrantRow";

import { ActuallyButton } from "../../../components/buttons";
import GeneralApiResponseParser from "../../../api/general-api-response-parser";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {emChunk} from "../../../intl/intl-helpers";
import {useLocalizer, useSimpleLocalizedMessage} from "../../../intl/use-localized-message";

// FIXME: TB-160
function AuthorizationEdit({entity, onDone=() => undefined, api}: {entity?: any; onDone?: () => void, api: any}) {
  const localizer = useLocalizer();

  const [newRule, setNewRule] = useState<{role: any; group: any}>({role: null, group: null});

  const onSubmitAuthChanges = async event => {
    event.preventDefault();

    const {response} = await api.createGrantedRight(newRule.role?.id, newRule.group?.id, entity?.id);

    response && onDone();
  };

  const handleGrantRemove = async grantedId => {
    const {error} = await api.destroyGrantedRight(grantedId);

    !error && onDone();
  };

  const setRuleAttribute = attr => entity => {
    setNewRule({...newRule, [attr]: entity});
  };

  const filterByAllowedContexts = dropdownOptions => {
    const filtered = dropdownOptions.filter(right => entity.allowed_contexts.includes(right.name));

    return GeneralApiResponseParser.convertRecordsIntoSelectCompatible(filtered, (option) => localizer(option.short_desc));
  };

  return (
    <div className="edit-authorization-modal">
      <p>
        <em>
          <LocalizedMessage text={{id: entity.long_desc}} />
        </em>

      </p>
      <form onSubmit={onSubmitAuthChanges} className="mb-2">
        <div className="form-row">
          <div className="form-group col">
            <label className="label-default" htmlFor="role">
              <LocalizedMessage text={{
                id: TRANSLATION_KEYS.authorizationRulesSettings.editRightModal.allowRightLabel,
                values: {
                  rightName: useSimpleLocalizedMessage(entity.short_desc),
                  ...emChunk,
                }
              }} />
            </label>
            <ActuallySelect variant="authroles" name="group" onChange={setRuleAttribute('role')}
                            onSelectionPostProcess={filterByAllowedContexts}/>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="label-default" htmlFor="group">
              <LocalizedMessage text={{
                id: TRANSLATION_KEYS.authorizationRulesSettings.editRightModal.requireAuthorizationGroupLabel,
              }} />
            </label>
            <ActuallySelect variant="authgroups" name="group" onChange={setRuleAttribute('group')}/>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col text-right">
            <ActuallyButton variant="link" onClick={onDone}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
            </ActuallyButton>
            <ActuallyButton variant="primary" type="submit" disabled={!newRule.role}>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationRulesSettings.editRightModal.addNewRuleButton}}/>
            </ActuallyButton>
          </div>
        </div>
      </form>

      <div className="authorization-active-rules">
        <label className="bold">
          <LocalizedMessage text={{
            id: TRANSLATION_KEYS.authorizationRulesSettings.editRightModal.activeRulesHeader,
            values: {
              rightName: useSimpleLocalizedMessage(entity.short_desc),
              ...emChunk,
            }
          }} />
        </label>
        <div>
          <AuthorizationGrantRow grantedRights={entity.grantedRights} onRemove={handleGrantRemove}/>
        </div>
      </div>
    </div>
  );
}

AuthorizationEdit.propTypes = {
  rights: PropTypes.array,
  api: PropTypes.object
};


export default AuthorizationEdit;
