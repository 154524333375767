import paginationSagaTypes from "./pagination-types";

const changePaginationForPageAction = (response, page) => ({
  type: paginationSagaTypes.CHANGE_PAGINATION_FOR_PAGE,
  response,
  page
});

const receiveNewPaginationForPage = (pagination, page) => ({
  type: paginationSagaTypes.RECEIVE_NEW_PAGINATION_FOR_PAGE,
  pagination,
  page
});

const changePageAction = (page, newPageNo) => ({
  type: paginationSagaTypes.CHANGE_PAGE,
  page,
  newPageNo
});

export {
  changePaginationForPageAction,
  receiveNewPaginationForPage,
  changePageAction
}