export interface Task {
  id?: number,
  name:string
}

class TaskEntity implements Task{
  name: string;
  constructor({name}) {
    this.name = name;
  }

  getName = () => {
    return `${this.name || ''}`;
  };

}

export default TaskEntity;