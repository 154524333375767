import {createEntityAdapter, EntityState} from "@reduxjs/toolkit";
import {Customer} from "../api/customers/CustomerEntity";

export function createActuallyAdapter<T>() {
  const entityAdapter = createEntityAdapter<T>();

  const addManyToFront = (state: EntityState<Customer>, action: any) => {
    const models = action.payload;
    const ids = models.map(model => model.id);

    state.ids = [...ids, ...state.ids];

    models.forEach(model => {
      state.entities[model.id] = model
    });

    return state;
  }

  return {
    ...entityAdapter,
    addManyToFront
  };
}
