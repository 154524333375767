import { orderBy } from "lodash";

class TimesheetResponseParser {
  static parseDailyResponse(response) {
    const {projects, tasks} = response;
    const project_ids = TimesheetResponseParser.extractProjectIdsWithOrder(projects);

    return {
      ...response,
      project_ids: project_ids,
      task_ids: Object.keys(tasks)
    }
  };

  static extractProjectIdsWithOrder(projectMap) {
    const projectInstances = Object.values(projectMap);
    const orderedInstances = orderBy(projectInstances, ['name']);

    // FIXME: TB-160
    return orderedInstances.map((project: any) => project.id);
  }
}

export default TimesheetResponseParser;