import { sendRequestSimple } from "../../../api/api";
import {
  deleteBalanceFix,
  getBalanceFixes,
  patchBalanceFix,
  postBalanceFix
} from "../../../api/balance-fixes/balance-fixes-api";
import GeneralApiResponseParser from "../../../api/general-api-response-parser";

// FIXME: TB-160
const useBalanceFixAPI = (): any => {

  const fetchBalanceFixes = async (userId) => {
    const {response} = await sendRequestSimple(getBalanceFixes({'balancefix.user': userId}));

    if (response) {
      return response.balance_fixes;
    }

    return null;
  };

  const destroyBalanceFix = async balanceFixId => {
    return await sendRequestSimple(deleteBalanceFix(balanceFixId));
  };

  const updateBalanceFix = async (balanceFixId, payload) => {
    return await sendRequestSimple(patchBalanceFix(balanceFixId, payload));
  };

  const createBalanceFix = async payload => {
    return await sendRequestSimple(postBalanceFix(payload));
  };

  const saveBalanceFix = async (balanceFixId, payload) => {
    if (balanceFixId) {
      const response = await updateBalanceFix(balanceFixId, payload);

      return extractResponse(response);
    }

    const response = await createBalanceFix(payload);

    return extractResponse(response);
  };

  const extractResponse = data => {
    const {response, error = {}} = data;

    if (error.data) {
      const formatedErrors = GeneralApiResponseParser.extractAllErrors(error.data);

      return {error: formatedErrors};
    }

    return {response};
  };


  return {
    fetchBalanceFixes,
    destroyBalanceFix,
    saveBalanceFix
  }

};

export {
  useBalanceFixAPI,
};
