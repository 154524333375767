import Filter from "./Filter";
import FILTER_TYPES from '../configuration/filter-type-definations';

class BooleanFilterField extends Filter {
  filterType = FILTER_TYPES.BOOLEAN;

  getActiveFilterSentence = () => {
    return this.getActiveFilterLabel();
  };

  formatValue = (value) => {
    return {[this.filterKey]: value || ""};
  };

}

export default BooleanFilterField;