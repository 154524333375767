import * as React from 'react';
import { ActuallyTable } from '../../../components/table/ActuallyTable';
import { TableColumnConfiguration } from '../../../components/table/types/TableColumnConfiguration';
import { PaginationData } from '../../../components/pagination/Pagination';
import { RowData } from '../../../components/table/components/ActuallyTableRow';
import PersonFullName from '../../../components/people/PersonFullName';
import { CustomerName } from '../../../components/customers/CustomerName';
import TaskName from '../../../components/task/TaskName';
import ProjectName from '../../../components/project/ProjectName';
import DateFormatter from '../../../components/ui-utility/DateFormatter';
import TimeValue from '../../../components/TimeValue';
import { QuoteBlock } from '../../../components/text/DescriptionTxt';
import WithErrorInput from '../../timesheet/components/WithErrorInput';
import { BlurInput, BlurInputVariant } from '../../timesheet/components/BlurInput';
import styled from 'styled-components';
import RecordEntity from '../../../api/report/RecordEntity';
import MinutesInput from '../../timesheet/components/MinutesInput';
import ToggleSwitch from '../../../components/toggle-switch/ToggleSwitch';
import { CheckIcon } from '../../../components/buttons';
import { overallReportEmptyResultParagraphs } from './overall-report-listing-columns';

const StyledCheckIcon = styled(CheckIcon)`
    color: #1c7430;
`

const StyledBlurInput = styled(BlurInput)`
    && {
        height: calc(1.5em + .75rem + 2px);
    }
`;

const StyledMinutesInput = styled(MinutesInput)`
    & {
        font-size: 1rem;
        text-align: center;
    }
`

interface OwnPropTypes {
    tableColumns: TableColumnConfiguration[];
    records: Array<any>;
    ordering: Array<string>;
    pagination?: PaginationData;
    footer: RowData;
    isLoading?: boolean;
    isLocked?: boolean;
    setErrorsForEntity: (recordEntity: RecordEntity) => void;
    onRecordChange: (recordId: number, fieldName: string, newValue: any) => void;
    onPageChange: (page: number) => void;
    onOrderingChange: (newOrdering: Array<string>) => void;
}

export type EditableOverallReportListingProps = OwnPropTypes;


const getEditableOverallReportListingData = (records: Array<object>, handleFieldChange: any, setErrorsForEntity: (entity: RecordEntity) => void, isLocked?: boolean) => records.map((record: any) => {
    // FIXME: Fix the type for RecordEntity
    const recordEntity = new RecordEntity(record);

    setErrorsForEntity(recordEntity);
    const isTimeTrackingAllowed = recordEntity.isTimeTrackingAllowed();

    return {
        person: <PersonFullName {...record.user} />,
        customer: <CustomerName id={record.customer?.id} name={record.customer?.name} placeholder="-" />,
        task: <TaskName {...record.task} />,
        project: <ProjectName {...record.project} />,
        date: <DateFormatter date={record.date} />,
        is_billable:
            isLocked ?
                <span> {record.is_billable && <StyledCheckIcon />}</span>
                : <ToggleSwitch isChecked={record.is_billable} disabled={!isTimeTrackingAllowed}
                    onChangeOfValue={handleFieldChange('is_billable', record.id)} />,
        description:
            isLocked ?
                <QuoteBlock text={recordEntity.getDescription()} cutLimit={200} />
                : <WithErrorInput errors={recordEntity?.getErrorsForField('description')}>
                    <StyledBlurInput value={recordEntity.getDescription()} onSave={handleFieldChange('description', record.id)} autoFocus={false}
                        disabled={!isTimeTrackingAllowed} variant={BlurInputVariant.Textarea} />
                </WithErrorInput>,
        time:
            isLocked ?
                <TimeValue minutes={record.minutes_sum ? record.minutes_sum : record.minutes} showHourLabel={true} />
                : <WithErrorInput errors={recordEntity.getErrorsForField('minutes')}>
                    <StyledMinutesInput minutesOfDay={record.minutes} onSave={handleFieldChange('minutes', record.id)} disabled={!isTimeTrackingAllowed} />
                </WithErrorInput>
    }
})
export const EditableOverallReportListing: React.FunctionComponent<EditableOverallReportListingProps> = ({
    tableColumns,
    records,
    ordering,
    pagination,
    footer,
    isLoading,
    isLocked,
    setErrorsForEntity,
    onRecordChange,
    onPageChange,
    onOrderingChange
}) => {
    const handleFieldChange = (fieldName, recordId) => newValue => onRecordChange(recordId, fieldName, newValue);

    return (
        <ActuallyTable
            tableColumns={tableColumns}
            tableData={getEditableOverallReportListingData(records, handleFieldChange, setErrorsForEntity, isLocked)}
            ordering={ordering}
            pagination={pagination}
            footer={footer}
            emptyResultProps={{
                paragraphs: overallReportEmptyResultParagraphs
            }}
            isLoading={isLoading}
            onPageChange={onPageChange}
            onOrderingChange={onOrderingChange} />
    )
}