import {useState, useEffect, useCallback} from "react";

import {
  getAvailableTenants,
  getUserAvailable,
  postTenantWithUser
} from "../../../api/authentication/authentication-api";
import {sendRequestSimple} from "../../../api/api";

// FIXME: TB-160
export const useTenantAPI = (): any=> {
  const fetchTenants = async () => {
    return await sendRequestSimple(getAvailableTenants());
  };

  const createTenantWithUser = async (payload) => {
    return await sendRequestSimple(postTenantWithUser(payload));
  };

  const fetchUserAvailable = async params => {
    return await sendRequestSimple(getUserAvailable(params));
  };

  return {
    fetchTenants,
    createTenantWithUser,
    fetchUserAvailable
  }
};


export const useAvailableTenantFetching = () => {
  const {fetchTenants} = useCallback(useTenantAPI(), []);

  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const {response} = await fetchTenants();

      if (response) {
        const formated = convertTenantsIntoSelectCompatible(response);

        setTenants(formated);
      }
    };
    fetch();
  }, [fetchTenants]);

  return tenants;
};

export function convertTenantsIntoSelectCompatible(response) {
  return response.map(tenant => {
    const name = tenant.name || `Workspace ${tenant.id}`;

    return {...tenant, label: name, value: tenant.id};
  });
}