import React from "react";
import PropTypes from 'prop-types';

import { ActuallyButton } from "../../../../components/buttons";

import AuthorizationGrantRow from "./AuthorizationGrantRow";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

function AuthorizationRow({authRight, onChangeRules}) {
  return (
    <div className="authorization-row">
      <Row className="align-items-center">
        <Col>
          <span className="bold">
            <LocalizedMessage text={{id: authRight.short_desc}}/>
          </span>
        </Col>
        <Col className="text-right">
          <ActuallyButton variant="link" onClick={() => onChangeRules(authRight)}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationRulesSettings.editSingleRuleLink}}/>
          </ActuallyButton>
        </Col>
      </Row>
      <AuthorizationGrantRow grantedRights={authRight.grantedRights}/>
    </div>
  );
}

AuthorizationRow.propTypes = {
  authRight: PropTypes.object,
  onChangeRules: PropTypes.func
};


export default AuthorizationRow;