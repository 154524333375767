import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestSimple } from "../../../../api/api";
import { getTenants, updateTenant } from "../../../../api/admin/admin-tenants-api";
import { TenantStatus } from "../../../../types/entities/Tenant";

export const fetchTenantsAction = createAsyncThunk('admin/tenants', async () => {
  const {response} = await sendRequestSimple(getTenants(), true);
  return response;
});

export interface UpdateTenantProps {
  id: number,
  trial_ending_date: string,
}

export const updateTenantSubscriptionAction = createAsyncThunk(
  'admin/updateTenant', async (params: UpdateTenantProps) => {
    const {response} = await sendRequestSimple(updateTenant(params), true);
    return response;
})

export const changeTenantStatusFilterAction = createAction<TenantStatus>('changeTenantStatusFilter');