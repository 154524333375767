import paginationSagaTypes from "./pagination-types";

// To illustrate the structure
const initialState = {
  people: {
  },
};

export const defaultPaginationState = {
  current: 0,
  itemsPerPage: 0,
  count: 0,
  totalPages: 0,
};

function updatePaginationForPage(state = defaultPaginationState, action) {
  const {pagination} = action;
  return {...state, ...pagination};
}

function changeCurrentPage(state = defaultPaginationState, pageNo) {
  return {...state, current: pageNo};
}

function paginationReducer(state = initialState, action) {
  switch (action.type) {
    case paginationSagaTypes.RECEIVE_NEW_PAGINATION_FOR_PAGE:
      const {page} = action;

      return {...state, [page]: updatePaginationForPage(state[page], action)};
    case paginationSagaTypes.CHANGE_PAGE: {
      const {page} = action;

      return {...state, [page]: changeCurrentPage(state[page], action.newPageNo)};
    }

    default:
      return state;
  }
}


export default paginationReducer;