import React, { useRef, useEffect, useContext, useCallback, Component, createRef } from "react";

import { VariableSizeList } from "react-window";

const VirtualListContext = React.createContext({});

// FIXME: TB-160 -- Also convert to FunctionComponent
class VirtualList extends Component<any> {
  virtualWindowRef: any;
  constructor(props) {
    super(props);

    this.virtualWindowRef = createRef();
  }

  render() {
    const {items, height, component, defaultRowHeight} = this.props;

    return (
      <VirtualListContainer
        virtualWindowRef={this.virtualWindowRef}
        defaultRowHeight={defaultRowHeight}
        items={items}
        height={height}
        component={component}/>
    )
  };
}

function VirtualListContainer({items, height, component, defaultRowHeight, virtualWindowRef}) {
  const domElementHeights = useRef({});

  const setSize = useCallback((index, size) => {
    domElementHeights.current = {...domElementHeights.current, [index]: size};
    virtualWindowRef.current.resetAfterIndex(index);
  }, [virtualWindowRef]);

  const getSize = useCallback(index => {
    return domElementHeights.current[index] || defaultRowHeight;
  }, [domElementHeights, defaultRowHeight]);

  return (
    <VirtualListContext.Provider value={{setSize}}>
      <VariableSizeList
        itemData={{items: items, Component: component}}
        height={height}
        itemCount={items.length}
        itemSize={getSize}
        width="100%"
        ref={virtualWindowRef}>
        {VirtualListWrapperRow}
      </VariableSizeList>
    </VirtualListContext.Provider>
  );
}

VirtualListContainer.defaultProps = {
  defaultRowHeight: 30
};

function VirtualListWrapperRow({data, index, style}) {
  const {Component, items} = data;

  // FIXME: TB-160
  const {setSize}: any = useContext(VirtualListContext);
  const root: any = useRef();

  useEffect(() => {
    const [childInstance] = root.current?.children;

    setSize(index, childInstance.clientHeight + 10);
  }, [root, index, setSize]);

  return (
    <div ref={root} style={style} className="overflow-hidden">
      <Component item={items[index]} index={index}/>
    </div>
  );
}

export default VirtualList;