import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Select from 'react-select'

import {AppLogo} from "../../../components/ui-utility/AppLogo";
import {CenteredSection} from "../../../components/layout/LayoutHelpers";
import {useAvailableTenantFetching} from "./tenant-selection-helpers";
import {authenticateIntoTenantAction} from "../../../store/ducks/auth/actions";

import {TimesheetSection} from "../Login";
import {PrimaryButton} from "../../../components/buttons";

import ToggleSwitch from "../../../components/toggle-switch/ToggleSwitch";


import '../authentication.css';

function TenantSelection() {
  const dispatch = useDispatch();
  // FIXME: TB-160
  const [selected, setSelected] = useState<any>({});

  const tenants = useAvailableTenantFetching();

  const handleSubmit = () => {
    dispatch(authenticateIntoTenantAction(selected.id, selected.asDefault));
  };

  return (
      <CenteredSection className="mt-4">
        <TimesheetSection>
          <h3 className="text-center mb-2">
            <AppLogo/>
          </h3>

          <div className="text-center mb-3">
            <span className="intro-text">Select the workspace you want to work with.</span>
          </div>
          <div className="mb-3">
            <Select value={selected}
                    options={tenants}
                    onChange={setSelected} />
          </div>
          <div className="d-flex align-items-center justify-content-end mb-3">
            <span className="intro-text mr-2"> Use this workspace as my default</span>
            <ToggleSwitch isChecked={selected.asDefault}
                          onChangeOfValue={asDefault => setSelected({...selected, asDefault})}/>
          </div>
          <div className="text-right">
            <PrimaryButton type={"submit"} disabled={!selected.id}
                           onClick={handleSubmit}>Lets roll!</PrimaryButton>
          </div>

        </TimesheetSection>
      </CenteredSection>
  )
}

export default TenantSelection;