import uuid from "uuid";

import {includes, isArray} from 'lodash';

class ColumnRowEntityManager {
  // FIXME: TB-160
  availableColumnList: any;
  availableColumnMap: any;
  uiColumnsList: any;
  constructor(availableColumns, chosenColumns) {
    this.availableColumnList = this.createAvailableColumnList(availableColumns);
    this.availableColumnMap = this.createAvailableColumnMap(availableColumns);
    this.uiColumnsList = chosenColumns || [];

    if (this.canAddNewColumn() && !this.doesContainAutomaticallyGeneratedColumn(chosenColumns))
      this.addUiColumn();
  }

  createAvailableColumnList = (columns) => columns.map(column => column.identifier);

  createAvailableColumnMap = (columns) => {
    return columns.reduce((accumulator, column) => ({...accumulator, [column.identifier]: column}), {});
  };

  getAvailableColumns = () => {
    const stillSelectableColumns = this.availableColumnList.filter(id => !includes(this.uiColumnsList, id));

    return stillSelectableColumns.map(id => this.availableColumnMap[id]);
  };

  addUiColumn = () => {
    if (this.canAddNewColumn())
      this.uiColumnsList.push(uuid.v4());
    return this.uiColumnsList;
  };

  canAddNewColumn = () => (this.uiColumnsList.length < this.availableColumnList.length);

  selectUiColumn = (position, selectedColumn) => {
    const isExistingColumn = this.availableColumnMap[this.uiColumnsList[position]];

    this.uiColumnsList[position] = selectedColumn.identifier;

    if (!isExistingColumn)
      this.addUiColumn();

    return this.uiColumnsList;
  };

  deleteUiColumn = (column) => {
    const columnIndex = this.uiColumnsList.indexOf(column.identifier);

    if (this.shouldAutomaticallyAddNewColumn()) {
      this.removeColumnAndAddNew(columnIndex);
    } else
      this.removeColumnFromIndex(columnIndex);
    return this.uiColumnsList;
  };

  shouldAutomaticallyAddNewColumn = () => {
    const allSelections = this.uiColumnsList.map(identifier => (this.availableColumnMap[identifier]));
    const filledSelections = allSelections.filter(Boolean);

    return allSelections.length === filledSelections.length;
  };

  removeColumnAndAddNew = (columnIndex) => {
    this.removeColumnFromIndex(columnIndex);
    this.addUiColumn();
  };

  removeColumnFromIndex = (columnIndex) => {
    this.uiColumnsList.splice(columnIndex, 1);
  };

  moveUiColumn = (column, directionIsUp) => {
    if (directionIsUp)
      this.moveColumnUp(column);
    else
      this.moveColumnDown(column);

    return this.uiColumnsList;
  };

  moveColumnUp = column => {
    const currentColumnIndex = this.uiColumnsList.indexOf(column.identifier);
    const currentColumnIndexValue = this.uiColumnsList[currentColumnIndex];

    if (currentColumnIndex > 0) {
      const swappedColumnIndex = currentColumnIndex - 1;

      this.uiColumnsList[currentColumnIndex] = this.uiColumnsList[swappedColumnIndex];
      this.uiColumnsList[swappedColumnIndex] = currentColumnIndexValue;
    }

    if (currentColumnIndex === 0) {
      this.uiColumnsList.push(this.uiColumnsList.shift());
    }
  };

  moveColumnDown = column => {
    const currentColumnIndex = this.uiColumnsList.indexOf(column.identifier);
    const currentColumnIndexValue = this.uiColumnsList[currentColumnIndex];

    if (currentColumnIndex < this.uiColumnsList.length - 1) {
      const swappedColumnIndex = currentColumnIndex + 1;

      this.uiColumnsList[currentColumnIndex] = this.uiColumnsList[swappedColumnIndex];
      this.uiColumnsList[swappedColumnIndex] = currentColumnIndexValue;
    }

    if (currentColumnIndex === this.uiColumnsList.length - 1) {
      this.uiColumnsList.unshift(this.uiColumnsList.pop());
    }

  };

  setNewUiColumnValue = (column, newValue) => {
    this.availableColumnMap[column.identifier].value = newValue;

    return this.uiColumnsList;
  };

  getUiColumns = () => this.uiColumnsList.map(identifier => (this.availableColumnMap[identifier] || {identifier}));

  doesContainAutomaticallyGeneratedColumn = cols => {
    return cols.filter(col => !this.availableColumnList.includes(col)).length > 0;
  }

  getSelectedColumns = () => {
    return this.uiColumnsList.filter(selectedColumnId => this.availableColumnList.includes(selectedColumnId));
  }

  formatForAjax = () => {
    return this.uiColumnsList.reduce((accum, identifier) => {
      const column = this.availableColumnMap[identifier];

      if (!column)
        return accum;

      const activeColumns = accum.activeColumns.concat([column.name]);
      const activeCount = accum.activeCount + 1;

      let queryParamValue = accum[column.asQueryParam];

      if (queryParamValue) {
        if (isArray(queryParamValue))
          queryParamValue.push(column.value);
        else {
          queryParamValue = [queryParamValue, column.value];
        }
      } else {
        queryParamValue = [column.value];
      }
      return {...accum, [column.asQueryParam]: queryParamValue, activeColumns, activeCount};
    }, {activeCount: 0, activeColumns: []});
  };

}

export {
  ColumnRowEntityManager,
}

