import GeneralApiResponseParser from "../../general-api-response-parser";

class CycleResponse {

  static parseCycleHistoryForApproverResponse = response => {
    const {cycles, cycles_completed} = response;

    const cyclesNormalized = GeneralApiResponseParser.normalizeListingNoSchema(cycles);

    return {
      cycles: cyclesNormalized,
      cycles_completed
    }
  }

}

export default CycleResponse;
