import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { If } from "../../../../../components/ui-utility/Conditional";

import PhaseActionRow from "./PhaseActionRow";
import PlainTable from "../../../../../components/ui-utility/table/PlainTable";
import ApproverPhaseStep from "./ApproverPhaseStep";
import EmptyStatePlaceholder from "../../../../../components/ui-utility/EmptyStatePlaceholder";
import {LocalizedMessage} from "../../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../../intl/intl";


function ApproverPhase({phaseSteps}) {
  // FIXME: TB-160
  const [hiddenStepIds, setHiddenStepIds] = useState<any>([]);

  // FIXME: TB-160
  const visibleSteps = phaseSteps.filter((step: any) => !hiddenStepIds.includes(step.id));

  return (
    <div className="approver-phase">
      <Row className="mb-2">
        <Col>
          <PhaseActionRow steps={phaseSteps} onShownChange={ids => setHiddenStepIds(ids)}/>
        </Col>
      </Row>
      <If condition={!visibleSteps.length}>
        <EmptyStatePlaceholder iconName="table">
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.table.noRecordsToApproveMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
      <If condition={visibleSteps.length}>
        <PlainTable hoverable={true} showLines={false} alignToSides={true}>
          <thead>
          <tr>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.table.person}}/>
            </th>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.table.status}}/>
            </th>
            <th>
              <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.table.action}}/>
            </th>
          </tr>
          </thead>
          <tbody>
          {visibleSteps.map(phaseStep => (
            <ApproverPhaseStep step={phaseStep} key={phaseStep.id}/>
          ))}
          </tbody>
        </PlainTable>
      </If>
    </div>
  );
}

ApproverPhase.defaultProps = {
  phaseSteps: []
}

export default ApproverPhase;
