import { takeLatest, put, select, call, fork } from 'redux-saga/effects';

import executeRequest from "../../../api/api";

import {
  fetchUserDetailsSuccess,
  fetchUserEmploymentSuccess,
  receivePeopleAction,
  receivePeopleSnapshotAction
} from './actions';
import { changePaginationForPageAction } from "../pagination/pagination-actions";

import {
  getPeople,
  getPeopleSnapshot,
  getUser,
  PeopleAPIResponseParser, getUserEmploymentInformation
} from '../../../api/people/people-api';

import peopleSagaTypes from './types';
import paginationSelectors from "../pagination/pagination-selectors";
import orderingSelectors from "../ordering/ordering-selectors";

import { paginationKeys } from "../pagination/pagination-keys";
import filterSelectors from "../filter/filter-selectors";
import PeoplePageFilterBlock
  from "../../../components/filter/configuration/filter-blocks-for-page/PeoplePageFilterBlock";

export function* fetchPeopleSagaFlow(action) {
  const page = yield select(paginationSelectors.selectCurrentPageForPage(paginationKeys.PEOPLE_LISTING));
  const ordering = yield select(orderingSelectors.selectOrderingAsQueryStringForPage(paginationKeys.PEOPLE_LISTING));
  const filters = yield select(filterSelectors.selectActiveFiltersAsQueryParams(PeoplePageFilterBlock.blockKey));

  const {response} = yield call(executeRequest, getPeople({page, ordering, ...filters}),
    {cancelOnFailure: true, trackRequest: action.type});

  yield fork(fetchPeopleSnapshotFlow, {page, ordering, ...filters});
  const responseWithNormalization = PeopleAPIResponseParser.parseNormalizedResponse(response.data);

  yield put(receivePeopleAction(responseWithNormalization));
  yield put(changePaginationForPageAction(response.data, paginationKeys.PEOPLE_LISTING));
}

function* fetchPeopleSnapshotFlow(params) {
  const {response} = yield call(executeRequest, getPeopleSnapshot({...params}), {cancelOnFailure: true, trackRequest: peopleSagaTypes.RECEIVE_PEOPLE_SNAPSHOT});

  yield(put(receivePeopleSnapshotAction(response.data)));
}

export function* fetchUserDetails(action) {
  const {response} = yield call(executeRequest, getUser(action.payload), {cancelOnFailure: true});

  yield put(fetchUserDetailsSuccess(response.data));
}

export function* fetchUserEmployment(action) {
  const userId = action.payload;

  const {response} = yield call(executeRequest, getUserEmploymentInformation(userId), {cancelOnFailure: true});

  yield put(fetchUserEmploymentSuccess(response.data));
}


const peopleSagaWatchers = [
  takeLatest(peopleSagaTypes.FETCH_PEOPLE, fetchPeopleSagaFlow),
  takeLatest(peopleSagaTypes.FETCH_USER_DETAILS, fetchUserDetails),
  takeLatest(peopleSagaTypes.FETCH_USER_EMPLOYMENT, fetchUserEmployment),
];

export default peopleSagaWatchers;