import { put, takeEvery } from "redux-saga/effects";
import paginationSagaTypes from "./pagination-types";

import { receiveNewPaginationForPage } from "../pagination/pagination-actions";
import { defaultPaginationState } from "./pagination-reducers";
import GeneralApiResponseParser from "../../../api/general-api-response-parser";

function* updatePaginationWatch(action) {
  const {response, page} = action;
  
  const extractedPagination = response ?
    GeneralApiResponseParser.extractPaginationData(response) : defaultPaginationState;

  yield put(receiveNewPaginationForPage(extractedPagination, page));
}


export const paginationSagas = [
  takeEvery(paginationSagaTypes.CHANGE_PAGINATION_FOR_PAGE, updatePaginationWatch),
];
