import React from "react";
import classNames from 'classnames';

import { useTimeInput } from "../../../util/hooks";
import styled from "styled-components";

interface StyledInputProps {
  invalid: number;
}

const StyledInput = styled.input<StyledInputProps>`
  text-align: center;
  min-width: 60px;
  font-size: 0.8rem;
  ${props => props.invalid ? `
    background-color: #FFE3E3;
    borderTopColor: #FFBDBD;
  ` : ''}
`

interface MinutesInputProps {
  minutesOfDay: number;
  onSave: (minutes: number) => void;
  className?: string;
  disabled?: boolean;
  tabIndex?: number; 
  autoFocus?: boolean;
}

const MinutesInput: React.FunctionComponent<MinutesInputProps> = ({minutesOfDay, onSave, className, disabled, tabIndex, autoFocus}) => {
  const {onBlur, onFocus, onChange, onKeyDown, value, isInvalid} = useTimeInput(minutesOfDay, onSave);
  const classes = classNames("form-control", className);

  return (
    <StyledInput type="text"
           disabled={disabled}
           invalid={isInvalid ? 1 : 0}
           className={classes}
           value={value}
           onFocus={onFocus}
           onBlur={onBlur}
           onKeyDown={onKeyDown}
           tabIndex={tabIndex}
           autoFocus={autoFocus}
           onChange={onChange}/>
  )
}

export default MinutesInput;