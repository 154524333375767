import React, {useState} from "react";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import {DefaultBlock} from "../layout/LayoutHelpers";
import {IconProp, SizeProp} from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";

interface BaseIconOwnPropTypes {
  iconName: IconProp;
}

export type BaseIconProps = Omit<FontAwesomeIconProps, 'icon'> & BaseIconOwnPropTypes;

export const BaseIcon: React.FunctionComponent<BaseIconProps> = ({iconName, ...props}) => {
  return (<FontAwesomeIcon icon={iconName} {...props} />)
}

export function IncreaseTimeButton({className, ...props}) {
  const classes = classNames(
    className,
    'btn btn-block touch-action-manipulation',
  );

  return (
    <button type="button" className={classes} {...props}>
      <PlusIcon className="fa-2x"/>
    </button>
  )
}

export function DecreaseTimeButton({className, ...props}) {
  const classes = classNames(
    className,
    'btn btn-block touch-action-manipulation',
  );

  return (
    <button type="button" className={classes} {...props}>
      <MinusIcon className="fa-2x"/>
    </button>
  )
}

export function DoubleAngleLeftIcon() {
  return (
    <FontAwesomeIcon icon="angle-double-left"/>
  )
}

export function DoubleAngleRightIcon() {
  return (
    <FontAwesomeIcon icon="angle-double-right"/>
  )
}

export function AngleLeftIcon() {
  return (
    <FontAwesomeIcon icon="angle-left"/>
  )
}

export function AngleRightIcon({light = false}) {
  const baseClass = light ? "fal" : "fas";

  return (
    <BaseIcon iconName={[baseClass, "angle-right"]}/>
  )
}

export function LightbulbExclamationIcon({light}) {
  const baseClass = light ? "fal" : "fas";

  return (
    <BaseIcon iconName={[baseClass, "lightbulb-exclamation"]}/>
  )
}

interface LightIconProps {
  light?: boolean
}

interface AngleDownIconProps extends LightIconProps {
  size?: SizeProp;
}

export function AngleDownIcon({light = false, size}: AngleDownIconProps) {
  const baseClass = light ? "fal" : "fas";

  return (
    <BaseIcon size={size || "1x"} iconName={[baseClass, "angle-down"]}/>
  )
}

interface AngleUpIconProps extends LightIconProps {
  size?: SizeProp;
}

export function AngleUpIcon({light = false, size = "1x"}: AngleUpIconProps) {
  const baseClass = light ? "fal" : "fas";

  return (
    <BaseIcon size={size as SizeProp} iconName={[baseClass, "angle-up"]}/>
  )
}

export function TimesIcon({...props}) {
  return (
    <FontAwesomeIcon {...props} icon="times"/>
  )
}

export function CaretUpIcon() {
  return (
    <FontAwesomeIcon icon="caret-up"/>
  )
}

export function CaretDownIcon() {
  return (
    <FontAwesomeIcon icon="caret-down"/>
  )
}

export function ExchangeIcon() {
  return (<BaseIcon iconName="exchange"/>);
}

export function IndentIcon() {
  return (<BaseIcon iconName="indent"/>);
}

export function ColumnIcon() {
  return (<BaseIcon iconName="columns"/>);
}

export function ExclamationTriangleIcon() {
  return (<BaseIcon iconName="exclamation-triangle" style={{fontSize: '0.7em'}}/>);
}

export function DivideIcon() {
  return (<BaseIcon iconName="divide"/>);
}

export function TrashIcon() {
  return (<BaseIcon iconName="trash"/>);
}

export function PlusIcon({...props}) {
  return (<BaseIcon {...props} iconName="plus"/>);
}

export function MinusIcon({...props}) {
  return (<BaseIcon {...props} iconName="minus"/>);
}

export function CaretRightIcon({...props}) {
  return (<BaseIcon iconName="caret-right" {...props}/>);
}

export function LockOpenIcon() {
  return (<BaseIcon iconName="lock-open"/>);
}

export function LockClosedIcon() {
  return (<BaseIcon iconName="lock"/>);
}

export function ArrowUpIcon() {
  return (<BaseIcon iconName="arrow-up"/>);
}

export function ArrowDownIcon() {
  return (<BaseIcon iconName="arrow-up" className="rotate-180"/>);
}

export function CheckCircleIcon() {
  return (<BaseIcon iconName="check-circle"/>);
}

export function SpinnerIcon() {
  return (<BaseIcon iconName="spinner"/>)
}

export function FileExportIcon() {
  return (<BaseIcon iconName="file-export"/>);
}

export function SignOutIcon() {
  return (<BaseIcon iconName={["fal", "sign-out"]}/>);
}

export function SignInIcon() {
  return (<BaseIcon iconName="sign-in"/>);
}

export function MenuCollapsedIcon(props) {
  return <BaseIcon iconName={["fal", "bars"]} {...props} />
}

export function MenuExpandedIcon(props) {
  return <BaseIcon iconName={["fal", "times"]} {...props} />
}

export function FileChartLineIcon() {
  return (<BaseIcon iconName={["fal", "file-chart-line"]}/>);
}

export function UsersIcon() {
  return (<BaseIcon iconName={["fal", "users"]}/>);
}

export function StopWatchIcon() {
  return (<BaseIcon iconName={["fal", "clock"]}/>);
}

export function BriefcaseIcon() {
  return (<BaseIcon iconName={["fal", "briefcase"]}/>);
}

export function InfoCircleIcon() {
  return (<BaseIcon iconName="info-circle"/>);
}

export function CogIcon({light = false}) {
  const baseClass = light ? "fal" : "fas";

  return (<BaseIcon iconName={[baseClass, "cog"]}/>);
}

export function CalendarEditIcon() {
  return (<BaseIcon iconName={["fal", "calendar-edit"]}/>);
}

export function AuthorizationGroupsIcon() {
  return (<BaseIcon iconName={["fal", "users-class"]}/>);
}

export function AccessRightsIcon() {
  return (<BaseIcon iconName={["fal", "users-slash"]}/>);
}

export function PenIcon({light = false, ...props}) {
  const baseClass = light ? "fal" : "fas";

  return (<BaseIcon iconName={[baseClass, "pen"]} {...props} />);
}

export function BanIcon(props) {
  return (<BaseIcon iconName="ban" {...props} />);
}

export function ProfileBadgeIcon(props) {
  return (<BaseIcon iconName={["fal", "id-badge"]} {...props} />);
}

export function CheckIcon(props) {
  return (<BaseIcon iconName="check" {...props} />);
}

export function CrownIcon(props) {
  return (<BaseIcon iconName="crown" {...props} />);
}

export function QuestionCircleIcon(props) {
  return (<BaseIcon iconName="question-circle" {...props} />);
}

export function ToolsIcon(props) {
  return (<BaseIcon iconName={["fal", "tools"]} {...props} />);
}

export function QuoteRightIcon(props) {
  return (<BaseIcon iconName={["fal", "quote-right"]} {...props} />);
}

export function LockIcon(props) {
  return (<BaseIcon iconName="lock" {...props} />);
}

export const BellIcon: React.FunctionComponent<LightIconProps> = ({light = false}) => {
  const baseClass = light ? "fal" : "fas";

  return (
    <BaseIcon iconName={[baseClass, "bell"]}/>
  )
}

export function LoadingIcon({className, ...props}) {
  const classes = classNames(
    'fa-spin',
    className,
  );

  return (
    <BaseIcon iconName="spinner" className={classes} {...props} />
  )
}

export function DeleteLinkToButton({
                                     label,
                                     confirmLabel,
                                     onConfirm,
                                     type = "button" as React.ButtonHTMLAttributes<HTMLButtonElement>['type'],
                                     className = undefined,
                                     ...props
                                   }) {
  const [confirm, setConfirm] = useState(false);

  const classes = classNames(
    'btn',
    confirm ? 'btn-danger' : 'btn-delete',
    className,
  );

  if (confirm) {
    return (
      <button {...props}
              type={type}
              className={classes}
              onClick={() => onConfirm()}>
        {confirmLabel}
      </button>
    )
  }

  return (
    <button {...props} type={type}
            className={classes}
            onClick={() => setConfirm(true)}>
      {label}
    </button>
  )
}

function IconButton({iconName, onClick, className = '', ...props}) {
  return (
    <button className="btn btn-link btn-light22" {...props} onClick={onClick}>
      <FontAwesomeIcon icon={iconName} className={className}/>
    </button>
  );
}

export function PreviousWeekButton({onClick, ...props}) {
  return (
    <IconButton {...props} iconName="angle-left" className="fa-2x" onClick={onClick}
                style={{...props.style, touchAction: 'manipulation'}}/>
  )
}

export function CurrentDayButton({onClick, ...props}) {
  return (
    <IconButton {...props} iconName="home" className="fa" onClick={onClick}
                style={{...props.style, touchAction: 'manipulation'}}/>
  )
}

export function NextWeekButton({onClick, ...props}) {
  return (
    <IconButton iconName="angle-right" onClick={onClick} className="fa-2x"
                style={{...props.style, touchAction: 'manipulation'}}/>
  )
}

export function CloseButton({onClick}) {
  return <IconButton iconName="times" onClick={onClick} style={{color: "#102A43"}}/>
}

// FIXME: TB-160
interface PrimaryButtonProps {
  className?: string;
  variant?: string;
  children?: any;
  disabled: boolean;
  onClick?: () => void;
  type: string;
}

export const PrimaryButton = React.forwardRef((props: PrimaryButtonProps, ref) => {
  return <ActuallyButton ref={ref} {...props} variant="primary"/>
});

export const SecondaryButton = React.forwardRef((props, ref) => {
  return <ActuallyButton ref={ref} {...props} variant="secondary"/>
});

// FIXME: TB-160
interface ActuallyButtonProps {
  className?: string;
  variant: string;
  children?: any;
  type?: any;
  onClick?: any;
  disabled?: boolean;
  tabIndex?: number;
  leftIcon?: React.ReactNode;
}

const ActuallyButtonIconWrapper = styled.div`
    padding-right: 0.5em;
`
const ActuallyButtonContentWrapper = styled.div`
    flex: 1;
`
const ActuallyButtonWrapper = styled.div`
  display: flex;
`

// FIXME: TB-160
export const ActuallyButton = React.forwardRef((props: ActuallyButtonProps, ref: ((instance: any) => void) | React.MutableRefObject<any> | null) => {
  let classes = classNames('btn', props.className);

  if (props.variant === 'primary')
    classes = classNames(classes, "btn-primary btn-actually-primary");
  else if (props.variant === 'secondary')
    classes = classNames(classes, "btn-secondary btn-actually-secondary");
  else if (props.variant === 'outline-secondary')
    classes = classNames(classes, "btn-outline-secondary");
  else if (props.variant === 'transparent')
    classes = classNames(classes, "btn-transparent");
  else if (props.variant === 'slick')
    classes = classNames(classes, "btn-slick");
  else if (props.variant === 'link')
    classes = classNames(classes, "btn-link");
  else if (props.variant === 'light')
    classes = classNames(classes, "btn-light");
  else if (props.variant === 'delete')
    classes = classNames(classes, 'btn-danger');
  else if (props.variant === 'delete-pre')
    classes = classNames(classes, 'btn-delete');

  const {children, leftIcon, ...rest} = props;

  return (
    <button ref={ref} type="button" {...rest} className={classes}>
      <ActuallyButtonWrapper>
        {leftIcon && (
          <ActuallyButtonIconWrapper>
            {leftIcon}
          </ActuallyButtonIconWrapper>
        )}
        <ActuallyButtonContentWrapper>
          {children}
        </ActuallyButtonContentWrapper>
      </ActuallyButtonWrapper>
    </button>
  )
});

// FIXME: TB-160
interface BlockButtonProps {
  className?: string;
  children?: any;
  onClick?: () => void;
}

export const BlockButton = React.forwardRef((props: BlockButtonProps, ref) => {
  const classes = classNames('btn-block', props.className);

  return (
    <ActuallyButton ref={ref} {...props} variant="slick" className={classes}>
      <DefaultBlock>
        {props.children}
      </DefaultBlock>
    </ActuallyButton>
  )
});
