import React, { useEffect, useCallback } from "react";
import { useProjectMembershipAPI } from "./project-members-api";

import ProjectMembershipAvatarSelector, { AvatarPlusXMore } from "./ProjectMembershipAvatar";
import { membershipSelectors } from "./project-members-reducer";
import { useSelector } from "react-redux";
import { If } from "../../../components/ui-utility/Conditional";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import InactiveIcon from "../../../components/icons/InactiveIcon";

const ProjectMembersManager = ({projectId}) => {
  const userIds = useSelector(membershipSelectors.selectUserIds);
  const hasMembers = userIds.length > 0;

  const {fetchProjectMemberships} = useCallback(useProjectMembershipAPI(), [projectId]);

  useEffect(() => {
    fetchProjectMemberships(projectId);
  }, [projectId, fetchProjectMemberships]);

  return (
    <>
      <If condition={hasMembers}>
        <AvatarPlusXMore collection={userIds} limit={5}
                         renderFn={(userId, index) => <ProjectMembershipAvatarSelector key={index} userId={userId}/>}/>
      </If>

      <If condition={!hasMembers}>
        <InactiveIcon className="ml-1">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectMembersModal.noActiveProjectMembersMessage}} />
        </InactiveIcon>

      </If>
    </>
  )
};

export {
  ProjectMembersManager as default,
};
