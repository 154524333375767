import React from 'react';
import {Controller} from "react-hook-form";
import ColumnBlockContainer from "./ColumnBlockContainer";
import DisabledOverlay from "../ui-utility/DisabledOverlay";


function FormColumnSelect({onChange, control, rules=undefined, name, watch, columns, disabled}) {
  const selectedColumns = watch(name);

  const onChangeProxy = cols => {
    if (disabled)
      return false;

    onChange(cols);
  }

  const component = (
      <DisabledOverlay disabled={disabled}>
        <ColumnBlockContainer columns={columns}
                              selectedColumns={selectedColumns}
                              onColumnsChanged={onChangeProxy}/>
      </DisabledOverlay>
  );
  return (
      <Controller as={component}
                  control={control}
                  name={name}
                  rules={rules}/>
  )
}

export default FormColumnSelect;
