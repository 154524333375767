import React from "react";

import { Link } from "@reach/router";

import { ActuallyButton } from "../../../components/buttons";
import { usePasswordResetApi } from "./use-password-reset";
import { LocalizedMessage } from "../../../intl/components/LocalizedMessage";
import { TRANSLATION_KEYS } from "../../../intl/intl";


function PasswordResetForm({email, setEmail, onGoBack}) {
  const {requestForLink} = usePasswordResetApi();

  const onSendResetLink = async (event) => {
    event.preventDefault();

    await requestForLink(email);
  };

  return (
    <form onSubmit={onSendResetLink}>
      <div className="form-group">
        <label className="label-default"><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.inputEmail }} /></label>
        <input className="form-control" name="email" type="email" onChange={e => setEmail(e.target.value)}
               value={email} required/>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <ActuallyButton variant="transparent" onClick={onGoBack}>
          <Link to=""><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.resetPassword.backToLoginButton }} /></Link>
        </ActuallyButton>
        <ActuallyButton variant="primary" type={"submit"}>
          <span><LocalizedMessage text={{ id: TRANSLATION_KEYS.login.resetPassword.sendResetLinkLabel }} /></span></ActuallyButton>
      </div>
    </form>
  )
}

export default PasswordResetForm;