import FilterFactory from "../../entities/FilterFactory";
import FilterBlockEntity from "../../entities/FilterBlockEntity";
import FilterRegistry from "../filter-registry";

class PeoplePageFilterBlock extends FilterBlockEntity {
  static blockKey = 'peoplePage';

  createFilters() {
    return [
      FilterFactory.createBooleanFilter('user.is_active'),
      FilterFactory.createInputFilter('user.q'),
    ];
  };

}

FilterRegistry.registerFilterBlock(PeoplePageFilterBlock.blockKey, new PeoplePageFilterBlock());

export default PeoplePageFilterBlock;
