import React from "react";
import { DoubleAngleLeftIcon, DoubleAngleRightIcon } from "../buttons";
import PersonEntity from '../../screens/people/PersonEntity';
import {useSelector} from "react-redux";
import peopleSelectors from "../../store/ducks/people/selectors";
import {PersonLink} from "./PersonLink";

function NotSpecified() {
  return (
    <span className="not-specified">
      <DoubleAngleLeftIcon/>
        <span className="not-specified-label">Not specified</span>
      <DoubleAngleRightIcon/>
    </span>
  )
}

export function PersonFullNameSelector({userId, addLink = false}) {
  const user = useSelector(peopleSelectors.selectUser(userId));

  return <PersonFullName {...user} addLink={addLink}/>
}


interface PersonFullNameProps {
  id?: number,
  first_name?: string,
  last_name?: string,
  addLink?: boolean,
}

const PersonFullName: React.FunctionComponent<PersonFullNameProps> = ({addLink, id, first_name, last_name}) => {
  const person = new PersonEntity({id, first_name, last_name});

  const renderName = () => {
    if (person.hasName()) {
      if (addLink) {
        return (
          <PersonLink userId={person.id}>
            {fullNameTemplate}
          </PersonLink>
        )
      }
      return fullNameTemplate;
    }
    return nameNotSpecifiedTemplate;
  };

  const fullNameTemplate = <span>{person.getFullName()}</span>;
  const nameNotSpecifiedTemplate = (<NotSpecified/>);

  return renderName();
}

export {
  PersonFullName as default,
  NotSpecified
};
