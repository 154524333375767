import * as React from 'react';
import { ControlWrapperControl, ControlWrapper } from '../../../components/layout/ControlWrapper';
import { TRANSLATION_KEYS } from '../../../intl/intl';
import ActiveFilterBlock from '../../../components/filter/ActiveFilterBlock';
import RequireGlobalRight from '../../../components/rights/RequireGlobalRight';
import RIGHTS from '../../../components/rights/right-definitions';
import AddPeople from '../../../components/people/add-people/AddPeople';
import { DefaultBlock } from '../../../components/layout/LayoutHelpers';
import { getPeopleListingColumnConfiguration, peopleListingEmptyResultParagraphs } from './people-listing-columns';
import { PersonLink } from '../../../components/people/PersonLink';
import PersonFullName from '../../../components/people/PersonFullName';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import PersonLatestRecord from '../../../components/people/PersonLatestRecord';
import { PersonUtilization } from '../../../components/people/PersonUtilization';
import PersonBalance from '../../../components/people/PersonBalance';
import { ActuallyTable } from '../../../components/table/ActuallyTable';
import { PaginationData } from '../../../components/pagination/Pagination';
import styled from 'styled-components';
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

const StyledAddPeople = styled(AddPeople)`
  .btn-actually-primary {
    padding: .175rem .75rem;
  } 
`

const PersonNameWrapper = styled.span`
  padding-right: .25rem;
`

// FIXME: TB-160
interface OwnPropTypes {
  filterBlock: any;
  results: Array<number>
  activeFilters: any;
  records: any;
  ordering: Array<string>
  pagination?: PaginationData;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOrderingChange: (newOrdering: Array<string>) => void;
}

export type PeopleListingProps = OwnPropTypes;

const getPeopleListingData = (users: Array<number>) => users.map((user: any) => {
  // FIXME: Janne The person is set as inactive - localize!!
  return {
    person: <>
      <PersonLink userId={user.id}>
        <PersonNameWrapper><PersonFullName {...user} /></PersonNameWrapper>
      </PersonLink>
      {
        !user.is_active && <InactiveIcon>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.people.inactivePersonMessage}} />
        </InactiveIcon>
      }
    </>,
    latestRecord: <PersonLatestRecord personId={user.id} />,
    lastMonth: <PersonUtilization personId={user.id} />,
    balance: user.is_balance_visible && <PersonBalance personId={user.id} />,
  }
})
export const PeopleListing: React.FunctionComponent<PeopleListingProps> = ({
  filterBlock,
  results,
  activeFilters,
  records,
  ordering,
  pagination,
  isLoading,
  onPageChange,
  onOrderingChange
}) => {

  const orderedRecords = results.map(result => records[result]);

  const peopleListingControls: ControlWrapperControl[] = [
    {
      name: 'filtering',
      label: { id: TRANSLATION_KEYS.search.filtersButton },
      component: <ActiveFilterBlock filterBlock={filterBlock} />,
      isActive: !!activeFilters.length
    },
  ]

  const peopleListingActions = [
    <RequireGlobalRight rightName={RIGHTS.addUser}><StyledAddPeople /></RequireGlobalRight>
  ]

  return (
    <ControlWrapper
      controls={peopleListingControls}
      actions={peopleListingActions}
      isLoading={isLoading}
    >
      <DefaultBlock withBackground={true}>
        <ActuallyTable
          tableColumns={getPeopleListingColumnConfiguration}
          tableData={getPeopleListingData(orderedRecords)}
          ordering={ordering}
          pagination={pagination}
          isLoading={isLoading}
          emptyResultProps={{
            paragraphs: peopleListingEmptyResultParagraphs
          }}
          onPageChange={onPageChange}
          onOrderingChange={onOrderingChange} />

      </DefaultBlock>
    </ControlWrapper>
  )
}

export default PeopleListing;
