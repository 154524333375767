import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useBalanceFixAPI } from "./balance-fix-hooks";
import { fetchUserEmploymentAction } from "../../../store/ducks/people/actions";

import SimpleManageFlow from "../../people/user-details/workday-length/SimpleCRUDFlow";
import BalanceFixList from "./components/BalanceFixList";
import AddEditBalanceFix from "./components/AddEditBalanceFix";
import { RouteComponentProps } from "@reach/router";
import {TRANSLATION_KEYS} from "../../../intl/intl";

// FIXME: TB-160
interface OwnPropTypes {
  userId?: any;
}

type BalanceFixProps = OwnPropTypes & RouteComponentProps;

export const BalanceFix: React.FunctionComponent<BalanceFixProps> = ({userId}) => {
  const dispatch = useDispatch();
  const api = useCallback(useBalanceFixAPI(), [userId]);

  const handleOnDone = () => {
    userId && dispatch(fetchUserEmploymentAction(userId));
  };

  return (
    <div style={{minHeight: '400px'}}>
      <SimpleManageFlow
        onDone={handleOnDone}
        titles={{
          addButton: {id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.addBalanceFixButton},
          list: {id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.listModalHeader},
          edit: {id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.editModalHeader},
          add: {id: TRANSLATION_KEYS.people.personDashboard.personBalanceFixesModal.addModalHeader},
        }}>
        <SimpleManageFlow.Add>
          <AddEditBalanceFix api={api} userId={userId}/>
        </SimpleManageFlow.Add>

        <SimpleManageFlow.Edit>
          <AddEditBalanceFix api={api} userId={userId}/>
        </SimpleManageFlow.Edit>

        <SimpleManageFlow.List>
          <BalanceFixList api={api} userId={userId}/>
        </SimpleManageFlow.List>
      </SimpleManageFlow>
    </div>
  )
}