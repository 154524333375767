import * as React from 'react';

import { Link, Match } from '@reach/router';
import classNames from 'classnames';
import '../Header.css';
import styled from "styled-components";

interface OwnPropTypes {
  to: string;
  exact?: boolean;
  className?: string
}

export type HeaderLinkProps = OwnPropTypes;

const RelativelyPositioned = styled.div`
  position:relative;
`

const HeaderLink: React.FunctionComponent<HeaderLinkProps> = ({to, exact=false, className, children}) => {
  const resolveClasses = isMatch => isMatch && 'active';

  const pathResolver =  exact ? `${to}` : `${to}/*`;

  return (
    <Match path={pathResolver}>
      {(theProps) => (
        <RelativelyPositioned className={classNames(className, resolveClasses(theProps.match))}>
          <Link to={to} className="header-link-item">{children}</Link>
        </RelativelyPositioned>
      )}
    </Match>
  )
}


export default HeaderLink;