import React from "react";
import PropTypes from 'prop-types';

const PersonEmail = ({email, className = ""}) => {
  return <span className={className}>{email}</span>;
};

PersonEmail.propTypes = {
  email: PropTypes.string
};

export default PersonEmail;
