import {PeopleAPIResponseParser} from "../../people/people-api";
import {ProjectsAPIResponseParser} from "../../projects/projects-api-response";

class CycleStepProjectAPIResponseParser {

  static parseApproverStepForPhaseResponse = response => {
    const {users, projects = [], projectleadPhase = [], approverPhase = []} = response;

    const usersNormalized = PeopleAPIResponseParser.normalizeResponse(users);
    const projectsNormalized = ProjectsAPIResponseParser.normalizeListing(projects);

    const steps = {
      projectleadPhase,
      approverPhase
    }

    return {
      steps,
      users: usersNormalized,
      projects: projectsNormalized
    }
  }

}

export default CycleStepProjectAPIResponseParser;
