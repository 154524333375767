import {useCallback} from 'react';

import {useDispatch} from "react-redux";

import {sendRequestSimple} from "../../../api/api";
import {getUserCycles, postUserCycle} from "../../../api/approval/user-cycle/user-cycle-api";
import {
  getApproverSteps,
  getCycleHistoryForApprover,
  getCycles,
  getUnsubmittedCycles
} from "../../../api/approval/cycle/cycle-api";
import {
  addCycleAction,
  updateCyclesAndIdsAction, updateCyclesCompetedByApproversAction,
} from "../../../store/ducks/cycle/cycle-reducers";
import {getCyclePhases} from "../../../api/approval/cycle-phase/cycle-phase-api";
import {
  getStepRecords, patchCycleStepProject
} from "../../../api/approval/cycle-step-project/cycle-step-project-api";
import {receivePeopleAction} from "../../../store/ducks/people/actions";
import {fetchProjectsSuccess} from "../../../store/ducks/projects/actions";
import {
  getUserStepRecords,
  patchCycleStepUser
} from "../../../api/approval/cycle-step-user/cycle-step-user-api";

import {ReportAPIResponseParser} from "../../../api/report/report-api-implementation";

import CycleStepProjectAPIResponseParser from "../../../api/approval/cycle-step-project/cycle-step-project-response";
import UserCycleAPIResponseParser from "../../../api/approval/user-cycle/user-cycle-api-response";
import CycleResponse from "../../../api/approval/cycle/cycle-response";


// FIXME: TB-160
const useApprovalAPI = (): any => {
  const dispatch = useDispatch();

  const fetchUserCycles = async params => {
    return await sendRequestSimple(getUserCycles(params));
  };

  const fetchUserCyclesForUser = async userId => {
    const params = {"usercycle.user": userId};

    const {response} = await fetchUserCycles(params);

    if (response)
      return UserCycleAPIResponseParser.normalizeByCycle(response.data);

    return null;
  };

  const fetchCyclePhases = useCallback(async cycleId => {
    return await sendRequestSimple(getCyclePhases(cycleId));
  }, []);

  const fetchCycles = useCallback(async params => {
    return await sendRequestSimple(getCycles(params));
  }, []);

  const fetchApproverSteps = useCallback(async (cycleId, approverId) => {
    const params = {"approver": approverId};

    const {response, error} = await sendRequestSimple(getApproverSteps(cycleId, params));

    if (response) {
      const {users, projects, steps} = CycleStepProjectAPIResponseParser.parseApproverStepForPhaseResponse(response);

      dispatch(receivePeopleAction(users));
      dispatch(fetchProjectsSuccess(projects));

      return steps;
    }

    return {error};
  }, [dispatch]);

  const fetchProjectStepRecords = useCallback(async stepId => {
    const {response} = await sendRequestSimple(getStepRecords(stepId));

    if (response) {
      const records = ReportAPIResponseParser.mergeEntitiesIntoRecords(response);

      return {
        ...response,
        records,
      }
    }

    return null;
  }, []);

  const fetchUserStepRecords = useCallback(async stepId => {
    const {response} = await sendRequestSimple(getUserStepRecords(stepId));

    if (response) {
      const records = ReportAPIResponseParser.mergeEntitiesIntoRecords(response);

      return {
        ...response,
        records,
      }
    }

    return null;
  }, []);

  const fetchCycleHistoryForApprover = useCallback(async approverId => {
    const {response} = await sendRequestSimple(getCycleHistoryForApprover(approverId));

    if (response) {
      const {cycles, cycles_completed} = CycleResponse.parseCycleHistoryForApproverResponse(response);

      dispatch(updateCyclesAndIdsAction(cycles));
      dispatch(updateCyclesCompetedByApproversAction({approverId, cycles_completed}));
    }
  }, [dispatch]);

  const fetchUnsubmittedCycles = useCallback(async userId => {
    return await sendRequestSimple(getUnsubmittedCycles(userId));
  }, []);

  const createUserCycle = async (userId, cycleId) => {
    const {response} = await sendRequestSimple(postUserCycle(userId, cycleId));

    if (response) {
      const {user_cycle: userCycle, cycle} = response;

      dispatch(addCycleAction({userCycle, cycle}));
    }

    return {response};
  };

  const updateCycleStepProject = async (stepId, payload) => {
    return await sendRequestSimple(patchCycleStepProject(stepId, payload));
  }

  const updateCycleStepUser = async (stepId, payload) => {
    return await sendRequestSimple(patchCycleStepUser(stepId, payload));
  }

  return {
    fetchCycles,
    fetchCyclePhases,
    fetchUserCycles,
    fetchUserCyclesForUser,

    fetchApproverSteps,

    fetchProjectStepRecords,
    fetchUserStepRecords,

    fetchCycleHistoryForApprover,
    fetchUnsubmittedCycles,

    createUserCycle,
    updateCycleStepProject,
    updateCycleStepUser
  }
};

export default useApprovalAPI;