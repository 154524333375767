import styled from "styled-components";
import React from "react";
import { BaseIcon, BaseIconProps } from "../buttons";

const CornerActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: white;
  border: 2px solid #F0F4F8;
  border-radius: 35px;
  width: 35px;
  height: 35px;
  &:hover {
    box-shadow: 0 0 2px #9FB3C8;
    cursor: pointer;
  }
`

const CornerActionIcon = styled(BaseIcon)`
  color: #BCCCDC;
`

export interface OnClickInterface {
  onClick: () => void;
}

type CornerActionProps = OnClickInterface & BaseIconProps

const CornerAction: React.FunctionComponent<CornerActionProps> = ({iconName, onClick, className, ...props}) => {
  return (
    <CornerActionWrapper className={className}>
      <button className="btn btn-sm d-flex align-items-center" onClick={onClick}>
        <CornerActionIcon iconName={iconName} {...props} />
      </button>
    </CornerActionWrapper>
  )
};

export default CornerAction;
