import React from "react";

import './ToggleSwitch.css';

// FIXME: TB-160
function ToggleSwitch({isChecked=false, onChangeOfValue=undefined, disabled=undefined, innerRef=undefined, ...props}: any) {
const handleValueChange = (event) => {
    onChangeOfValue && onChangeOfValue(event.target.checked);
  };

  const customProps = {};

  if (!innerRef) {
    customProps['checked'] = isChecked;
    customProps['onChange'] = handleValueChange;
  } else {
    // This makes the component support react-hook-form
    customProps['ref'] = innerRef;
  }

  return (
    <label className="switch">
      <input type="checkbox"
             {...customProps}
             disabled={disabled}
             {...props}
      />
      <span className="slider"/>
    </label>)
}

ToggleSwitch.defaultProps = {
  isChecked: false
};

export default ToggleSwitch;