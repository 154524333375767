export const GET_CURRENT_USER_REQUESTED = 'GET_CURRENT_USER_REQUESTED';
export const GET_CURRENT_USER_SUCCESSFUL = 'GET_CURRENT_USER_SUCCESSFUL';

export const AUTHENTICATION_SUCCESSFUL = 'AUTHENTICATION_SUCCESSFUL';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const RESET_STATE = 'RESET_STATE';
export const AUTHENTICATE_INTO_TENANT = 'AUTHENTICATE_INTO_TENANT';
export const CHANGE_TENANT = 'CHANGE_TENANT';
export const CHANGE_TENANT_SUCCESS = 'CHANGE_TENANT_SUCCESS';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const SET_CHECKOUT_REQUIRED = 'SET_CHECKOUT_REQUIRED';
export const SET_IS_ON_INITIAL_TRIAL = 'SET_IS_ON_INITIAL_TRIAL';