import Filter from "./Filter";
import FILTER_TYPES from '../configuration/filter-type-definations';

import {formatISO} from "../../../util/dates";
import {get} from 'lodash';
import {formatDateShort} from "../../ui-utility/dates/date-formatters";

class DateFilter extends Filter {

  formatDateValue = (newDate) => {
    return newDate ? formatISO(newDate) : "";
  }
}

class DateFilterField extends DateFilter {
  filterType = FILTER_TYPES.DATE;

  getActiveFilterSentence = (value) => {
    const text = this.getActiveFilterLabel();
    const label = value ? value : '';

    return `${text} ${label}`
  };

  formatValue = (filterValue = {}) => {
    const gte = `${this.filterKey}__gte`;

    return {[gte]: this.formatDateValue(filterValue)};
  };
}

class DateRangeFilterField extends DateFilter {
  filterType = FILTER_TYPES.DATERANGE;

  // Demo purposes.
  getActiveFilterSentence = (value) => {
    const translationString = this.getActiveFilterLabel();

    const from = value.__gte ? value.__gte : null;
    const to = value.__lte ? value.__lte : null;

    return {
      ...translationString,
      values: {
        startDate: formatDateShort(from) || "∞",
        endDate: formatDateShort(to) || "∞",
      },
    };
  };

  formatValue = (filterValue = {}) => {
    const gteQueryParamKey = `${this.filterKey}__gte`;
    const lteQueryParamKey = `${this.filterKey}__lte`;

    const gteQueryParamValue = get(filterValue, '__gte', null);
    const lteQueryParamValue = get(filterValue, '__lte', null);

    return {
      [gteQueryParamKey]: this.formatDateValue(gteQueryParamValue),
      [lteQueryParamKey]: this.formatDateValue(lteQueryParamValue)
    };
  };

}

export { DateRangeFilterField, DateFilterField };
