import { getCookie, serializeQueryDict } from "./utils";

const defaultRequestConfiguration = () => ({
  baseURL: getBaseURL(),
  credentials: 'include',
  mode: 'cors',
  cache: 'no-cache',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  },
  paramsSerializer: function (params) {
    return serializeQueryDict(params);
  },
});

export function getBaseURL() {
  return process.env.REACT_APP_BACKEND_URL;
}

export { defaultRequestConfiguration };