import React, { useState } from 'react';
import { ActuallyButton } from "../buttons/index";
import { CaretRightIcon } from "../buttons";
import { ColumnRowEntityManager } from "./ColumnBlockManager";
import ColumnBlockContainer from "./ColumnBlockContainer";

import './ColumnBlock.css';
import styled from 'styled-components';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

const ColumnSelectionLabel = styled.p`
  font-weight: bold;
`;

const ColumnActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 24px;
  z-index: -100;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ApplyChangesButton = styled(ActuallyButton)`
  align-self: flex-end;
  margin-bottom: 4px;
`

// FIXME: TB-160
interface OwnPropTypes {
  columns: any;
  onApplyColumns: (columns: any) => void;
  currentColumns: Array<string>
}

export type ColumnSelectionWithControlsProps = OwnPropTypes;

export const ColumnSelectionWithControls: React.FunctionComponent<ColumnSelectionWithControlsProps> = ({ columns, onApplyColumns, currentColumns }) => {
  const [selectedColumns, setSelectedColumns] = useState(currentColumns);

  const handleColumnReset = () => {
    setSelectedColumns([]);
  };

  const handleColumnApply = () => {
    const controller = new ColumnRowEntityManager(columns, selectedColumns);

    onApplyColumns(controller.formatForAjax());
  };

  return (
    <>
      <ColumnSelectionLabel>
        <LocalizedMessage text={{ id: TRANSLATION_KEYS.search.availableColumns}} />
      </ColumnSelectionLabel>
      <ColumnBlockContainer columns={columns} isClearable={false} selectedColumns={selectedColumns}
        onColumnsChanged={cols => setSelectedColumns(cols)} />
        <div>
      <ColumnActionWrapper>
        <ButtonWrapper>
          <ApplyChangesButton variant="primary" onClick={handleColumnApply}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.applyChangesButton}} />
          </ApplyChangesButton>

          <button type="button" className="btn btn-slick" onClick={handleColumnReset}>
            <span className="text-primary">
              <span className="pr-1">
                <LocalizedMessage text={{id: TRANSLATION_KEYS.actualsReport.resetToDefaultsLink }} />
              </span>
              <CaretRightIcon />
            </span>
          </button>
        </ButtonWrapper>
      </ColumnActionWrapper>
      </div>
    </>
  );
}