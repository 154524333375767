import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { If } from "../ui-utility/Conditional";
import { useContextRights } from "./right-hooks";

function RequireContextRight({rightName, objectId, contextType, children}) {
  const {selectObjectRightsById} = useContextRights(contextType);

  const rights = useSelector(selectObjectRightsById(objectId)) || {};
  const hasRight = rights[rightName] === true;

  return (
    <If condition={hasRight}>{children}</If>
  );
}

RequireContextRight.propTypes = {
  rightName: PropTypes.string.isRequired,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  contextType: PropTypes.oneOf(['users', 'projects']).isRequired
};

export default RequireContextRight;