import React from 'react';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

const SupportAddress = () => {
  return <a href="mailto:support@getactually.com">support@getactually.com</a>;
};

const SupportFooter = () => {
  return <h3 className="text-center">
    <LocalizedMessage text={{
      id: TRANSLATION_KEYS.login.supportFooterMessage,
      values: {
        supportEmailAddress: <SupportAddress/>

      }
    }} />
  </h3>;
};

export {
  SupportFooter,
  SupportAddress
};
