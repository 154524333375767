import React from "react";

import {If} from "../../../../components/ui-utility/Conditional";

import AuthorizationGrant from "./AuthorizationGrant";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

// FIXME: TB-160
interface AuthorizationGrantRowProps {
  grantedRights: Array<any>;
  onRemove?: (grantedId: any) => Promise<void>;
}

const AuthorizationGrantRow: React.FunctionComponent<AuthorizationGrantRowProps> = ({grantedRights, onRemove}) => (
  <>
    {
      grantedRights.map((grant, index) => (
        <div className="d-inline-block mr-2 mb-1" key={index}>
          <AuthorizationGrant grantedRight={grant} onRemove={onRemove}/>
        </div>
      ))
    }
    <If condition={grantedRights.length === 0}>
      <span>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationRulesSettings.noRulesMessage}}/>
      </span>
    </If>
  </>
);

export default AuthorizationGrantRow;