import React from 'react';

import PropTypes from 'prop-types';

import FilterBlockEntity from './entities/FilterBlockEntity';

import { ActiveFilters } from "./ActiveFilters";
import FilterBlock from "./FilterBlock";

function ActiveFilterBlock({filterBlock}) {
  return (
    <>
      <div className="mb-4">
        <FilterBlock filterBlock={filterBlock}/>
      </div>
      <div className="mb-4">
        <ActiveFilters filterBlock={filterBlock}/>
      </div>
    </>
  );
}

ActiveFilterBlock.propTypes = {
  filterBlock: PropTypes.instanceOf(FilterBlockEntity)
};

export default ActiveFilterBlock;