import React from 'react';
import { Controller } from "react-hook-form";

import ActuallySelect from "../components/select/ActuallySelect";

interface FormApiSelectProps {
  variant: any;
  onChange: any;
  control: any;
  rules?: any;
  name?: string;
  watch: any;
  params?: any // Object of query parameters e.g {"user.is_active": true}
}

const FormApiSelect: React.FunctionComponent<FormApiSelectProps> = ({variant, onChange, control, rules, name, watch, params = {}}) => {
  const componentName = name || variant;

  const entityId = watch(componentName);

  const component = (
    <ActuallySelect variant={variant} name={componentName} onChange={onChange} entityId={entityId} params={params}/>);

  return (
    <Controller as={component}
                control={control}
                name={componentName}
                rules={rules}/>
  )
}

export default FormApiSelect;
