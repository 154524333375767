import React from "react";

import {useModal} from "../modal/modal-hooks";

import SimpleModal from "../modal/SimpleModal";
import ModalFrame from "../modal/ModalFrame";
import VisibleTasksContainer from "./visible-tasks/VisibleTasksContainer";

import modalDefinitions from "../modal/modal-definitions";
import {ActuallyButton} from "../buttons";
import {LocalizedMessage, localizeMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {selectWeeklyTimesheetRows} from "../../store/ducks/timesheet/selectors";
import styled from "styled-components";
import {getPulsatingEffect} from "../../styles/common-styled-components";
import loadingSelectors from "../../store/ducks/loading/loading-selectors";
import {TIMESHEET_LOADING} from "../../store/ducks/loading/loading-types";

const pulsating = getPulsatingEffect(245, 116, 24);

const PulsatingButton = styled(ActuallyButton)<{ ispulsating: number }>`
  && {
    ${props => props.ispulsating && `
      border: 0;
      background-color: rgba(245, 116, 24);
    `}
    ${props => props.ispulsating ? pulsating : ''};
   }
`

function VisibleTasks({...props}) {
  const [openModal] = useModal(modalDefinitions.VISIBLETASKS);
  const intl = useIntl();
  const visibleTasks = useSelector(selectWeeklyTimesheetRows);
  const loadingElement = useSelector(loadingSelectors.selectLoadingForRequest(TIMESHEET_LOADING));

  const shouldHighlightButton = visibleTasks.length === 0 && !loadingElement.loading;

  const visibleTasksModalHeader = localizeMessage({
    id: TRANSLATION_KEYS.timesheet.visibleTasksModal.modalHeader
  }, intl);

  return (
    <>
      <PulsatingButton {...props} onClick={openModal} variant="primary" ispulsating={shouldHighlightButton ? 1 : 0}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.visibleTasksButton}}/>
      </PulsatingButton>
      <SimpleModal name={modalDefinitions.VISIBLETASKS} className="scaled-simple-modal">
        <ModalFrame heading={visibleTasksModalHeader}>
          <VisibleTasksContainer/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}


export default VisibleTasks;