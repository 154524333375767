import { useSelector } from "react-redux";
import { selectAuthenticationState } from "../store/ducks/auth/selectors";
import React from "react";
import { Redirect } from "@reach/router";


// Permit access only for unauthorized users.
function UnAuthenticatedRoute({component: Component, ...props}) {
  const authState = useSelector(selectAuthenticationState);

  if (authState.isLoading) {
    return <></>;
  } else if (!authState.systemUserId && !authState.userId) {
    return <Component {...props} />;
  } else {
    return <Redirect to="/timesheet" noThrow/>;
  }
}


export default UnAuthenticatedRoute;