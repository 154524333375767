import jQuery from 'jquery';
import { formatISO } from "../util/dates"; // TODO: Replace jQuery

export function getCookie(name) {
  if (!document.cookie) {
    return undefined;
  }

  const xsrfCookies = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith(name + '='));

  if (xsrfCookies.length === 0) {
    return undefined;
  }

  return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

//TODO Remove this and move to classnames
export function mergeStylesWithProps(styles, props) {
  return mergeStyles(styles, props.className);
}

//TODO Remove this and move to classnames
export function mergeStyles(styles, anotherStyles) {
  return `${styles} ${anotherStyles || ""}`
}

export function appendTrailingSlash(route) {
  const noSlash = String(route).replace(/\/$/, "");

  return `${noSlash}/`;
}

export function joinRouteParts(baseUrl, ...remainingParts) {
  const withSlashEndpoint = remainingParts.map(appendTrailingSlash).join('');
  const withSlashBaseUrl = appendTrailingSlash(baseUrl);

  return `${withSlashBaseUrl}${withSlashEndpoint}`;
}

export function joinQueryParams(baseUrl, queryDict) {
  const serializedParams = serializeQueryDict(queryDict);
  const withSlashBaseUrl = appendTrailingSlash(baseUrl);

  return `${withSlashBaseUrl}?${serializedParams}`;
}

export function serializeQueryDict(queryDict) {
  return jQuery.param(queryDict, true);
}

export function extractOnlyChangedAttributes(original, modified) {
  const allKeys = Object.keys(original).concat(Object.keys(modified));

  return allKeys.reduce((accum, fieldKey) => {
    const originalValue = original[fieldKey];

    if (fieldKey in modified) {
      const modifiedValue = checkForValueConversion(modified[fieldKey]);

      // eslint-disable-next-line
      if (modifiedValue != originalValue)
        accum[fieldKey] = modifiedValue;
    }
    return accum;
  }, {});
}

// TESTS
function checkForValueConversion(value) {
  if (value instanceof Date) {
    return formatISO(value);
  }
  return value;
}