import FilterRegistry from "../../../components/filter/configuration/filter-registry";

const filterSelectors = Object.freeze({
  selectFilters: state => state.filters,
  selectFilterFields: state => filterSelectors.selectFilters(state).fields,
  selectFilterForField: field => state => {
    const allFilters = filterSelectors.selectFilterFields(state);

    return allFilters[field];
  },

  selectDropdownOptions: entityKey => state => {
    const {dropdown} = filterSelectors.selectFilters(state);
    const entitiesForKey = dropdown.result[entityKey];

    if (!entitiesForKey)
      return null;

    return entitiesForKey.map((entityId) => dropdown.entities[entityKey][entityId]);
  },

  selectActiveFiltersForBlock: filterBlock => state => {
    const {fields} = filterSelectors.selectFilters(state);

    return filterBlock.getFilterFields().filter(filter => {
      return fields[filter.getFilterKey()];
    });
  },

  selectActiveFiltersAsQueryParams: filterBlockName => state => {
    const filterBlock = FilterRegistry.getFilterBlockForName(filterBlockName);
    const stateOfEveryFilter = filterSelectors.selectFilterFields(state);
    const filtersForBlock = extractFieldsForGivenFilterBlock(filterBlock, stateOfEveryFilter);

    return filterBlock.formatQueryParameters(filtersForBlock);
  }

});

export const extractFieldsForGivenFilterBlock = (filterBlock, stateOfEveryFilter = {}) => {
  const filterKeys = filterBlock.getFilterKeys();

  return filterKeys.reduce((accumulator, currentValue) => {
    return {...accumulator, [currentValue]: stateOfEveryFilter[currentValue]};
  }, {});
};

export default filterSelectors;
