import { takeLatest, takeEvery, put, all, fork, select, call } from 'redux-saga/effects';
import { takeEveryUnique } from "../../../sagas/saga-utils";
import executeRequest from '../../../../api/api';

import * as actions from './actions';

import { parseISO, getMonthStartAndEndDates } from "../../../../util/dates";
import { fetchTimesheetStatusSuccess } from "./actions";
import { selectTimesheetUser } from "./selectors";

import {
  saveTotalForDay,
  deleteRecord,
  fetchDailyTimesheet,
  fetchUserMonthlyStatus,
  addPinnedTask,
  removePinnedTask,
  addPinForEveryProjectTask
} from "../../../../api/timesheet/mobile-timesheet-api";
import { selectPinnedTaskByTaskId } from "../../timesheet/selectors";
import { removePinnedTaskAction, updatePinnedTaskAction, addPinnedTaskAction } from "./actions";
import TimesheetResponseParser from "../../../../api/timesheet/timesheet-response-parser";
import UserCycleAPIResponseParser from "../../../../api/approval/user-cycle/user-cycle-api-response";
import timesheetTypes from './types';
import { fetchUserEmployment } from "../../people/sagas";
import { updateCycleStateAction } from "../../cycle/cycle-reducers";
import { AnyAction } from 'redux';
import {TIMESHEET_LOADING} from "../../loading/loading-types";


// FIXME: TB-160
function* saveNewTotalForDayWatch({payload: recordData}: AnyAction) {
  const user = yield select(selectTimesheetUser);

  const {response} = yield call(executeRequest, saveTotalForDay({user: user.id, ...recordData}), {
    cancelOnFailure: true,
  });
  const {startDate, endDate} = getMonthStartAndEndDates(parseISO(recordData.date));

  yield fork(fetchTimesheetStatus, startDate, endDate, user.id);

  // TODO: What is the response? Should it be the new record, or the updated one?
  yield put(actions.saveRecordSuccess(response.data));
}

function* deleteRecordWatch({payload: record}: AnyAction) {
  const user = yield select(selectTimesheetUser);
  const {startDate, endDate} = getMonthStartAndEndDates(parseISO(record.date));

  yield call(executeRequest, deleteRecord(record.id), {cancelOnFailure: true});

  yield all([
    fetchTimesheetStatus(startDate, endDate, user.id),
    put(actions.deleteRecordSuccess(record)),
  ]);
}

function* fetchTimesheetRecordsWatch(userId, date, enddate) {
  const {response} = yield call(executeRequest, fetchDailyTimesheet(userId, date, enddate), {
    cancelOnFailure: true,
    trackRequest: TIMESHEET_LOADING,
  });

  const parsedResponse = TimesheetResponseParser.parseDailyResponse(response.data);

  yield put(actions.fetchDailyTimesheetSuccess(date, parsedResponse));
  yield call(updateCycleStore, parsedResponse.cycles);
}

function* updateCycleStore(cycles) {
  const normalized = UserCycleAPIResponseParser.normalizeListing(cycles);

  yield put(updateCycleStateAction(normalized));
}

function* fetchTimesheetStatusWatch(action) {
  const {userId, startDate, endDate} = action.payload;

  yield fetchTimesheetStatus(startDate, endDate, userId);
}

function* fetchTimesheetStatus(firstDate, lastDate, userId = null) {
  // TODO: Change this when we really support the viewing of other people's timesheet.
  if (userId === null) {
    const user = yield select(selectTimesheetUser);

    userId = user.id;
  }

  yield fork(fetchUserEmployment, {payload: userId});

  const {response} = yield call(
    executeRequest, fetchUserMonthlyStatus(userId, firstDate, lastDate), {cancelOnFailure: true}
  );

  yield put(fetchTimesheetStatusSuccess(userId, firstDate, lastDate, response.data));
}

function* fetchDailyTimesheetWatch(action) {
  const {userId, date, enddate} = action.payload;
  const {startDate, endDate} = getMonthStartAndEndDates(date);

  yield fork(fetchTimesheetRecordsWatch, userId, date, enddate);
  yield fork(fetchTimesheetStatus, startDate, endDate);
}

function* taskVisibilityStatusChangeFlow(action) {
  const {taskId} = action.payload;

  const pinnedTask = yield select(selectPinnedTaskByTaskId(taskId));

  if (pinnedTask) {
    yield call(executeRequest, removePinnedTask(pinnedTask.id), {cancelOnFailure: true});
    yield put(removePinnedTaskAction(taskId));

  } else {
    yield call(pinSingleTask, action);
  }
}

function* pinSingleTask(action) {
  const {taskId} = action.payload;

  const targetUser = yield select(selectTimesheetUser);

  const {response} = yield call(executeRequest, addPinnedTask({
    user: targetUser.id,
    task: taskId
  }), {
    cancelOnFailure: true
  });

  yield put(addPinnedTaskAction(response.data));
}

function* pinAllProjectTasks(action) {
  const {projectId} = action;

  const targetUser = yield select(selectTimesheetUser);

  const {response} = yield call(executeRequest, addPinForEveryProjectTask({
    project: projectId,
    user: targetUser.id
  }), {
    cancelOnFailure: true,
  });

  yield put(updatePinnedTaskAction(response.data));
}

export const timesheetSagas = [
  takeLatest(timesheetTypes.FETCH_DAILY_TIMESHEET, fetchDailyTimesheetWatch),
  takeEvery(timesheetTypes.SAVE_NEW_TOTAL_FOR_DAY, saveNewTotalForDayWatch),
  takeEvery(timesheetTypes.DELETE_RECORD, deleteRecordWatch),

  takeEvery(timesheetTypes.FETCH_TIMESHEET_STATUS, fetchTimesheetStatusWatch),
  takeEvery(timesheetTypes.PIN_ALL_PROJECT_TASKS, pinAllProjectTasks),
  takeEvery(timesheetTypes.PIN_SINGLE_TASK, pinSingleTask),

  takeEveryUnique(timesheetTypes.CHANGE_TASK_VISILITY, action => action.payload.taskId, taskVisibilityStatusChangeFlow)
];