import {useDispatch} from "react-redux";
import {sendRequestSimple} from "../api";
import {getCustomerDetails, patchCustomer} from "./customers-api";

import {fetchSingleCustomerSuccessAction} from "../../store/ducks/customer/customer-reducers";
import {useCallback} from "react";

export const useCustomerAPI = () => {
  const dispatch = useDispatch();

  const updateCustomer = async (id, data) => {
    const {response, error} = await sendRequestSimple(patchCustomer(id, data));

    if (response) {
      dispatch(fetchSingleCustomerSuccessAction(response));

      return {response};
    }

    return {error: error?.data};
  }

  const fetchCustomerDetails = useCallback(async (params) => {
    const {response = {}} = await sendRequestSimple(getCustomerDetails(params));

    return response;
  }, []);

  return {
    updateCustomer,
    fetchCustomerDetails
  }
}