const modalDefinitions = {
  VISIBLETASKS: "/timesheet/tasks",
  PROJECT_MANAGE_TASKS: "/project/manageTasks",
  EDIT_PROJECT_TASK: "/project/editTask",
  PROJECTMEMBERS: "/project/members",
  EDIT_PROJECT: "/project/edit",
  DELETE_PROJECT: "/project/delete",
  USER_WORKDAY_LENGTHS: "/user/workdayLengths",
  BALANCE_FIX: "/balance-fixes",
  EDIT_PERSON: "/user/edit",
  DELETE_USER: "/user/delete",
  ADD_CALENDAR: "/calendars/add",
  EDIT_CALENDAR: "/calendars/:id/edit/",

  ADD_AUTH_GROUP: "/authorization-group/add",
  EDIT_AUTH_GROUP: "/authorization-group/:id/edit",
  SEND_RECORDS_TO_APPROVAL: "/timesheet/send-to-approval",
  APPROVE_CYCLE: "approver_cycle",
  APPROVE_USER_PROJECT: 'approve_user_project',
  APPROVE_USER: 'approve_user',

  CYCLE_TEMPLATE: "cycle_template",
  EDIT_CUSTOMER: "customer_edit"
};

export default modalDefinitions;
