import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';

import { CHANGE_TENANT_SUCCESS, RESET_STATE } from './ducks/auth/types';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import authReducer from './ducks/auth/reducers';
import peopleReducer from './ducks/people/reducers';
import dailyTimesheetReducer from "./ducks/mobile/timesheet/reducers";
import apiNotifierReducer from "./ducks/api-notifier/api-notifier-reducers";
import rootSaga from "./sagas";
import filterReducer from "./ducks/filter/filter-reducers";
import projectsReducer from "./ducks/projects/reducers";
import projectDetailsReducer from "./ducks/project-details/reducers";
import tasksReducer from "./ducks/project-tasks/reducers";
import paginationReducer from './ducks/pagination/pagination-reducers'
import { modalReducer } from "../components/modal/modal-redux";
import loadingReducer from "./ducks/loading/loading-reducers";
import workdayLengthReducer from "./ducks/workday-length/workday-length-reducer";
import membershipReducer from "../screens/projects/membership/project-members-reducer"
import customerReducer from "./ducks/customer/customer-reducers"
import orderingReducer from "./ducks/ordering/ordering-reducers";
import rightsReducer from "./ducks/rights/rights-reducers";
import calendarReducer from "./ducks/calendar/calendar-reducers";
import authGroupReducer from './ducks/authorization/auth-group-reducers';
import cycleReducer from './ducks/cycle/cycle-reducers';
import cycleTemplateReducer from './ducks/cycle/cycle-template-reducers';
import bootstrapReducer from './ducks/bootstrap/bootstrapSlice';
import tenantsReducer from './ducks/admin/tenants/tenant-reducers';

const adminReducers = combineReducers({
  tenants: tenantsReducer,
})

const appReducers = combineReducers({
  auth: authReducer,
  people: peopleReducer,
  workdayLengths: workdayLengthReducer,
  projects: projectsReducer,
  projectDetails: projectDetailsReducer,
  tasks: tasksReducer,
  dailyTimesheet: dailyTimesheetReducer,
  notification: apiNotifierReducer,
  pagination: paginationReducer,
  filters: filterReducer,
  modal: modalReducer,
  loading: loadingReducer,
  memberships: membershipReducer,
  customers: customerReducer,
  ordering: orderingReducer,
  rights: rightsReducer,
  calendars: calendarReducer,
  authGroups: authGroupReducer,
  cycles: cycleReducer,
  cycleTemplates: cycleTemplateReducer,
  bootstrap: bootstrapReducer,
  admin: adminReducers,
});

const rootReducer = (state, action) => {
  // Never ever remove this!!
  if (action.type === RESET_STATE) {
    state = undefined;
  }

  if (action.type === CHANGE_TENANT_SUCCESS) {
    state = undefined;
  }

  return appReducers(state, action);
};

// NOTE: This is done this way because Redux doesn't provide types for __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
// because this function is exposed by Redux DevTools, not Redux itself.
// https://stackoverflow.com/a/52801110
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const configureStore = initialState => {

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, ...getDefaultMiddleware({serializableCheck: false})];

  // FIXME: TB-160
  const store: any = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;

};

const store = configureStore({});

store.runSaga(rootSaga);

export type ActuallyRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };