export interface Project {
  name: string,
  id: number,
  is_active?: boolean
}

class ProjectEntity implements Project{
  name: string;
  id: number;

  constructor({name, id}) {
    this.name = name;
    this.id = id;
  }

  getName = () => {
    return ProjectEntity.parseName({name: this.name});
  };

  getId = () => {
    return this.id;
  };

  static parseName({name}) {
    return `${name || ""}`;
  }

}

export default ProjectEntity;