import React, { useState } from "react";

import { MobileTimesheetRecordDetails } from "./MobileTimesheetViewRow";
import {
  DailyTimesheetActionPanel,
  DailyTimesheetDescriptionInput
} from "../../../timesheet/daily/components/DailyTimesheetEditRow";

import Error from "../../../../components/notication/Error";
import RecordTimeEditor from "./RecordTimeEditor";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


const MobileTimesheetEditRow = ({task, project, record, errors, handleRecordCanceling, handleRecordSaving, handleRecordDeleting}) => {
  const [localRecord, setLocalRecord] = useState(record);

  const setAttribute = (attrValue, attrKey) => {
    setLocalRecord({...localRecord, [attrKey]: attrValue});
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleRecordSaving(localRecord);
  };

  return (
    <div className="dailytimesheet-edit-record-row">
      <Row>
        <Col xs={12}>
          <Error error={errors.general}/>
        </Col>
      </Row>

      <form onSubmit={handleFormSubmit}>

        <Row>
          <Col xs={12}>
            <MobileTimesheetRecordDetails task={task} project={project} record={record} showPin={false}/>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Error error={errors.minutes}/>
            <RecordTimeEditor minutes={localRecord.minutes} onUpdateMinutes={mins => setAttribute(mins, 'minutes')}/>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <DailyTimesheetDescriptionInput record={localRecord} onChange={setAttribute} errors={errors}/>
          </Col>
          <Col xs={12} className="text-right">
            <DailyTimesheetActionPanel record={localRecord} handleRecordCanceling={handleRecordCanceling}
                                       handleRecordDeleting={handleRecordDeleting}/>
          </Col>
        </Row>
      </form>
    </div>
  )
};

MobileTimesheetEditRow.defaultProps = {
  errors: {}
};

export default MobileTimesheetEditRow;