import React, { useState } from "react";
import useProjectAPI from "../../../screens/projects/use-project-api";
import useShallowEqualSelector from "../../../store/helpers";
import EditProjectForm from "./EditProjectForm";

import { selectProject } from "../../../store/ducks/projects/selectors";

function EditProjectFormManager({projectId, onEditingDone}) {
  const [formErrors, setFormErrors] = useState({});

  const {updateProject} = useProjectAPI();
  const project = useShallowEqualSelector(selectProject(projectId));

  const onSubmit = async (payload) => {
    const {response, error} = await updateProject(projectId, payload);

    if (response) {
      onEditingDone && onEditingDone();
    } else {
      setFormErrors(error);
    }
  };

  return (
    <EditProjectForm project={project} onSubmit={onSubmit} onCancel={() => onEditingDone()} errors={formErrors}/>
  )
}

export default EditProjectFormManager;