import React from 'react';

import { useModalAsRoute } from '../../../../components/modal/modal-hooks';
import { extractUrlParameter } from "../../daily/timesheet-utility";

import modalDefinitions from '../../../../components/modal/modal-definitions';

import SimpleModal from '../../../../components/modal/SimpleModal';
import ModalFrame from "../../../../components/modal/ModalFrame";
import ApproverPhaseListing from "../approve/components/ApproverPhaseListing";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

function ApproveUserModal({location}) {
  // FIXME: TB-160
  const stepId: any = extractUrlParameter(location, 'modal.stepId');
  const {isOpen, onClose} = useModalAsRoute(modalDefinitions.APPROVE_USER, location);

  return (
    <SimpleModal manualControl={isOpen} onClose={onClose}>
      <ModalFrame heading={
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.approveOrDeclineModal.approveUsersHoursHeader}}/>
      }>
        <ApproverPhaseListing stepId={stepId}/>
      </ModalFrame>
    </SimpleModal>
  )
}

export default ApproveUserModal;
