import React, { useEffect, useState, useCallback } from "react";
import { Controller } from "react-hook-form";

import { PeopleAPIResponseParser } from "../../../api/people/people-api";

import DualBoxSelect from "./DualBoxSelect";
import useUserAPI from "../../people/user-hooks";


export function FormDualBoxUserSelect({setValue, control, watch}) {
  const users = watch('users');

  return (
    <Controller as={
      <DualBoxUserSelect selected={users} onChange={(users) => setValue('users', users)}/>
    } name="users" control={control}/>
  );
}

function DualBoxUserSelect({selected, onChange, asFormComponent}) {
  const {fetchUsers} = useCallback(useUserAPI(), []);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const params = {page_size: 0, fields: ['id', 'first_name', 'last_name']};

    const loadUsers = async () => {
      const {response} = await fetchUsers(params);

      response && setUsers(PeopleAPIResponseParser.convertRecordsIntoSelectCompatible(response));
    };

    loadUsers();
  }, [fetchUsers]);

  return (
    <DualBoxSelect options={users} selected={selected} onChange={onChange}/>
  )
}

DualBoxUserSelect.defaultProps = {
  selected: [],
  asFormComponent: false
};

export default DualBoxUserSelect;