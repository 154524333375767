import styled from "styled-components";

export const PrimaryColored = styled.span`
  color: #2dbaae;
  font-weight: 700;
`;

export const PrimaryElement = styled(PrimaryColored)`
  font-size: 0.9em;
  text-transform: uppercase;
`;
