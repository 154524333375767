import React from 'react';

import PropTypes from 'prop-types';

import FilterField from "./FilterField";
import FilterBlockEntity from './entities/FilterBlockEntity';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

function FilterBlock(props) {
  const {filterBlock} = props;

  return (
    <>
      <p className="bold">
        <LocalizedMessage text={{ id: TRANSLATION_KEYS.search.availableFilters }} />
      </p>
      {filterBlock.getFilterFields().map((item, index) => (
        <div className="mt-2" key={index}><FilterField filter={item}/></div>))
      }
    </>
  );
}

FilterBlock.propTypes = {
  filterBlock: PropTypes.instanceOf(FilterBlockEntity)
};

export default FilterBlock;