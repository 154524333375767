import { parse } from 'query-string';

import { today, parseISO, isValid, formatISO } from "../../../util/dates";

const extractInitialDateFromQueryParams = locationSearch => {
  const locationParams = parse(locationSearch);

  return validOrToday(locationParams.startdate);
};

const extractPhaseParams = locationSearch => {
  return parse(locationSearch);
};

const extractUrlParameter = (locationObject, paramKey) => {
  const locationParams = parseQueryString(locationObject);

  return locationParams[paramKey];
};

const parseQueryString = (locationObject) => {
  const {search = {}} = locationObject;

  return parse(search);
};

const validOrToday = date => {
  const parsedDate = parseISO(date);

  if (isValid(parsedDate))
    return parsedDate;
  return today();
};

const formatDailyRoute = date => {
  return `/timesheet/daily?startdate=${formatISO(date)}`
};

const formatWeeklyRoute = date => {
  return `/timesheet?startdate=${formatISO(date)}`
};

const formatMobileTimesheetBaseRoute = dateStr => {
  return `/timesheet/${dateStr}`;
};

const formatMobileDailyRoute = date => {
  const isoDate = formatISO(date);

  return formatMobileTimesheetBaseRoute(isoDate);
};

const formatMobileTimesheetProjectPhase = date => {
  const isoDate = formatISO(date);

  const base = formatMobileTimesheetBaseRoute(isoDate);

  return `${base}/?phase=project`;
};

const formatMobileTimesheetTaskPhase = (projectId, date) => {
  const isoDate = formatISO(date);

  const base = formatMobileTimesheetBaseRoute(isoDate);

  return `${base}/?phase=task&project_id=${projectId}`;
};

export {
  extractInitialDateFromQueryParams,
  extractPhaseParams,
  extractUrlParameter,
  parseQueryString,

  formatDailyRoute,
  formatWeeklyRoute,
  formatMobileTimesheetBaseRoute,
  formatMobileDailyRoute,
  formatMobileTimesheetProjectPhase,
  formatMobileTimesheetTaskPhase,

  validOrToday
};