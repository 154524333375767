import React, {useState, useEffect, useCallback} from "react";
import {PHASES_FOR_UI_SELECT} from "../../../timesheet/approval/approval-constants";

import {useForm} from "react-hook-form";
import {extractOnlyChangedAttributes} from "../../../../api/utils";
import {parseISO} from "../../../../util/dates";
import {TooltipCircle} from "../../../../components/ui-utility/Tooltip";

import WithErrorInput from "../../../timesheet/components/WithErrorInput";
import ActuallyDatePicker from "../../../../components/datepicker/ActuallyDatePicker";
import useCycleTemplateAPI from '../cycle-template-hooks';
import FormActionRow from "../../../../forms/FormActionRow";
import Error from "../../../../components/notication/Error";
import FormColumnSelect from "../../../../components/column/FormColumnBlockContainer";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {useLocalizedMessage} from "../../../../intl/use-localized-message";

function CycleTemplateForm({template, onDone}) {
  const {createCycleTemplate, updateCycleTemplate, destroyCycleTemplate} = useCycleTemplateAPI();

  // FIXME: TB-160
  const [apiErrors, setApiErrors] = useState<any>([]);

  const extractDefaults = useCallback(original => ({
    duration: original.duration,
    unit: original.unit,
    start_date: original.start_date ? parseISO(original.start_date) : undefined,
    end_date: original.end_date ? parseISO(original.end_date) : undefined,
    phases: original.phases
  }), []);

  const {register, formState, handleSubmit, control, setValue, watch, reset} = useForm({
    mode: 'onChange',
    defaultValues: extractDefaults(template)
  });

  const onSubmitProxy = async data => {
    const payload = extractOnlyChangedAttributes(template, data);
    const {response, error} = await performCorrectProcedure(payload);

    response && onDone();
    error && setApiErrors(error);
  }

  const performCorrectProcedure = async data => {
    if (template.id)
      return updateCycleTemplate(template.id, data);

    return createCycleTemplate(data);
  }

  const onDelete = async () => {
    const {error} = await destroyCycleTemplate(template.id);

    !error && onDone();
    error && setApiErrors(error);
  }

  // FIXME: TB-160
  const phases: any = watch('phases');
  const {dirty, isValid} = formState;

  useEffect(() => {
    reset(extractDefaults(template));
  }, [reset, extractDefaults, template]);

  const daysLabel = useLocalizedMessage({
    id: TRANSLATION_KEYS.commonProperties.days,
  });
  const weeksLabel = useLocalizedMessage({
    id: TRANSLATION_KEYS.commonProperties.weeks,
  });
  const monthsLabel = useLocalizedMessage({
    id: TRANSLATION_KEYS.commonProperties.months,
  });
  const amountPlaceholderText = useLocalizedMessage({
    id: TRANSLATION_KEYS.commonProperties.amount,
  });

  return (
      <form onSubmit={handleSubmit(onSubmitProxy)} className="actually-form">
        <Row>
          <Col>
            <Error error={apiErrors.general}/>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col lg={12} className="text-center">
            <label htmlFor="start_date">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.addEditTemplateModal.selectTimeRangeMessage}} />
            </label>
          </Col>
          <Col lg={12}>
            <div className="d-flex align-items-center">
              <WithErrorInput errors={apiErrors.start_date}>
                <ActuallyDatePicker control={control} name="start_date" required={true}/>
              </WithErrorInput>
              <div className="pl-2 pr-2">-</div>
              <WithErrorInput errors={apiErrors.end_date}>
                <ActuallyDatePicker control={control} name="end_date" required={true}/>
              </WithErrorInput>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col lg={12} className="text-center">
            <label htmlFor="unit">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.addEditTemplateModal.selectCycleDurationMessage}} />
            </label>
          </Col>
          <Col lg={12}>
            <div className="d-flex align-items-center">
              <WithErrorInput errors={apiErrors.duration}>
                <input className="form-control"
                       name="duration"
                       type="number"
                       ref={register}
                       placeholder={amountPlaceholderText}
                       disabled={template.id}
                       min={1} max={99}/>
              </WithErrorInput>
              <div className="pl-2 pr-2"/>
              <WithErrorInput errors={apiErrors.unit}>
                <select className="form-control" ref={register} name="unit" disabled={template.id}>
                  <option value="DAYS">
                    {daysLabel}
                  </option>
                  <option value="WEEKS">
                    {weeksLabel}
                  </option>
                  <option value="MONTHS">
                    {monthsLabel}
                  </option>
                </select>
              </WithErrorInput>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <label htmlFor="phases">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.addEditTemplateModal.selectApprovalPhaseMessage}} />
            </label>
            <TooltipCircle instructionText={
              <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheetApprovalCycleSettings.addEditTemplateModal.selectApprovalPhaseMessageTooltip}} />
            }/>
            <FormColumnSelect columns={PHASES_FOR_UI_SELECT} onChange={phases => setValue('phases', phases)}
                              disabled={!!template.id} control={control} watch={watch} name='phases'/>
          </Col>
        </Row>

        <FormActionRow showDeleteButton={!!template.id}
                       handleDelete={() => onDelete()}
                       isValid={isValid && phases.length}
                       dirty={dirty}
                       handleCancel={onDone}/>
      </form>
  )
}

CycleTemplateForm.defaultProps = {
  template: {
    phases: []
  }
}

export default CycleTemplateForm;