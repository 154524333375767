import React, {useState, useEffect} from "react";
import {DefaultBlock} from "../../../components/layout/LayoutHelpers";
import {useCheckoutAPI} from "../../checkout/checkout-hooks";

import Bold from "../../../components/ui-utility/Bold";

import {ActuallyButton} from "../../../components/buttons";

import {Row, Col} from "react-bootstrap";
import {If} from "../../../components/ui-utility/Conditional";
import {navigate} from "@reach/router";


const useBillingPortalFetching = () => {
  const [billingInformation, setBillingInformation] = useState({});

  const {fetchBillingPortal} = useCheckoutAPI();

  useEffect(() => {
    const doFetch = async () => {
      const {response} = await fetchBillingPortal();

      response && setBillingInformation(response);
    }
    doFetch();
  }, [fetchBillingPortal]);

  return billingInformation;
}

function BillingPortal() {
  const billingInformation: any = useBillingPortalFetching();

  const goToBillingPortal = () => {
    navigate(billingInformation.url);
  }

  return (
    <If condition={billingInformation.id}>
      <Row>
        <Col lg={12} xs={12}>
          <DefaultBlock withBackground={false}>
            <h6>
              <Bold>
                Manage and view billing information
              </Bold>
            </h6>
            <p>
              Billing portal allows you to view billing history and download past invoices as pdf documents.
              You can also manage your billing information, this includes adding a new credit card or removing an
              existing one.
              Cancelling your subscription to Actually can also be done through the portal.
            </p>
            <ActuallyButton variant="primary" onClick={goToBillingPortal}>
              Take me to the billing portal
            </ActuallyButton>
          </DefaultBlock>
        </Col>
      </Row>
    </If>
  );

}

export default BillingPortal;
