import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {ActuallyButton} from "../../buttons";

import {addMultipleProjects} from "../../../store/ducks/projects/actions";
import {TextArea} from "../../forms/TextArea";
import {hasActualCharacters} from "../../../forms/validators";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import { BreatherRow } from "../../../screens/authentication/signup/SignUpEmailPhase";
import Col from "react-bootstrap/Col";

function AddProjectsMultiple({onDone}) {
  const dispatch = useDispatch();

  const [projectList, setProjectList] = useState("");

  const onSubmitAdvancedForm = useCallback(event => {
    event.preventDefault();
    dispatch(addMultipleProjects({project_list: projectList}));

    onDone && onDone();
  }, [onDone, dispatch, projectList]);

  return (
    <form onSubmit={onSubmitAdvancedForm}>
      <label htmlFor="email_list" className="label-default">
        <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.addProjectsPopover.inputProjectNamesLabel}}/>
      </label>
      <TextArea className="form-control" id="project_name_list" onChange={event => setProjectList(event.target.value)}
                minRows={3}/>
      <small>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.addProjectsPopover.inputProjectNamesHelp}}/>
      </small>
      <BreatherRow className="text-right">
        <Col>
          <ActuallyButton variant="primary" type="submit" disabled={!hasActualCharacters(projectList)}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.addButton}}/>
          </ActuallyButton>
        </Col>
      </BreatherRow>
    </form>
  );
}

export default AddProjectsMultiple;
