import React, {useMemo} from "react";
import {useForm} from "react-hook-form";

import WithErrorInput from "../../../screens/timesheet/components/WithErrorInput";

import {extractOnlyChangedAttributes} from "../../../api/utils";
import {getCustomers, saveCustomer} from "../../../api/customers/customers-api";

import DeleteProjectButton from "../delete-project/DeleteProjectButton";
import ToggleSwitch from "../../toggle-switch/ToggleSwitch";
import SelectOrAdd from "../../select/SelectOrAdd";
import ActuallyDatePicker from "../../datepicker/ActuallyDatePicker";
import FormActionRow from "../../../forms/FormActionRow";

import {parseISO} from '../../../util/dates';
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {FormElement, SimpleFormBuilder} from "../../forms/SimpleFormBuilder";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EditProjectForm({project, onSubmit, onCancel, errors}) {
  const customerApi = useMemo(() => ({fetch: getCustomers, post: saveCustomer}), []);

  const {register, formState, handleSubmit, setValue, control, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      is_active: project.is_active,
      name: project.name,
      customer: project.customer,
      start_date: project.start_date ? parseISO(project.start_date) : undefined,
      end_date: project.end_date ? parseISO(project.end_date) : undefined,
    }
  });

  const {dirty, isValid} = formState;

  const onSubmitProxy = (data) => {
    const changedAttributes = extractOnlyChangedAttributes(project, data);

    onSubmit(changedAttributes);
  };

  const handleCustomerChange = (entity) => {
    const entityId = entity && entity.id;
    setValue('customer', entityId);
  };

  register({name: 'customer'});

  const customer = watch('customer');

  const formElements: Array<FormElement> = [
    {
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.nameLabel}}/>
      ,
      input: <WithErrorInput errors={errors.name}>
        <input className="form-control" name="name" type="text" ref={register} tabIndex={1}/>
      </WithErrorInput>
    },
    {
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.customerLabel}}/>
      ,
      input: <SelectOrAdd api={customerApi} onChange={handleCustomerChange} name="customer" entityId={customer}
                          tabIndex={2}/>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.startDateLabel}}/>
      ,
      input: <WithErrorInput errors={errors.start_date}>
        <ActuallyDatePicker control={control}
                            name="start_date"/>
      </WithErrorInput>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.endDateLabel}}/>
      ,
      input: <WithErrorInput errors={errors.end_date}>
        <ActuallyDatePicker control={control}
                            name="end_date"/>
      </WithErrorInput>
    },
    {
      width: 6,
      label: <LocalizedMessage
        text={{id: TRANSLATION_KEYS.projects.projectDashboard.editProjectModal.editForm.isProjectActiveLabel}}/>
      ,
      input: <ToggleSwitch innerRef={register} name="is_active" tabIndex={3}/>
    },
  ]
  return (
    <form onSubmit={handleSubmit(onSubmitProxy)}>
      <SimpleFormBuilder formElements={formElements}/>

      <Row>
        <Col className="text-right">
          <FormActionRow showDeleteButton={false} tabIndexStart={4}
                         isValid={isValid}
                         dirty={dirty}
                         handleCancel={onCancel}>
            <DeleteProjectButton projectId={project.id} onDeleted={onCancel}/>
          </FormActionRow>
        </Col>
      </Row>
    </form>
  )
}

export default EditProjectForm;
