import axios, { AxiosResponse } from "axios";
import { call, cancel, put } from 'redux-saga/effects';

import { setRequestPending, setRequestDone } from "../store/ducks/loading/loading-actions";

import { defaultRequestConfiguration } from "./api-configuration";
import { appendTrailingSlash } from "./utils";

export interface ApiResponse<T> {
  response?: T,
  error?: any
}

async function sendRequest(requestConfiguration): Promise<ApiResponse<AxiosResponse<any>>> {
  const defaultConfiguration = defaultRequestConfiguration();
  const configuration = {...defaultConfiguration, ...requestConfiguration};

  return axios(configuration).then(response => ({response})).catch(error => ({error}));
}

async function sendRequestSimple<T=any>(requestConfiguration, shouldThrow=false): Promise<ApiResponse<T>> {
  const {response, error} = await sendRequest(requestConfiguration);

  if (response && 'data' in response) {
    return {response: response.data};
  }

  if (shouldThrow) throw error;

  return {error: error.response};
}

// FIXME: TB-160
function* executeRequest(requestConfiguration, meta: any = {}) {

  if (meta.trackRequest)
    yield put(setRequestPending(meta.trackRequest));

  const {response, error} = yield call(sendRequest, requestConfiguration);

  if (meta.trackRequest)
    yield put(setRequestDone(meta.trackRequest));

  if (error && meta.cancelOnFailure)
    yield cancel();

  return {response, error};
}

const executeCancelleableRequest = (fn) => {
  // FIXME: TB-160
  let onGoingPromise: Promise<any> | null = null;
  let controlSignal = axios.CancelToken.source();

  return async (config) => {

    if (onGoingPromise) {
      controlSignal.cancel();
      controlSignal = axios.CancelToken.source();
    }

    onGoingPromise = new Promise(async (resolve, reject) => {
      const {response, error} = await sendRequest(fn({...config, cancelToken: controlSignal.token}));

      if (error) {
        if (axios.isCancel(error))
          reject('Promise rejected due to request canceling');
      } else {
        resolve({response, error});
      }
      onGoingPromise = null;
    });

    return onGoingPromise;
  };
};

const createGetRequest = (params) => createRequest('GET', params);
const createPostRequest = (params) => createRequest('POST', params);
const createPatchRequest = (params) => createRequest('PATCH', params);
const createPutRequest = (params) => createRequest('PUT', params);
const createDeleteRequest = (params) => createRequest('DELETE', params);

const createRequest = (method, params) => {
  params.url = appendTrailingSlash(params.url);

  return {method, ...params}
};

export {
  executeRequest as default,
  sendRequest,
  sendRequestSimple,
  executeCancelleableRequest,

  createGetRequest,
  createPostRequest,
  createPatchRequest,
  createPutRequest,
  createDeleteRequest,

  createRequest
}


