import React from 'react';
import SimpleManageFlow from "./SimpleCRUDFlow";
import useWorkdayLengthAPI from './workday-length-api-hook';
import { ListContainer } from "./WorkdayLengthList";
import { AddEditItem } from "./AddEditWorkdayLength";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

const WorkdayLengthManager = ({userId}) => {
  const api = useWorkdayLengthAPI(userId);

  return (
    <div style={{ minHeight: '400px' }}>
      <SimpleManageFlow titles={{
        list: {id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.listModalHeader},
        add: {id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.addModalHeader},
        edit: {id: TRANSLATION_KEYS.people.personDashboard.personWorkdayLengthModal.editModalHeader},
      }}>
        <SimpleManageFlow.Add>
          <AddEditItem api={api} />
        </SimpleManageFlow.Add>

        <SimpleManageFlow.Edit>
          <AddEditItem api={api} />
        </SimpleManageFlow.Edit>

        <SimpleManageFlow.List>
          <ListContainer api={api} userId={userId} />
        </SimpleManageFlow.List>
      </SimpleManageFlow>
    </div>
  )
};


export default WorkdayLengthManager;
