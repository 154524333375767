import { schema } from "normalizr";

const createCustomerSchema = () => {
  const customerSchema = new schema.Entity('customers');

  return [customerSchema];
};

export {
  createCustomerSchema,
};
