import React from 'react';
import PropTypes from 'prop-types';


import '../column/ActiveColumnsIndicator.css';
import { FileExportIcon } from "../buttons";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

//TODO: Make transparent button
function ExportLink({exportUrl}) {
  return (
    <button type="button" className="btn btn-slick">
      <a href={exportUrl} download>
        <span>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.exportButton}} />
        </span>
        <span className="ml-2"><FileExportIcon/></span>
      </a>
    </button>
  );
}

ExportLink.propTypes = {
  exportUrl: PropTypes.string
};


export default ExportLink;