import React from "react";

import TimeValue from "../../../../components/TimeValue";
import DateFormatter from "../../../../components/ui-utility/DateFormatter";

import {ActiveIndicatorIcon} from "../../../../components/icons";
import {ActuallyButton} from "../../../../components/buttons";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {TableColumnConfiguration} from "../../../../components/table/types/TableColumnConfiguration";
import {TableColumnContentAlignment} from "../../../../components/table/types/TableColumnContentAlignment";


export const WorkdayLengthTableColumns: TableColumnConfiguration[] = [
  {
    key: 'date',
    label: {id: TRANSLATION_KEYS.commonProperties.date},
  },
  {
    key: 'reason',
    label: {id: TRANSLATION_KEYS.commonProperties.reason},
  },
  {
    key: 'time',
    label: {id: TRANSLATION_KEYS.commonProperties.time},
    columnContentAlignment: TableColumnContentAlignment.Right
  }
];

export const createWorkdayLengthTableData = (workdayLengths, onSelect, activeWorkdayLength) => workdayLengths.map((workdayLength) => {
  const isActive = workdayLength.id === activeWorkdayLength.id;

  const handleSelect = () => onSelect(workdayLength);

  return {
    date: (<ActuallyButton variant="link" className="btn-plain btn-block text-left" onClick={handleSelect}>
      <DateFormatter date={workdayLength.date}/>
      {isActive && <span className="pl-2"><ActiveIndicatorIcon/></span>}
    </ActuallyButton>),
    reason: (
      <button onClick={handleSelect} type="button" className="btn btn-plain btn-block text-left">
        {workdayLength.description}
      </button>
    ),
    time: (<TimeValue minutes={workdayLength.minutes} showZero/>)
  }
});