import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import useRecordAPI from "../../record-hooks";
import SimpleRecordListing from "./SimpleRecordListing";
import {
  emptyRecordsData,
  RecordsApiResponse,
  ReportAPIResponseParser
} from "../../../../api/report/report-api-implementation";

function ApprovalListing({userId, cycle}) {
  const {fetchRecordsForApprovalListing} = useRecordAPI();
  const [recordData, setRecordData] = useState<RecordsApiResponse>(emptyRecordsData);

  useEffect(() => {
    const fetchRecordData = async () => {
      const recordData: RecordsApiResponse = await fetchRecordsForApprovalListing(cycle.start_date, cycle.end_date, userId);

      setRecordData(recordData);
    };

    fetchRecordData();
  }, [fetchRecordsForApprovalListing, cycle, userId]);

  const records = ReportAPIResponseParser.mergeEntitiesIntoRecords(recordData);

  return (
    <SimpleRecordListing records={records} hideUserColumn={true}/>
  )

}

ApprovalListing.propTypes = {
  userId: PropTypes.number,
  cycle: PropTypes.object
};

export default ApprovalListing;