import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { navigate } from "@reach/router";
import { STEP_STATUSES } from "../../approval-constants";

import { DateRange } from "../../../../../components/project/ProjectSchedule";
import { PhaseButtonRow } from "../../components/ProjectPhaseStepListing";

import useApprovalAPI from "../../approval-hooks";
import PersonFullName from "../../../../../components/people/PersonFullName";
import SimpleRecordListing from "../../send/SimpleRecordListing";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function ApproverPhaseListing({stepId}) {
  const {fetchUserStepRecords, updateCycleStepUser} = useApprovalAPI();
  // FIXME: TB-160
  const [state, setState] = useState<any>({records: [], cycle: {}, cycleProject: {}, cycleUser: {}});

  useEffect(() => {
    const fetchEssentials = async () => {
      const response = await fetchUserStepRecords(stepId);

      response && setState(response);
    };

    fetchEssentials();
  }, [fetchUserStepRecords, stepId]);

  const onApproveStep = async () => {
    const {response} = await updateCycleStepUser(stepId, {status: STEP_STATUSES.approved});

    response && goBackToListing();
  }

  const onDeclineStep = async () => {
    const {response} = await updateCycleStepUser(stepId, {status: STEP_STATUSES.declined});

    response && goBackToListing();
  }

  const goBackToListing = () => {
    navigate(-1);
  }

  const {cycle, cycleUser} = state;

  return (
    <div className="approver-phase-listing">
      <Row className="mb-2">
        <Col>
          <h2 className="text-center">
            <DateRange startDate={cycle.start_date} endDate={cycle.end_date}/>
          </h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h2>
            <PersonFullName {...cycleUser} addLink={false}/>
          </h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <SimpleRecordListing records={state.records} hideUserColumn={true}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <PhaseButtonRow onApprove={onApproveStep} onDecline={onDeclineStep} onCancel={goBackToListing}/>
        </Col>
      </Row>
    </div>
  )
}

ApproverPhaseListing.propTypes = {
  stepId: PropTypes.string,
};

export default ApproverPhaseListing;