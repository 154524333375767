import { createDeleteRequest, createGetRequest, createPatchRequest, createPostRequest } from "../api";
import { joinRouteParts } from "../utils";

const url = 'balance-fixes';

const getBalanceFixes = params => createGetRequest({url: url, params});

const deleteBalanceFix = balanceFixId => {
  const apiUrl = joinRouteParts(url, balanceFixId);

  return createDeleteRequest({url: apiUrl});
};

const patchBalanceFix = (balanceFixId, data) => {
  const apiUrl = joinRouteParts(url, balanceFixId);

  return createPatchRequest({url: apiUrl, data});
};

const postBalanceFix = data => createPostRequest({url, data});

export {
  getBalanceFixes,
  deleteBalanceFix,
  patchBalanceFix,
  postBalanceFix
};