import EmptyStatePlaceholder from "../../components/ui-utility/EmptyStatePlaceholder";
import React from "react";

const EmptyTimesheetPlaceholder = () => {
  return (
    <EmptyStatePlaceholder iconName="clock">
      <p>There are no visible tasks set for the timesheet.</p>
      <p>You can add tasks to the timesheet by clicking the <em>visible tasks</em> button above.</p>
    </EmptyStatePlaceholder>
  )
};

export default EmptyTimesheetPlaceholder;
