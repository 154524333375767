import React from "react";
import PropTypes from 'prop-types';

import {useSelector} from "react-redux";
import peopleSelectors from "../../store/ducks/people/selectors";

import {parseISO} from "../../util/dates";
import DateShort from "../ui-utility/dates/DateShort";
import DateAgoISO from "../ui-utility/dates/DateAgo";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

function PersonLatestRecord({personId}) {
  const latestDate = useSelector(peopleSelectors.selectLatestRecordDateForId(personId));

  return <LatestRecord latestDate={latestDate}
                       placeholder={<LocalizedMessage text={{id: TRANSLATION_KEYS.people.noLatestRecordFoundMessage}}/>}/>
}

interface LatestRecordProps {
  latestDate: string,
  placeholder: React.ReactNode
}

export const LatestRecord: React.FC<LatestRecordProps> = ({latestDate, placeholder}) => {
  const render = () => {
    if (!latestDate)
      return <>{placeholder}</>;

    return (
      <span>
        <DateShort date={parseISO(latestDate)}/>
        <small className="text-muted ml-1">
          <DateAgoISO date={latestDate}/>
        </small>
      </span>
    )
  };

  return render();
}

PersonLatestRecord.propTypes = {
  personId: PropTypes.number
};

export default PersonLatestRecord;