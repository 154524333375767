import React, { useEffect, useState } from "react";
import useApprovalAPI from "../approval-hooks";
import CyclePhases from "./CyclePhases";

function ApproveForm({cycleId, user}) {
  const {fetchCyclePhases, fetchCycleHistoryForApprover} = useApprovalAPI();
  const [cyclePhases, setPhases] = useState([]);

  useEffect(() => {
    const fetchEssentials = async () => {
      const {response} = await fetchCyclePhases(cycleId);

      response && setPhases(response);
    };

    fetchCycleHistoryForApprover(user.id);
    fetchEssentials();
  }, [fetchCyclePhases, cycleId, fetchCycleHistoryForApprover, user]);

  return (
    <div className="approve-form">
      <CyclePhases phases={cyclePhases} user={user} cycleId={cycleId}/>
    </div>
  );
}


export default ApproveForm;
