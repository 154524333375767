import {TRANSLATION_KEYS} from "../../../intl/intl";

const STEP_STATUSES = {
  approved: 'APPROVED',
  declined: 'DECLINED',
  pending: 'PENDING',
  blocked: 'BLOCKED'
};

const PHASES_FOR_UI_SELECT = [
  {name: {id: TRANSLATION_KEYS.commonProperties.assignedApprover}, asQueryParam: "group_by", value: "approver", identifier: 'GLOBAL_APPROVER_PHASE'},
  {name: {id: TRANSLATION_KEYS.commonProperties.projectManager}, asQueryParam: "group_by", value: "projectlead", identifier: 'PROJECTLEAD_PHASE'},
];

const isUserCycleDeclined = userCycle => userCycle?.status === STEP_STATUSES.declined;

const isUserCyclePendingOrApproved = userCycle => (
    userCycle?.status === STEP_STATUSES.approved ||
    userCycle?.status === STEP_STATUSES.pending
)

const canBeApproved = status => (
    status === STEP_STATUSES.pending
);

const requiresAttention = status => (
    status === STEP_STATUSES.pending ||
    status === STEP_STATUSES.declined ||
    status === STEP_STATUSES.blocked
)

export {
  STEP_STATUSES,
  PHASES_FOR_UI_SELECT,

  canBeApproved,
  requiresAttention,
  isUserCycleDeclined,
  isUserCyclePendingOrApproved
}