import {useDispatch} from "react-redux";

import {sendRequestSimple} from "../../../api/api";
import {
  deleteAuthGroup,
  getAuthGroups, patchAuthGroup,
  postAuthGroup,
} from "../../../api/authorization/groups/auth-group-api";
import {
  receiveManyAuthGroupsAction,
  receiveOneAuthGroupAction,
  removeAuthGroupAction
} from "../../../store/ducks/authorization/auth-group-reducers";

import GeneralApiResponseParser from "../../../api/general-api-response-parser";

// FIXME: TB-160
const useAuthGroupAPI = (): any => {
  const dispatch = useDispatch();

  const fetchAuthGroups = async () => {
    const {response} = await sendRequestSimple(getAuthGroups({page_size: 0}));

    response && dispatch(receiveManyAuthGroupsAction(response));
  };

  const createAuthGroup = async data => {
    const {response, error: errorResponse} = await sendRequestSimple(postAuthGroup(data));

    if (errorResponse) {
      const error = GeneralApiResponseParser.extractAllErrors(errorResponse.data);

      return {error};
    }

    response && dispatch(receiveOneAuthGroupAction(response));

    return {response};
  };

  const removeAuthGroup = async authGroupId => {
    const {error} = await sendRequestSimple(deleteAuthGroup(authGroupId));

    !error && dispatch(removeAuthGroupAction(authGroupId));
  };

  const updateAuthGroup = async (authGroupId, data) => {
    const {response, error: errorResponse} = await sendRequestSimple(patchAuthGroup(authGroupId, data));

    if (errorResponse) {
      const error = GeneralApiResponseParser.extractAllErrors(errorResponse.data);

      return {error};
    }

    response && dispatch(receiveOneAuthGroupAction(response));

    return {response};
  };

  return {
    fetchAuthGroups,
    removeAuthGroup,
    updateAuthGroup,

    createAuthGroup,
  }

};

export {
  useAuthGroupAPI,
};
