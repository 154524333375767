import React, {useEffect, useCallback} from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {useCalendarAPI} from "./calendar-hooks";
import {DefaultBlock, ResponsiveCol} from "../../../components/layout/LayoutHelpers";

import PageHeader from "../../../components/text/PageHeader";
import RequireGlobalRight from "../../../components/rights/RequireGlobalRight";
import AddCalendarButton from "./add/AddCalendarButton";
import {CalendarListing} from "./list/CalendarListing";

import RIGHTS from "../../../components/rights/right-definitions";
import {RouteComponentProps} from "@reach/router";
import {useSelector} from "react-redux";
import {selectAll} from "../../../store/ducks/calendar/calendar-selectors";
import {CalendarResponse} from "../../../api/calendars/CalendarEntity";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

type CalendarPropTypes = RouteComponentProps;

export const Calendar: React.FunctionComponent<CalendarPropTypes> = () => {
  const {fetchCalendars} = useCallback(useCalendarAPI(), []);

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars]);

  // FIXME: TB-160
  const calendars: Array<CalendarResponse> = useSelector<any, any>(selectAll);

  return (
    <Container>
      <Row>
        <ResponsiveCol>
          <PageHeader className="align-middle">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.calendarSettings.calendarManagement.pageHeader}} />
          </PageHeader>
        </ResponsiveCol>
        <ResponsiveCol className="text-right">
          <RequireGlobalRight rightName={RIGHTS.manageCalendar}>
            <AddCalendarButton/>
          </RequireGlobalRight>
        </ResponsiveCol>
      </Row>
      <Row>
        <ResponsiveCol>
          <DefaultBlock>
            <CalendarListing calendars={calendars}/>
          </DefaultBlock>
        </ResponsiveCol>
      </Row>
    </Container>
  );
}