// Current react-select component only knows how to display the label thru the label property.
const makeRecordsLabelSelectCompatible = (records, labelKey) => {
  return records.map(record => makeSingleRecordSelectCompatible(record, labelKey));
};

const makeSingleRecordSelectCompatible = (record, labelKey) => (
  {
    ...record,
    label: record.label ? record.label : record[labelKey]
  });

const makeSelectionNullOnLackOfLabeling = (record, labelKey) => {
  const recordForLabel = makeSingleRecordSelectCompatible(record, labelKey);

  return recordForLabel.label ? recordForLabel : null;
};


export {
  makeRecordsLabelSelectCompatible,
  makeSingleRecordSelectCompatible,
  makeSelectionNullOnLackOfLabeling,
}