const apiNotifierTypes = Object.freeze({
  API_PENDING: 'API_PENDING',
  API_ERROR: 'API_ERROR',
  API_SUCCESS: 'API_SUCCESS',
  API_INFO: 'API_INFO',
  API_UNAUTHORIZED: 'API_UNAUTHORIZED',
  API_VALIDATION_ERROR: 'API_VALIDATION_ERROR'
});

export default apiNotifierTypes;
