import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import {Link, Match} from "@reach/router";
import {AppLogo} from "../ui-utility/AppLogo";
import {AdminMenuButton} from "./components/AdminMenu";
import {CogIcon} from "../buttons";
import {LogOutButton} from "../../screens/authentication/Logout";
import {NotificationIndicator} from "../notication/NotificationIndicator";

import SidebarToggle from "./SidebarToggle";

import './TopNavigation.css';
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";

const TopNavItem = ({to, makeLink = true, ...props}) => {
  const resolveClasses = isMatch => isMatch ? 'top-nav-item active' : 'top-nav-item';
  const pathResolver = `${to}/*`;

  // FIXME: TB-160
  return (
    <Match path={pathResolver}>
      {(theProps: any) => (
        <div style={{position: 'relative'}}>
          <div className={classNames(props.className, theProps.className, resolveClasses(theProps.match))}>
            {makeLink && <Link className="btn" to={to}>{props.children}</Link>}
            {!makeLink && props.children}
          </div>
        </div>
      )}
    </Match>
  )
};

const TopBarImplementation = ({className, ...props}) => {
  return (
    <div className="d-flex justify-content-between top-navigation align-items-center">
      <div className="d-flex align-items-center">
        <div className="top-bar-toggle-button">
          <SidebarToggle isCollapsed={props.isCollapsed} onToggle={props.toggleCollapsed}/>
        </div>
        <AppLogo/>
      </div>
      <div className="d-flex">
        <TopNavItem to={'/approval'}>
          <NotificationIndicator/>
        </TopNavItem>
        <TopNavItem to={'/settings/*'} makeLink={false}>
          <AdminMenuButton/>
        </TopNavItem>

        <TopNavItem to={'/profile'}>
          <CogIcon/>
          <span className="top-nav-item-label pl-1">
            <LocalizedMessage text={{ id: TRANSLATION_KEYS.topMenu.profile }} />
          </span>
        </TopNavItem>

        <TopNavItem to={'/logout'} makeLink={false}>
          <LogOutButton/>
        </TopNavItem>

      </div>
    </div>
  )
};

const TopBar = styled(TopBarImplementation)`
  background-color: white;
  border-bottom: 1px solid #D9E2EC;
`;

export default TopBar;
