import React from 'react';
import PropTypes from 'prop-types';

import Delay from "./Delay";

import './LoadingIndicator.css';
import { LoadingIcon } from '../buttons';
import styled from 'styled-components';

const PlaceholderText = styled.div`
  max-width: 300px;
  text-align: center;
  font-weight: 700;
  color: #9FB3C8;
`;

const iconColor = '#D9E2EC';

function LoadingIndicator({ delay }) {
  return (
    <Delay delay={delay}>
      <div className="d-flex align-items-center flex-column p-4 loading-indicator">
        <div className="pb-3">
          {<LoadingIcon style={{ color: iconColor }} className="fa-7x" />}
        </div>

        <PlaceholderText>
          <span className="text-uppercase">Loading ...</span>
        </PlaceholderText>
      </div>
    </Delay>
  )
}

LoadingIndicator.propTypes = {
  delay: PropTypes.number
};

LoadingIndicator.defaultProps = {
  delay: 300
};


export default LoadingIndicator;
