import { call, takeLatest, put } from 'redux-saga/effects';

import executeRequest from "../../../api/api";

import * as types from './types';
import * as actions from './actions';

import {
  authenticateIntoTenant,
  getCurrentUser,
  logout
} from '../../../api/authentication/authentication-api';

import { resetState } from "./actions";
import { getCurrentUserSuccessful } from "./actions";
import { navigate } from "@reach/router";
import { changeTenantSuccess } from "./actions";

function* fetchMeWatch() {
  const { response, error } = yield call(executeRequest, getCurrentUser());

  // FIXME: let interceptor handle the logic for error handling
  if (error)
    yield put(resetState());

  else {
    yield put(getCurrentUserSuccessful(response.data));
  }
}

function* logoutWatch() {
  yield put(resetState());
  yield call(executeRequest, logout());
}

function* tenantAuthenticationWatch(action) {
  const { response } = yield initiateTenantAuthentication(action);

  if (response) {
    yield put(actions.completeAuthentication(response.data));
  }

}

function* tenantChangeWatch(action) {
  const { response } = yield initiateTenantAuthentication(action);

  if (response) {
    yield put(changeTenantSuccess(response.data));

    yield call(() => navigate('/'))
  }

}

function* initiateTenantAuthentication(action) {
  const { id, asDefault } = action.payload;

  return yield call(executeRequest, authenticateIntoTenant(id, asDefault));
}

export const authSagas = [
  takeLatest(types.LOGOUT_REQUESTED, logoutWatch),

  takeLatest(types.GET_CURRENT_USER_REQUESTED, fetchMeWatch),
  takeLatest(types.AUTHENTICATE_INTO_TENANT, tenantAuthenticationWatch),
  takeLatest(types.CHANGE_TENANT, tenantChangeWatch)
];