class FilterBlockEntity {
  // FIXME: TB-160
  filters: any;
  filterKeys: any;
  static blockKey = '';

  constructor() {
    this.filters = this.createFilters();
    this.filterKeys = this.constructFilterKeys();
  }

  createFilters() {
    throw Error('FilterBlockEntity:createFilters Not Implemented');
  };

  getFilterFields = () => {
    return this.filters;
  };

  constructFilterKeys = () => {
    return this.filters.map(filter => filter.getFilterKey());
  };

  getFilterKeys = () => {
    return this.filterKeys;
  };

  formatQueryParameters = (valuesForFilters) => {
    return this.filters.reduce((accumulator, currentFilter) => {
      const filterKey = currentFilter.getFilterKey();
      const filterValue = valuesForFilters[filterKey];
      const filterValueObject = currentFilter.formatValue(filterValue);

      return {...accumulator, ...filterValueObject};
    }, {});
  };

}

export default FilterBlockEntity;