import {useEffect, useRef, useState} from "react";

import {useDispatch} from "react-redux";
import {navigate} from "@reach/router";

import {openModalAction, closeModalAction} from "./modal-redux";
import {serializeQueryDict} from "../../api/utils";
import {extractUrlParameter} from "../../screens/timesheet/daily/timesheet-utility";

const useModal = (name, id = "") => {
  const dispatch = useDispatch();

  const parsedName = name.replace(':id', id);

  const delegator = action => {
    return () => dispatch(action);
  };

  return [
    delegator(openModalAction(parsedName)),
    delegator(closeModalAction(parsedName)),
    parsedName
  ]
};

const useModalAsRoute = (modalName, location) => {
  const modalKey = extractUrlParameter(location, 'modal.name');

  const onClose = () => {
    navigate(-1);
  };

  const isOpen = modalKey === modalName;

  return {
    isOpen,
    onClose
  }
};

const useHeightMeasurement = () => {
  const [height, setHeight] = useState(0);
  // FIXME: TB-160
  const elementRef: any = useRef();

  useEffect(() => {
    const {current} = elementRef;

    const handleResize = () => {
      current && setHeight(current?.clientHeight);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [elementRef]);

  return {
    elementRef,
    height
  };
};

const constructModalUrl = (modalKey, extraKwargs) => {
  const queryDict = serializeQueryDict(extraKwargs);

  return `?modal.name=${modalKey}&${queryDict}`;
};

const dropModalQueryParamsFromDict = (queryDict) => {
  const keys = Object.keys(queryDict);

  return keys.reduce((acc, key) => {
    if (key.startsWith('modal.'))
      return acc;
    acc[key] = queryDict[key];

    return acc;
  }, {});
};

export {
  useModal,
  useModalAsRoute,

  useHeightMeasurement,
  constructModalUrl,
  dropModalQueryParamsFromDict
}
