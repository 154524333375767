import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { navigate } from "@reach/router";
import { STEP_STATUSES } from "../approval-constants";

import ProjectName from "../../../../components/project/ProjectName";
import PersonFullName from "../../../../components/people/PersonFullName";
import SimpleRecordListing from "../send/SimpleRecordListing";
import useApprovalAPI from "../approval-hooks";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { DateRange } from "../../../../components/project/ProjectSchedule";
import { RightAngleLightIcon } from "../../../../components/icons";
import { ActuallyButton, DeleteLinkToButton } from "../../../../components/buttons";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";

export function PhaseButtonRow({onDecline, onApprove, onCancel}) {
  return (
    <div className="d-flex justify-content-end">
      <DeleteLinkToButton label={
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.declineButton}}/>
      } onConfirm={onDecline}
      confirmLabel={
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.declineButton}}/>
      }/>
      <ActuallyButton variant="link" onClick={onCancel}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
      </ActuallyButton>
      <ActuallyButton variant="primary" onClick={onApprove}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.approveRecordsModal.approveButton}}/>
      </ActuallyButton>
    </div>
  )
}


function ProjectPhaseStepListing({stepId}) {
  const {fetchProjectStepRecords, updateCycleStepProject} = useApprovalAPI();

  // FIXME: TB-160
  const [state, setState] = useState<any>({records: [], cycle: {}, cycleProject: {}, cycleUser: {}});

  useEffect(() => {
    const fetchEssentials = async () => {
      const response = await fetchProjectStepRecords(stepId);

      response && setState(response);
    };

    fetchEssentials();
  }, [fetchProjectStepRecords, stepId]);

  const onApproveStep = async () => {
    const {response} = await updateCycleStepProject(stepId, {status: STEP_STATUSES.approved});

    response && goBackToListing();
  }

  const onDeclineStep = async () => {
    const {response} = await updateCycleStepProject(stepId, {status: STEP_STATUSES.declined});

    response && goBackToListing();
  }

  const goBackToListing = () => {
    navigate(-1);
  }

  const {cycle, cycleProject, cycleUser} = state;

  return (
    <div className="project-phase-step-listing">
      <Row className="mb-4">
        <Col>
          <h2 className="text-center">
            <DateRange startDate={cycle.start_date} endDate={cycle.end_date}/>
          </h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h2>
            <PersonFullName {...cycleUser} addLink={false}/>
            <span className="pl-2 pr-2">
              <RightAngleLightIcon/>
            </span>
            <ProjectName name={cycleProject.name} addLink={false}/>
          </h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <SimpleRecordListing records={state.records} hideUserColumn={true}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <PhaseButtonRow onApprove={onApproveStep} onDecline={onDeclineStep} onCancel={goBackToListing}/>
        </Col>
      </Row>
    </div>
  )
}

ProjectPhaseStepListing.propTypes = {
  stepId: PropTypes.string,
};

export default ProjectPhaseStepListing;