import React, {useRef} from "react";
import {keyBy} from 'lodash';

import {
  formatDate,
  getDay,
  getMonthDates,
  getWeekDates,
  isSameMonth,
  formatISO,
  differenceInCalendarWeeks, isFirstDayOfMonth
} from "../../util/dates";
import {
  formatDayNarrow,
  formatDayNumber,
  formatFirstThreeLettersOfMonth
} from "../../components/ui-utility/dates/date-formatters";
import {noop} from "../../tests/tools/test-utility";
import { getCurrentUserDateLocale } from "../../intl/intl";

export interface DailyActual {
  date: string | Date,
  actual_minutes: number,
  capacity_minutes: number
}

interface Coordinate {
  x: number,
  y: number,
  date: Date
}

export type DailyActualCoordinate = DailyActual & Coordinate;

class CoordinateResolver {
  // FIXME: Tb-160
  locale: any;
  monthDates: any;
  startOfCalendar: any;

  constructor(date) {
    this.locale = getCurrentUserDateLocale();
    this.monthDates = getMonthDates(date);

    this.startOfCalendar = this.monthDates[0];
  }

  createWeeklyLevelCoordinates = (actuals): Array<DailyActualCoordinate> => {
    const coordinates: Array<Coordinate> = this.monthDates.map(this.mapDateToCoordinates);

    return coordinates.map((coord) => this.mapActualsIntoCoordinates(coord, actuals));
  };

  mapDateToCoordinates = (date): Coordinate => {
    const y = differenceInCalendarWeeks(date, this.startOfCalendar, {locale: this.locale});
    const {weekStartsOn} = this.locale.options;
    const x = (7 + getDay(date) - weekStartsOn) % 7;

    return {x, y, date};
  };

  mapActualsIntoCoordinates = (coordinate: Coordinate, actuals): DailyActualCoordinate => {
    const date: string = formatISO(coordinate.date);

    const dailyActual: DailyActual = actuals[date] || {};

    return {
      ...dailyActual,
      ...coordinate
    }
  }


  static convertRawRecordsIntoDayStatusCompliantSet(listOfRecords, dateObjects, dayCapacity = 480): Array<DailyActual> {
    const recordsGroupedByDate = keyBy(listOfRecords, 'date');

    return dateObjects.map(dateObject => {
      const date = formatDate(dateObject);
      const recordForDate = recordsGroupedByDate[date];

      const recordsInMinutesForSlot = recordForDate?.minutes_sum || 0;

      const coordinateItem: DailyActual = {
        date, actual_minutes: recordsInMinutesForSlot, capacity_minutes: dayCapacity
      };

      return coordinateItem;
    });
  }

  static getHorizontalPositionForElement = (elementIndex, blockSize, gutter) => {
    return (blockSize + gutter) * elementIndex + 0.5 * blockSize;
  }

}

export const resolveStatusColor = (actualMinutes, capacityMinutes, colorOptions = {}) => {
  if (actualMinutes === 0) return colorOptions['default'] || '#ebedf0';
  else if (actualMinutes < capacityMinutes) return colorOptions['low'] || '#3EBD93';
  else if (actualMinutes === capacityMinutes) return colorOptions['exact'] || '#147D64';
  else return colorOptions['over'] || '#EF4E4E';
};


interface DayStatusProps {
  blockSize: number;
  gutter?: number;
  actualCoordinate: DailyActualCoordinate;
  yOffset?: number;
  colorOptions?: any; // This could be ColorOption enum which would contain all the possible options
  onMouseOver?: (element: SVGRectElement | null, status: any) => void;
  onMouseLeave?: (element: SVGRectElement | null, status: any) => void
}

// FIXME: TB-160
const DayStatus: React.FunctionComponent<DayStatusProps> = ({
                                                              blockSize, gutter = 2, actualCoordinate, yOffset, colorOptions = undefined,
                                                              onMouseOver = noop,
                                                              onMouseLeave = noop
                                                            }: any) => {
  const fillColor = resolveStatusColor(actualCoordinate.actual_minutes, actualCoordinate.capacity_minutes, colorOptions);

  const xx = actualCoordinate.x * (blockSize + gutter);
  const yy = actualCoordinate.y * (blockSize + gutter);

  const ref = useRef<SVGRectElement>(null);

  return (
    <rect data-date={formatISO(actualCoordinate.date)} ref={ref}
          onMouseOver={() => onMouseOver(ref.current, actualCoordinate)}
          onMouseLeave={() => onMouseLeave(ref.current, actualCoordinate)}
          x={xx}
          y={yy + yOffset}
          width={blockSize}
          height={blockSize}
          style={{fill: fillColor}}/>
  )
};

const WeekHighlighter = ({blockSize, gutter = 2, dateCoordinate, yOffset}) => {
  const yy = dateCoordinate.y * (blockSize + gutter);
  const highlightBoxHeight = blockSize / 2;
  const highlightBoxStartY = (blockSize - highlightBoxHeight) / 2;

  return (<rect x={0}
                y={yy + yOffset + highlightBoxStartY}
                height={highlightBoxHeight}
                width={highlightBoxHeight}
                style={{fill: '#8EEDC7'}}/>)
};

const AxisSlot = ({index, y, children, blockSize, gutter}) => {
  const x = CoordinateResolver.getHorizontalPositionForElement(index, blockSize, gutter);

  return (
    <text x={x} y={y}
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily="Work+Sans"
          color="##212529"
          fontSize={7}>
      {children}
    </text>
  )
}

const WeekDayLetters = ({weekDays, blockSize, gutter}) => {
  return (
    weekDays.map((date, i) =>
      <AxisSlot index={i} blockSize={blockSize} gutter={gutter} y={7} key={formatDate(date)}>
        {formatDayNarrow(date)}
      </AxisSlot>
    )
  );
};

const MonthAxis = ({days, blockSize, gutter, y}) => {
  const shouldDisplayMonth = (date, i) => {
    return i === 0 || isFirstDayOfMonth(date);
  }

  return (
    days.map((date, i) =>
      <AxisSlot index={i} blockSize={blockSize} gutter={gutter} y={y} key={formatDate(date)}>
        {shouldDisplayMonth(date, i) && formatFirstThreeLettersOfMonth(date)}
      </AxisSlot>
    )
  );
};

const DayNumberAxis = ({days, blockSize, gutter, y}) => {
  return (
    days.map((date, i) =>
      <AxisSlot index={i} blockSize={blockSize} gutter={gutter} y={y} key={formatDate(date)}>
        {formatDayNumber(date)}
      </AxisSlot>
    )
  );
}

const MonthlyStatus = ({startDate, highlightWeekDate, data, blockSize = 8, gutter = 2, colorOptions = {}}) => {
  const resolver = new CoordinateResolver(startDate);
  const coordinates = resolver.createWeeklyLevelCoordinates(data);

  const maxWeekAmount = 6;  // A months divides to maximum of 6 weeks. Let's keep the height same even if less weeks.

  const weekDayLettersHeight = 14;
  const height = ((blockSize + gutter) * maxWeekAmount - 1) + weekDayLettersHeight;
  const width = 7 * (blockSize + gutter) - 1 + blockSize;

  const weekDays = getWeekDates(startDate);

  const highlightWeekCoords = resolver.mapDateToCoordinates(highlightWeekDate);

  const isInThisMonth = isSameMonth(highlightWeekDate, startDate);

  const transformation = `translate(${blockSize},0)`;

  return (
    <svg width={width} height={height}>
      {isInThisMonth && <WeekHighlighter
        blockSize={blockSize}
        dateCoordinate={highlightWeekCoords}
        yOffset={weekDayLettersHeight}/>}
      <g transform={transformation}>
        <WeekDayLetters weekDays={weekDays} blockSize={blockSize} gutter={gutter}/>
        {coordinates.map((coordinate, index) => <DayStatus
          yOffset={weekDayLettersHeight}
          key={index}
          blockSize={blockSize}
          actualCoordinate={coordinate}
          colorOptions={colorOptions}/>)}
      </g>
    </svg>
  );
};

MonthlyStatus.propTypes = {
  // FIXME: TB-160 -- Date
  // startDate: PropTypes.objectOf(Date),
};

export {
  MonthlyStatus as default,
  WeekDayLetters,
  DayStatus,
  MonthAxis,
  DayNumberAxis,

  CoordinateResolver,
};
