import * as React from 'react';
import { environment } from '../config';

export const useKeyEventListener = (keysToListen?: Array<string>, options?: {preventInProduction?: boolean}) => {
    const [keysPressed, setKeysPressed] = React.useState<Record<string, boolean>>({});

    const allPressed = keysToListen?.every(key => keysPressed[key]);

    const keyPressEvent = (event: KeyboardEvent) => {
        if (!event.repeat && (!keysToListen || keysToListen.includes(event.key)))
            setKeysPressed(currentKeysPressed => ({
                ...currentKeysPressed,
                [event.key]: event.type === 'keydown' ? true : false
            }))
    }

    React.useEffect(() => {
        if (!options?.preventInProduction || environment === 'development') {
            document.addEventListener('keydown', (event) => keyPressEvent(event))
            document.addEventListener('keyup', (event) => keyPressEvent(event))
        }
        return () => {
            document.removeEventListener('keydown', (event) => keyPressEvent(event))
            document.removeEventListener('keyup', (event) => keyPressEvent(event))
        }
    }, [])

    return { keysPressed, allPressed };
}
