import BooleanFilterField from "./BooleanFilter";

import {
  SelectFilterField,
  APISelectFilterField,
} from "./SelectFilter";

import {DateFilterField, DateRangeFilterField} from "./DateFilter";
import InputFilterField from "./InputFilterField";

class FilterFactory {

  static createInputFilter(filterKey) {
    return new InputFilterField(filterKey);
  }

  static createBooleanFilter(filterKey) {
    return new BooleanFilterField(filterKey);
  }

  static createSelectFilter(filterKey, isMultiSelect) {
    return new SelectFilterField(filterKey, isMultiSelect);
  }

  static createApiSelectFilter(filterKey, entityType, isMultiSelect=false) {
    return new APISelectFilterField(filterKey, entityType, isMultiSelect);
  }

  static createDateFilter(filterKey) {
    return new DateFilterField(filterKey);
  }

  static createDateRangeFilter(filterKey) {
    return new DateRangeFilterField(filterKey);
  }

}


export default FilterFactory;