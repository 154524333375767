import * as React from 'react';
import { CustomerNameSelector } from "../../../components/customers/CustomerName";
import ProjectName from "../../../components/project/ProjectName";
import DateFormatter from "../../../components/ui-utility/DateFormatter";
import InactiveIcon from "../../../components/icons/InactiveIcon";
import { ControlWrapper, ControlWrapperControl } from '../../../components/layout/ControlWrapper';
import { TRANSLATION_KEYS } from '../../../intl/intl';
import ActiveFilterBlock from '../../../components/filter/ActiveFilterBlock';
import { PaginationData } from '../../../components/pagination/Pagination';
import RequireGlobalRight from '../../../components/rights/RequireGlobalRight';
import RIGHTS from '../../../components/rights/right-definitions';
import AddProjects from '../../../components/projects/add-projects/AddProjects';
import { DefaultBlock } from '../../../components/layout/LayoutHelpers';
import { ActuallyTable } from '../../../components/table/ActuallyTable';
import styled from 'styled-components';
import { getProjectListingColumnConfiguration, projectListingEmptyResultParagraphs } from './project-listing-table-columns';

const StyledAddProjects = styled(AddProjects)`
  .btn-actually-primary {
    padding: .175rem .75rem;
  } 
`

const StyledProjectName = styled(ProjectName)`
  padding-right: 0.25rem;
`

interface OwnPropTypes {
  filterBlock: any;
  results: Array<number>
  activeFilters: any;
  records: any;
  ordering: Array<string>
  pagination?: PaginationData;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOrderingChange: (newOrdering: Array<string>) => void;
}

export type ProjectListingProps = OwnPropTypes;

// FIXME: TB-160
const getProjectListingData = (projects: Array<number>) => projects.map((project: any) => {
  return {
    project: <>
      <StyledProjectName {...project} />
      {!project.is_active && <InactiveIcon>The person is set as inactive.</InactiveIcon>}
    </>,
    customer: <CustomerNameSelector customerId={project.customer} />,
    startDate: <DateFormatter date={project.start_date} />,
    endDate: <DateFormatter date={project.end_date} />,
  }
})

export const ProjectListing: React.FunctionComponent<ProjectListingProps> = ({
  filterBlock,
  results,
  activeFilters,
  records,
  ordering,
  pagination,
  isLoading,
  onPageChange,
  onOrderingChange
}) => {

  const orderedRecords = results.map(result => records[result]);

  const peopleListingControls: ControlWrapperControl[] = [
    {
      name: 'filtering',
      label: { id: TRANSLATION_KEYS.search.filtersButton },
      component: <ActiveFilterBlock filterBlock={filterBlock} />,
      isActive: !!activeFilters.length
    },
  ]

  const projectListingActions = [
    <RequireGlobalRight rightName={RIGHTS.addProject}><StyledAddProjects /></RequireGlobalRight>
  ]

  return (
    <ControlWrapper
      controls={peopleListingControls}
      actions={projectListingActions}
      isLoading={isLoading}
    >
      <DefaultBlock withBackground={true}>
        <ActuallyTable
          tableColumns={getProjectListingColumnConfiguration}
          tableData={getProjectListingData(orderedRecords)}
          ordering={ordering}
          pagination={pagination}
          isLoading={isLoading}
          emptyResultProps={{
            paragraphs: projectListingEmptyResultParagraphs
          }}
          onPageChange={onPageChange}
          onOrderingChange={onOrderingChange} />

      </DefaultBlock>
    </ControlWrapper>
  )
}



