import peopleSelectors from "../people/selectors";

const selectAuthState = state => state.auth;

const IMMUTABLE_USER = {};

export const selectCurrentUser = state => {
  const authState = selectAuthState(state);

  if (authState.isLoading) {
    return IMMUTABLE_USER;
  }

  const currentUserId = authState.userId;

  return peopleSelectors.selectPeopleEntities(state).users[currentUserId] || IMMUTABLE_USER;
};

export const selectNotificationCount = state => {
  const authState = selectAuthState(state);

  return authState.notifications;
}

export const selectAuthenticationState = state => state.auth;
export const selectCurrentTenantId = state => selectAuthenticationState(state).tenantId;