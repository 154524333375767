import {formatWithCurrentLocale} from "./format-with-locale";

export const formatFirstThreeLettersOfMonth = date => {
  return formatWithCurrentLocale(date, 'MMMM').substr(0, 3);
}

export const formatDateShort = date => {
  return date ? formatWithCurrentLocale(date, 'P') : '';
};

export const formatDayShort = date => {
  return formatWithCurrentLocale(date, 'EEEEEE');
};

export const formatDayNarrow = date => {
  return formatWithCurrentLocale(date, 'EEEEE');
};

export const formatDayNumber = date => {
  return formatWithCurrentLocale(date, 'dd');
};

// Monday, tuesday ...
export const formatDayOfWeek = date => {
  return formatWithCurrentLocale(date, 'EEEE');
};
