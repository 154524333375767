import React from 'react';

import { useDispatch } from "react-redux";
import { Link } from "@reach/router";

import { logout } from "../../store/ducks/auth/actions";
import { SignOutIcon } from "../../components/buttons";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS, removeCurrentLocaleFromLocalStorage} from "../../intl/intl";

export const Logout = ({children}) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    removeCurrentLocaleFromLocalStorage();
    dispatch(logout());
  };

  return (
    <Link to="/" onClick={e => handleLogout()} className="btn logout-link">{children}</Link>
  )
};

export const LogOutButton = () => {
  return (
    <Logout>
      <SignOutIcon/>

      <span className="top-nav-item-label pl-1">
        <LocalizedMessage text={{ id: TRANSLATION_KEYS.topMenu.logout }} />
      </span>
    </Logout>
  )
};