import {customerAdapter} from './customer-reducers';
import {ActuallyRootState} from "../../index";

const {
  selectIds,
  selectEntities,
  selectById,
  selectAll
} = customerAdapter.getSelectors((state: ActuallyRootState) => state.customers);

export const selectCustomerById = (id) => (state: ActuallyRootState) => selectById(state, id);

export {
  selectIds,
  selectEntities,
  selectById,
  selectAll as selectAllCustomers
}
