import uuid from "uuid";
import GeneralApiResponseParser from "../../../../api/general-api-response-parser";

import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
import {
  cancelRecordEdit, createRecord,
  deleteRecord, deleteRecordSuccess,
  fetchTimesheetStatus,
  saveRecordSuccess, startRecordEdit
} from "../../../../store/ducks/mobile/timesheet/actions";
import { getMonthStartAndEndDates, parseISO } from "../../../../util/dates";

import { sendRequestSimple } from "../../../../api/api";
import { addRecord, updateRecord } from "../../../../api/timesheet/mobile-timesheet-api";
import { selectTimesheetUser } from "../../../../store/ducks/mobile/timesheet/selectors";
import { selectRecordsBeingEdited } from "../../../../store/ducks/timesheet/selectors";


const useTimesheetAPI = () => {
  const dispatch = useDispatch();

  const updateStatusAndDispatchSuccess = (record, date) => {
    const {startDate, endDate} = getMonthStartAndEndDates(parseISO(date));

    dispatch(fetchTimesheetStatus(null, startDate, endDate));
    dispatch(saveRecordSuccess(record));
  };

  const patchRecord = async record => {
    return await sendRequestSimple(updateRecord(record));
  };

  const postRecord = async record => {
    return await sendRequestSimple(addRecord(record));
  };

  const destroyRecord = record => {
    dispatch(deleteRecord(record))
  };

  return {
    patchRecord,
    postRecord,
    destroyRecord,
    updateStatusAndDispatchSuccess,
  }

};

const useTimesheetEditing = () => {
  const dispatch = useDispatch();
  const {patchRecord, postRecord, destroyRecord, updateStatusAndDispatchSuccess} = useTimesheetAPI();

  const recordIdsInEdit = useSelector(selectRecordsBeingEdited);
  const timesheetUser = useSelector(selectTimesheetUser);

  const [apiErrors, setApiErrors] = useState({});

  const handleRecordCreation = (taskId, date) => {
    const newRecord = createLocalRecord(taskId, date);

    dispatch(createRecord(newRecord));
    dispatch(startRecordEdit(newRecord));
  };

  const handleRecordEdit = record => {
    dispatch(startRecordEdit(record));
  };

  const handleRecordCanceling = record => {
    record.local && dispatch(deleteRecordSuccess(record));

    dispatch(cancelRecordEdit(record.id));
  };

  const handleRecordSaving = async record => {
    // FIXME: TB-160
    let apiResult: any = null;

    if (isCreatedLocally(record))
      apiResult = await postRecord({...record, id: null});
    else
      apiResult = await patchRecord(record);

    if (apiResult.error && apiResult.error.data) {
      const extractedErrors = GeneralApiResponseParser.extractAllErrors(apiResult.error.data);

      setApiErrors({...apiErrors, [record.id]: extractedErrors});
    } else {
      handleRecordCanceling(record);
      updateStatusAndDispatchSuccess(apiResult.response, record.date);

      setApiErrors({...apiErrors, [record.id]: {}});
    }
  };

  const handleRecordDeleting = record => {
    destroyRecord(record);
  };

  const isCreatedLocally = record => record.local;

  const createLocalRecord = (taskId, date) => ({
    id: uuid.v4(),
    task: taskId,
    date: date,
    local: true,
    user: timesheetUser.id,
    minutes: 0
  });

  return {
    apiErrors,
    recordIdsInEdit,

    handleRecordCreation,
    handleRecordEdit,
    handleRecordCanceling,
    handleRecordSaving,
    handleRecordDeleting
  }

};

export default useTimesheetEditing;