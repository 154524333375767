import React from "react";

import { usePasswordResetState } from "./use-password-reset";
import { ActuallyButton } from "../../../components/buttons";
import { If } from "../../../components/ui-utility/Conditional";

import WithErrorInput from "../../timesheet/components/WithErrorInput";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

function PasswordChangeForm({onSubmitForm, errors, showOldPassword=false}) {
  const {password, rePassword, oldPassword, setPassword, setRePassword, setOldPassword} = usePasswordResetState();

  const onSubmit = (event) => {
    event.preventDefault();

    onSubmitForm(password, rePassword, oldPassword);
  };

  return (
    <form onSubmit={onSubmit}>
      <If condition={showOldPassword}>
        <div className="form-group">
          <label>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.oldPasswordTextfield}} />
          </label>
          <WithErrorInput errors={errors.old_password}>
            <input className="form-control" name="password" type="password" onChange={setOldPassword} required/>
          </WithErrorInput>
        </div>
      </If>

      <div className="form-group">
        <label>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.newPasswordTextfield}} />
        </label>
        <WithErrorInput errors={errors.new_password1}>
          <input className="form-control" name="password" type="password" onChange={setPassword} required/>
        </WithErrorInput>
      </div>
      <div className="form-group">
        <label>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.confirmPasswordTextfield}} />
        </label>
        <WithErrorInput errors={errors.new_password2}>
          <input className="form-control" name="password" type="password" onChange={setRePassword} required/>
        </WithErrorInput>
      </div>
      <div className="text-right">
        <ActuallyButton variant="primary" type={"submit"}><span>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.profile.setNewPasswordButton}} />
        </span></ActuallyButton>
      </div>
    </form>
  )
}

PasswordChangeForm.defaultProps = {
  errors: {}
};

export default PasswordChangeForm;