import {DailyActual} from "../../screens/timesheet/MonthlyStatus";

export class BarCharLogic {
  dailyActuals: Array<DailyActual>;
  scaler?: number;

  constructor(dailyActuals, scaler?) {
    this.dailyActuals = dailyActuals;
    this.scaler = scaler;
  }

  findBiggest = () => {
    const onlyValues = this.dailyActuals.map(item => item.actual_minutes);

    return Math.max(...onlyValues);
  };

  getScaler = () => {
    return this.scaler || this.findBiggest();
  }

  calculateBarHeight = (value, scaler) => {
    return value === 0 ? 5 : (value / scaler) * 100;
  }

  buildChartObjects = () => {
    const scaler = this.getScaler();

    return this.dailyActuals.map(dailyActual => (
      {
        item: dailyActual,
        height: this.calculateBarHeight(dailyActual.actual_minutes, scaler)
      }
    ));
  }
}