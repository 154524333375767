import { ActuallyRootState } from "../../../index";
import { tenantAdapter } from "./tenant-reducers";

const {
  selectIds,
  selectEntities,
  selectById,
  selectAll
} = tenantAdapter.getSelectors((state: ActuallyRootState) => state.admin.tenants);

export const selectTenantById = (id) => (state: ActuallyRootState) => selectById(state, id);

export const selectTenantStatusFilter = (state: ActuallyRootState) => state.admin.tenants.statusFilterType;

export {
  selectIds,
  selectEntities,
  selectById,
  selectAll as selectTenants
}
