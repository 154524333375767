import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

export const authGroupAdapter = createEntityAdapter();

const initialState = authGroupAdapter.getInitialState();

const authGroupSlice = createSlice({
  name: 'authGroups',
  initialState,
  reducers: {
    receiveManyAuthGroupsAction: authGroupAdapter.upsertMany,
    receiveOneAuthGroupAction: authGroupAdapter.upsertOne,
    removeAuthGroupAction: authGroupAdapter.removeOne,
    updateAuthGroupAction: authGroupAdapter.upsertOne
  }
});

const {actions, reducer} = authGroupSlice;

export const {
  receiveManyAuthGroupsAction,
  receiveOneAuthGroupAction,
  removeAuthGroupAction,
} = actions;

export default reducer;
