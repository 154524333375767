import React from 'react';
import classNames from "classnames";


function DisabledOverlay({children, disabled}) {
  const classes = classNames({"disabled-overlay": disabled});

  return (
      <div className={classes}>
        {children}
      </div>
  )
}

export default DisabledOverlay;
