import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {RouteComponentProps} from "@reach/router";

import {
  CardElement,
  Elements, useStripe,
} from '@stripe/react-stripe-js';

import {Alert, Row, Col} from 'react-bootstrap';
import {CenterAligned} from "../../components/table/ActualDataTable";
import {ActuallyButton} from "../../components/buttons";

import {DefaultBlock} from "../../components/layout/LayoutHelpers";
import {If} from "../../components/ui-utility/Conditional";
import {useCheckoutFlow, stripeLoader, useCheckoutDetailsFetching} from "./checkout-hooks";
import {CardCouldNotBeCharged, PlanDetails} from "./components/PlanDetails";
import {PaymentSolutionProvider} from "./components/PaymentSolutionProvider";
import {TaxRateResolver} from "./components/TaxRateResolver";

import Error from "../../components/notication/Error";
import CountrySelect from "../../components/select/CountrySelect";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";


export const Upgrade: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <BaseCheckout/>
  )
}

const CheckoutContainer = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
`

const CardElementWrapper = styled(CardElement)`
    display: block;
    width: 100%;
    padding: .6rem 1rem;
    border-radius: 10px;
    box-shadow: inset 0 1px 1px #d0e2e2;
    background-color: #f9fafa;
`

const CheckoutForm: React.FunctionComponent<any> = ({onCheckoutDone, stripeCustomer = {}, planDetails}) => {
  const [state, setState] = useState<any>({errors: null, loading: false});
  const [custInfo, setCustInfo] = useState<any>({});

  useEffect(() => {
    setCustInfo(stripeCustomer);
  }, [stripeCustomer]);

  const stripe: any = useStripe();

  const {processCheckoutCall} = useCheckoutFlow(stripe);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setState({errors: null, loading: true});

    processCheckoutCall(custInfo).then(onCheckoutDone).catch(displayErrors)
  };

  const displayErrors = error => setState({errors: error?.reason, loading: false});

  const isCheckoutButtonDisabled =
    !stripe ||
    !custInfo.business_vat_id ||
    !custInfo.country ||
    !custInfo.address ||
    state.loading ||
    !custInfo.name;

  return (
    <form onSubmit={handleSubmit}>
      <Error error={state.errors}/>

      <PlanDetails planDetails={planDetails} country={custInfo.country}/>

      <Row className="mb-4">
        <Col>
          <label className="label-default" htmlFor="creditcard">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.creditCardLabel}} />
          </label>
          <CardElementWrapper/>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <label className="label-default" htmlFor="name">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.businessNameLabel}} />
          </label>
          <input className="form-control" name="first_name" type="text" defaultValue={custInfo.name}
                 onChange={event => setCustInfo({...custInfo, name: event.target.value})}/>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <label className="label-default" htmlFor="country">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonProperties.country}} />
          </label>
          <CountrySelect value={custInfo.country} onChange={country => setCustInfo({...custInfo, country})}/>
          <small>
            <TaxRateResolver country={custInfo.country}/>
          </small>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <label className="label-default" htmlFor="address">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.addressLabel}} />
          </label>
          <input className="form-control" name="address" type="text" defaultValue={custInfo.address}
                 onChange={event => setCustInfo({...custInfo, address: event.target.value})}/>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <label className="label-default" htmlFor="vat">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.vatOrTaxNumberLabel}} />
          </label>
          <input className="form-control" name="business_vat_id" type="text" defaultValue={custInfo.business_vat_id}
                 onChange={event => setCustInfo({...custInfo, business_vat_id: event.target.value})}/>
        </Col>
      </Row>

      <ActuallyButton className="btn-block" variant="primary" type="submit"
                      disabled={isCheckoutButtonDisabled}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.checkoutButton}} />
      </ActuallyButton>
    </form>
  )
}

export const BaseCheckout: React.FunctionComponent<RouteComponentProps | any> = () => {
  const [redirectAboutToHappen, setRedirectAboutToHappen] = useState(false);

  const stripePromise = stripeLoader();

  const checkoutDetails: any = useCheckoutDetailsFetching();

  const onCheckoutDone = () => {
    setRedirectAboutToHappen(true);
  }

  useEffect(() => {
    let intervalId: any = null;

    if (redirectAboutToHappen) {
      intervalId = setTimeout(() => {
        window.location.replace('/');
      }, 3000);
    }

    return () => {
      clearTimeout(intervalId);
    }

  }, [redirectAboutToHappen]);

  return (
    <CheckoutContainer className="mt-4">
      <CenterAligned className="mb-4">
        <h2>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.pageHeader}} />
        </h2>
      </CenterAligned>
      <DefaultBlock>
        <CardCouldNotBeCharged show={checkoutDetails.has_unpaid_invoices}/>

        <LocalizedMessage text={{
          id: TRANSLATION_KEYS.trial.checkout.form.descriptionText,
          values: {
            p: chunk => <p>{chunk}</p>,
            strong: chunk => <strong>{chunk}</strong>,
          }
        }} />

        <label className="bold mb-2">
          <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.startingWithLabel}} />
        </label>

        <div className="mb-4">
          <If condition={redirectAboutToHappen}>
            <Alert variant="success">
              <LocalizedMessage text={{id: TRANSLATION_KEYS.trial.checkout.form.paymentInfoUpdatedSuccessfullyMessage}} />
            </Alert>
          </If>

          <If condition={!redirectAboutToHappen}>
            <Elements stripe={stripePromise}>
              <CheckoutForm onCheckoutDone={onCheckoutDone} planDetails={checkoutDetails}
                            stripeCustomer={checkoutDetails.stripe_customer}/>
            </Elements>
          </If>
        </div>

        <CenterAligned>
          <PaymentSolutionProvider/>
        </CenterAligned>
      </DefaultBlock>
    </CheckoutContainer>
  )
}
