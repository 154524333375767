import React from 'react';
import TimeValue from "../../../components/TimeValue";

import styled from 'styled-components';
import {TooltipOverlay} from "../../../components/ui-utility/Tooltip";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {useSimpleLocalizedMessage} from "../../../intl/use-localized-message";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";

const BalanceValue = styled(TimeValue)`
  font-weight: 700;
  // color: ${props => props.minutes < 0 ? 'red' : 'green'};
`;

export const UserBalance = ({minutes}) => {
  const tooltip = useSimpleLocalizedMessage(TRANSLATION_KEYS.people.balanceValueTooltip);

  return (
    <TooltipOverlay tooltipContent={tooltip}>
      <>
        {minutes !== null && <BalanceValue minutes={minutes} showZero/>}
        {minutes === null && <span>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.people.balanceNotAvailable}} />
        </span>}
      </>
    </TooltipOverlay>
  );
};
