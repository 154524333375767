import * as React from "react";
import {BaseIcon} from "./index";
import styled from "styled-components";

interface StyledBaseIconProps {
  pinned: number;
}

const StyledBaseIcon = styled(BaseIcon)<StyledBaseIconProps>`
  ${props => props.pinned ? `
    color: #1C69A9;
  ` : `
    transform: rotate(-35deg);
    color: #BCCCDC;
  `}
`

interface OwnProps {
  onClick: (event: React.MouseEvent) => void;
  isPinned: boolean;
  className?: string;
}

type ThumbTackButtonProps = OwnProps;

export const ThumbTackButton: React.FunctionComponent<ThumbTackButtonProps> = ({onClick, isPinned, className}) => {
  const iconPrefix = isPinned ? "fas" : "fal";

  return (
    <button onClick={onClick} className={`${className} btn btn-slick`} type="button">
      <StyledBaseIcon iconName={[iconPrefix, "thumbtack"]} pinned={isPinned ? 1 : 0}/>
    </button>
  )
};
