import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  selectActiveCyclesForApprover,
  selectHistoryCyclesForApprover
} from "../../../../store/ducks/cycle/cycle-selectors";

import PlainTable from "../../../../components/ui-utility/table/PlainTable";
import CycleRow from "./components/CycleRow";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";
import {If} from "../../../../components/ui-utility/Conditional";
import {updateNotificationCount} from "../../../../store/ducks/auth/actions";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

export function ActiveCycleTable({approverId}) {
  const dispatch = useDispatch();
  const activeCycleIds = useSelector(selectActiveCyclesForApprover(approverId));

  useEffect(() => {
    //fixme: temporarily, change when notifications
    dispatch(updateNotificationCount(activeCycleIds.length));
  }, [activeCycleIds.length, dispatch]);

  return (
    <>
      <If condition={activeCycleIds.length}>
        <ApproverCycleTable cycleIds={activeCycleIds} approverId={approverId}/>
      </If>
      <If condition={activeCycleIds.length === 0}>
        <EmptyStatePlaceholder iconName={'check'}>
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.emptyPendingListMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
    </>
  )
}

export function HistoryCycleTable({approverId}) {
  const historyCycleIds = useSelector(selectHistoryCyclesForApprover(approverId));

  return (
    <>
      <If condition={historyCycleIds.length}>
        <ApproverCycleTable cycleIds={historyCycleIds} approverId={approverId}/>
      </If>
      <If condition={historyCycleIds.length === 0}>
        <EmptyStatePlaceholder iconName={'table'}>
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.table.emptyApprovedListMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>
    </>
  )
}

function ApproverCycleTable({cycleIds, approverId}) {
  return (
    <PlainTable showLines={true} hoverable={true} className="approver-table" alignToSides={true}>
      <thead>
      <tr>
        <th>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.table.periodLabel}}/>
        </th>
        <th>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.approvalList.table.statusLabel}}/>
        </th>
      </tr>
      </thead>
      <tbody>
      {cycleIds.map(cycleId => (
        <CycleRow cycleId={cycleId} key={cycleId} approverId={approverId}/>
      ))}
      </tbody>
    </PlainTable>
  );
}

ApproverCycleTable.defaultProps = {
  cycles: []
};

export default ApproverCycleTable;
