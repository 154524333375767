import { createGetRequest, createPostRequest } from "../../api";

const url = 'user-cycle';

const getUserCycles = params => createGetRequest({url, params});

const postUserCycle = (user, cycle) => {
  return createPostRequest({url, data: {user, cycle}});
};

export {
  getUserCycles,
  postUserCycle
};