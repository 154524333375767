import * as React from 'react'
import { PlainSectionBlock } from '../layout/LayoutHelpers';
import { ExpandableLeftArea } from '../ui-utility/ExpandableLeftArea';
import { ControlButton } from './ControlButton';
import styled from 'styled-components';
import { LocalizedText } from '../../intl/components/LocalizedMessage';

interface DisabledProps {
    disabled: number;
}

const Disabled = styled.div<DisabledProps>`
    ${props => props.disabled ? `
        pointer-events: none;
        position: relative;
        transform: translateX(0);
        opacity: 0.5;
    ` : ''
    }
`

const ControlAndActionsWrapper = styled(Disabled)`
    display: flex;
    padding-bottom: 4px;
    justify-content: space-between;
`

const Controls = styled.div``

const Actions = styled.div`
    display: flex;
    align-items: center;
`

const StyledTableControlButton = styled(ControlButton)`
    display: inline-block;
`

export interface ControlWrapperControl {
    name: string;
    label: LocalizedText;
    component: React.ReactElement;
    isActive?: boolean;
}

interface OwnPropTypes {
    className?: string;
    controls: ControlWrapperControl[];
    actions?: Array<React.ReactElement>;
    isLoading?: boolean;
}

interface OwnStateTypes {
    activeControl?: ControlWrapperControl;
}

export type ControlWrapperProps = OwnPropTypes;

export const ControlWrapper: React.FunctionComponent<ControlWrapperProps> = ({
    children,
    className,
    controls,
    actions,
    isLoading
}) => {
    const [state, setState] = React.useState<OwnStateTypes>({ activeControl: undefined });

    const handleLeftSideCollapse = (activeControl: ControlWrapperControl) => setState({ activeControl: activeControl.name === state.activeControl?.name ? undefined : activeControl })

    return (
        <PlainSectionBlock className={className}>
            <ExpandableLeftArea
                isCollapsed={!!state.activeControl}
                left={null}
                right={
                    <ControlAndActionsWrapper disabled={isLoading ? 1 : 0}>
                        <Controls>
                            {controls.map((control, index) =>
                                <StyledTableControlButton key={index} onClick={() => handleLeftSideCollapse(control)} label={control.label} isControlOpen={control.name === state.activeControl?.name} isControlActive={control.isActive} />
                            )}
                        </Controls>
                        <Actions>
                            {actions && actions.map((action, index) => <React.Fragment key={index}>{action}</React.Fragment>)}
                        </Actions>
                    </ControlAndActionsWrapper>
                }
            />
            <ExpandableLeftArea
                isCollapsed={!!state.activeControl}
                left={<Disabled disabled={isLoading ? 1 : 0}>
                    {state.activeControl?.component}
                </Disabled>}
                right={
                    children
                } />
        </PlainSectionBlock>
    )
}