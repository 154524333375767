import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';

export const DuplicateTaskIcon = props => {
  return <FontAwesomeIcon icon="copy" style={{ color: '#D9E2EC'}} {...props} />
};

export const FavouriteIcon = props => {
  return <FontAwesomeIcon icon="heart" style={{ color: '#D9E2EC'}} {...props} />
};

export const RightAngleIcon = props => {
  return <FontAwesomeIcon icon="chevron-right" {...props} />
};

export const RightAngleLightIcon = props => {
  return <FontAwesomeIcon icon={['fal', 'angle-right']} style={{ color: '#829AB1' }} {...props} />
};

export const RightArrowIcon = props => {
  return <FontAwesomeIcon icon="arrow-right" {...props} />
};

export const HomeIcon = props => {
  return <FontAwesomeIcon icon="home" {...props} />
};

export const WorkdayLengthIcon = props => {
  return <FontAwesomeIcon icon='history' {...props} />
};

export const ExclamationCircleIcon = props => {
  return <FontAwesomeIcon icon='exclamation-circle' {...props} />
};

export const ActiveIndicatorIcon = props => {
  const classes = classNames(
    props.className,
    'fa-xs',
  );

  const styles = {
    ...props.style,
    color: "#3EBD93",
    position: 'relative',
    top: '-2px',
  };

  return <FontAwesomeIcon className={classes} {...props} icon="circle" style={styles} />
};