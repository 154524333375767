import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import paginationSelectors from "../../store/ducks/pagination/pagination-selectors";
import { changePageAction } from "../../store/ducks/pagination/pagination-actions";

const usePagination = pageKey => {
  const dispatch = useDispatch();

  const page = useSelector(paginationSelectors.selectCurrentPageForPage(pageKey));

  useEffect(() => {
    return () => {
      dispatch(changePageAction(pageKey, 1));
    }
  }, [dispatch, pageKey]);

  return page;
};


export {
  usePagination
}
