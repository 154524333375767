import React, { useState } from "react";

import MinutesInput from "../../components/MinutesInput";
import ProjectAndTaskName from "../../../../components/project/ProjectAndTaskName";
import WithErrorInput from "../../components/WithErrorInput";
import Error from "../../../../components/notication/Error";

import Conditional, { If } from "../../../../components/ui-utility/Conditional";
import { ActuallyButton, DeleteLinkToButton } from "../../../../components/buttons";
import { TextArea } from "../../../../components/forms/TextArea";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

export function DailyTimesheetActionPanel({record, handleRecordDeleting, handleRecordCanceling}) {
  return (
    <>
      <If condition={!record.local}>
        <DeleteLinkToButton label={'Delete'}
                            confirmLabel={'Confirm deletion'}
                            tabIndex={5}
                            onConfirm={() => handleRecordDeleting(record)}/>
      </If>
      <ActuallyButton variant="link"
                      tabIndex={4}
                      onClick={() => handleRecordCanceling(record)}>
        <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
      </ActuallyButton>
      <ActuallyButton variant="primary" type="submit" tabIndex={3}>
        <Conditional condition={record.local} onTrue={
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.addButton}}/>
        } onFalse={
          <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.updateButton}}/>
        }/>
      </ActuallyButton>
    </>
  )
}

export function DailyTimesheetDescriptionInput({record, errors, onChange}) {
  return (
    <div className="form-group">
      <label>
        <small>
          <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.recordDescriptionLabel}}/>
        </small>
      </label>
      <WithErrorInput errors={errors.description}>
              <TextArea className="form-control"
                        value={record.description || ''}
                        tabIndex={2}
                        minRows={3}
                        maxRows={50}
                        onChange={(e) => onChange(e.target.value, 'description')}/>
      </WithErrorInput>
    </div>
  )
}

const DailyTimesheetEditRow = ({task, project, record, errors, handleRecordCanceling, handleRecordSaving, handleRecordDeleting}) => {
  const [localRecord, setLocalRecord] = useState(record);

  const setAttribute = (attrValue, attrKey) => {
    setLocalRecord({...localRecord, [attrKey]: attrValue});
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleRecordSaving(localRecord);
  };

  return (
    <div className="dailytimesheet-edit-record-row">
      <div className="row">
        <div className="col-lg-12">
          <Error error={errors.general}/>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="row">
          <div className="col-lg-10">
            <ProjectAndTaskName project={project} task={task}/>
          </div>

          <div className="col-lg-2">
            <WithErrorInput errors={errors.minutes}>
              <MinutesInput minutesOfDay={localRecord.minutes}
                            onSave={mins => setAttribute(mins, 'minutes')}
                            tabIndex={1}
                            autoFocus={true}/>
            </WithErrorInput>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <DailyTimesheetDescriptionInput record={localRecord} onChange={setAttribute} errors={errors}/>
          </div>
          <div className="col-lg-12 text-right">
            <DailyTimesheetActionPanel record={localRecord} handleRecordCanceling={handleRecordCanceling}
                                       handleRecordDeleting={handleRecordDeleting}/>
          </div>
        </div>
      </form>
    </div>
  )
};

DailyTimesheetEditRow.defaultProps = {
  errors: {}
};

export default DailyTimesheetEditRow;