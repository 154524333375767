import React from "react";
import { addDays } from "date-fns";

import PropTypes from 'prop-types';
import {
  DailyTimesheetButton,
  WeeklyTimesheetCurrentWeekButton, WeeklyTimesheetNextWeekButton,
  WeeklyTimesheetPreviousWeekButton
} from "../../weekly/WeeklyNavigatorButtons";
import { today } from "../../../../util/dates";

const DailyTimesheetNavigator = ({startDate, onChangeStartDate}) => {
  return (
    <div className="btn-group" role="group">
      <WeeklyTimesheetPreviousWeekButton onClick={e => onChangeStartDate(addDays(startDate, -1))}/>

      <WeeklyTimesheetCurrentWeekButton onClick={e => onChangeStartDate(today())}/>

      <DailyTimesheetButton startDate={startDate}/>

      <WeeklyTimesheetNextWeekButton onClick={e => onChangeStartDate(addDays(startDate, 1))}/>
    </div>
  )
};

DailyTimesheetNavigator.propTypes = {
  // FIXME: TB-160 -- Date
  // startDate: PropTypes.objectOf(Date),
  onChangeStartDate: PropTypes.func.isRequired,
};

export default DailyTimesheetNavigator;