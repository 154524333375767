import {normalize} from "normalizr";
import {createCustomerSchema} from "./customers-schemas";

import CustomerEntity, {Customer} from "./CustomerEntity";

export class CustomersAPIResponseParser {
  static normalizeListing = (response) => {
    const schema = createCustomerSchema();

    return normalize(response, schema);
  };

  static extractCustomersFromProjectResponse = (response) => {
    const {customers = []} = response.entities;

    return customers;
  };

  static convertRecordsIntoSelectCompatible = (response) => {
    return response.map(record =>
      ({...record, label: CustomerEntity.parseHRN(record.code, record.name), value: record.id}));
  };

  static mergeExtraColumnsIntoCustomers = (customers: Array<Customer>, extraColumns) => {
    return customers.map(customer => {
      const projectCountMapping = extraColumns.project_count || {};
      const latestRecordMapping = extraColumns.latest_records || {};

      const projectCount = projectCountMapping[customer.id]?.count || 0
      const latestRecord = latestRecordMapping[customer.id]?.latest_record_date;

      return {
        projectCount,
        latestRecord,
        ...customer
      }
    });
  }

}
