import React from 'react';
import {MenuCollapsedIcon, MenuExpandedIcon} from "../buttons";

const ToggleIcon = ({isCollapsed}) => {
  if (isCollapsed) {
    return <MenuCollapsedIcon />;
  } else {
    return <MenuExpandedIcon />
  }
};

const SidebarToggle = ({isCollapsed, onToggle}) => {
  return (
    <div>
      <button type="button"
              className="btn btn-link22 btn-plain"
              onClick={onToggle}>
        <ToggleIcon isCollapsed={isCollapsed} />
      </button>
    </div>
  )
};

export default SidebarToggle;
