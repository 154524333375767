import React, { useMemo } from "react";
import PropTypes from 'prop-types';

import { useDispatch } from "react-redux";
import { selectRecords } from "../../../store/ducks/timesheet/selectors";
import { saveNewTotalForDay } from "../../../store/ducks/mobile/timesheet/actions";
import { formatISO } from "../../../util/dates";
import { useSelectorWithDefault } from "../../../util/redux-utils";
import { TimesheetRowManager } from "../../../store/ducks/mobile/timesheet/entities";

import TimeInput from "../../../components/TimeInput";
import { useCycleDateCollisionChecker } from "../record-hooks";


const WeeklyDayInput = ({date, taskId, isTimetrackingAllowed}) => {
  const dispatch = useDispatch();
  const rawRecords = useSelectorWithDefault(selectRecords, []);

  const minutesOfDay = useMemo(() =>
      TimesheetRowManager.sumTaskRecordsForGivenDay(rawRecords, taskId, date),
    [taskId, date, rawRecords]);

  const onSave = minutes => {
    dispatch(saveNewTotalForDay({date: formatISO(date), task: taskId, minutes}));
  };

  const isDateLocked = useCycleDateCollisionChecker(date);

  const timetrackingDisabled = !isTimetrackingAllowed || isDateLocked;

  return (
    <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
      <TimeInput minutes={minutesOfDay} onSave={onSave} style={{minWidth: '60px'}}
                 disabled={timetrackingDisabled}/>
    </td>
  )
};

WeeklyDayInput.propTypes = {
  // FIXME: TB-160 -- Date
  // date: PropTypes.objectOf(Date),
  taskId: PropTypes.number.isRequired,
};

export default WeeklyDayInput;