import { useDispatch, useSelector } from "react-redux";

import orderingSelectors from "../../store/ducks/ordering/ordering-selectors";
import { changeOrderingAction } from "../../store/ducks/ordering/ordering-reducers";

const useColumnOrdering = (pageKey) => {
  const dispatch = useDispatch();

  const ordering = useSelector(
    orderingSelectors.selectOrderingForPage(pageKey)
  );

  const changeOrdering = (newOrdering) => {
    dispatch(changeOrderingAction({ pageKey, ordering: newOrdering }));
  };

  return [ordering, changeOrdering];
};

export { useColumnOrdering };
