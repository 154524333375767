import { schema } from 'normalizr';


const createUserSchema = (entityKey = 'users') => {
  const userSchema = new schema.Entity(entityKey);

  return [userSchema];
};

export default createUserSchema;
