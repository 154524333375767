import React from 'react';
import styled from 'styled-components';

const ExpandableLeftAreaWrapper = styled.div`
  position: relative;
`

interface LeftAndRightSideProps {
  collapsed: number;
}

const LeftSide = styled.div<LeftAndRightSideProps>`
    height: 100%;
    min-height: 600px;
    position: absolute;
    top: 0;
    transition: width 0.5s;
    overflow-y: ${props => props.collapsed ? 'visible' : 'hidden'};
    width: ${props => props.collapsed ? '300px' : '0px'};
    white-space: nowrap;
`

const RightSide = styled.div<LeftAndRightSideProps>`
  position: relative;
  transition: margin-left .5s;
  ${props => props.collapsed ? 'margin-left: 315px;' : ''}
`;

interface OwnPropTypes {
  isCollapsed?: boolean;
  left: React.ReactElement | null;
  right: React.ReactNode;
}

export type ExpandableLeftAreaProps = OwnPropTypes;

export const ExpandableLeftArea: React.FunctionComponent<ExpandableLeftAreaProps> = ({ isCollapsed, left, right }) => (
  <ExpandableLeftAreaWrapper>
    <LeftSide collapsed={isCollapsed ? 1 : 0}>{left}</LeftSide>
    <RightSide collapsed={isCollapsed ? 1 : 0}>{right}</RightSide>
  </ExpandableLeftAreaWrapper>
);
