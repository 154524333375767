import { isSameDay, parseISO } from "../../../../util/dates";
import { selectCurrentUser } from "../../auth/selectors";

const IMMUTABLE_LIST = [];

// TODO: change this when we want to support user selection on timesheet view(s)
export const selectTimesheetUser = state => selectCurrentUser(state);
export const selectDailyTimesheetState = state => state.dailyTimesheet;

export const selectTimesheetTotalMinutesForDate = date => state => {
  const data = selectDailyTimesheetState(state);
  // FIXME: TB-160
  const records = Object.values(data.records).filter((record: any) => isSameDay(parseISO(record.date), date));
  // FIXME: TB-160
  return records.reduce((sumOfMinutes, record: any) => sumOfMinutes + record.minutes, 0);
};

export const selectProjectsAsMember = state => selectDailyTimesheetState(state).projects_as_member || IMMUTABLE_LIST;

export const selectIsMemberOfProject = projectId => state => {
  return selectProjectsAsMember(state).includes(projectId);
}
