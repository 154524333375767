import projectSagaTypes from "./types";
import * as taskSagaTypes from "../project-tasks/types";
import { removeObjectFromResult, removeObjectFromMapOfObjects } from "../../helpers";
import {uniq} from "lodash";

// FIXME: TB-160
const initialState: any = {
  entities: {},
  result: [],
  details: {}
};

function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case projectSagaTypes.FETCH_PROJECT_SUCCESS:
      const project = action.payload;

      return {
        ...state,
        entities: {...state.entities, [project.id]: project},
        result: uniq([...state.result, project.id])
      };

    case taskSagaTypes.UPDATE_PROJECTS_LARGEST_TASK_MINUTES_SUCCESS:
      const {projectId, minutes} = action.payload;

      return {
        ...state,
        details: updateProjectDetailsWithLargestMinutes(state.details, projectId, minutes),
      };

    case projectSagaTypes.FETCH_PROJECTS_SUCCESS: {
      const {projects} = action.payload.entities;

      return {
        ...state,
        entities: {...state.entities, ...projects},
        result: action.payload.result,
      };
    }

    case projectSagaTypes.REMOVE_PROJECT: {
      const entities = removeObjectFromMapOfObjects(state.entities, action.projectId);
      const result = removeObjectFromResult(state.result, action.projectId);

      return {
        ...state,
        entities,
        result
      };
    }

    default:
      return state;
  }
}

function updateProjectDetailsWithLargestMinutes(detailsState, projectId, minutes) {
  return {
    ...detailsState,
    largestMinutes: {
      ...detailsState.largestMinutes,
      [projectId]: minutes,
    }
  };
}

export default projectsReducer;
