import ProjectLeadPhase from "./components/ProjectLeadPhase";
import ApproverPhase from "./components/ApproverPhase";
import SupervisorPhase from "./components/SupervisorPhase";
import {STEP_STATUSES} from "../approval-constants";
import {TRANSLATION_KEYS} from "../../../../intl/intl";

const stepsForGivenStatus = (steps, status) => steps.filter(step => step.status === status).map(step => step.id).length;


const PHASE_BLUEPRINT = {
  "PROJECTLEAD_PHASE": {
    "tabKey": "PROJECTLEAD_PHASE",
    "component": ProjectLeadPhase,
    "extractSteps": steps => steps.projectleadPhase,
    "getTitle": steps => {
      const extractedSteps = PHASE_BLUEPRINT['PROJECTLEAD_PHASE'].extractSteps(steps);
      const approvedCount = stepsForGivenStatus(extractedSteps, STEP_STATUSES.approved);
      const stepCount = extractedSteps.length - approvedCount;

      return {
        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.projectManagerTab,
        values: {count: stepCount},
      };
    }
  },
  "GLOBAL_APPROVER_PHASE": {
    "tabKey": "GLOBAL_APPROVER_PHASE",
    "title": "Approver",
    "component": ApproverPhase,
    "extractSteps": steps => steps.approverPhase,
    "getTitle": steps => {
      const extractedSteps = PHASE_BLUEPRINT['GLOBAL_APPROVER_PHASE'].extractSteps(steps);
      const approvedCount = stepsForGivenStatus(extractedSteps, STEP_STATUSES.approved);
      const stepCount = extractedSteps.length - approvedCount;

      return {
        id: TRANSLATION_KEYS.approvalList.approveRecordsModal.tabsAndOptions.approverTab,
        values: {count: stepCount},
      };
    }
  },
  "SUPERVISOR_PHASE": {
    "tabKey": "SUPERVISOR_PHASE",
    "title": "Supervisor",
    "component": SupervisorPhase
  }
};

export default PHASE_BLUEPRINT;