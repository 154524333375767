import React from "react";
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";

import ProjectEntity from './ProjectEntity';

import { LinkToProject } from "./ProjectLink";
import { selectProject } from "../../store/ducks/projects/selectors";

export function ProjectNameSelector({projectId, addLink = true, className=''}) {
  const project = useSelector(selectProject(projectId)) || {};

  return <ProjectName {...project} addLink={addLink} className={className}/>
}

function ProjectName({name, id, addLink = true, className=''}) {
  const project = new ProjectEntity({name, id});

  if (addLink)
    return (<LinkToProject projectId={project.getId()}>{project.getName()}</LinkToProject>);

  return <span className={className}>{project.getName()}</span>;
}

ProjectName.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  id: PropTypes.number
};

export default ProjectName;

