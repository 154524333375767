import * as React from 'react';
import { CaretUpIcon, CaretDownIcon } from '../buttons';
import styled from 'styled-components';

export enum ColumnSorterCaretDirection {
    Up = 'Up',
    Down = 'Down'
}

interface CaretWrapperProps {
    bold: number;
    direction: ColumnSorterCaretDirection;
}

const CaretWrapper = styled.div<CaretWrapperProps>`
    position: absolute;
    top: ${props => props.direction === ColumnSorterCaretDirection.Up ? '0px' : '6px'};
    opacity: ${props => props.bold ? '1' : '0.2'};
  `

interface CaretProps {
    bold: boolean;
    direction: ColumnSorterCaretDirection;
}

export const ColumnSorterCaret = ({ bold, direction }: CaretProps) => (
    <CaretWrapper bold={bold ? 1 : 0} direction={direction}>
        {direction === ColumnSorterCaretDirection.Up ? <CaretUpIcon /> : <CaretDownIcon />}
    </CaretWrapper>
)