import React, {useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {ActuallyButton} from "../../../../components/buttons";

import {If} from "../../../../components/ui-utility/Conditional";
import {ErrorElement} from "../../../../components/notication/Error";
import {isUserCyclePendingOrApproved} from "../approval-constants";

import ApprovalListing from "./ApprovalListing";
import EmptyStatePlaceholder from "../../../../components/ui-utility/EmptyStatePlaceholder";
import UnSubmittedUserCycleSelect from "../../../../components/select/UnSubmittedUserCycleSelect";
import useApprovalAPI from "../approval-hooks";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";
import {formatDateShort} from "../../../../components/ui-utility/dates/date-formatters";
import {tryParseISO} from "../../../../util/dates";


function SendApprovalForm({userId, onDone}) {
  // FIXME: TB-160
  const [selectedCycle, setSelectedCycle] = useState<any>({});
  const [userCycles, setUserCycles] = useState({});

  const {createUserCycle, fetchUserCyclesForUser} = useCallback(useApprovalAPI(), [userId]);

  const onSubmitRecords = async event => {
    event.preventDefault();

    await createUserCycle(userId, selectedCycle.id);

    onDone && onDone();
  };

  useEffect(() => {
    const fetchEssentials = async () => {
      const response = await fetchUserCyclesForUser(userId);

      response && setUserCycles(response);
    };

    fetchEssentials();
  }, [userId, fetchUserCyclesForUser]);

  const targetCycle = userCycles[selectedCycle.id];
  const userCyclePending = isUserCyclePendingOrApproved(targetCycle)

  const canSubmit = !userCyclePending && selectedCycle.id;

  const startDate = canSubmit ? formatDateShort(tryParseISO(selectedCycle.start_date)) : null;
  const endDate = canSubmit ? formatDateShort(tryParseISO(selectedCycle.end_date)) : null;

  return (
    <form onSubmit={onSubmitRecords}>
      <Row>
        <Col>
          <label className="label-default" htmlFor="cycle-select">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.selectCycleLabel}}/>
          </label>
          <UnSubmittedUserCycleSelect onChange={cycle => setSelectedCycle(cycle || {})} userId={userId}/>
        </Col>
      </Row>

      <If condition={!selectedCycle.id}>
        <EmptyStatePlaceholder iconName="table">
          <p>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.selectCycleMessage}}/>
          </p>
        </EmptyStatePlaceholder>
      </If>

      <If condition={userCyclePending}>
        <Row className="mt-2 mb-2">
          <Col>
            <ErrorElement>
                <span>
                  <LocalizedMessage
                    text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.alreadySubmittedCycleMessage}}/>
                </span>
            </ErrorElement>
          </Col>
        </Row>
      </If>

      <If condition={canSubmit}>
        <Row className="mt-2">
          <Col>
            <ApprovalListing userId={userId} cycle={selectedCycle}/>
          </Col>
        </Row>
      </If>

      <Row className="mt-2">
        <Col className="text-right" lg={12}>
          <ActuallyButton variant="link" onClick={onDone}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.commonActions.cancelButton}}/>
          </ActuallyButton>
          <ActuallyButton variant="primary" type="submit" disabled={!canSubmit}>
            <LocalizedMessage text={{id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.submitRecordsButton}}/>
          </ActuallyButton>
        </Col>
        <Col className="text-right" lg={12}>
          <If condition={selectedCycle.id}>
            <small>
              <LocalizedMessage text={{
                id: TRANSLATION_KEYS.timesheet.submitForApprovalModal.submitRecordsButtonHelp,
                values: {startDate, endDate}
              }}/>
            </small>
          </If>
        </Col>
      </Row>

    </form>
  )

}

SendApprovalForm.propTypes = {
  userId: PropTypes.number,
  onDone: PropTypes.func
};

export default SendApprovalForm;