import { useMemo } from "react";
import { executeCancelleableRequest } from "./api";

const useCancellableFetch = instructionFn => {
  return useMemo(() => executeCancelleableRequest(instructionFn),
    [instructionFn]);
};

export {
  useCancellableFetch,
}
