import React from "react";

import styled from 'styled-components';
import {ExclamationCircleIcon, RightArrowIcon} from "../../../components/icons";
import TimeValue from "../../../components/TimeValue";
import DateShort from "../../../components/ui-utility/dates/DateShort";
import {TooltipOverlay} from "../../../components/ui-utility/Tooltip";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {formatWithCurrentLocale} from "../../../components/ui-utility/dates/format-with-locale";

function getWorkdayLengthMissingTooltip(firstName) {
  return <LocalizedMessage text={{
    id: TRANSLATION_KEYS.people.personDashboard.workdayMissingTooltip,
    values: {
      personName: firstName,
    }
  }}/>;
}

const getWorkdayLengthTooltip = (personName: string, startDate: string) => {
  if (!startDate) {
    return "-";
  }

  const formattedStartDate = formatWithCurrentLocale(startDate, 'P');

  return <LocalizedMessage text={{
    id: TRANSLATION_KEYS.people.personDashboard.workdayStartTooltip,
    values: {
      personName,
      startDate: formattedStartDate,
    }
  }} />
}

export const CurrentWorkdayLength = ({minutes, startDate, firstName}) => {
  const missingTooltipMessage = getWorkdayLengthMissingTooltip(firstName);

  if (minutes === undefined || minutes === null) {
    return (
      <>
        <TooltipOverlay tooltipContent={missingTooltipMessage}>
          <ExclamationCircleIcon style={{color: '#DE911D'}}/>
        </TooltipOverlay>
      </>
    );
  }

  const existingWorkdayLengthTooltip = getWorkdayLengthTooltip(firstName, startDate);

  return (
    <TooltipOverlay
      tooltipContent={existingWorkdayLengthTooltip}>
                  <span className="text-nowrap">
        <strong><TimeValue minutes={minutes} showZero/></strong>
      </span>
    </TooltipOverlay>
  );
};

const NextWorkdayLength = ({minutes, date, ...props}) => {
  return (
    <span {...props}>
      <span className="pl-1 pr-1">
        <RightArrowIcon style={{fontSize: '0.7rem'}}/>
      </span>

      <strong>
        <TimeValue minutes={minutes} showZero/>
      </strong>

      &nbsp;

      <span>from <DateShort date={date}/></span>
    </span>
  );
};

export const NextWorkdayLengthWrapper = styled(NextWorkdayLength)`
  color: #9FB3C8;
`;
