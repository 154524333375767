import { GroupableTableColumnConfiguration } from "../../../components/table/types/TableColumnConfiguration";
import { DateGroupingSelection } from "../../../components/column/components/DateGroupingSelection";
import { TableColumnContentAlignment } from "../../../components/table/types/TableColumnContentAlignment";
import {TRANSLATION_KEYS} from "../../../intl/intl";

const getOverallReportColumnConfiguration = (isGrouped: boolean): GroupableTableColumnConfiguration[] => [{
    key: 'person',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.person},
    isGroupable: true,
    groupOptions: {
        value: 'user'
    },
    isSortable: true,
    sortBy: 'user__user__last_name'
},
{
    key: 'customer',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.customer},
    isGroupable: true,
    isSortable: true,
    sortBy: 'task__project__customer__name'
}, {
    key: 'task',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.task},
    isGroupable: true,
    isSortable: true,
    sortBy: 'task__name'
}, {
    key: 'project',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.project},
    isGroupable: true,
    isSortable: true,
    sortBy: 'task__project__name'
}, {
    key: 'date',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.date},
    isGroupable: true,
    groupOptions: {
        asQueryParam: 'timeslot',
        value: 'day',
        component: DateGroupingSelection
    },
    isSortable: true,
    sortBy: 'date'
}, {
    key: 'is_billable',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.isBillable},
    isGroupable: true,
    isSortable: true,
}, {
    key: 'description',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.description},
}, {
    key: 'time',
    label: {id: TRANSLATION_KEYS.actualsReport.resultTableHeaders.time},
    isSortable: true,
    isAlwaysVisible: true,
    columnContentAlignment: TableColumnContentAlignment.Right,
    sortBy: isGrouped ? 'minutes_sum' : 'minutes',
}]

export const getGroupedOverallReportColumnConfiguration = getOverallReportColumnConfiguration(true);
export const getEditableOverallReportColumnConfiguration = getOverallReportColumnConfiguration(false); 


export const overallReportEmptyResultParagraphs = [
  {id: TRANSLATION_KEYS.actualsReport.noMatchingRecordsFoundTitle},
  {id: TRANSLATION_KEYS.actualsReport.noMatchingRecordsFoundReason},
]