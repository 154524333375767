import React from "react";

import {Link} from "@reach/router";
import {RightAngleIcon} from "../icons";
import styled from "styled-components";


interface ActuallyLinkProps {
  to: string,
}

const LinkIcon = styled(RightAngleIcon)`
  font-size: 11px;
`

export const ActuallyLink: React.FunctionComponent<ActuallyLinkProps> = ({to, children}) => {
  return (
    <Link to={to}>
      <span className="pr-1">{children}</span>
      <LinkIcon/>
    </Link>
  );
}
