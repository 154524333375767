import { addDays, isWithinInterval, parseISO } from "../../../util/dates";
import { selectTimesheetUser } from "../mobile/timesheet/selectors";
import { createSelector } from "reselect";

const selectWeeklyTimesheetState = state => state.dailyTimesheet;

const selectDate = state => selectWeeklyTimesheetState(state).date;
const selectRecords = state => selectWeeklyTimesheetState(state).records;
const selectRecordsBeingEdited = state => selectWeeklyTimesheetState(state).recordIdsBeingEdited;

const selectRecordById = recordId => state => {
  const records = selectRecords(state);

  return records[recordId];
};

const selectRecordsForTask = taskId => records => {
  // FIXME: TB-160
  return records && Object.values(records).filter((record: any) => Number(record.task) === Number(taskId));
};

const selectWeeklyTimesheetRows = state => {
  const fullState = selectWeeklyTimesheetState(state);

  return fullState.visibleTasksOnTimesheet;
};

const selectUserDayStatus = userId => state => {
  const fullState = selectWeeklyTimesheetState(state);
  return fullState.userDailyStatus[userId];
};

const selectProjectIdsForTaskSelection = state => {
  const {project_ids} = selectWeeklyTimesheetState(state);

  return project_ids;
};

const selectProjects = state => {
  const fullState = selectWeeklyTimesheetState(state);

  return fullState.projects;
};

const selectPinnedTasks = state => {
  const fullState = selectWeeklyTimesheetState(state);

  return fullState.pinned_tasks;
};

const selectTasks = state => {
  const fullState = selectWeeklyTimesheetState(state);

  return fullState.tasks;
};

const selectProjectTasks = state => {
  const fullState = selectWeeklyTimesheetState(state);

  return fullState.project_tasks;
};

const selectProjectForId = projectId => state => {
  const projects = selectProjects(state);

  return projects[projectId];
};

const selectTasksForProjectId = projectId => state => {
  const projectTasks = selectProjectTasks(state);

  return projectTasks[projectId];
};

const selectActiveTasksForProjectId = projectId => state => {
  const tasks = selectTasks(state);
  const projectTasks = selectProjectTasks(state);

  if(!projectTasks[projectId])
    return undefined;

  return projectTasks[projectId].filter(taskId => tasks[taskId].is_active);
};

const selectTaskForId = taskId => state => {
  const tasks = selectTasks(state);

  return tasks[taskId];
};

const selectPinnedTaskByTaskId = taskId => state => {
  const pinnedTasks = selectPinnedTasks(state);

  return pinnedTasks[taskId];
};

const deriveWeeklyTotalForUser = (startDate, records, user) => {
  const endDate = addDays(startDate, 6);

  const isInRange = dateStr => {
    const date = parseISO(dateStr);
    return isWithinInterval(date, {
      start: startDate,
      end: endDate,
    });
  };

  // FIXME: TB-160
  const myRecords = Object.values(records).filter((record: any) => record.user === user.id && isInRange(record.date));

  return myRecords.reduce((acc, record: any) => acc + record.minutes, 0);
};

const selectWeeklyTotal = createSelector(
  selectDate,
  selectRecords,
  selectTimesheetUser,
  deriveWeeklyTotalForUser,
);

const selectRecordsByTaskId = taskId => createSelector([selectRecords], selectRecordsForTask(taskId));

export {
  selectWeeklyTimesheetRows,
  selectUserDayStatus,
  selectRecords,
  selectRecordById,
  selectRecordsByTaskId,
  selectRecordsBeingEdited,

  //Task-selection
  selectProjectIdsForTaskSelection,
  selectProjectForId,
  selectTasksForProjectId,
  selectActiveTasksForProjectId,
  selectTaskForId,
  selectPinnedTaskByTaskId,

  selectWeeklyTotal,
};
