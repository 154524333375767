import React from "react";
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from "react-redux";
import { selectPinnedTaskByTaskId } from "../../../store/ducks/timesheet/selectors";

import { changeTaskVisibilityStatusAction } from "../../../store/ducks/mobile/timesheet/actions";
import { ThumbTackButton } from "../../../components/buttons/ThumbTackButton";
import TaskEntity from "../../../components/task/TaskEntity";
import {selectIsMemberOfProject} from "../../../store/ducks/mobile/timesheet/selectors";


function TimesheetPinColumn({task}) {
  const dispatch = useDispatch();

  const isPinned = useSelector(selectPinnedTaskByTaskId(task.id));
  const isMemberOf = useSelector(selectIsMemberOfProject(task.project));

  const shouldShownPin = isPinned || isMemberOf;

  const handleTaskPinning = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(changeTaskVisibilityStatusAction(task.id));
  };

  return (
    <span>
      {
        shouldShownPin && (
            <ThumbTackButton isPinned={isPinned} onClick={handleTaskPinning}/>
        )
      }
    </span>
  )
}

TimesheetPinColumn.propTypes = {
  taskId: PropTypes.instanceOf(TaskEntity),
};

export default TimesheetPinColumn;