import {useIntl} from "react-intl";
import {LocalizedText, localizeMessage} from "./components/LocalizedMessage";
import { useContext } from "react";
import { ActuallyIntlContext } from "./components/IntlProviderWrapper";

export const useLocalizedMessage = (message: LocalizedText) => {
  const intl = useIntl();
  const intlContext = useContext(ActuallyIntlContext);
  return localizeMessage(message, intl, {showKeys: intlContext.showKeys});
};

export const useSimpleLocalizedMessage = (messageKey: string) => {
  const intl = useIntl();
  const intlContext = useContext(ActuallyIntlContext);
  return localizeMessage({id: messageKey}, intl, {showKeys: intlContext.showKeys});
}

export const useLocalizer = () => {
  const intl = useIntl();
  const intlContext = useContext(ActuallyIntlContext);

  return (messageKey: string) => {
    return localizeMessage({id: messageKey}, intl, {showKeys: intlContext.showKeys});
  }
}
