import { GroupableTableColumnConfiguration } from "./types/TableColumnConfiguration";

const columnGroupOptionsValueDefault = 'group_by';

export const getGroupableTableColumns = (tableColumns: GroupableTableColumnConfiguration[]) => {
  const filteredColumns = tableColumns.filter(column => column.isGroupable && !column.isAlwaysVisible);

  return filteredColumns.map((groupableColumn, index) => ({
    name: groupableColumn.key,
    label: groupableColumn.label,
    asQueryParam: groupableColumn.groupOptions?.asQueryParam || columnGroupOptionsValueDefault,
    value: groupableColumn.groupOptions?.value || groupableColumn.key,
    component: groupableColumn.groupOptions?.component,
    identifier: index+1
  }))
}