import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import peopleSelectors from "../../../store/ducks/people/selectors";

import {Row} from "react-bootstrap";
import {fetchUserDetails} from "../../../store/ducks/people/actions";
import {CurrentWorkdayLength, NextWorkdayLengthWrapper} from "./WorkdayLengthStatus";
import {
  today,
  getFirstOfMonth,
  getFirstOfPreviousMonth,
  lastDayOfMonth,
  parseISO,
} from "../../../util/dates";
import {fetchTimesheetStatus} from "../../../store/ducks/mobile/timesheet/actions";
import {ResponsiveCol, SectionBlock} from '../../../components/layout/LayoutHelpers';
import {If} from "../../../components/ui-utility/Conditional";

import useShallowEqualSelector from "../../../store/helpers";
import PersonFullName from '../../../components/people/PersonFullName';
import UserWorkdayLengthModal from './workday-length/UserWorkdayLengthModal';
import RequireContextRight from "../../../components/rights/RequireContextRight";
import TimesheetBalanceToday from "../../timesheet/TimesheetBalanceToday";
import BalanceFixModal from "../../administration/balance-fix/BalanceFixModal";
import EditPersonButton from "../../../components/people/edit-person/EditPersonButton";
import {PersonRecordsByProjectsTable} from "../components/PersonRecordsByProjectsTable";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import UserDetailsWorkdayLengthEntity from "../../../entities/users/UserDetailsWorkdayLengthEntity";
import UserDetailsBalanceEntity from "../../../entities/users/UserDetailsBalanceEntity";

import RIGHTS from "../../../components/rights/right-definitions";

import styled from "styled-components";

import {DetailsPageHeader} from "../../../components/header/DetailsPageHeader";

import './UserDetails.css';
import PersonEmail from "../../../components/people/PersonEmail";

export const InformationDivider = styled.span`
  margin-left: 1rem;
  margin-right: 1rem;
  border-left: 1px solid #6c757d;
`

export const ButtonGutter = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const PersonEmailWrapper = styled(PersonEmail)`
  color: #6c757d;
  font-size: 14px;
`

const PersonNameEmailWrapper = styled.div`
  line-height: 1em;
`

const useTimesheetStatusFetching = (userId) => {
  const dispatch = useDispatch();
  const todaysDate = useMemo(() => today(), []);
  const firstOfThisMonth = useMemo(() => getFirstOfMonth(todaysDate), [todaysDate]);
  const firstOfPreviousMonth = useMemo(() => getFirstOfPreviousMonth(todaysDate), [todaysDate]);
  const lastOfMonth = useMemo(() => lastDayOfMonth(todaysDate), [todaysDate]);

  useEffect(() => {
    dispatch(fetchTimesheetStatus(userId, firstOfPreviousMonth, lastOfMonth));
  }, [dispatch, userId, firstOfThisMonth, firstOfPreviousMonth, todaysDate, lastOfMonth]);
};

export const useEmployment = userId => {
  // FIXME: TB-160
  const employment: any = useShallowEqualSelector(peopleSelectors.selectUserEmployment(userId));

  return {
    workdayLength: new UserDetailsWorkdayLengthEntity(employment ? employment.workday_length : null),
    balance: new UserDetailsBalanceEntity(employment ? employment.balance : null),
  }
};


const UserDetails = ({userId}) => {
  const dispatch = useDispatch();
  // FIXME: TB-160
  const user: any = useShallowEqualSelector(peopleSelectors.selectUser(userId));

  const {workdayLength, balance} = useEmployment(userId);

  useEffect(() => {
    dispatch(fetchUserDetails(userId));
  }, [dispatch, userId]);

  useTimesheetStatusFetching(userId);

  if (!user) {
    return <></>
  }

  return (
    <Container fluid>
      <Row>
        <ResponsiveCol lg={12}>
          <DetailsPageHeader>
            <PersonNameEmailWrapper>
              <DetailsPageHeader.Heading>
                <PersonFullName first_name={user.first_name} last_name={user.last_name}/>
              </DetailsPageHeader.Heading>
              <PersonEmailWrapper email={user.email}/>
            </PersonNameEmailWrapper>
            <DetailsPageHeader.Action>
              <RequireContextRight rightName={RIGHTS.editUser} objectId={user.id} contextType="users">
                <EditPersonButton userId={userId}/>
              </RequireContextRight>
            </DetailsPageHeader.Action>
          </DetailsPageHeader>
          <If condition={user.is_balance_visible}>
            <InformationDivider/>
            <RequireContextRight rightName={RIGHTS.viewUserEmployment} objectId={userId} contextType="users">
              <TimesheetBalanceToday minutes={balance.minutes}/>
              <ButtonGutter>
                <BalanceFixModal userId={userId}/>
              </ButtonGutter>
            </RequireContextRight>
          </If>

          <RequireContextRight rightName={RIGHTS.viewUser} objectId={userId} contextType={'users'}>
            <InformationDivider/>
            <CurrentWorkdayLength minutes={workdayLength.currentMinutes} startDate={workdayLength.currentDate}
                                  firstName={user.first_name}/>
            {workdayLength.hasNext() && <NextWorkdayLengthWrapper minutes={workdayLength.nextMinutes}
                                                                  date={parseISO(workdayLength.nextDate)}/>}
            <ButtonGutter>
              <UserWorkdayLengthModal userId={userId}/>
            </ButtonGutter>

          </RequireContextRight>
        </ResponsiveCol>
      </Row>

      <Row>
        <Col lg={12}>
          <SectionBlock>
            <PersonRecordsByProjectsTable userId={user.id}/>
          </SectionBlock>
        </Col>
      </Row>

    </Container>
  )
};

export default UserDetails;