import React, { useState } from "react";

import { ActuallyButton } from "../../../../components/buttons";

import { BaseDatePicker } from "../../../../components/datepicker/ActuallyDatePicker";
import { parseISO } from "../../../../util/dates";

import '../Calendar.css';


function CalendarDateSelection({onChange, disabledDates}) {
  const [selected, setSelected] = useState("");

  const handleDateSelect = () => {
    onChange(selected);
    setSelected("");
  };

  const parsedDates = disabledDates.map(parseISO);

  return (
    <div className="row align-items-center">
      <div className="col-lg-9">
        <BaseDatePicker className="w-100" value={selected} onChange={setSelected} disabledDates={parsedDates}/>
      </div>
      <div className="col-lg-3 text-right">
        <ActuallyButton variant="primary" onClick={handleDateSelect} className="btn-block"
                        disabled={!(!!selected)}>Add date</ActuallyButton>
      </div>
    </div>
  )
}

export default CalendarDateSelection;