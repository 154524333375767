import * as React from "react";
import { ActuallyTableRow, RowData } from "./components/ActuallyTableRow";
import { ActuallyTableHeader } from "./components/ActuallyTableHeader";
import Pagination, { PaginationData } from "../pagination/Pagination";
import { TableColumnConfiguration } from "./types/TableColumnConfiguration";
import styled from "styled-components";
import { ActuallyTableEmptyResultsBlock } from "./components/ActuallyTableEmptyResultsBlock";
import { StaticSoftLoading } from "../loaders/SoftLoading";
import { WithClassName } from "../../types/WithClassName";
import { LocalizedText } from "../../intl/components/LocalizedMessage";

interface ActuallyTableWrapper {
  even_cells: number;
}

const ActuallyTableWrapper = styled.table<ActuallyTableWrapper>`
  margin-bottom: 0px;
  width: 100%;
  table-layout: ${props => props.even_cells ? 'auto' : 'fixed'};
`

interface EmptyResultProps {
  paragraphs?: LocalizedText[];
  iconName?: string;
}

interface OwnPropTypes {
  tableColumns: TableColumnConfiguration[];
  tableData: Array<RowData>
  ordering?: Array<string>;
  pagination?: PaginationData;
  footer?: RowData;
  evenCells?: boolean;
  isLoading?: boolean;
  emptyResultProps?: EmptyResultProps;
  onPageChange?: (newPageNumber: number) => void;
  onOrderingChange?: (newOrdering: Array<string>) => void
}

const ActuallyTableBody = styled.tbody`
   tr:hover {
    background-color: rgba(0,0,0,.01);
   }
`;

export type ActuallyTableProps = OwnPropTypes & WithClassName;

const getTableRowKey = (index: number, page: number) => `${page}-${index}`;

export const ActuallyTable: React.FunctionComponent<ActuallyTableProps> = ({ className, tableColumns, tableData, onPageChange, pagination, footer, ordering, onOrderingChange, evenCells, isLoading, emptyResultProps }) => (
  tableData.length > 0 ?
    <StaticSoftLoading isLoading={isLoading}>
      <ActuallyTableWrapper className={className} even_cells={evenCells ? 1 : 0}>
        <thead>
          <tr>
            {tableColumns.map((column, index) =>
              <ActuallyTableHeader key={index}
                onOrderingChange={onOrderingChange}
                field={column.key}
                sortBy={column.sortBy}
                ordering={ordering}
                label={column.label}
                component={column.component}
                isSortable={column.isSortable}
                columnContentAlignment={column.columnContentAlignment}
              />)}
          </tr>
        </thead>
        <ActuallyTableBody>
          {tableData.map((row, index) => <ActuallyTableRow key={pagination ? getTableRowKey(index, pagination.current) : index} tableColumns={tableColumns} rowData={row} />)}
        </ActuallyTableBody>
        {footer &&
          <tfoot>
            <ActuallyTableRow tableColumns={tableColumns} rowData={footer} />
          </tfoot>
        }
      </ActuallyTableWrapper>
      {pagination && <Pagination onCommitPageChange={onPageChange} data={pagination} />}
    </StaticSoftLoading>
  : <ActuallyTableEmptyResultsBlock isLoading={isLoading} paragraphs={emptyResultProps?.paragraphs} iconName={emptyResultProps?.iconName} />
)
