import React from "react";
import PropTypes from 'prop-types';

import TaskEntity from './TaskEntity';

function TaskName(props) {
  const task = new TaskEntity(props);

  return (<span>{task.getName()}</span>);
}

TaskName.propTypes = {
  name: PropTypes.string,
};

export default TaskName;
