import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {selectProject} from "../../store/ducks/projects/selectors";
import {useTaskAPI} from "./project-tasks/tasks-api-hook";
import {ProjectMembershipSelect} from "./membership/ProjectMembershipSelect";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";

import {DefaultBlock, ResponsiveCol} from '../../components/layout/LayoutHelpers';
import {selectActualAndBudgetedMinutes} from "../../store/ducks/project-tasks/selectors";
import {selectProjectProgress} from "../../store/ducks/project-details/selectors";

import ProjectName from "../../components/project/ProjectName";
import useShallowEqualSelector from "../../store/helpers";
import ProjectProgressView from "./ProjectProgressView";
import ProjectPreviousMonthActuals from "./ProjectPreviousMonthActuals";
import ProjectMembersManager from "./membership/ProjectMembersManager";
import useProjectDetails from "./project-details-hook";
import EditProjectButton from "../../components/projects/edit-project/EditProjectButton";
import {ProjectProgressIcon} from "./icons/ProjectBlocksIcons";
import BlockContentWithIcon from "../../components/layout/BlockContentWithIcon";
import {ProjectDetailsCustomer, ProjectDetailsSchedule} from "./ProjectInformationBlocks";
import RequireContextRight from "../../components/rights/RequireContextRight";
import RIGHTS from "../../components/rights/right-definitions";
import InactiveIcon from "../../components/icons/InactiveIcon";
import {If} from "../../components/ui-utility/Conditional";

import {membershipSelectors} from "./membership/project-members-reducer";
import {RightAligned} from "../../components/table/ActualDataTable";
import {ButtonGutter} from "../people/user-details/UserDetails";
import {DetailsPageHeader} from "../../components/header/DetailsPageHeader";
import {TooltipOverlay} from "../../components/ui-utility/Tooltip";
import {ProjectDetailsTabs} from "./ProjectDetailsTabs";
import {LocalizedMessage} from "../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../intl/intl";


export const ProjectDetailsScreen = ({projectId}) => {
  const dispatch = useDispatch();
  const {fetchProject, fetchProjectProgress} = useProjectDetails(projectId);

  useEffect(() => {
    fetchProject();
    fetchProjectProgress();
  }, [dispatch, fetchProject, fetchProjectProgress]);

  // FIXME: any defined here to make things faster - TB-160
  const project: any = useShallowEqualSelector(selectProject(projectId));

  return <>
    {project && <ProjectDetails project={project}/>}
  </>
};

const ProjectDetails = ({project}) => {
  const {fetchTasks} = useTaskAPI(project.id);
  const budgets = useSelector(selectActualAndBudgetedMinutes(project.id));
  const progress = useSelector(selectProjectProgress(project.id));

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks, project.id]);

  const userIds = useSelector(membershipSelectors.selectUserIds);

  return (
    <Container fluid>
      <Row>
        <Col lg={6}>
          <h4>
            <ProjectDetailsCustomer project={project}/>
          </h4>
          <DetailsPageHeader>
            <DetailsPageHeader.Heading>
              <TooltipOverlay tooltipContent={(<ProjectDetailsSchedule project={project}/>)}>
                <ProjectName name={project.name} addLink={false}/>
                <If condition={!project.is_active}>
                  <InactiveIcon className="ml-1">
                    <LocalizedMessage text={{id: TRANSLATION_KEYS.projects.projectDashboard.inactiveProjectTooltipMessage}}/>
                  </InactiveIcon>
                </If>
              </TooltipOverlay>
            </DetailsPageHeader.Heading>
            <DetailsPageHeader.Action>
              <RequireContextRight rightName={RIGHTS.manageProject} objectId={project.id} contextType="projects">
                <EditProjectButton projectId={project.id}/>
              </RequireContextRight>
            </DetailsPageHeader.Action>
          </DetailsPageHeader>
        </Col>
        <Col lg={6}>
          <RightAligned>
            <ProjectMembersManager projectId={project.id}/>
            <ButtonGutter>
              <ProjectMembershipSelect projectId={project.id} members={userIds}/>
            </ButtonGutter>
          </RightAligned>
        </Col>
      </Row>

      <Row className="mt-4">
        <ResponsiveCol lg={4}>
          <DefaultBlock>
            <BlockContentWithIcon icon={<ProjectProgressIcon/>}>
              <ProjectProgressView actualMinutes={budgets.actualMinutes}
                                   budgetedMinutes={budgets.budgetedMinutes}/>
              <div className="pt-1">
                <ProjectPreviousMonthActuals minutes={progress.previous_month_actual_minutes}/>
              </div>
            </BlockContentWithIcon>
          </DefaultBlock>
        </ResponsiveCol>
      </Row>

      <ProjectDetailsTabs projectId={project.id}/>

    </Container>
  )
};

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired,
};
