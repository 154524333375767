import styled from "styled-components";
import React from "react";
import {LocalizedMessage, LocalizedText} from "../../intl/components/LocalizedMessage";

const TabbedContentWrapper = styled.div`
  padding: 1rem;
`

const TabbedContainerWrapper = styled.div`
  border-radius: .25rem;
  overflow: hidden;
  background-color: transparent;
`

const Tabs = styled.div`
  display: flex;
`

const TabsAndActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface TabProps {
  active: number;
  last_item: number;
}

const Tab = styled.div<TabProps>`
  cursor: pointer;
  padding: .5rem 1rem;
  background-color: ${props => props.active ? '#FFF' : '#ececec'};
  ${props => props.last_item ? 'border-top-right-radius: .25rem;' : ''}
`

const TabbedContainerContent = styled.div`
  border-top-right-radius: .25rem;
  background-color: #FFF;
`

export interface TabbedContainerTab {
  key: string;
  label: LocalizedText;
  contentComponent?: React.ReactNode;
}

interface OwnPropTypes {
  tabs: TabbedContainerTab[];
  currentTab: string;
  onTabChange: (tabKey: string) => void,
  actions?: React.ReactNode
}

export type TabbedContainerProps = OwnPropTypes;

export const TabbedContainer: React.FunctionComponent<TabbedContainerProps> = ({tabs, onTabChange, currentTab, children, actions}) => {
  const activeTab = tabs.find(tab => tab.key === currentTab);
  return (
    <TabbedContainerWrapper>
      <TabsAndActions>
        <Tabs>
          {tabs.map((tab, index) =>
            <Tab key={tab.key}
                 active={currentTab === tab.key ? 1 : 0}
                 onClick={() => onTabChange(tab.key)}
                 last_item={(tabs.length - 1 === index) ? 1 : 0}>
              <LocalizedMessage text={tab.label}/>
            </Tab>
          )}
        </Tabs>
        {actions && actions}
      </TabsAndActions>
      <TabbedContainerContent>
        <TabbedContentWrapper>
          {activeTab?.contentComponent ? activeTab.contentComponent : children}
        </TabbedContentWrapper>
      </TabbedContainerContent>
    </TabbedContainerWrapper>

  )
};
