import {useContext} from 'react';
import {useDispatch} from "react-redux";
import {CalendarContext} from "./edit/EditCalendarManager";

import {sendRequest, sendRequestSimple} from "../../../api/api";
import {
  receiveAddedDaysAction,
  receiveHolidayDatesAction
} from "./holiday-dates-reducers";
import {formatISO} from "../../../util/dates";

import {
  receiveCalendarsAction,
  removeCalendarAction,
} from "../../../store/ducks/calendar/calendar-reducers";
import {
  deleteCalendar,
  getCalendars,
  getHolidayRules,
  postCalendar,
  retrievePresetCalendar,
  postCalendarAndRules
} from "../../../api/calendars/calendar-api";

import GeneralApiResponseParser from "../../../api/general-api-response-parser";
import {setRequestDone, setRequestPending} from "../../../store/ducks/loading/loading-actions";


export const FETCH_PRESET_CALENDAR = 'FETCH_PRESET_CALENDAR';

// FIXME: TB-160
const useCalendarAPI = (): any => {
  const dispatch = useDispatch();

  const fetchPresetCalendar = async (isoCode) => {
    dispatch(setRequestPending(FETCH_PRESET_CALENDAR));

    const {response, error} = await sendRequestSimple(retrievePresetCalendar(isoCode));

    dispatch(setRequestDone(FETCH_PRESET_CALENDAR));

    return {response, error};
  };

  const fetchCalendars = async () => {
    const {response} = await sendRequestSimple(getCalendars());

    response && dispatch(receiveCalendarsAction(response))
  };

  const fetchHolidayRules = async calendarId => {
    const {response} = await sendRequestSimple(getHolidayRules({'holidayrule.calendar': calendarId}));

    if (response) {
      const normalized = GeneralApiResponseParser.normalizeListing(response, 'holidays', {idAttribute: 'date'});

      return {response: normalized};
    }
  };

  const createCalendar = async (data) => {
    const {response, error: errorResponse} = await sendRequestSimple(postCalendar(data));

    if (errorResponse) {
      const error = GeneralApiResponseParser.extractAllErrors(errorResponse.data);

      return {error};
    }

    return updateCalendarStore(response);
  };

  const updateCalendarAndRules = async (calendarId, payload) => {
    const {response, error} = await sendRequestSimple(postCalendarAndRules(calendarId, payload));

    updateCalendarStore(response);

    return {response, error};
  };

  const destroyCalendar = async calendarId => {
    const {response} = await sendRequest(deleteCalendar(calendarId));

    response && dispatch(removeCalendarAction(calendarId));

    return {response};
  };

  const updateCalendarStore = response => {
    response && dispatch(receiveCalendarsAction(response));

    return {response};
  };

  return {
    fetchPresetCalendar,
    fetchCalendars,
    fetchHolidayRules,

    createCalendar,
    destroyCalendar,

    updateCalendarAndRules,
  }
};


const useCalendarManagement = () => {
  const {updateCalendarAndRules} = useCalendarAPI();

  // FIXME: TB-160
  const {state, dispatch} = useContext<any>(CalendarContext);

  const selectHolidayDates = () => state.holidayDates;

  const selectPresetDates = () => state.presets;

  const selectHolidays = () => state.holidays;

  const selectHolidayDate = date => selectHolidays()[date];

  const selectIsRemoved = date => state.removed.includes(date);

  const selectIsAdded = date => state.added.includes(date);

  const selectDescription = date => {
    const preset = selectPresetDates()[date] || {};

    return preset.description;
  };

  const tempAddPresetDates = year => {
    const presetDatesForYear = state.presetsPerYear[year] || [];

    addNewDatesIntoState(presetDatesForYear);
  };

  const tempAddManualDate = date => {
    const newDates = [formatISO(date)];

    addNewDatesIntoState(newDates);
  };

  const addNewDatesIntoState = (dates) => {
    const intersectionDates = dates.filter(date => !state.holidayDates.includes(date));
    const intersectionDateObjects = intersectionDates.map(date => state.presets[date] || {date});

    const normalized = GeneralApiResponseParser.normalizeListing(intersectionDateObjects, 'holidays', {idAttribute: 'date'});

    dispatch(receiveHolidayDatesAction(normalized));
    dispatch(receiveAddedDaysAction(intersectionDates));
  };

  const commitCalendarChanges = async (calendarId, calendar) => {
    const payload = {calendar, holidays: {added: state.added, removed: state.removed}};

    return await updateCalendarAndRules(calendarId, payload);
  };

  return {
    selectHolidayDates,
    selectHolidayDate,
    selectIsRemoved,
    selectDescription,
    selectIsAdded,

    tempAddPresetDates,
    tempAddManualDate,
    commitCalendarChanges,

    dispatch
  }
};

export {
  useCalendarAPI,
  useCalendarManagement
}