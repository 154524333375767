import React, {useEffect, useCallback} from "react";

import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {DefaultBlock, ResponsiveCol} from "../../../components/layout/LayoutHelpers";
import {useAuthGroupAPI} from "./auth-group-hooks";

import PageHeader from "../../../components/text/PageHeader";
import RequireGlobalRight from "../../../components/rights/RequireGlobalRight";
import AddAuthGroupButton from "./add/AddAuthGroupButton";
import AuthGroupListing from "./list/AuthGroupListing";

import RIGHTS from "../../../components/rights/right-definitions";
import {RouteComponentProps} from "@reach/router";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";

type AuthorizationGroupProps = RouteComponentProps;

export const AuthorizationGroup: React.FunctionComponent<AuthorizationGroupProps> = () => {
  const {fetchAuthGroups} = useCallback(useAuthGroupAPI(), []);

  useEffect(() => {
    fetchAuthGroups();
  }, [fetchAuthGroups]);

  return (
    <Container>
      <Row>
        <ResponsiveCol>
          <PageHeader className="align-middle">
            <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.authorizationGroupManagement.pageHeader}}/>
          </PageHeader>
        </ResponsiveCol>
        <ResponsiveCol className="text-right">
          <RequireGlobalRight rightName={RIGHTS.manageAuthorizationRights}>
            <AddAuthGroupButton/>
          </RequireGlobalRight>
        </ResponsiveCol>
      </Row>
      <Row>
        <ResponsiveCol>
          <DefaultBlock>
            <AuthGroupListing/>
          </DefaultBlock>
        </ResponsiveCol>
      </Row>
    </Container>
  );
}