import React from 'react';

import { useModal } from '../../../../components/modal/modal-hooks';
import { EditAuthGroupForm } from "../add/AddAuthGroupForm";

import modalDefinitions from '../../../../components/modal/modal-definitions';

import SimpleModal from '../../../../components/modal/SimpleModal';
import ModalFrame from "../../../../components/modal/ModalFrame";
import {ActionColumn} from "../../calendar/edit/EditCalendarModal";
import {LocalizedMessage} from "../../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../../intl/intl";


function EditAuthGroupModal({authGroup}) {
  const [openModal, closeModal, name] = useModal(modalDefinitions.EDIT_AUTH_GROUP, authGroup.id);

  return (
    <>
      <ActionColumn onClick={openModal}>{authGroup.name}</ActionColumn>

      <SimpleModal name={name} onClose={closeModal}>
        <ModalFrame heading={
          <LocalizedMessage text={{id: TRANSLATION_KEYS.authorizationGroupSettings.addAuthGroupModal.editPageHeader}}/>
        }>
          <EditAuthGroupForm onDone={closeModal} authGroup={authGroup}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default EditAuthGroupModal;
