import React from "react";
import {Link} from "@reach/router";
import {constructModalUrl} from "../../../../../components/modal/modal-hooks";
import modalDefinitions from "../../../../../components/modal/modal-definitions";

function AppoverCycleLink({cycleId, children}) {
  const url = constructModalUrl(modalDefinitions.APPROVE_CYCLE, {"modal.cycleId": cycleId});

  return (
      <Link to={url}>{children}</Link>
  );
}


export default AppoverCycleLink;
