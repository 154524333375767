import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

export interface FormElement {
  input: React.ReactNode,
  label: React.ReactNode,
  width?: number,
  isHidden?: boolean,
  inline?: boolean;
}

interface SimpleFormProps {
  formElements: Array<FormElement>
}

const FormCol = styled(Col)<{ inline: number }>`
  margin: 0.5em 0em;
  ${props => props.inline && (
  `display: flex;
    justify-content: space-between;
  `)}
`;

export const SimpleFormBuilder: React.FC<SimpleFormProps> = ({formElements}) => {
  return (
    <Row>
      {
        formElements.map((element, index) => element.isHidden ? null :
          (
            <FormCol lg={element.width || 12} key={index} inline={element.inline ? 1 : 0}>
              <label className="label-default">{element.label}</label>
              <div>
                {element.input}
              </div>
            </FormCol>
          ))}
    </Row>
  )
}
