import React from 'react';
import { useSelector } from "react-redux";

import {useCycleDateCollisionChecker, useTimetrackingAllowed} from "../../../timesheet/record-hooks";
import { TimetrackingNotAllowed } from "../../record-creation-flow/RecordFlowSelectTask";
import { selectRecordById } from "../../../../store/ducks/timesheet/selectors";
import { QuoteBlock } from "../../../../components/text/DescriptionTxt";
import { If } from "../../../../components/ui-utility/Conditional";

import TimesheetPinColumn from "../../../timesheet/components/TimesheetPinColumn";
import ProjectName from "../../../../components/project/ProjectName";
import TaskName from "../../../../components/task/TaskName";
import TimeValue from "../../../../components/TimeValue";
import ActuallyHr from "../../../../components/ui-utility/ActuallyHr";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function MobileTimesheetRecordDetails({task, project, record, showPin}) {
  const timeTrackingAllowed = useTimetrackingAllowed(task, project);
  const isDateLocked = useCycleDateCollisionChecker(record.date);

  const timetrackingDisabled = !timeTrackingAllowed || isDateLocked;

  return (
    <div className="mobile-record-row-container">
      <div className="mobile-record-pin pr-3">
        <If condition={showPin}>
          <TimesheetPinColumn task={task}/>
        </If>
      </div>
      <div className="mobile-record-task">
        <If condition={timetrackingDisabled}>
          <TimetrackingNotAllowed/>
        </If>
        <ProjectName name={project.name} addLink={false} className="bold"/>
        <span>&nbsp;</span>
        <TaskName name={task.name}/>
      </div>
      <div className="mobile-record-minutes">
        <small>
          <TimeValue minutes={record.minutes} showZero={!!record.id} className="time-value shorten-text"/>
        </small>
      </div>
    </div>
  )
}

function MobileTimesheetViewRow({task, project, recordId=undefined, onClick}) {
  const record = useSelector(selectRecordById(recordId)) || {description: '', minutes: 0};

  const timeTrackingAllowed = useTimetrackingAllowed(task, project);

  const isDateLocked = useCycleDateCollisionChecker(record.date);
  const timetrackingDisabled = !timeTrackingAllowed || isDateLocked;

  const handleOnClick = () => {
    !timetrackingDisabled && onClick(record);
  };

  return (
    <Row onClick={handleOnClick}>
      <Col xs={12}>
        <MobileTimesheetRecordDetails task={task} project={project} record={record} showPin={true}/>
      </Col>

      <If condition={record.description}>
        <Col xs={12}>
          <ActuallyHr/>
        </Col>
        <Col xs={12}>
          <QuoteBlock text={record.description}/>
        </Col>
      </If>
    </Row>
  )
}

export {
  MobileTimesheetViewRow as default,
  MobileTimesheetRecordDetails
};