import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { sendRequestSimple } from "../../api/api";

import {
  getGlobalRights,
  getProjectObjectRights,
  getUserObjectRights
} from "../../api/authorization/rights/auth-rights-api";

import {
  fetchUserRightsAction,
  fetchGlobalRightsAction,
  fetchProjectRightsAction
} from "../../store/ducks/rights/rights-reducers";

import { selectCurrentUser } from "../../store/ducks/auth/selectors";
import rightSelectors from "../../store/ducks/rights/rights-selectors";

const useRightsAPI = () => {
  const dispatch = useDispatch();

  const fetchUserObjectRights = useCallback(async userId => {
    const {response: rights} = await sendRequestSimple(getUserObjectRights(userId));

    if (rights) {
      dispatch(fetchUserRightsAction({userId, rights}));
    }
  }, [dispatch]);

  const fetchProjectObjectRights = useCallback(async projectId => {
    const {response: rights} = await sendRequestSimple(getProjectObjectRights(projectId));

    if (rights) {
      dispatch(fetchProjectRightsAction({projectId, rights}));
    }
  }, [dispatch]);

  const fetchGlobalRights = useCallback(async userId => {
    const {response: rights} = await sendRequestSimple(getGlobalRights(userId));

    if (rights) {
      dispatch(fetchGlobalRightsAction({userId, rights}));
    }
  }, [dispatch]);

  return {
    fetchUserObjectRights,
    fetchProjectObjectRights,
    fetchGlobalRights,
  }
};

const useGlobalRightFetching = () => {
  const currentUser = useSelector(selectCurrentUser);
  const {fetchGlobalRights} = useRightsAPI();

  useEffect(() => {
    currentUser.id && fetchGlobalRights(currentUser.id);
  }, [currentUser.id, fetchGlobalRights]);
};

const useContextRights = contextType => {
  const {fetchProjectObjectRights, fetchUserObjectRights} = useRightsAPI();

  const fetchObjectRights = useCallback(async (objectId) => {
    if (contextType === 'projects')
      return await fetchProjectObjectRights(objectId);
    return await fetchUserObjectRights(objectId);
  }, [fetchUserObjectRights, fetchProjectObjectRights, contextType]);

  const selectObjectRightsById = useCallback(objectId => {
    if (contextType === 'projects')
      return rightSelectors.selectProjectRightsById(objectId);
    return rightSelectors.selectUserRightsById(objectId);
  }, [contextType]);

  return {
    fetchObjectRights,
    selectObjectRightsById
  }
};


export {
  useRightsAPI,
  useGlobalRightFetching,
  useContextRights
};