import {defaultLoadingElement} from "./loading-reducers";

const loadingSelectors = Object.freeze({
  selectLoading: state => state.loading,
  selectLoadingForRequest: actionName => state => {
    const loadingState = loadingSelectors.selectLoading(state);

    return loadingState[actionName] || defaultLoadingElement;
  }
});

export default loadingSelectors;