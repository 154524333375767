import * as React from "react";
import styled from "styled-components";
import { TableColumnConfiguration } from "../types/TableColumnConfiguration";
import { TableColumnWrapper } from "./table-util-components";

const TableRow = styled.tr`
    border-top: 1px solid #F0F4F8;
`

const ActuallyTableColumnWrapper = styled(TableColumnWrapper)`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export interface RowData {
    [key: string]: string | React.ReactElement;
}

interface OwnPropTypes {
    tableColumns: Array<TableColumnConfiguration>;
    rowData: RowData;
}

export type ActuallyTableRowProps = OwnPropTypes;

export const ActuallyTableRow: React.FunctionComponent<ActuallyTableRowProps> = ({ rowData, tableColumns }) => {
    return (
        <TableRow>
            {tableColumns.map((column, index) => <td key={index} >
                <ActuallyTableColumnWrapper alignment={column.columnContentAlignment}>
                    {rowData[column.key]}
                </ActuallyTableColumnWrapper>
                </td>
                )}
        </TableRow>

    )
}