import * as React from 'react';
import { FormattedMessage, MessageDescriptor, IntlShape } from 'react-intl';
import { ActuallyIntlContext } from './IntlProviderWrapper';

interface LocalizedMessageDescriptor extends Omit<MessageDescriptor, 'id'>{
    id: string; 
    values?: object;
}

// NOTE: The better way would be to add different interface for already localized texts 
// because it makes the things less error prone, but for now string is fine, since it makes things a bit easier. 
// We can change it afterwards if we see a need for it. 
export type LocalizedText = LocalizedMessageDescriptor | string;

interface OwnPropTypes {
    text: LocalizedText;
}

export type LocalizedMessageProps = OwnPropTypes; 

export const LocalizedMessage: React.FunctionComponent<LocalizedMessageProps> = ({text}) => {
  const intlConfig = React.useContext(ActuallyIntlContext)

  if (typeof text === 'string' || intlConfig.showKeys) {
    return typeof text === 'string' ? <>{text}</> : <>{text.id}</>
  }
  
  return <FormattedMessage {...text} />
}

export const localizeMessage = (localizedMessage: LocalizedText, intl: IntlShape, options?: {showKeys?: boolean}) => {
  if (typeof localizedMessage === 'string' || (options?.showKeys && localizedMessage.id)) {
    return typeof localizedMessage === 'string' ? localizedMessage : localizedMessage.id 
  }

  const values = localizedMessage.values;

  return intl.formatMessage(localizedMessage, values as any);
}