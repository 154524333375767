import React from "react";
import {useModal} from "../../../components/modal/modal-hooks";
import modalDefinitions from "../../../components/modal/modal-definitions";
import SimpleModal from "../../../components/modal/SimpleModal";
import {ActuallyButton, PlusIcon} from "../../../components/buttons";
import {LocalizedMessage} from "../../../intl/components/LocalizedMessage";
import {TRANSLATION_KEYS} from "../../../intl/intl";
import {AddTask} from "./AddTask";
import ModalFrame from "../../../components/modal/ModalFrame";

function AddTasksModal({projectId, onClose}) {
  const [openModal] = useModal(modalDefinitions.PROJECT_MANAGE_TASKS);

  return (
    <>
      <ActuallyButton variant="primary" onClick={openModal} leftIcon={<PlusIcon/>}>
        <LocalizedMessage
          text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.addModalHeader}}/>
      </ActuallyButton>

      <SimpleModal name={modalDefinitions.PROJECT_MANAGE_TASKS} onClose={onClose}>
        <ModalFrame heading={<LocalizedMessage
          text={{id: TRANSLATION_KEYS.projects.projectDashboard.projectTasksModal.addModalHeader}}/>}>
          <AddTask projectId={projectId} onDone={onClose}/>
        </ModalFrame>
      </SimpleModal>
    </>
  )
}

export default AddTasksModal;
