import React from 'react';

import './vendor';
import './App.css';

import './styles/styles_index';

import { Router } from "@reach/router";
import PrivateRoute from "./auth/PrivateRoute";
import { Home } from "./screens/Home";
import { People } from "./screens/people/People";
import { MobileTimesheetFlow } from "./screens/mobile/timesheet/MobileTimesheet";
import { OverallReport } from "./screens/reports/overall/OverallReport";
import { Reports } from "./screens/reports";
import { ProjectDetailsScreen } from "./screens/projects/ProjectDetails";
import { Timesheet } from "./screens/timesheet/weekly/Timesheet";
import UserDetails from "./screens/people/user-details/UserDetails";
import PasswordReset from "./screens/authentication/password-reset/PasswordReset";
import UnAuthenticatedRoute from "./auth/UnAuthenticatedRoute";
import Notification from "./components/notication/Notification";
import { Profile } from "./screens/profile/Profile";
import { TimesheetDaily } from "./screens/timesheet/daily/TimesheetDaily";
import { Authorization } from "./screens/administration/authorization/Authorization";
import { Projects } from "./screens/project-listing/Projects";
import { RestrictAccess } from "./components/rights/RestrictAccess";
import { BalanceFix } from "./screens/administration/balance-fix/BalanceFix";
import RIGHTS from "./components/rights/right-definitions";
import SignUp from "./screens/authentication/SignUp";
import { Calendar } from "./screens/administration/calendar/Calendar";
import { AuthorizationGroup } from "./screens/administration/authorization-group/AuthorizationGroup";
import { ApprovalScreen } from "./screens/timesheet/approval/approval-listing/ApprovalScreen";
import { CycleTemplate } from "./screens/administration/cycle-template/CycleTemplate";
import { IntlProviderWrapper } from './intl/components/IntlProviderWrapper';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import { Upgrade } from "./screens/checkout/Checkout";
import { Customers } from "./screens/customers/Customers";
import { TenantList } from "./screens/tenant-admin/TenantList";
import { TenantDetailsContainer } from "./screens/tenant-admin/TenantDetailsContainer";
import { AdminDashboard, AdminSection } from "./AdminSection";


// Let's not listen for resize event... otherwise
// we should check if there are unsaved changes
// before reloading a new screen.
// TODO: Use a lib for this?
const isMobile = window.innerWidth <= 500;


function DesktopApp() {
  return (
    <>
      <Notification/>
      <Router primary={false}>
        <UnAuthenticatedRoute path="reset/:uib/:token/" component={PasswordReset}/>

        <AdminSection path="admin">
          <AdminDashboard path="/" />
          <TenantList path="tenants"/>
          <TenantDetailsContainer path="tenants/:tenantId/"/>
        </AdminSection>

        <PrivateRoute path="/">
          <Home path="home"/>
          <Authorization path="settings/authorization"/>
          <AuthorizationGroup path="settings/authorization-group"/>
          <BalanceFix path="balance-fixes"/>
          <Calendar path="settings/calendars"/>
          <CycleTemplate path="settings/cycle-templates"/>

          <Profile path="profile"/>

          <Timesheet path="timesheet"/>
          <Timesheet path="/"/>
          <TimesheetDaily path="timesheet/daily"/>

          <ApprovalScreen path="approval"/>

          <Upgrade path="upgrade"/>

          <Reports path="reports">
            <OverallReport path="overall"/>
          </Reports>
          <People path="people"/>
          <RestrictAccess path="people/:objectId" rightName={RIGHTS.viewUser} contextType="users"
                          componentFn={userId => <UserDetails userId={userId}/>}/>
          <Projects path="projects"/>
          <Customers path="customers"/>

          <RestrictAccess path="projects/:objectId" rightName={RIGHTS.viewProject} contextType="projects"
                          componentFn={projectId => <ProjectDetailsScreen projectId={projectId}/>}/>
        </PrivateRoute>
      </Router>
    </>

  );
}

function MobileApp() {
  return (
    <Router>
      <UnAuthenticatedRoute path="reset/:uib/:token/" component={PasswordReset}/>
      <PrivateRoute isMobile={true} path="/">
        <MobileTimesheetFlow path="*/timesheet"/>
        <MobileTimesheetFlow path="/timesheet/:date/"/>
      </PrivateRoute>
    </Router>
  )
}


function App() {
  return (
    <IntlProviderWrapper>
      <ThemeProvider theme={theme}>
        <div className="timesheet-app">
          {
            isMobile ?
              <MobileApp/> :
              <DesktopApp/>
          }
        </div>
      </ThemeProvider>
    </IntlProviderWrapper>
  );
}

export default App;
