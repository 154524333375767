import { TableColumnContentAlignment, tableColumnContentAlignmentMapToFlex } from '../types/TableColumnContentAlignment';
import styled from 'styled-components';

export interface TableColumnWrapperProps {
    alignment?: TableColumnContentAlignment;
}

export const TableColumnWrapper = styled.div<TableColumnWrapperProps>`
    display: flex;
    justify-content: ${props => props.alignment ? tableColumnContentAlignmentMapToFlex[props.alignment] : tableColumnContentAlignmentMapToFlex[TableColumnContentAlignment.Left]};
    padding: .50rem .25rem;
`