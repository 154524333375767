import peopleSagaTypes from "./types";
import * as types from "../auth/types";
import {
  mergeObjectIntoEntities,
  mergeObjectIntoResult,
  mergeObjectMapIntoEntities,
  removeObjectFromEntities, removeObjectFromResult
} from "../../helpers";

export const initialState = {
  entities: {users: {}},
  result: [],
  snapshot: {},
  // TODO: Would it be better to just create a separate reducer? Maybe a sub reducer for people reducer...?
  employment: {},
};


function peopleResult(state = [], action) {
  const {result} = action.payload;

  return [...result];
}


function peopleReducer(state = initialState, action) {
  switch (action.type) {

    case peopleSagaTypes.RECEIVE_PEOPLE: {
      const entities = mergeObjectMapIntoEntities(state, action.payload.entities, 'users');

      return {
        ...state,
        entities,
        result: peopleResult(state.result, action)
      };
    }
    case peopleSagaTypes.RECEIVE_PEOPLE_SNAPSHOT:
      return {
        ...state,
        snapshot: {
          ...action.payload
        }
      };

    case types.GET_CURRENT_USER_SUCCESSFUL:
    case types.AUTHENTICATION_SUCCESSFUL:
    case types.CHANGE_TENANT_SUCCESS:
    case peopleSagaTypes.FETCH_USER_DETAILS_SUCCESS:
      const entities = mergeObjectIntoEntities(state, action.payload, 'users');
      const result = mergeObjectIntoResult(state, action.payload);

      return {
        ...state,
        entities,
        result
      };

    case peopleSagaTypes.FETCH_USER_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        employment: {
          ...state.employment,
          [action.payload.user]: action.payload,
        }
      };

    default:
      return state;

    case peopleSagaTypes.REMOVE_USER: {
      const entities = removeObjectFromEntities(state, action.userId, 'users');
      const result = removeObjectFromResult(state.result, action.userId);

      return {
        ...state,
        entities,
        result
      };
    }
  }
}


export default peopleReducer;