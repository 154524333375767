import {sendRequestSimple} from "../../api/api";
import {
  postCheckout,
  getCheckoutDetails,
  getBillingPortal
} from "../../api/checkout/checkout-api";
import {CardElement, useElements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import {useCallback, useEffect, useState} from "react";

export const useCheckoutAPI = () => {

  const createCheckout = async (params) => {
    return await sendRequestSimple(postCheckout(params));
  }

  const fetchCheckoutDetails = useCallback(async () => {
    return await sendRequestSimple(getCheckoutDetails());
  }, []);

  const fetchBillingPortal = useCallback(async () => {
    return await sendRequestSimple(getBillingPortal());
  }, []);

  return {
    createCheckout,
    fetchCheckoutDetails,
    fetchBillingPortal
  }
};

export const useCheckoutFlow = (stripe) => {
  const elements: any = useElements();

  const {createCheckout} = useCheckoutAPI();

  const errorDueToServer = "Server error, please try again later. If this problem still persist contact us at support@getactually.com";
  const unknownError = "Unknown error, please refresh page and try again later";
  const cardError = "Invalid card, please try a different card. If this problem still persist contact us at support@getactually.com";
  const stripeDownError = "We are unable to connect the payment provider. Please try again later. If this problem still persist contact us at support@getactually.com";

  const createPaymentMethod = async () => {
    return await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  }

  const processCheckoutCall = async (payload) => {
    const {error, paymentMethod}: any = await createPaymentMethod();

    if (error)
      throw {reason: error.message || stripeDownError};

    return createCheckout({payment_method: paymentMethod.id, ...payload})
      .then(({response, error}) => {
        if (!response || error) {
          const errorMsg = error?.data?.general;

          throw {reason: errorMsg || errorDueToServer};
        }
        return {
          paymentMethodId: paymentMethod.id,
          subscription: response.subscription,
          invoice: response.invoice,
        };
      })
      .then(handlePaymentThatRequiresCustomerAction);
  }

  const handlePaymentThatRequiresCustomerAction = ({paymentMethodId, subscription, invoice}) => {
    if (subscription && subscription.status === 'active') {
      return {paymentMethodId, subscription}
    }

    if (subscription.status === 'trialing') {
      return handlePendingSetupIntent({paymentMethodId, subscription});
    }

    const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice?.payment_intent;

    if (!paymentIntent)
      throw {reason: unknownError};

    const requiresAction = paymentIntent.status === 'requires_action';
    const requiresPaymentMethod = paymentIntent.status === 'requires_payment_method';

    if (requiresAction || requiresPaymentMethod) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            throw {reason: result.error.message};
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return {
                subscription,
                invoice,
                paymentMethodId,
              };
            } else {
              throw {reason: unknownError};
            }
          }
        })
    } else {
      throw {reason: unknownError};
    }
  }

  const handlePendingSetupIntent = ({paymentMethodId, subscription}) => {
    const {pending_setup_intent} = subscription;

    if (!pending_setup_intent?.id) {
      return {paymentMethodId, subscription}
    }

    const {client_secret, status, payment_method} = subscription.pending_setup_intent;

    const requiresAction = status === 'requires_action';
    const requiresPaymentMethod = status === 'requires_payment_method';

    if (requiresAction) {
      return stripe
        .confirmCardSetup(client_secret, {
          payment_method: payment_method
        })
        .then(function (result) {
          if (result.error) {
            throw {reason: result.error.message};
          } else {
            if (result.setupIntent.status === 'succeeded') {
              return {
                subscription,
                paymentMethodId,
              };
            } else {
              throw {reason: unknownError};
            }
          }
        });
    } else if (requiresPaymentMethod) {
      throw {reason: cardError};
    } else {
      throw {reason: unknownError};
    }
  }

  return {
    processCheckoutCall
  }

}

export const stripeLoader = async () => {
  const stripePk = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  if (!stripePk)
    return null;

  return loadStripe(stripePk);
}

export const useCheckoutDetailsFetching = () => {
  const [checkoutDetails, setCheckoutDetails] = useState<any>({});

  const {fetchCheckoutDetails} = useCheckoutAPI();

  useEffect(() => {
    const doFetching = async () => {
      const {response} = await fetchCheckoutDetails();

      response && setCheckoutDetails(response);
    }

    doFetching();
  }, [fetchCheckoutDetails]);

  return checkoutDetails;
}
