import React from "react";
import {useSelector} from "react-redux";
import cycleSelectors from "../../../../../store/ducks/cycle/cycle-selectors";

import {DateRange} from "../../../../../components/project/ProjectSchedule";

import AppoverCycleLink from "./ApproverCycleLink";
import CycleStatusBadge from "./CycleStatusBadge";


function CycleRow({cycleId, approverId}) {
  const cycle = useSelector(cycleSelectors.selectCycleForId(cycleId));

  const cyclesCompletedByApprover = useSelector(cycleSelectors.selectCyclesCompletedByApproversForApprover(approverId));

  const cycleCompleted = cyclesCompletedByApprover[cycleId];

  return (
      <tr>
        <td>
          <AppoverCycleLink cycleId={cycle.id}>
            <DateRange startDate={cycle.start_date} endDate={cycle.end_date}/>
          </AppoverCycleLink>
        </td>
        <td>
          <CycleStatusBadge requiresAttention={!cycleCompleted}/>
        </td>
      </tr>
  )
}

export default CycleRow;
