export enum TableColumnContentAlignment {
    Right = 'right',
    Left = 'left',
    Center = 'center'
}

// This is not optimal, but this way we can use the the same prop to justify-content in 
// components displayed as flex
export const tableColumnContentAlignmentMapToFlex = {
    [TableColumnContentAlignment.Right]: 'flex-end',
    [TableColumnContentAlignment.Left]: 'flex-start',
    [TableColumnContentAlignment.Center]: 'center',
}