import {TableColumnConfiguration} from "../../../components/table/types/TableColumnConfiguration";
import {TRANSLATION_KEYS} from "../../../intl/intl";

export const getProjectListingColumnConfiguration: TableColumnConfiguration[] = [{
  key: 'project',
  label: {id: TRANSLATION_KEYS.projects.projectsTableHeaders.name},
  isSortable: true,
  sortBy: 'name'
},
  {
    key: 'customer',
    label: {id: TRANSLATION_KEYS.projects.projectsTableHeaders.customer},
    isSortable: true,
    sortBy: 'customer__name',
  }, {
    key: 'startDate',
    label: {id: TRANSLATION_KEYS.projects.projectsTableHeaders.startDate},
    isSortable: true,
    sortBy: 'start_date'
  }, {
    key: 'endDate',
    label: {id: TRANSLATION_KEYS.projects.projectsTableHeaders.endDate},
    isSortable: true,
    sortBy: 'end_date'
  }]

export const projectListingEmptyResultParagraphs = [
  {id: TRANSLATION_KEYS.projects.noMatchingProjectsFoundMessageHeader},
  {id: TRANSLATION_KEYS.projects.noMatchingProjectsFoundMessageBody},
]
