import React, {useEffect} from "react";
import Login from "../screens/authentication/Login";
import TenantSelection from "../screens/authentication/tenant-selection/TenantSelection";
import {useSelector} from "react-redux";
import {selectAuthenticationState} from "../store/ducks/auth/selectors";
import Content from "../AppContent";
import Header from "../components/header/AppHeader";
import {useGlobalRightFetching} from "../components/rights/right-hooks";
import TopBar from "../components/header/TopNavigation";
import {TrialingBannerSelector} from "../components/header/TrialingBanner";
import {Upgrade} from "../screens/checkout/Checkout";
import {useBooleanLocalStorage} from "../util/common-hooks/use-local-storage";
import {LOCAL_STORAGE_ATTRIBUTES} from "../util/local-storage-attributes";
import {useSystemSettings} from "../api/systemsettings/systemsetting-hooks";

const LoggedInPage = ({children}) => {
  const {fetchBootstrapConfiguration} = useSystemSettings();

  useGlobalRightFetching();

  useEffect(() => {
    fetchBootstrapConfiguration();
  }, [fetchBootstrapConfiguration]);

  const [isCollapsed, setIsCollapsed] = useBooleanLocalStorage(LOCAL_STORAGE_ATTRIBUTES.SIDEBAR_TOGGLE, false);

  return (
    <>
      <TrialingBannerSelector/>
      <TopBar isCollapsed={isCollapsed} toggleCollapsed={() => setIsCollapsed(!isCollapsed)}/>

      <div className="d-flex">
        <Header isCollapsed={isCollapsed}/>

        <Content>
          {children}
        </Content>
      </div>
    </>
  )
};

const LoggedInMobilePage = ({children}) => {
  return (
    <div>
      {children}
    </div>
  )
};

function PrivateRoute({isMobile = false, ...props}) {
  const authState = useSelector(selectAuthenticationState);
  const Wrapper = !isMobile ? LoggedInPage : LoggedInMobilePage;

  if (authState.isLoading) {
    return <></>;
  } else if (authState.systemUserId && !authState.userId) {
    return <>
      <TopBar isCollapsed={false} toggleCollapsed={() => null}/>
      <TenantSelection/>
    </>
  } else if (authState.checkoutRequired) {
    return <Wrapper><Upgrade/></Wrapper>
  } else if (authState.userId) {
    return <Wrapper>{props.children}</Wrapper>
  } else {
    return <Login/>
  }
}


export default PrivateRoute;