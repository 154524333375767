import React from 'react';

import classNames from 'classnames';

import './tables.css';

function PlainTable({children, className='', showLines=true, evenCells=false, hoverable=false, alignToSides=false, isResponsive=true}) {
  const classes = classNames(
    'table table-borderless',
    'plain-table',
    showLines ? 'table-lines' : '',
    evenCells ? 'table-even-cells' : '',
    hoverable ? 'hoverable': '',
    alignToSides ? 'table-side-aligned': '',
    className,
  );

  const wrapperClasses = classNames(
    isResponsive && 'table-responsive'
  );

  return (
    <div className={wrapperClasses}>
      <table className={classes}>
        {children}
      </table>
    </div>
  )
}

export default PlainTable;
