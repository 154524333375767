import {createGetRequest} from "../api";

const baseUrl = 'country';

function getCountries() {
  return createGetRequest({url: baseUrl})
}

export {
  getCountries
}